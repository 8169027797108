import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/EditorNodes'
import EditorMarks from './marks/EditorMarks'


//https://discuss.prosemirror.net/t/nodes-append-is-not-a-function/2216/2
const editorSchema = new Schema({
  nodes:EditorNodes,
  marks: EditorMarks
})


// const editorSchema = new Schema({
//   nodes:addListNodes(mySchema.spec.nodes,"paragraph block*", "block"),
//   marks: EditorMarks
// })

export default editorSchema