import React, {useState, useEffect} from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../misc/Icon';
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'
import { useTransition, animated, config } from 'react-spring';


function EditorVariantStringArea({text}) {
  const [tooltips, setTooltips] = useState([]);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
      
      // Add a new tooltip
      const newTooltip = {
        id: Date.now(),
        text: 'Copied'
      };
      setTooltips(prevTooltips => [...prevTooltips, newTooltip]);

      // Remove the tooltip after animation completes
      setTimeout(() => {
        setTooltips(prevTooltips => prevTooltips.filter(t => t.id !== newTooltip.id));
      }, 600); // Reduced from 5000 to 2000ms

    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const transitions = useTransition(tooltips, {
    from: { opacity: 0, transform: 'translateY(-2px) scale(0.8)' },
    enter: { opacity: 1, transform: 'translateY(-4px) scale(1)' },
    leave: { opacity: 0, transform: 'translateY(-12px) scale(1)' },
    config: (item, index, phase) => 
      phase === 'enter' 
        ? { tension: 600, friction: 18 } // Very fast enter
        : { tension: 500, friction: 24, clamp: true  }, // Smooth exit
    trail: 50 // Reduced from 100 to 50ms
  });

  return (
    <div className='variantPopover-formulaArea'>
      <div className='variantPopover-formulaArea-formula'>
        {text}
      </div>

      <button 
        className='variantPopover-formulaArea-copyToClipboardBtn'
        onClick={handleCopyToClipboard}
      >
        {transitions((style, item) => (
          <animated.div 
            style={style} 
            className='variantPopover-formulaArea-copyToClipboardBtn-tooltipContainer'
          >
            <div className='variantPopover-formulaArea-copyToClipboardBtn-tooltip'>
              {item.text}
            </div>
          </animated.div>
        ))}

        Copy to Clipboard
      </button>
    </div>
  );
}

function EditorVariantPopoverVariableRow({variableName, variableType}) {
  

  return (
    <div className='variantPopover-variableRow'>
      <div className='variantPopover-variableRow-checkmarkContainer'>
        <Icon name='toDoItemCheckmark' />
      </div>
      <div className='variantPopover-variableRow-variableName'>
        {`{{${variableName}}}`}
      </div>
      {variableType && 
        <div className='variantPopover-variableRow-variableType'>
          {variableType}
        </div>
      }
    </div>
  );
}




function EditorVariantPopover({ children,projectVariables,refreshProjectVariables,projectId ,projectName}) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const isGif = false

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  
  const orgName = getOrgNameLabel()

  const generateBaseUrl = (orgName, projectName, projectId) => {
    const formattedProjectName = projectName.replace(/\s+/g, '-');
    let baseUrl = `https://gen.yarn.so/${orgName}/${formattedProjectName}-${projectId}`
    if(isGif){
      baseUrl = `https://gen.yarn.so/gif/${orgName}/${formattedProjectName}-${projectId}`
    }
    return baseUrl;
};

const generateUrl = () => {
    const baseUrl = generateBaseUrl(orgName, projectName, projectId);
    const queryParams = projectVariables
      .map(variable => `${encodeURIComponent(variable)}={{${encodeURIComponent(variable.toUpperCase())}}}`)
      .join('&');
    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
  };


const generateGoogleSpreadsheetFormula = () => {
  const baseUrl = generateBaseUrl(orgName, projectName, projectId);
  
  // Start with the base formula
  let formula = `=CONCATENATE("${baseUrl}`;
  
  // If there are variables, add the question mark for query parameters
  if (projectVariables.length > 0) {
    formula += '?",';
  } else {
    formula += '")';
  }

  // Add each variable to the formula
  projectVariables.forEach((variable, index) => {
    // Add '&' before each variable except the first one
    if (index > 0) {
      formula += ',"&",';
    }
    
    // Add the variable name and its placeholder
    formula += `"${encodeURIComponent(variable)}=", ENCODEURL(${String.fromCharCode(65 + index)}2)`;
    
    // Close the CONCATENATE function if it's the last variable
    if (index === projectVariables.length - 1) {
      formula += ')';
    }
  });

  return formula;
};

const generateClayFormula = () => {
  const baseUrl = generateBaseUrl(orgName, projectName, projectId);
  
  let formula = `"${baseUrl}`;
  
  // Add each variable to the formula
  projectVariables.forEach((variable, index) => {
    // Add '?' for the first variable, '&' for subsequent ones
    if (index === 0) {
      formula += '?';
    } else {
      formula += '&';
    }
    
    // Add the variable name and its placeholder
    formula += `${encodeURIComponent(variable)}=" + {{${variable}}}`;
    
    // Add '+' if it's not the last variable
    if (index < projectVariables.length - 1) {
      formula += ' + "';
    }
  });

  // Close the string if there were variables
  if (projectVariables.length > 0) {
    formula += '';
  }

  return formula;
};

const handleOpenUrl=()=>{
  window.ipcRenderer.send('open-oauth-window',{url:url})  
}

const url = generateUrl()
const googleSpreadsheetsFormula = generateGoogleSpreadsheetFormula()
const clayFormula = generateClayFormula()

const [activeTab, setActiveTab] = useState('googleSheets');



  return (
    <Popover.Root 
      open={popoverOpen} 
      onOpenChange={(open) => {
        refreshProjectVariables()
        setPopoverOpen(open);
      }} 
      modal={true}
    >
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          sideOffset={5}
          align="end"
          alignOffset={-2}          
          className='dropdownMenu variantPopover forceDarkTheme'
        >

          <div className='variantPopover-sectionHeader'>            
            <div className='variantPopover-sectionHeader-label'>
              Variables Found
            </div>
            <div className='variantPopover-sectionHeader-hSpacer' />
            {projectVariables && 
            <div className='variantPopover-sectionHeader-variableCountPill'>
              {projectVariables.length}
            </div>        
            }
          </div>

          <div className='variantPopover-variableList'>

            {projectVariables.map((variable)=>{
              return(
                <EditorVariantPopoverVariableRow 
                  variableName={variable}
                  //variableType='Text'
                />
              )
            })}

          </div>

          <div className='variantPopover-divider' />

          {!isGif && 
            <div className='variantPopover-sectionHeader'>
              Video URL Schema
            </div>
          }
          {isGif && 
            <div className='variantPopover-sectionHeader'>
              GIF URL Schema
            </div>
          }
          <div className='variantPopover-sectionDescription'>
            Download video assets for any appropriate set of variable values for this project using this URL schema.
          </div>


          <div className='variantPopover-linkUrl'>           
            <EditorVariantStringArea 
              text={url}
            />
          </div>

          <div className='variantPopover-sectionHeaderTabs'>
            <button onClick={()=> setActiveTab('googleSheets')} data-active-state={activeTab === 'googleSheets' ? 'active' : 'inactive'} className='variantPopover-sectionHeaderTabs-tab'>
              Google Sheets
              <div className='variantPopover-sectionHeaderTabs-tab-bottomBorder' />
            </button>
            <button onClick={()=> setActiveTab('clay')} data-active-state={activeTab === 'clay' ? 'active' : 'inactive'} className='variantPopover-sectionHeaderTabs-tab'>
              Clay Formula
              <div className='variantPopover-sectionHeaderTabs-tab-bottomBorder' />
            </button>        
          </div>          

          <div className='variantPopover-sectionHeaderCopyArea'>
            {activeTab === 'googleSheets' &&
              <EditorVariantStringArea 
                text={googleSpreadsheetsFormula}
              />
            }
            {activeTab === 'clay' &&
              <EditorVariantStringArea 
                text={clayFormula}
              />
            }
          </div>





        {/*}
          Stuff in here
          {projectVariables.map((variable)=>{
            return(

              <div>{variable}</div>
              )
          })}

          <br/>
          URL is

           <div onClick={handleOpenUrl}>
              {url}
            </div>
          */}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default EditorVariantPopover;