import React, { useContext } from 'react';
import { connect } from 'react-redux';
import EditorStatusBar from './statusBar/EditorStatusBar'
import EditorCanvasOuterContainer from './canvas/EditorCanvasOuterContainer'
import EditorTimelineFooter from './timeline/EditorTimelineFooter'
import TranscriptPanel from './leftPanels/transcriptPanel/TranscriptPanel'
import EditorRightPanelContainer from './rightPanels/EditorRightPanelContainer'
import EditorToolbar from './toolbar/EditorToolbar'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Rnd } from "react-rnd"
import {createTimeline} from '../../timeline/utils/createTimeline'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import {setCopiedClip} from '../../actions/clipActions'
import { TextSelection } from 'prosemirror-state';
import {updateProjectBackground,updateProjectDefaultMotionStyle,fetchSingleProject,updateProjectSubtitlesType} from '../../actions/projects'
import {syncNewRecording} from '../../actions/recordings'
import {syncNewScreenRecording} from '../../actions/screenRecordings'
import {randomID} from '../../utils/randomID'
import {focusSlideAndSelectText} from '../../prosemirror/textSlide/utils/focusSlideAndSelectText'
import {focusSlide} from '../../prosemirror/textSlide/utils/focusSlide'
import EditorTrimBar from './EditorTrimBar'
import EditorTopBar from './topbar/EditorTopbar'
import EditorDetailPanel from './detailPanel/EditorDetailPanel'
import {clearClipsData} from '../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import {clearScreenClipsData} from '../../utils/recordings/screenRecordings/getCursorPositionAtTime'
import { numberChart, donutChart, barChart } from './chartData';
import {updateProMode} from '../../actions/user'
import {uploadMusicAndHandleResponse} from '../../actions/backgroundMusic'
import {uploadImageAndHandleResponse,updateImageInsertCount,updateImageDefaultDisplayWidth} from '../../actions/imageUpload'
import {uploadVideoAndHandleResponse,updateVideoInsertCount,updateVideoDefaultDisplayWidth} from '../../actions/videoUpload'
import {uploadWebcamVideoAndHandleResponse} from '../../actions/webcamVideo'
import {maybeSyncRecording} from '../../utils/assets/maybeSyncRecording'
import PMPanel from './rightPanels/PMPanel'
import {getOrgBrand} from '../../utils/brands/getOrgBrand'
import { Mixpanel } from '../../Mixpanel'
import ScenesTimeline from './timeline/scenes/ScenesTimeline'
import { ProseMirrorManager } from '../../timeline/prosemirrorManager/ProseMirrorManager'
import { TranscriptProsemirrorManager } from './leftPanels/transcriptPanel/prosemirrorManager/TranscriptProsemirrorManager'
import UpdatesMajorReleaseBanner from '../changelog/UpdatesMajorReleaseBanner'
import EditorShortcutsPopover from './popovers/EditorShortcutsPopover'
import { timeToPixels } from './timeline/utils/timeToPixels'
import {calculateDropLines} from './timeline/utils/calculateDropLines'
import Icon from '../misc/Icon'
import SlideEditorContextMenu from './SlideEditorContextMenu'
import TemplateBar from './templateBar/TemplateBar'
import {saveSlideAsTemplate} from '../../actions/slideTemplates'
import cloneDeep from 'lodash/cloneDeep'
import ExportManager from '../../ExportManager';
import {groupCharactersIntoWords} from '../../timeline/utils/groupCharactersIntoWords'
import {generateSubtitles} from '../../actions/captions'
import {generateSRTFile} from '../../utils/generateSRTFile'


const MIN_PIXELS_PER_MS = 0.015 
const MAX_PIXELS_PER_MS = 0.25
const MIN_ZOOM = 0
const MAX_ZOOM = 1
const defaultTimelineZoom=0.14
const ANIMATION_PREVIEW_TIME=2

const COMMAND_KEY_DOWN_TIMEOUT = 200
const SCENE_GAP = 34 //px

function createTempImageObject(file) {
  return new Promise((resolve) => {
    const localUrl = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      const tempObject = {
        id: `temp_${Date.now()}`,
        asset_id: `temp_${Date.now()}`,
        containerWidth: img.width,
        created_at: new Date().toISOString(),
        created_by: "local",
        default_display_width: img.width,
        delivery_url: localUrl,
        format: file.type.split('/')[1],
        original_filename: file.name,
        original_height: img.height,
        original_width: img.width,
        public_id: `temp_${file.name}`,
        semi_transparent: false, // You might need more complex logic to determine this
        starred: false,
        status: "pending",
        type: "image",
        upload_failed: false,
        used_at: new Date().toISOString(),
        version: "temp",
        width: img.width
      };
      resolve(tempObject);
    };
    img.src = localUrl;
  });
}


class Editor extends React.Component{  
	
	constructor(props) {
		super(props);
		const pixelsPerMs=this.calculatePixelsPerMs(defaultTimelineZoom)
		
		this.state = {
			clips:[],
			isDnDMode:false,
			scenes:[],
			currentTime: 0,
			isPlaying: false,
			duration:0,
			timelineZoom:defaultTimelineZoom,
			pixelsPerMs:pixelsPerMs,  
			pixelsPerSec:pixelsPerMs*1000, 			
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			// timelineDragging:true,
			voiceoverKey:0,
			activePanelClip:null,
			projectName:'',
			// temporary zoom prototype
			zoomBox:{
				x: 0,
				y: 0,
				width: 640,
				height: 360,
			},			
			meshWidth:null,
			meshHeight:null,
			isPreviewing:false,
			prePreviewState:{},
			previewClip:null,
			previewType:null,
			//brand kit
			projectBackgroundId:null,
			textSlideEditorKey:0,
			// Trim Mode
			trimMode: false,
			trimPreviewPlaying: false,
			trimPreviewProgress: 0,
			activeVoice:'',
			voiceoverPlaybackRate:1,
			backgroundMusicTrack:null,
			backgroundMusicVolume:0,
			isDraggingProgressMarker:false,
			defaultMotionStyle:null,
			testKey:0,
			showPMPanel:true,
			isDragResizingMedia: false,
			maxTimelineDurationSeconds:120,
			pmDoc:{},
			draggingClips:[],
			isDragging:false, 
			dragClipId:null,
			dragClipNewStartTime:null,
			resizingSkipSegmentHandle:null, //null left or right
			isResizingSkipSegment:false,
			preSkipResizeCurrentTime:null,
			transcriptChunksForDragHandles:[],
			transcriptSceneHeaders:[],
			hoveredTranscriptChunk:null,
			transcriptChunkWithCursorInside:null,
			timelineCmdIsDown: false,
			previewingAudioClipId:null,
			showShortcutsPopover: false,
			dropLines:{},
			focusedSlideElement:null,
			selectedSlideItems:[], //elements and layout groups
			projectVariables:[],
			showTranscriptPanel: true,
			isDragResizingNumberInput: false,
      slideEditorContextMenuPosition: null,
      slideEditorContextMenuElementId: null,
      slideEditorDragElementId:null,
     	slideEditorResizeElementId:null,
      slideEditorDragLayoutGroupId:null,
      slideElementTextSelectionInfo:null,
      slideEditorDraggingSpacer:null,
      isDraggingToReorder:false,
      isSlideEditorDragResizing:false,
      showReplaceImageMediaModal:false,
      subtitlesType:null
		};
	 
	 	this.audioElements = {}
		this.backgroundAudioElement=null
		this.player = null
		this.slidePlayer=null
		this.editorTimelineRef = React.createRef();
		this.debouncedSaveDisplayWidth = debounce(this.saveDisplayWidth, 1000);
		this.editorRef = React.createRef();
		this.pmManager = new ProseMirrorManager(this.setPMDocForDevMode);
		this.transcriptPmManager = new TranscriptProsemirrorManager(this.setPMDocForDevMode,this.updateTimelineFromTranscript,this.playClipFromTranscript,this.recalculateAudioClip,this.addSceneFromTranscriptPanel,this.handleMouseOverTranscriptChunk,this.handleMouseLeaveTranscriptChunk,this.handleCursorInsideTranscriptChunk)
	
		this.cmdKeyTimer = null
		this.scrollContainerRef = React.createRef();

	}

	componentDidMount(){
		this.setState({ windowWidth: window.innerWidth });
		this.setState({ windowHeight: window.innerHeight });
		window.addEventListener('resize', this.handleWindowResize);
		window.addEventListener('keydown',this.handleKeydown)
		window.addEventListener('keyup', this.handleKeyup);
		if (this.props.project) {
			this.loadProject();
		}else{
      this.props.fetchSingleProject(this.props.projectId);
		}
		if (window.isElectron) {
			window.ipcRenderer.receive("recording-stopped", this.recordingStoppedListener);
		}
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps) {
		if (this.props.project && !prevProps.project) {
			this.loadProject();
		}
	}

	componentWillUnmount() {
		clearClipsData()
		clearScreenClipsData()
		window.removeEventListener('resize', this.handleWindowResize);
		window.removeEventListener('keydown', this.handleKeydown);
		window.removeEventListener('keyup', this.handleKeyup);
		if(this.timeline){
			this.timeline.destroy()
			this.timeline = null
		}
		if (window.isElectron) {
			window.ipcRenderer.removeListener("recording-stopped", this.recordingStoppedListener);
		}
		if (this.cmdKeyTimer) {
      clearTimeout(this.cmdKeyTimer);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setPreviewingAudioClipId=(clipId)=>{
		this.setState({previewingAudioClipId:clipId})
	}

	cancelPreviewingAudioClip=(clipId)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			clip.stopPreviewingClip()
		}
	}

	manuallyRefreshTranscriptChunksForDragHandles=()=>{
		setTimeout(() => { //hacky timeout to wait for transcript to be ready
			const initialChunks = this.transcriptPmManager.getInitialChunks()
			if(initialChunks){
				this.setState({transcriptChunksForDragHandles:initialChunks.transcriptChunks,transcriptSceneHeaders:initialChunks.sceneHeaders})
			}
    }, 100);
	}

	handleMouseOverTranscriptChunk=(clipId)=>{
		this.setState({hoveredTranscriptChunk:clipId})
	}

	handleMouseLeaveTranscriptChunk=(clipId)=>{
		if(this.state.handleMouseOverTranscriptChunk==clipId){
			this.setState({hoveredTranscriptChunk:null})
		}
	}

	handleCursorInsideTranscriptChunk=(clipId)=>{
		if(clipId!==this.state.transcriptChunkWithCursorInside){
			this.setState({transcriptChunkWithCursorInside:clipId})
		}
	}

	setPMDocForDevMode=(pmDoc)=>{
		this.setState({pmDoc:pmDoc})
	}

	loadProject=()=>{
		const projectId = this.props.projectId
		const project = this.props.project 
		const timeline = this.props.project.timeline
		const isExport = false
		const showCaptions=false
		createTimeline(isExport,projectId,timeline, this.onTimeUpdate,this.handlePlaybackEnded,this.handleClipMediaLoaded,this.getClipForId,this.setPMDocForDevMode,project.background,this.handleUpdateProjectBackground,this.pmManager,this.transcriptPmManager,this.handleVoiceoverAudioFileUpdated,this.setPreviewingAudioClipId,this.handleTextElementFontLoaded)
			.then(timeline => {
				this.timeline = timeline;
				this.updateStateFromTimelineWithTranscriptRefresh()
				setTimeout(() => {
					this.setState({testKey:this.state.testKey+1})
					this.setActivePanelClipFromCurrentTime()		
				}, 200)

			this.setState({
				defaultMotionStyle:project.default_motion_style,
				projectName: project.name,
				projectBackgroundId:project.background ,
				subtitlesType:project.subtitles_type
			})

		setTimeout(() => { //hacky timeout to wait for transcript to be ready
			const initialChunks = this.transcriptPmManager.getInitialChunks()
			if(initialChunks){
				this.setState({transcriptChunksForDragHandles:initialChunks.transcriptChunks,transcriptSceneHeaders:initialChunks.sceneHeaders})
			}
    }, 100)
		})
		.catch(error => {
			console.error('Error in creating timeline:', error);
		});
	}

	handleKeydown=(e)=>{
		if ((e.metaKey || e.ctrlKey) && e.key === 'v') {
			this.maybePasteClip()
		}
		if ((e.metaKey || e.ctrlKey) && e.key === 'c' && !e.shiftKey) {
			this.maybeCopyClip()
		}
		if(e.metaKey && e.shiftKey && e.key=="\\"){
			this.props.updateProMode(!this.props.userIsProMode)
		}
		if (e.metaKey && e.key === 'z' && !e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			const prevActiveClip = this.state.activePanelClip;
			const prevTime = this.state.currentTime;

			this.timeline.undo();

			this.updateStateFromTimeline().then(() => {
				if (prevActiveClip) {
					const updatedClip = this.getClipForId(prevActiveClip.id);
					const isClipStillValid = updatedClip && 
					updatedClip.startTime <= prevTime && 
					prevTime < (updatedClip.startTime + updatedClip.duration);
					if (!isClipStillValid) {
						this.handleDeactivateActiveClip();
					}else{
						this.setState({activePanelClip:updatedClip})
					}
				}
					this.manuallyRefreshTranscriptChunksForDragHandles();
					this.setState({ textSlideEditorKey: this.state.textSlideEditorKey + 1 });
				});
			}
		if (e.metaKey && e.key === 'c' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.splitScene()
		}
		if (e.metaKey && e.key === 'h' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(-30)
		}
		if (e.metaKey && e.key === 'l' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(30)
		}
		if (e.metaKey && e.key === 'c' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.splitScene()
		}
		if (e.metaKey && e.key === 'h' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(-30)
		}
			if (e.metaKey && e.key === 'l' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(30)
		}
		//REDO
		if (e.metaKey && e.shiftKey && e.key === 'z') {
			e.stopPropagation()
			e.preventDefault()
			const prevActiveClip = this.state.activePanelClip;
			const prevTime = this.state.currentTime;
			this.timeline.redo();

			this.updateStateFromTimeline().then(() => {
				if (prevActiveClip) {
					const updatedClip = this.getClipForId(prevActiveClip.id);
					const isClipStillValid = updatedClip && 
					updatedClip.startTime <= prevTime && 
					prevTime < (updatedClip.startTime + updatedClip.duration);
					if (!isClipStillValid) {
						this.handleDeactivateActiveClip();
					}else{
						this.setState({activePanelClip:updatedClip})
					}
				}
				this.manuallyRefreshTranscriptChunksForDragHandles();
				this.setState({ textSlideEditorKey: this.state.textSlideEditorKey + 1 });
			});
		}
		else if ((e.metaKey && e.key === 'e') || (e.metaKey && e.key === 'j')) {
		 	if(e.shiftKey){
		 		this.seekFromShortcut(-2)
		 	}else{
		 		this.togglePlayPause()
		 	}
    	}
    	else if (e.metaKey && e.key === 'k' && e.shiftKey) {	
    		this.seekFromShortcut(2)	 	
    	}
		else if (e.key === 'Backspace') {
			if(window.view && window.view.hasFocus()){
				//console.log('in the transcript panel')
			}else{
				var focusedElement = document.activeElement;
				if(focusedElement.tagName !=='INPUT' && !focusedElement.className.includes('ProseMirror') && !focusedElement.className.includes('rightPanel') && !focusedElement.className.includes('spreadsheet') ){
					this.handleDeleteAction();
				}
			}
		}
		if (e.code === 'Space') {
        var focusedElement = document.activeElement;
        if (focusedElement.tagName !== 'INPUT' && 
            !focusedElement.className.includes('ProseMirror') && 
            !focusedElement.className.includes('rightPanel') && 
            !focusedElement.className.includes('spreadsheet')) {
            e.preventDefault(); // Prevent page scroll
            this.togglePlayPause();
        }
    }


		if (e.key === 'Meta' || e.key === 'Control') {
				if (this.cmdKeyTimer) {
					clearTimeout(this.cmdKeyTimer);
				}

				this.cmdKeyTimer = setTimeout(() => {
					if(this.transcriptPmManager && this.transcriptPmManager.view){
						if (!this.transcriptPmManager.view.hasFocus()) {
						this.setState({ timelineCmdIsDown: true });
					}
					}				
				}, COMMAND_KEY_DOWN_TIMEOUT)
  	} else {
			if (this.cmdKeyTimer) {
				clearTimeout(this.cmdKeyTimer);
			}
    	this.setState({ timelineCmdIsDown: false });
  	}
	}

	seekFromShortcut=(seekAmount)=>{
		const newTime = this.state.currentTime + seekAmount
		this.handleSeek(newTime)
		//scroll into view
		const newPixelPosition = timeToPixels(newTime, this.state.pixelsPerSec, this.state.scenes, SCENE_GAP);
		const scrollableDiv = document.getElementById('timeline-scroll-div')		
		if(scrollableDiv){
			const leftEdgePixels = scrollableDiv.scrollLeft
			const rightEdgePixels = window.innerWidth + scrollableDiv.scrollLeft
			if(newPixelPosition < leftEdgePixels){
				scrollableDiv.scrollLeft = newPixelPosition - (window.innerWidth * 0.4);		
			}	
			if(newPixelPosition > rightEdgePixels){
				scrollableDiv.scrollLeft = newPixelPosition - window.innerWidth + (window.innerWidth * 0.4);		
			}	
		}
	}

	handleKeyup = (e) => {
  if (e.key === 'Meta' || e.key === 'Control') {
  	if (this.cmdKeyTimer) {
      clearTimeout(this.cmdKeyTimer);
    }
    this.setState({ timelineCmdIsDown: false });
  }
};

	handleDeleteAction=()=>{//check if there is an active clip and if it is video or zoom delete it
		const {activePanelClip} = this.state 
		if(activePanelClip && (activePanelClip.type=='zoom' || activePanelClip.type=='video' || activePanelClip.type=='chart' || activePanelClip.type=='image'|| activePanelClip.type=='textSlide'|| activePanelClip.type=='webcam')) {
			this.deleteClip(activePanelClip.id)
		}
		else if(activePanelClip && activePanelClip.type=='slide'){
			if(this.state.selectedSlideItems.length==0 && !this.state.focusedElement){
				this.deleteClip(activePanelClip.id)
			}
			else if(this.state.selectedSlideItems.length){
				this.deleteSlideItems()
			}
		}
	}

	recordingStoppedListener = (captureId,isDevice)=>{
		const isScreenRecording = isDevice?false:true
		
		this.addRecordingToProject(captureId,isDevice,isScreenRecording)
		
		if(!isDevice){
			this.props.syncNewScreenRecording(captureId)
		}else{
			this.props.syncNewRecording(captureId,isDevice)
		}
		Mixpanel.track('new_recording',{
			captureId:captureId,
			isDevice:isDevice||false,
		})
	}

	handleUpdateDefaultMotionStyle=(style)=>{
		this.setState({defaultMotionStyle:style})
		this.timeline.updateProjectDefaultMotionStyle(style)
		this.props.updateProjectDefaultMotionStyle(this.props.projectId,style)
	}

  handleUpdateProjectBackground = (value,isUndo) =>{
  	this.props.updateProjectBackground(this.props.projectId,value)
  	this.setState({projectBackgroundId:value})
  	if(!isUndo==true){
  		this.timeline.updateProjectBackground(value)
  	}
  }	

	////// Timeline playback
	togglePlayPause=()=>{
		if(this.state.isPlaying){
			this.pauseTimeline()
		}else{
			this.playTimeline()
		}
	}

	playTimeline=()=>{
		this.timeline.play()
		this.handleDeactivateActiveClip()
		this.setState({isPlaying:true})
	}

	pauseTimeline=()=>{
		this.timeline.pause()
		this.setState({isPlaying:false})
		this.setActivePanelClipFromCurrentTime()	
	}

	setActivePanelClipFromCurrentTime=()=>{
		let clipsAtPlayhead=[]
		const {clips,currentTime,activePanelClip} = this.state 
		clips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime <= (clip.startTime + clip.duration)){
				clipsAtPlayhead.push(clip)
			}
		})
		//This bit is so if you click a clip and it seeks it doesnt override making that clip active
		const isActiveClipCurrent = activePanelClip &&
			currentTime >= activePanelClip.startTime &&
			currentTime < (activePanelClip.startTime + activePanelClip.duration)

		if (!isActiveClipCurrent && clipsAtPlayhead.length > 0) {
        // Find the clip with the highest z-index
        let highestZIndexClip = clipsAtPlayhead.reduce((maxClip, clip) => {
            return (maxClip.zIndex > clip.zIndex) ? maxClip : clip;
        });
        this.handleSetActivePanelClip(highestZIndexClip)
    } else {
    	if(clipsAtPlayhead.length==0){
    		this.handleDeactivateActiveClip()
    	}
    }
	}

	onTimeUpdate=(currentTime)=>{
		this.setState({currentTime:currentTime},()=>{
			if(!this.state.isPlaying){
				this.setActivePanelClipFromCurrentTime()
			}
		})
		const {isPreviewing} = this.state
		if(isPreviewing){
			const {previewClip} = this.state
			if(currentTime>=previewClip.startTime+previewClip.startTranstionDuration){
				this.resetPreview()
			}
		}
	}

	handleSeek=(newTime)=>{
		this.timeline.seek(newTime)
		this.updateStateFromTimeline()
	}

	handleSeekWithPause=(newTime)=>{
		if(this.state.isPlaying){
			this.pauseTimeline()
		}
		this.timeline.seek(newTime)
	}

	handlePlaybackEnded=()=>{
		setTimeout(() => { //hacky set timeout for when start playing at time>duration
   			this.pauseTimeline()
		}, 10)
	}

	///// Timeline zoom 
	handleTimelineZoomChange = (value) => {
	  const scrollContainer = this.scrollContainerRef.current;
	  if (scrollContainer) {
	    const containerWidth = scrollContainer.clientWidth;
	    const scrollLeft = scrollContainer.scrollLeft;
	    const currentScrollWidth = scrollContainer.scrollWidth;
	    // Use high-precision calculations
	    const markerPosition = (this.state.currentTime / this.state.duration) * currentScrollWidth;
	    const visibleMarkerRatio = (markerPosition - scrollLeft) / containerWidth;
	    
	    const newPixelsPerMs = this.calculatePixelsPerMs(value);
	    this.setState({ 
	      timelineZoom: value, 
	      pixelsPerMs: newPixelsPerMs, 
	      pixelsPerSec: newPixelsPerMs * 1000 
	    }, () => {
	      // Recalculate with new dimensions
	      const newScrollWidth = scrollContainer.scrollWidth;
	      const newMarkerPosition = (this.state.currentTime / this.state.duration) * newScrollWidth;
	      
	      // Calculate new scroll position
	      const newScrollLeft = newMarkerPosition - (visibleMarkerRatio * containerWidth);
	      
	      // Apply the new scroll position, rounding to the nearest integer
	      scrollContainer.scrollLeft = Math.round(newScrollLeft);
	      
	      // Fine-tune the position if needed
	      requestAnimationFrame(() => {
	        const actualMarkerPosition = (this.state.currentTime / this.state.duration) * scrollContainer.scrollWidth;
	        const actualVisiblePosition = actualMarkerPosition - scrollContainer.scrollLeft;
	        const targetVisiblePosition = visibleMarkerRatio * containerWidth;
	        
	        if (Math.abs(actualVisiblePosition - targetVisiblePosition) > 1) {
	          scrollContainer.scrollLeft += (actualVisiblePosition - targetVisiblePosition);
	        }
	      });
	    });
	  } else {
	    const newPixelsPerMs = this.calculatePixelsPerMs(value);
	    this.setState({ 
	      timelineZoom: value, 
	      pixelsPerMs: newPixelsPerMs, 
	      pixelsPerSec: newPixelsPerMs * 1000 
	    });
	  }
	};


	calculatePixelsPerMs = (timelineZoom) => {
		const pixelsPerMs=(MIN_PIXELS_PER_MS+((MAX_PIXELS_PER_MS-MIN_PIXELS_PER_MS)*timelineZoom))
		return pixelsPerMs
	}

	handleWindowResize = () => {
		this.setState({ windowWidth: window.innerWidth,windowHeight: window.innerHeight  });
	}

	handleTimelineZoomToFit = () => {
		const totalWidth = this.state.windowWidth - 100; // some buffer
		const requiredPixelsPerMs = totalWidth / (this.state.duration * 1000);
		let newZoomLevel = (requiredPixelsPerMs - MIN_PIXELS_PER_MS) / (MAX_PIXELS_PER_MS - MIN_PIXELS_PER_MS);
		newZoomLevel = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoomLevel)); // Clamping between min and max zoom levels
		if (this.editorTimelineRef && this.editorTimelineRef.current) {
			this.editorTimelineRef.current.scrollToStart();
		}
		this.handleTimelineZoomChange(newZoomLevel);
	}

	/////// active clip
	handleDeactivateActiveClip = () => {
		this.setState({activePanelClip:null})
	}

	handleSetActivePanelClip = (clip) =>{
		if(clip && this.state.activePanelClip && this.state.activePanelClip.id==clip.id){
		}else{
			this.setState({activePanelClip:clip})
			if(clip && clip.type!=='slide'){
				this.setState({selectedSlideItems:[]})
			}
		}	
	}

	////// update slide animation settings
	updateSlideClipTextStyle=(clipId,textStyle)=>{ 
		this.timeline.updateSlideClipTextStyle(clipId, textStyle)
		this.updateStateFromTimeline()
	}

	updateClipMetadata=(clipId,settings)=>{ //TODO
		this.timeline.updateClipMetadata(clipId, settings)
		this.updateStateFromTimeline()
	}

	//isPreview so dont update timeline with min clip duration if just previewing
	updateClipAnimationSettings=(clipId,settings,isPreview)=>{ //TODO
		this.timeline.updateClipAnimationSettings(clipId, settings,isPreview)
		this.updateStateFromTimeline()
	}

	//////////// Drag to move clip
	updateClipStartTime = (clipId,newStartTime) =>{
		this.timeline.updateClipStartTime(clipId, newStartTime)
		this.updateStateFromTimeline()
	}

	updateMultipleClipsStartTime = (updatedClips) =>{
		this.timeline.updateMultipleClipStartTimes(updatedClips)
		this.updateStateFromTimeline()
	}

  updateClipStartTimeAndDuration = (clipId, newStartTime, newDuration) => {    
    this.timeline.updateClipStartTime(clipId, newStartTime);    
    this.timeline.updateClipDuration(clipId, newDuration);    
    this.updateStateFromTimeline();
  };

	updateClipDuration = (clipId,newDuration) =>{
		this.timeline.updateClipDuration(clipId, newDuration)
		this.updateStateFromTimeline()
	}

	//For when inset text slides and chanrts back to back to make sure teh text area doenst overlay teh next slide
	bumpTimeOnInsertClip=()=>{
		///New April 19th seek slightly forward so are in the new clip (so text slide text editor doesnt show)
		this.handleSeek(this.state.currentTime+0.01)
	}

	 setFocusToEditor = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

	updateTextSlideTextColor = (clipId,textColorId)=>{
		this.timeline.setTextSlideTextColor(clipId,textColorId)
		this.updateStateFromTimeline()
	}

	updateTextSlideBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}


/////ZOOOMS 
addZoom=()=>{
		const {currentTime,clips}=this.state 
		let parentClip=null 
		let parentClipIsDeviceRecording = false
		let nearestZoomStartTime = Infinity;
		let activeZoomAtPlayhead = false;
		let activeZoomClip = null;

		clips.forEach((clip)=>{
			if(clip.type=='video' && clip.startTime<=currentTime && currentTime<=clip.endTime){
				parentClip=clip.id
				if(clip.isDeviceRecording){
					parentClipIsDeviceRecording=true
				}
			}
			if (clip.type == 'zoom' && clip.startTime > currentTime) {
				nearestZoomStartTime = Math.min(nearestZoomStartTime, clip.startTime);
			}
			if (clip.type == 'zoom' && currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)) {
				activeZoomAtPlayhead = true;
				activeZoomClip = clip;
			}
		})
		let newDuration
		//If there is an active zoom lets shorten it and then add a new one at current time
		// if (activeZoomAtPlayhead && activeZoomClip) { 
		// 	newDuration = currentTime - activeZoomClip.startTime
		// 	if(newDuration >0){
		// 		this.timeline.updateClipDuration(activeZoomClip.id, newDuration)
		// 	}
		// 	this.handleSeek(currentTime + 1.5)
		// }

		const DEFAULT_ZOOM_DURATION= 3
		const MIN_ZOOM_DURATION=0.5
		if(newDuration>0 || !activeZoomClip){
			let duration = DEFAULT_ZOOM_DURATION;
			if (nearestZoomStartTime - currentTime < DEFAULT_ZOOM_DURATION) {
				duration = nearestZoomStartTime - currentTime;
			}
			if(duration>MIN_ZOOM_DURATION){ //prevent super tiny zooms
				const newClip = {
					id:randomID(),
					type:"zoom",
					startTime:this.state.currentTime,
					duration:duration,
					metadata:{      	
						zoomValues: {
							originX: 0,  
							originY: 0, 
							scale: 0.8,
							motionSettings: 'zoomSmooth',
							endMotionSettings:null
						},
						zoomBox: {
							x: 0,
							y: 0,
							width: 1920 * 0.8,
							height: 1080 * 0.8,
						},      	
						parentClip: parentClip,
						parentClipIsDeviceRecording:parentClipIsDeviceRecording
					},
					zIndex:2
				}
				this.timeline.addClip(newClip)
				const seekAmount = Math.min(duration/2,1.5)
				this.handleSeek(currentTime + seekAmount)

				this.updateStateFromTimeline().then(() => {
					const createdClip = this.getClipForId(newClip.id);
					this.handleSetActivePanelClip(createdClip);
				});
			}
		}
	}

	updateZoomValues = (clipId,zoomValues)=>{
		this.timeline.updateClipMetadata(clipId, {zoomValues:zoomValues})		
		this.updateStateFromTimeline()
	}

	updateZoomBox = (clipId,zoomBox)=>{
		this.timeline.updateClipMetadata(clipId, {zoomBox:zoomBox})		
		this.updateStateFromTimeline()
	}

////// Preview aniation 
	previewAnimation=(clip,animationType)=>{
		let prePreviewState={} //so can return to previewstate
		prePreviewState.currentTime=this.state.currentTime
		prePreviewState.startTransitionType=clip.metadata.startTransitionType
		this.setState({
			isPreviewing:true,
			previewClip:clip,
			previewType:animationType,
			prePreviewState:prePreviewState
		})
		const isPreview=true
		this.updateClipAnimationSettings(clip.id,{startTransitionType:animationType},isPreview)
		this.handleSeek(clip.startTime)
		this.togglePlayPause()
	}

	resetPreview=()=>{
		this.togglePlayPause()
		const {prePreviewState,previewClip}=this.state 
		this.handleSeek(previewClip.startTime)
		const isPreview=true
		this.updateClipAnimationSettings(previewClip.id,{startTransitionType:prePreviewState.startTransitionType},isPreview)
		this.setState({isPreviewing:false,previewClip:null,previewType:null,prePreviewState:null})
	}

	//update mesh width/hegight
	updateMeshWidth=(clipId,width)=>{
		this.setState({meshWidth: width})
		this.updateClipMetadata(clipId,{meshWidth:width})
	}
	updateMeshHeight=(clipId,height)=>{
		this.setState({meshHeight: height})
		this.updateClipMetadata(clipId,{meshHeight:height})
	}


/////UTILS
	getClipForId=(clipId)=>{
		const {clips} = this.state 
		const clip=find(clips,{id:clipId})
		return clip
	}

	updateChartBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	updateChartClip=(clipId,metadata)=>{
		this.timeline.updateChartClip(clipId,metadata)
		this.updateStateFromTimeline()
	}

	//Background music 
	updateBackgroundMusicTrack=(trackId)=>{
		this.timeline.updateBackgroundMusic(trackId)
		this.updateStateFromTimeline()
	}

	updateBackgroundMusicVolume=(newVolume)=>{
		this.timeline.updateBackgroundMusicVolume(newVolume)
		this.updateStateFromTimeline()
	}

//Dragging progress marker
	handleMouseDownProgressMarker=()=>{
		this.setState({isDraggingProgressMarker:true})
	}
	handleMouseUpProgressMarker=()=>{
		this.setState({isDraggingProgressMarker:false})
	}


	removeFreeze=(clipId,segmentId)=>{
		this.timeline.removeFreeze(clipId,segmentId)
		this.updateStateFromTimeline()
	}

	removeSkip=(clipId,segmentId)=>{
		this.timeline.removeSkip(clipId,segmentId)
		this.updateStateFromTimeline()
	}

	updateClipBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	updateClipDisplayWidth=(clipId,displayWidth)=>{
		this.timeline.updateClipMetadata(clipId, {displayWidth:displayWidth})		
		this.updateStateFromTimeline()
		this.debouncedSaveDisplayWidth(clipId,displayWidth)
	}

	saveDisplayWidth=(clipId,displayWidth)=>{
		const clip =this.getClipForId(clipId)
		if(clip.type=='image'){
			this.props.updateImageDefaultDisplayWidth(clip.imageId,displayWidth)
		}else if(clip.type=='video'){
			this.props.updateVideoDefaultDisplayWidth(clip.videoId,displayWidth)
		}
	}

	handleNewMusicFileUpload=async (file)=>{
		const musicId = randomID()
		await this.props.uploadMusicAndHandleResponse(file,musicId)
		return
	}

	setPMDocForDevMode=(pmDoc)=>{
		this.setState({pmDoc:pmDoc})
	}

	updateStateFromTimeline = () => {
		return new Promise((resolve, reject) => {
			if (this.timeline) { // Check for initial load
				this.setState({
					duration: this.timeline.duration,
					currentTime: this.timeline.currentTime,
					clips: this.timeline.clips,
					activeVoice: this.timeline.activeVoice,
					voiceoverPlaybackRate: this.timeline.voiceoverPlaybackRate,
					backgroundMusicTrack: this.timeline.backgroundMusicTrack,
					backgroundMusicVolume: this.timeline.backgroundMusicVolume,
					maxTimelineDurationSeconds: this.timeline.maxTimelineDurationSeconds,
					scenes: this.timeline.scenes,
					isDragging: this.timeline.isDragging,
					dragClipId: this.timeline.dragClipId,
					isDnDMode: this.timeline.isDnDMode,
					dragClipNewStartTime:this.timeline.dragClipNewStartTime,
					projectVariables:this.timeline.variables,
					showCaptions:this.timeline.showCaptions
				}, resolve); // resolve the promise when setState completes
			} else {
			//reject(new Error("Timeline is undefined")); // Reject the promise if there is no timeline
			}
		});
	}

	updateStateFromTimelineWithTranscriptRefresh = () =>{
		this.setState({
			duration:this.timeline.duration,
			currentTime:this.timeline.currentTime,
			clips:this.timeline.clips,
			activeVoice:this.timeline.activeVoice,
			voiceoverPlaybackRate:this.timeline.voiceoverPlaybackRate,
			backgroundMusicTrack:this.timeline.backgroundMusicTrack,
			backgroundMusicVolume:this.timeline.backgroundMusicVolume,
			maxTimelineDurationSeconds:this.timeline.maxTimelineDurationSeconds,
			voiceoverKey:this.state.voiceoverKey+1, //lets take this out for now not sure we need it
			scenes:this.timeline.scenes,
			isDragging:this.timeline.isDragging, 
			dragClipId:this.timeline.dragClipId,
			isDnDMode:this.timeline.isDnDMode,
			dragClipNewStartTime:this.timeline.dragClipNewStartTime,
			projectVariables:this.timeline.variables,
			showCaptions:this.timeline.showCaptions
		})
	}

	refreshProjectVariables=()=>{
		this.timeline.calculateUniqueVariables()
		this.updateStateFromTimeline()
	}

///Timeline clip drag etc
	onDragStart=(clip,isDnDMode,isDragPullMode)=>{
		this.timeline.onDragStart(clip,isDnDMode,isDragPullMode)
		this.updateStateFromTimeline()
	}

	onDragEnd=(dragClip,dropTime,activeDropType)=>{
		this.timeline.onDragEnd(dragClip,dropTime,activeDropType)
		this.updateStateFromTimeline()
		setTimeout(() => {
		//check if should deactivate the active panel clip
		const {activePanelClip} = this.state 
		if(activePanelClip){
			const updatedClip = this.getClipForId(activePanelClip.id);
			const isClipStillValidAsActive = updatedClip && 
			updatedClip.startTime <= this.state.currentTime && 
				this.state.currentTime < (updatedClip.startTime + updatedClip.duration);
				if (!isClipStillValidAsActive) {
					setTimeout(() => { //try adding a slight timeout to prevent the # transaction getting added to new view
						this.handleDeactivateActiveClip();
					}, 10)
				}else{
					this.setState({activePanelClip:updatedClip})
				}
			}
		}, 5)
	}

	handleDragClip=(sceneId, clip, newStartTime,metaKeyIsPressed)=>{
		this.timeline.handleDragClip(sceneId, clip, newStartTime,metaKeyIsPressed,this.state.pixelsPerSec)
		this.updateStateFromTimeline()
	}

	onResizeStart=(clip)=>{
		this.timeline.onResizeStart(clip)
		this.updateStateFromTimeline()
	}

	onResizeStop=()=>{
		this.timeline.onResizeStop()
		this.updateStateFromTimeline()
	}

	onResize=(clip,newDuration,direction)=>{
		this.timeline.onResize(clip, newDuration,direction,this.state.pixelsPerSec)
		this.updateStateFromTimeline()
	}

//////// Audio clips
	handleChangeVoiceoverPlaybackRate = (rate)=>{
		this.timeline.updateVoiceoverPlaybackRate(rate)
		this.updateStateFromTimeline()
	}

		//// VOICEOVER CLIPS HERE
	handleChangeActiveVoice = (voiceId) =>{
		this.timeline.updateActiveVoice(voiceId)
		this.updateStateFromTimeline()
	}

	handleVoiceoverAudioFileUpdated=()=>{ //when file is calculated we need to update the spacing and durations 
		if(this.timeline){
			this.timeline.calculateAudioTrackSpacing()
			this.timeline.debouncedSaveProjectTimeline()
			this.updateStateFromTimeline()
		}
	}

	playClipFromTranscript=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.playFromTranscript()
		}
	}

	recalculateAudioClip=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.recalculateAudio()
		}
	}

	//turn transcript changes in timeline clips
	updateTimelineFromTranscript=(nodes)=>{
		let updatesArray=[]
		let {scenes} = this.state 
		const {transcriptChunks,sceneHeaders} = nodes
		this.setState({transcriptChunksForDragHandles:transcriptChunks,transcriptSceneHeaders:sceneHeaders})

		const clips = this.state.clips.filter(clip =>clip.type=='audio')
		const createdChunks = transcriptChunks.filter(chunk =>!clips.some(clip => clip.id === chunk.clipId))
		const deletedClips= clips.filter(clip =>!transcriptChunks.some(chunk => chunk.clipId === clip.id))
		const updatedChunks = transcriptChunks.filter(chunk =>!createdChunks.includes(chunk) && clips.some(clip => clip.id === chunk.clipId && (clip.metadata.text !== chunk.textContent || clip.clipIndex!=chunk.clipIndex)))

		createdChunks.forEach((chunk)=>{ //need to do this before the delete thing
			updatesArray.push({
				type:'create',
				clipId:chunk.clipId,
				sceneId:chunk.sceneId,
				text:chunk.textContent,
				clipIndex:chunk.clipIndex
			})
		})

		updatedChunks.forEach((chunk)=>{
			if(chunk.textContent && chunk.textContent !=='#'){
				updatesArray.push({
					type:'updateChunk',
					clipId:chunk.clipId,
					text:chunk.textContent,
					clipIndex:chunk.clipIndex,

				})
			}else{ //empty text things lets delete from timeline
				const clip = find(clips,{id:chunk.clipId})
				updatesArray.push({
					type:'delete',
					clip:clip,
				})
			}
		})

		deletedClips.forEach((clip)=>{
			updatesArray.push({
				type:'delete',
				clip:clip,
			})
		})

		const updatedHeaders = sceneHeaders.filter(header =>scenes.some(scene => scene.id === header.sceneId && (scene.title !== header.sceneTitle)))
		updatedHeaders.forEach((header)=>{
			updatesArray.push({
				type:'updateTitle',
				sceneId:header.sceneId,
				title:header.sceneTitle
			})
		})

		const deletedHeaders = scenes.filter(scene => !sceneHeaders.some(header => header.sceneId === scene.id));
		deletedHeaders.forEach((scene) => {
			updatesArray.push({
				type: 'mergeScene',
				sceneId: scene.id,
			});
		});
		this.timeline.updateTimelineFromTranscript(updatesArray)
		this.updateStateFromTimeline()
	}


	recalculateAudioClip=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.recalculateAudio()
		}
	}


////Context menu actions
	deleteClip=(clipId,refreshTranscriptPanel)=>{
		if(this.state.activePanelClip && this.state.activePanelClip.id==clipId){
			this.handleDeactivateActiveClip()
		}
		this.timeline.deleteClipById(clipId)
		this.updateStateFromTimeline()
		this.setFocusToEditor()

		if(refreshTranscriptPanel && this.timeline){
			this.timeline.updateTranscriptFromTimeline()
			this.timeline.calculateAudioTrackSpacing()
		}
	}

	duplicateClip=(clip)=>{
		let newClip = cloneDeep(clip)
		newClip.id=randomID()
		newClip.startTime = clip.startTime //now this is calc field need to add it for duplicate to work
		const duplicateLayoutGroup = (group) => {
			let newGroup = cloneDeep(group)
			newGroup.id = randomID()
			newGroup.children = group.children.map(child => {
				if (child.isLayoutGroup ) {
					return duplicateLayoutGroup(child);
				} else {
					return duplicateElement(child);
				}
			});
		return newGroup;
		};

		const duplicateElement = (element) => {
			let newElement = cloneDeep(element)
			newElement.id = randomID()
			return newElement
		};
		
		if(clip.type=='slide'){
			newClip.layout=duplicateLayoutGroup(clip.layout)
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
	}

	copyClip=(clip)=>{
		this.props.setCopiedClip(clip)
	}

	cutClip=(clip)=>{
		this.props.setCopiedClip(clip)
		this.deleteClip(clip.id)
	}

	maybePasteClip=()=>{
		if(this.props.copiedClip){
			const activeElement = document.activeElement;
			const isEditableFocused = activeElement && (
            activeElement.tagName === 'INPUT' || 
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable // Checks if the element is content editable
        );
			const hasProsemirrorClass = activeElement && Array.from(activeElement.classList).some(className => className.includes('ProseMirror'))
			if(!hasProsemirrorClass && !isEditableFocused){
				//let newClip={...this.props.copiedClip}
				let newClip=cloneDeep(this.props.copiedClip)
				newClip.id=randomID()
				newClip.startTime=this.state.currentTime
				this.timeline.addClip(newClip)
				this.updateStateFromTimeline()
			}
		}
	}

////Scenes
	addSceneFromTranscriptPanel=(splitSceneId,splitClipId,splitClipIndex)=>{
		setTimeout(() => { //try adding a slight timeout to prevent the # transaction getting added to new view
			this.timeline.addSceneFromTranscriptPanel(splitSceneId,splitClipId,splitClipIndex)
			this.updateStateFromTimeline()
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}, 10)
	}

	addSceneAtEndOfProject=()=>{ //when click the add scene button in transcript panel it adds a scene not splits it
		this.timeline.addNewScene()
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	addAudioClipAtEndOfProject=()=>{
		this.transcriptPmManager.manuallyAddAudioClip()
	}

	splitScene = () =>{
		this.timeline.splitScene()
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	addSceneAfterScene=(sceneId)=>{ //plus button in scene gap adds a scene before the scene
		this.timeline.addSceneAfterScene(sceneId)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	deleteScene=()=>{
		this.handleDeactivateActiveClip()
		const {scenes} = this.state
		this.timeline.deleteScene(scenes[0].id)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	mergeScene=(sceneId,mergeDirection)=>{ //default is merges with scene after unless merge direction is "before" 
		this.timeline.mergeScene(sceneId,mergeDirection)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	handleTranscriptPanelChunkDrop=(dropClipId,dropPosition)=>{
		this.timeline.handleTranscriptDnd(dropClipId,dropPosition)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	openShortcutsPopover=()=>{
		this.setState({showShortcutsPopover: true})
	}

	closeShortcutsPopover=()=>{
		this.setState({showShortcutsPopover: false})
	}

	calculateAndSetDropLines=(clip,dx,sceneGap) => {
    const lines=calculateDropLines(clip,clip.startTime,dx,this.state.pixelsPerSec,this.state.clips, this.state.scenes,sceneGap) 
    this.setState({dropLines:lines})
  }

///////// VIDEOS /////////
	addRecordingToProject = async (captureId,isDevice,isScreenRecording) => {
		try {
			await this.timeline.addVideoClipFromCaptureId(captureId,isDevice,isScreenRecording,this.state.defaultMotionStyle)
			this.updateStateFromTimeline()
		} catch (error) {
			console.error('Error adding video clip:', error);
		}
	}

	addRecordingFromVideoLibrary = (captureId,isDevice,isScreenRecording)=>{
		this.addRecordingToProject(captureId,isDevice,isScreenRecording)
		// maybeSyncRecording(captureId,isDevice,isScreenRecording)
		// Mixpanel.track('add_recording_from_library',{
		// 	captureId:captureId,
		// 	isDevice:isDevice,
		// })
	}

	handleNewVideoFileUpload=(file,duration)=>{
		let startTime=this.state.currentTime
		const clipId = randomID()
		const videoId = randomID()
		const newClip = {
			id:clipId,
			type:"video",
			videoId:videoId,
			isUploadingVideo:true,
			isBasicVideo:true,
			startTime:startTime,
			duration:duration,
			metadata:{
				backgroundId:'none',
				startTransitionType:'fadeAndMoveUp',
				endTransitionType:'fadeAndMoveDown',
				isAutoMotionStyle:true,
				motionStyle:this.state.defaultMotionStyle,
				label:file.name
			},
			zIndex:0
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(clipId)
		if(createdClip){
			this.handleSetActivePanelClip(createdClip)
		}
		this.props.uploadVideoAndHandleResponse(file,videoId).then((response)=>{
			this.timeline.updateVideoWithUploadResponse(clipId,videoId,response)
			this.updateStateFromTimeline()
		})
	}

	insertVideoFromRecent=(videoObj)=>{
		let startTime=this.state.currentTime
		const id = randomID()
		const newClip = {
			id:id,
			type:"video",
			videoId:videoObj.id,
			isBasicVideo:true,
			startTime:startTime,
			duration:videoObj.duration,
			metadata:{
				label:videoObj.original_filename,
				backgroundId:'none',
				startTransitionType:'fadeAndMoveUp',
				endTransitionType:'fadeAndMoveDown',
				isAutoMotionStyle:true,
				motionStyle:this.state.defaultMotionStyle,
				displayWidth:videoObj.default_display_width,
				semiTransparent:videoObj.semi_transparent,
				originalHeight:videoObj.original_height,
				originalWidth:videoObj.original_width
			},
			zIndex:0
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(id)
		this.handleSetActivePanelClip(createdClip)
		this.props.updateVideoInsertCount(videoObj.id)
	}

	handleNewWebcamFileUpload=(file,duration)=>{
		let startTime=this.state.currentTime
		const clipId = randomID()
		const videoId = randomID()
		const newClip = {
			id:clipId,
			type:"webcam",
			videoId:videoId,
			isUploadingVideo:true,
			startTime:startTime,
			duration:duration,
			metadata:{
				backgroundId:'none',
				startTransitionType:'fadeAndMoveUp',
				endTransitionType:'fadeAndMoveDown',
				isAutoMotionStyle:true,
				motionStyle:this.state.defaultMotionStyle,
				label:file.name,
				positionStyle:'right',
				zoom:0,
				xOffset:0,
				yOffset:0
			},
			zIndex:0
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(clipId)
		this.handleSetActivePanelClip(createdClip)
		this.props.uploadWebcamVideoAndHandleResponse(file,videoId).then((response)=>{
			this.timeline.updateVideoWithUploadResponse(clipId,videoId,response)
			this.updateStateFromTimeline()
		})
	}

	insertWebcamFromRecent=(videoObj)=>{
		let startTime=this.state.currentTime
		const id = randomID()
		const newClip = {
			id:id,
			type:"webcam",
			videoId:videoObj.capture_id,
			startTime:startTime,
			duration:5,
			metadata:{
				label:videoObj.original_filename,
				backgroundId:'none',
				startTransitionType:'fadeAndMoveUp',
				endTransitionType:'fadeAndMoveDown',
				isAutoMotionStyle:true,
				motionStyle:this.state.defaultMotionStyle,
				displayWidth:500,
				semiTransparent:false,
				originalHeight:500,
				originalWidth:500,
				positionStyle:'right',
				zoom:0,
				xOffset:0,
				yOffset:0
			},
			zIndex:0
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(id)
		this.handleSetActivePanelClip(createdClip)
	}

	splitRecordingClip=()=>{
		const {currentTime,clips} = this.state
		const videoClips = filter(clips,{type:'video'})
		let activeClip 
		videoClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				activeClip = clip
			}
		})
		if(activeClip){
			this.timeline.splitVideoClip(activeClip,currentTime)
			this.updateStateFromTimeline()
		}
	}

	findVideoClipAtPlayhead=()=>{
		let activeClip=null 
		const {clips,currentTime} = this.state
		const videoClips = filter(clips,{type:'video'})
		videoClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				activeClip = clip
			}
		})
		return activeClip
	}

	addFreezeFrame=(clipId)=>{
		let id = clipId 
		if(!id){
			const activeClip = this.findVideoClipAtPlayhead()
			if(activeClip){
				id=activeClip.id
			}
		}
		if(id){
			this.timeline.addFreezeFrame(id,this.state.currentTime)
			this.updateStateFromTimeline()
		}
	}

	addSkipSegment=(clipId)=>{
		let id = clipId 
		if(!id){
			const activeClip = this.findVideoClipAtPlayhead()
			if(activeClip){
				id=activeClip.id
			}
		}
		if(id){
			this.timeline.addSkipSegment(id,this.state.currentTime)
			this.updateStateFromTimeline()
		}
	}

	toggleSkipSegment=(clipId,segmentId,isExpanded)=>{
		this.timeline.toggleSkipSegment(clipId,segmentId,isExpanded)
		this.updateStateFromTimeline()
	}

	handleChangeSegmentPlaybackRate=(clipId,segmentId,playbackRate)=>{
		this.timeline.updateVideoSegmentTimeStretch(clipId,segmentId,playbackRate)
		this.updateStateFromTimeline()
	}

	handleResizeSkipSegmentStart=(direction)=>{
		this.setState({resizingSkipSegmentHandle:direction,preSkipResizeCurrentTime:this.state.currentTime})
	}

	handleResizeSkipSegmentStop=()=>{
		this.handleSeek(this.state.preSkipResizeCurrentTime);
		this.setState({resizingSkipSegmentHandle:null,preSkipResizeCurrentTime:null})
	}

	handleChangeSkipSegmentDuration=(clipId,segmentId,newDuration,resizeDirection)=>{
		this.timeline.updateSkipSegmentDuration(clipId,segmentId,newDuration,resizeDirection)
		this.updateStateFromTimeline()
	}

	handleChangeVideoClipPlaybackRate=(clipId,playbackRate)=>{
		this.timeline.changeVideoClipPlaybackRate(clipId,playbackRate)
		this.updateStateFromTimeline()
	}

	handleClipMediaLoaded=()=>{//bump state when video clip finishes loading
		this.updateStateFromTimeline()
	}

	toggleTrimMode = ()=>{
		this.setState({trimMode:!this.state.trimMode})
	}

	updateClipTrimValues = (clip,trimStart,trimEnd) =>{
		const trimStartTime = trimStart * clip.recordingDuration
		const trimEndTime = trimEnd * clip.recordingDuration
		this.timeline.updateClipTrimValues(clip.id,trimStartTime,trimEndTime)
		this.updateStateFromTimeline()
		this.toggleTrimMode()
	}
	
	onTrimPreviewPlayPause = () => {
		this.setState(prevState => {
			return { trimPreviewPlaying: !prevState.trimPreviewPlaying };
		});
	};

	onTrimPreviewSeekChange = (value) => {
		this.setState({ trimPreviewProgress: value });
	};

	addSlide=async(imageObj,elementId,slideClipId,elementType)=>{
		let startTime=this.state.currentTime
		const clipId = slideClipId || randomID()
		const elementText='Headline'
		let paragraphs=[]
		paragraphs.push({
			type: "paragraph",
			attrs: { indentLevel: 0 },
			content: [
				{ type: "text", text: elementText }
			]
		});
		const nodeJson = JSON.stringify({
			type: "doc",
			content: paragraphs
		})
		const newClip = {
			id:clipId,
			type:"slide",
			startTime:startTime,
			duration:5,
			metadata:{
				backgroundId:'none',
				variables:[]
			},
			zIndex:1,
			elements:[]	
		}
		this.timeline.addClip(newClip)
		if(imageObj){
			const newElementId = elementId || randomID()
		 	this.timeline.addImageElementToSlide(newClip.id,imageObj,newElementId)
		 	this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
		}else if(elementType=='chart'){
			await this.addSlideElement(clipId,'chart',false)
		}else{
			await this.addSlideElement(clipId,'text',false)
		}
		this.updateStateFromTimeline()
		setTimeout(() => { //hacky timeout to wait for transcript to be ready
			const createdClip = this.getClipForId(clipId)
			this.handleSetActivePanelClip(createdClip)
    }, 100);
	}

	addSlideElement = async (clipId, type, isVariable) => {
    try {
    	const newElementId = randomID()
      await this.timeline.addSlideElement(clipId, type, isVariable,newElementId);
      this.updateStateFromTimeline();
      this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
    } catch (error) {
    	console.log('error adding slide element')
    }
  }

	deleteSlideItems = () => {
		this.timeline.deleteSlideItems(this.state.activePanelClip.id,this.state.selectedSlideItems)
		this.updateStateFromTimeline();
		this.resyncActivePanelClipInState()
		this.closeSlideEditorContextMenu()
	}

	handleUpdateCanvasFromSlideEditor=()=>{ //force canvas to update with changes while dragging
		if(this.state.isSlideEditorDragResizing){
			this.handleSyncSlideChanges()
		}else{
			this.resyncActivePanelClipInState()
		}
	}

	handleSyncSlideChanges=()=>{
		if(this.state.activePanelClip){
			const clip = this.getClipForId(this.state.activePanelClip.id)
			this.timeline.debouncedSaveProjectTimeline()
			this.updateStateFromTimeline()
			this.pmManager.syncSlideClip(clip)
			this.setState({activePanelClip:clip})
		}else{
			console.log('no panel clip')
		}
	}

	//after we make changes to the slide we update the clip in state and also check that the selected items still exist in the clip (e.g. delete selected item)
	resyncActivePanelClipInState = () => {
		if(this.state.activePanelClip && this.state.activePanelClip.type=='slide'){
			const activeClipId = this.state.activePanelClip.id;
			const newActivePanelClip = this.getClipForId(activeClipId);
			// Filter out selected items that no longer exist in the updated clip
			const updatedSelectedItems = this.state.selectedSlideItems.filter(item => {
				if (item.type === 'layoutGroup') {
					return newActivePanelClip.layoutGroups.some(group => group.id === item.id);
				} else {
					return newActivePanelClip.elements.some(element => element.id === item.id);
				}
			});
			this.setState({
				activePanelClip: newActivePanelClip,
				selectedSlideItems: updatedSelectedItems
			});
		}
	}

	updateLayoutGroupField=(clipId,layoutGroupId,field,value)=>{
		this.timeline.updateSlideLayoutGroupField(clipId,layoutGroupId,field,value)
		this.updateStateFromTimeline()
	}

	updateSlideAlignment = (clipId,alignment,value)=>{
		this.timeline.updateSlideAlignment(clipId,alignment,value)
		this.updateStateFromTimeline()
	}

	updateSlideBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	handleSlideDragOrResizeStart=()=>{
		this.setState({isSlideEditorDragResizing:true})
		this.timeline.handleSlideDragOrResizeStart()
	}

	handleSlideDragOrResizeEnd=()=>{
		this.setState({isSlideEditorDragResizing:false})
		this.timeline.handleSlideDragOrResizeEnd()
	}

	handleSlideImageFileUpload=async (file,replaceElementId,dropPosition)=>{
		const tempImageObject = await createTempImageObject(file);
		const elementId=replaceElementId ||randomID()
		const {clips,currentTime} = this.state
		let slideClipAtPlayhead=null 
		const slideClips = filter(clips,{type:'slide'})
	
		slideClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				slideClipAtPlayhead = clip
			}
		})

		let clipId
		if (slideClipAtPlayhead) {
			if(replaceElementId){
				this.timeline.updateImageElementImage(slideClipAtPlayhead.id,tempImageObject,replaceElementId)
			}else{
				this.timeline.addImageElementToSlide(slideClipAtPlayhead.id,tempImageObject,elementId,dropPosition)
				this.setState({selectedSlideItems:[{type:'element',id:elementId}]})
			}
			this.updateStateFromTimeline()
			clipId=slideClipAtPlayhead.id
		} else{
			clipId=randomID()
			this.addSlide(tempImageObject,elementId,clipId)
		}
		const imageId = randomID()
		this.props.uploadImageAndHandleResponse(file,imageId).then((response)=>{
			this.timeline.updateImageWithUploadResponse(clipId,elementId,response)
			this.updateStateFromTimeline
		})
	}


	//put this through seperate thing to other metadata so we can do font loading
	updateSlideTextElementTextProperties=(clipId,elementId,textStyle,newTextProperties)=>{
		this.timeline.updateSlideTextElementTextProperties(clipId, elementId,textStyle,newTextProperties)
		const activeClipId = this.state.activePanelClip.id
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
		this.updateStateFromTimeline()
	}

	handleTextElementFontLoaded=()=>{
		this.updateStateFromTimeline()
	}

	updateSlideElementMetadata=(clipId,elementId,newMetadata)=>{ 
		this.timeline.updateSlideElementMetadata(clipId, elementId,newMetadata)
		const activeClipId = this.state.activePanelClip.id
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
		this.updateStateFromTimeline()
	}

	updateSlideElementField=(clipId,elementId,field,value)=>{ 
		this.timeline.updateSlideElementField(clipId, elementId,field,value)
		this.updateStateFromTimeline()
	}

	updateSlideElementAnimationIndex=(clipId,elementId,newIndex)=>{
		this.timeline.updateSlideElementAnimationIndex(clipId, elementId,newIndex)
		this.updateStateFromTimeline()
	}

	updateSlideTextElementText=(lettersArray,text,docJson,clipId,elementId)=>{
		this.timeline.updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId)
		this.timeline.calculateUniqueVariables()
		this.updateStateFromTimeline()
	}


	updateSlideElementZOrder = (clipId,elementId,updateType)=>{
		this.timeline.updateSlideElementZOrder(clipId,elementId,updateType)
		this.updateStateFromTimeline()
	}

	//either insert image or replace image if there is a replaceElementId
	insertImageFromRecent=(imageObj,replaceElementId)=>{
		const {clips,currentTime} = this.state
		let slideClipAtPlayhead=null 
		const slideClips = filter(clips,{type:'slide'})
		slideClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				slideClipAtPlayhead = clip
			}
		})
		if (slideClipAtPlayhead) {
			if(replaceElementId){
				this.timeline.updateImageElementImage(slideClipAtPlayhead.id,imageObj,replaceElementId)
			}else{
				const newElementId = randomID()
				this.timeline.addImageElementToSlide(slideClipAtPlayhead.id,imageObj,newElementId)
				this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
			}
			this.updateStateFromTimeline()
    } else{
    	this.addSlide(imageObj)
    }
	}

	handleDimensionsUpdatedFromDetailPanel=()=>{
		const clip=this.state.activePanelClip
		this.timeline.debouncedSaveProjectTimeline()
		this.updateStateFromTimeline()
		this.pmManager.syncSlideClip(clip)
	}

	//TODO this is sleected slide items now
	handleAlignSlideElements=(alignType,alignValue)=>{
		const {selectedSlideElements}= this.state 
		const activeClipId = this.state.activePanelClip.id
		this.timeline.alignSlideElements(activeClipId,selectedSlideElements,alignType,alignValue)
		this.updateStateFromTimeline()
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
	}

	duplicateSlideItems = ()=>{
		const {selectedSlideItems} = this.state
		let duplicateItemIds=[]
		selectedSlideItems.forEach((item)=>{
			duplicateItemIds.push({originalId:item.id,duplicateItemId:randomID(),type:item.type})
		})
		const activeClipId = this.state.activePanelClip.id
		this.timeline.duplicateSlideItems(activeClipId,duplicateItemIds)
		this.updateStateFromTimeline()
		const newActivePanelClip = this.getClipForId(activeClipId)
		let newSelectedSlideItems=[]
		duplicateItemIds.forEach((duplicateItem)=>{
			newSelectedSlideItems.push({type:duplicateItem.type,id:duplicateItem.duplicateItemId})
		})
		this.closeSlideEditorContextMenu()
		this.setState({activePanelClip:newActivePanelClip,selectedSlideItems:newSelectedSlideItems})	
	}

	openSlideEditorContextMenu=(elementId,position)=>{
		//check if clicked element is in the current selectedSlideItems
		//if so just keep the selection and the context menu actions apply to all the selected things
		const isClickedElementSelected = this.state.selectedSlideItems.some(
			selectedItem => selectedItem.id === elementId 
		);
		if(!isClickedElementSelected){
			this.setState({selectedSlideItems:[{ type: 'element', id: elementId }]})
		}
			this.setState({slideEditorContextMenuElementId:elementId,slideEditorContextMenuPosition:position})
	}

	  closeSlideEditorContextMenu=()=>{
	  	this.setState({slideEditorContextMenuElementId:null,slideEditorContextMenuPosition:null})
	  }

		handleClickOutside = (event) => {
			if (this.state.slideEditorContextMenuElementId && 
				!event.target.closest('.slideEditorContextMenu')) {
				this.closeSlideEditorContextMenu();
			}
		};

		handleContextMenuButtonClick=(updateType)=>{
			this.updateSlideElementZOrder(this.state.activePanelClip.id,this.state.slideEditorContextMenuElementId,updateType)
			this.closeSlideEditorContextMenu()
		}

		handleSetSlideEditorDragElement=(dragElementId,dragLayoutGroup)=>{
			this.setState({slideEditorDragElementId:dragElementId,slideEditorDragLayoutGroupId:dragLayoutGroup})
		}

		handleSetSlideEditorResizeElement=(slideEditorResizeElementId)=>{
			this.setState({slideEditorResizeElementId:slideEditorResizeElementId})
		}

		updateSlideElementTextSelectionInfo=(info)=>{
			this.setState({slideElementTextSelectionInfo:info})
		}

	clearSlideElementsSelection=()=>{
		this.setState({selectedSlideItems:[],focusedSlideElement:null})
		this.clearUnselectedTextElementSelections([]);
	}

	setFocusedSlideElement=(elementId)=>{
		this.setState({focusedSlideElement:elementId})
	}

	setSlideEditorSelection=(items)=>{
		this.setState({selectedSlideItems:items})
		//clear the prosmirror selection for any text elements that are not selected
		this.clearUnselectedTextElementSelections(items);
	}

	clearUnselectedTextElementSelections = (newlySelectedItems) => {
	  if (this.state.activePanelClip && this.state.activePanelClip.type === 'slide') {
	    const selectedIds = new Set(newlySelectedItems.map(item => item.id));

	    this.state.activePanelClip.elements.forEach(element => {
	      if (element.type === 'text' && !selectedIds.has(element.id)) {
	        // Clear selection for this text element
	        this.clearSelectionForElement(element.id);
	      }
	    });
	  }
	}

	clearSelectionForElement = (elementId) => {
	  const view = window[`textEditView_${elementId}`];
	  if (view) {
	    const tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, 0));
	    view.dispatch(tr);
	  }
	}

	selectSlideItem = (item, commandKeyDown) => {
    if (!commandKeyDown) {
      this.setState({ selectedSlideItems: [item] });
    } else {
      this.setState(prevState => {
        const isItemSelected = prevState.selectedSlideItems.some(
          selectedItem => selectedItem.id === item.id && selectedItem.type === item.type
        );
        if (isItemSelected) {
          return {
            selectedSlideItems: prevState.selectedSlideItems.filter(
              selectedItem => selectedItem.id !== item.id || selectedItem.type !== item.type
            )
          };
        } else {
          return { selectedSlideItems: [...prevState.selectedSlideItems, item] };
        }
      });
    }
  }

  groupSlideItems = (groupingType) => {
  	if(this.state.selectedSlideItems.length>1){
	    const clip = this.state.activePanelClip;
	    const newLayoutGroupId=randomID()
	    this.timeline.groupSlideItems(clip.id, this.state.selectedSlideItems, groupingType,newLayoutGroupId);
	    this.updateStateFromTimeline();
	    this.setState({selectedSlideItems:[{type: 'layoutGroup', id: newLayoutGroupId }]})
	    this.closeSlideEditorContextMenu()
  	}
  }

	ungroupLayoutGroup=(layoutGroupId)=>{
		this.timeline.ungroupSlideLayoutGroup(this.state.activePanelClip.id,layoutGroupId)
		this.updateStateFromTimeline()
		this.setState({selectedSlideItems:[]})
	}

	toggleShowCaptions=()=>{
		this.timeline.toggleShowCaptions()
		this.updateStateFromTimeline()
	}

	useSlideTemplate=(template)=>{
		const clip = this.state.activePanelClip;
		this.timeline.useSlideTemplate(clip.id, template);
		this.updateStateFromTimeline();
		this.updateActivePanelClipInState()
	}

	updateActivePanelClipInState=()=>{ //update the clip object in state after changes made on clip
		const newActivePanelClip = this.getClipForId(this.state.activePanelClip.id)
		this.setState({activePanelClip:newActivePanelClip})
	}

	alignSlideItems=(alignType,alignValue)=>{
		const clip = this.state.activePanelClip;
    this.timeline.alignSlideItems(clip.id, this.state.selectedSlideItems, alignType,alignValue);
    this.updateStateFromTimeline();
	}

	setIsDraggingToReorder=(value)=>{

		this.setState({isDraggingToReorder:value})
	}

	saveSlideAsTemplate=()=>{
		this.props.saveSlideAsTemplate(this.props.projectId, this.state.activePanelClip)
	}

	deleteClip=(clipId,refreshTranscriptPanel)=>{
		if(this.state.activePanelClip && this.state.activePanelClip.id==clipId){
			this.handleDeactivateActiveClip()
		}
		this.timeline.deleteClipById(clipId)
		this.updateStateFromTimeline()
		this.setFocusToEditor()
		if(refreshTranscriptPanel && this.timeline){
			this.timeline.updateTranscriptFromTimeline()
			this.timeline.calculateAudioTrackSpacing()
		}
	}

	maybeCopyClip=()=>{
		if(this.state.activePanelClip){
			this.copyClip(this.state.activePanelClip)
		}
	}

	 handleSetIsBGImage = (element,isBGImage) => {
	 	if(this.state.activePanelClip){
	 		const clipId = this.state.activePanelClip.id
			this.updateSlideElementMetadata(clipId,element.id,{...element.metadata,isBGImage:isBGImage,isFill:true,zOrder:-1})
			this.closeSlideEditorContextMenu()
	 	}
  }  

  handleSetShowReplaceImageMediaModal=(value)=>{
  	this.setState({showReplaceImageMediaModal:value})
  	this.closeSlideEditorContextMenu()
  }


  handleExportProject=()=>{
  	ExportManager.startExport(this.props.projectId)
  }

  handleCancelExportProject=()=>{
  	ExportManager.cancelExport(this.props.projectId)
  }

  
  handleChangeSubtitlesType=(type)=>{ //subtitles can be null, standard or captions
  	this.props.updateProjectSubtitlesType(this.props.projectId,type)
  	this.setState({subtitlesType:type})
  }

	handleGenerateSRTFile = () => {
  	const { clips } = this.state;	
  	const audioClips = filter(clips, { type: 'audio' });
  	const srtContent = generateSRTFile(audioClips);
	  const blob = new Blob([srtContent], { type: 'text/plain;charset=utf-8' });	
	  const url = URL.createObjectURL(blob);
	  const link = document.createElement('a');
  	link.href = url;
  	link.download = `${this.props.project.name}_subtitles.srt`; // Set the desired filename
	  document.body.appendChild(link);
	  link.click();
	  document.body.removeChild(link);
  	URL.revokeObjectURL(url);
	}
  

  regenerateAllSubtitles=()=>{
  	const { clips } = this.state;	
  	const audioClips = filter(clips, { type: 'audio' });
  	audioClips.forEach((clip)=>{
  		clip.calculateSubtitles()
  	})
  }


	render(){     

		const timelineHeight = 238
		let centerContainerHeight = this.state.windowHeight - timelineHeight - 40// 34 is topbar height		
		const {clips,duration,currentTime,isPlaying, isDragResizingMedia, isDragResizingNumberInput}=this.state
		//const timelineHeight = 255
		//let centerContainerHeight = this.state.windowHeight - timelineHeight - 42// 34 is topbar height		
		const voiceClips = filter(clips,{zIndex:-1})
		const sortedVoiceClips = sortBy(voiceClips,'startTime')
		const {meshHeight, meshWidth, zoomBox} = this.state

		let activeScreenVideoAtPlayhead=false 
		if(!isPlaying){
			const videoClips = filter(clips,{type:'video'})
				videoClips.forEach((clip)=>{
				if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
					activeScreenVideoAtPlayhead = true
				}
			})
		}

		let slideClipAtPlayhead=null 
		if(!isPlaying){
			const slideClips = filter(clips,{type:'slide'})
				slideClips.forEach((clip)=>{
				if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
					slideClipAtPlayhead = clip
				}
			})
		}


		const {pmDoc} = this.state

		let showPMPanel = false 
		if(this.props.user && this.props.user.email=='nicole@yarn.so'){
			showPMPanel=false
		}

		const {scenes,pixelsPerSec,maxTimelineDurationSeconds, showTranscriptPanel} = this.state
		const preventSelection = this.state.isDnDMode || this.state.isDragging || this.state.isDraggingProgressMarker
		const {dragClipNewStartTime} = this.state
		//const showMajorReleaseBanner = !this.props.userSettings.updateBannerSlidesDismissed
		const showMajorReleaseBanner = false
		const {slideEditorContextMenuPosition,slideEditorContextMenuElementId, activePanelClip}=this.state
		const showTemplateBar = activePanelClip && activePanelClip.type === 'slide'  // when slide is selected

		const {subtitlesType} = this.state

		//console.log(this.props.projectId)

		// clips.forEach((clip)=>{
		// 	if(clip.type=='slide'){
		// 		clip.elements.forEach((element)=>{
		// 			console.log(element)
		// 		})
		// 	}
		// })
		//console.log(clips)
		
		return (            
			<Tooltip.Provider>
				
				<UpdatesMajorReleaseBanner 
	      	animateIn={true}
	      	showMajorReleaseBanner={showMajorReleaseBanner}
	      />
	      

	      {slideEditorContextMenuElementId &&
	      <SlideEditorContextMenu 
	      	left={slideEditorContextMenuPosition.x}
	      	top={slideEditorContextMenuPosition.y}
	      	updateZOrder={this.handleContextMenuButtonClick}
	      	duplicateSlideItems={this.duplicateSlideItems}
	      	deleteSlideItems={this.deleteSlideItems}
	      	selectedSlideItems={this.state.selectedSlideItems}
	      	activePanelClip={this.state.activePanelClip}
	      	handleSetIsBGImage={this.handleSetIsBGImage}
	      	handleSetShowReplaceImageMediaModal={this.handleSetShowReplaceImageMediaModal}
	      	groupSlideItems={this.groupSlideItems}
	      />
	    }

				<div ref={this.editorRef} tabIndex="-1"  className={'editor ' + (isDragResizingMedia ? ' editor--isDragResizingMedia ' : '') + (isDragResizingNumberInput ? ' editor--isDragResizingNumberInput ' : ' editor--isNotDragResizingNumberInput ') + (showPMPanel ? ' editor--showPMPanel ' : '')} data-dragging-slide-editor-spacer={this.state.slideEditorDraggingSpacer ? this.state.slideEditorDraggingSpacer : false} data-dragging-to-reorder={this.state.isDraggingToReorder ? 'true' : 'false'} data-prevent-selection={preventSelection ? true : false} >


					<div className='editor-tabBarSpacer'>
						<EditorStatusBar 
							projectBackgroundId={this.state.projectBackgroundId}
							handleUpdateProjectBackground={this.handleUpdateProjectBackground}
							defaultMotionStyle={this.state.defaultMotionStyle}
							handleUpdateDefaultMotionStyle={this.handleUpdateDefaultMotionStyle}
							projectId={this.props.projectId}
							isEmpty={clips.length == 0}
							subscriptionStatus={this.props.subscriptionStatus}
							refreshProjectVariables={this.refreshProjectVariables}
							projectVariables={this.state.projectVariables}
							projectName={this.state.projectName}
							handleExportProject={this.handleExportProject}
							cancelExportProject={this.handleCancelExportProject}
							exportState={this.props.exportState}
							project={this.props.project}
						/>
					</div>
					<div style={{height: `${centerContainerHeight}px`}} className='editor-center'>

						{!showTranscriptPanel &&
							<button onClick={()=> this.setState({showTranscriptPanel: true})} className='editor-center-showTranscriptPanelBtn'>
								<Icon name='doubleChevronLeft' />
							</button>
						}

						{showTranscriptPanel &&
							<>
								<div className='editor-center-leftEdgeSpacer' />
								<div className='editor-center-leftContainer'>
									<div className='editor-center-panelContainer'>														
										<TranscriptPanel
											key={this.state.voiceoverKey} //force reload when paste clips in
											voiceoverClips={sortedVoiceClips}      
											activeVoice={this.state.activeVoice}
											handleChangeActiveVoice={this.handleChangeActiveVoice}
											handleChangeVoiceoverPlaybackRate={this.handleChangeVoiceoverPlaybackRate}
											activeVoiceoverPlaybackRate={this.state.voiceoverPlaybackRate}
											transcriptPmManager={this.transcriptPmManager}
											scenes={scenes}
											transcriptChunksForDragHandles={this.state.transcriptChunksForDragHandles}
											transcriptSceneHeaders={this.state.transcriptSceneHeaders}
											hoveredTranscriptChunk={this.state.hoveredTranscriptChunk}
											handleTranscriptPanelChunkDrop={this.handleTranscriptPanelChunkDrop}
											transcriptChunkWithCursorInside={this.state.transcriptChunkWithCursorInside}
											playClipFromTranscript={this.playClipFromTranscript}
											recalculateAudioClip={this.recalculateAudioClip}
											previewingAudioClipId={this.state.previewingAudioClipId}
											cancelPreviewingAudioClip={this.cancelPreviewingAudioClip}
											mergeScene={this.mergeScene}
											addSceneAtEndOfProject={this.addSceneAtEndOfProject}
											addAudioClipAtEndOfProject={this.addAudioClipAtEndOfProject}
											hideTranscriptPanel={()=> this.setState({showTranscriptPanel: false})}
											//toggleShowCaptions={this.toggleShowCaptions}
											//showCaptions={this.state.showCaptions}
											//generateSubtitles={this.generateSubtitles}
											subtitlesType={subtitlesType}
											handleChangeSubtitlesType={this.handleChangeSubtitlesType}
											handleGenerateSRTFile={this.handleGenerateSRTFile}
											regenerateAllSubtitles={this.regenerateAllSubtitles}
										/>
										{/* THIS IS FOR GRAPH ONLY -- PORT TO DETAIL PANEL */}
										<EditorRightPanelContainer 
											activePanelClip={this.state.activePanelClip}
											deleteClip={this.deleteClip}
											projectBackgroundId={this.state.projectBackgroundId}
											updateChartClip={this.updateChartClip}
											defaultMotionStyle={this.state.defaultMotionStyle}
											updateChartBackgroundColor={this.updateChartBackgroundColor}
										/>
									</div>
								</div>  
							</> 
						} 

						{showPMPanel && 
							<div className='editor-devPanel'>
								<PMPanel
									pmDoc={this.state.pmDoc}
								/>
							</div>
						}
						
						<div className='editor-center-center'>
							<div onClick={this.clearSlideElementsSelection} className='editor-backgroundClick' />
							<div className='editor-center-center-templateBarContainer'>
								{showTemplateBar &&
									<>
									<TemplateBar 
										useSlideTemplate={this.useSlideTemplate}
										projectBackgroundId={this.state.projectBackgroundId}
										currentSlideBackgroundId={activePanelClip.metadata.backgroundId}
										saveSlideAsTemplate={this.saveSlideAsTemplate}
									/>				
									<div className='editor-center-center-templateBarContainer-edgeContainer'/>													
									</>
								}

								<EditorTopBar 
									activePanelClip={this.state.activePanelClip}
									updateClipAnimationSettings={this.updateClipAnimationSettings}
									updateClipMetadata={this.updateClipMetadata}
									updateSlideClipTextStyle={this.updateSlideClipTextStyle}
									activeZoomClip={false}
									meshWidth={meshWidth}
									meshHeight={meshHeight}									
									updateZoomValues={this.updateZoomValues}
									updateZoomBox={this.updateZoomBox}
									deleteClip={this.deleteClip}
									previewAnimation={this.previewAnimation}
									previewClip={this.state.previewClip}
									previewType={this.state.previewType}
									resetPreview={this.resetPreview}
									projectBackgroundId={this.state.projectBackgroundId}
									updateTextSlideTextColor={this.updateTextSlideTextColor}
									updateTextSlideBackgroundColor={this.updateTextSlideBackgroundColor}
									handleDeactivateActiveClip={this.handleDeactivateActiveClip}
									getClipForId={this.getClipForId}
									trimMode={this.state.trimMode}
									toggleTrimMode={this.toggleTrimMode}
									updateChartClip={this.updateChartClip}
									defaultMotionStyle={this.state.defaultMotionStyle}
									updateChartBackgroundColor={this.updateChartBackgroundColor}
									updateClipBackgroundColor={this.updateClipBackgroundColor}
									changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
									addSlideElement={this.addSlideElement}
									selectedSlideItems={this.state.selectedSlideItems}
									updateSlideElementMetadata={this.updateSlideElementMetadata}
									insertImageFromRecent={this.insertImageFromRecent}	
									updateSlideBackgroundColor={this.updateSlideBackgroundColor}
									updateSlideAlignment={this.updateSlideAlignment}				
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}	
									addZoom={this.addZoom}	
									handleAlignSlideElements={this.handleAlignSlideElements}	
									alignSlideItems={this.alignSlideItems}	
								/>
							</div>
							
							<div className='editor-center-center-mainContainer'>
								<EditorCanvasOuterContainer 
									currentTime={currentTime}
									clips={clips}
									textSlideClips = {filter(clips, {zIndex: 1, type: 'textSlide'})}
									slideClips = {filter(clips, {zIndex: 1, type: 'slide'})}
									audioClips = {filter(clips, {type: 'audio'})}
									imageClips = {filter(clips, {zIndex: 1, type: 'image'})}
									chartClips = {filter(clips, {type: 'chart'})}
									videoClips = {filter(clips, {zIndex: 0})}
									zoomClips = {filter(clips, {zIndex: 2})}
									isPlaying = {isPlaying}
									currentTime={currentTime}
									updateSlideTextElementText={this.updateSlideTextElementText}
									activePanelClip={this.state.activePanelClip}
									handleSetActivePanelClip={this.handleSetActivePanelClip}
									updateMeshWidth={this.updateMeshWidth}
									updateMeshHeight={this.updateMeshHeight}
									zoomBox={zoomBox}
									updateZoomBox={this.updateZoomBox}
									updateZoomValues={this.updateZoomValues}
									projectBackgroundId={this.state.projectBackgroundId}
									textSlideEditorKey={this.state.textSlideEditorKey}
									trimMode={this.state.trimMode}
									toggleTrimMode={this.toggleTrimMode}
									trimPreviewPlaying={this.state.trimPreviewPlaying}
									trimPreviewProgress={this.state.trimPreviewProgress}
									onTrimPreviewPlayPause={this.onTrimPreviewPlayPause}
									onTrimPreviewSeekChange={this.onTrimPreviewSeekChange}   
									isDraggingProgressMarker={this.state.isDraggingProgressMarker}    
									activeBrand={this.state.activeBrand} 		
									testKey={this.state.testKey}			        					
									updateClipDisplayWidth={this.updateClipDisplayWidth}
									isDragResizingMedia={this.state.isDragResizingMedia}
									startDragResizingMedia={()=>{this.setState({isDragResizingMedia: true})}}
									stopDragResizingMedia={()=>{this.setState({isDragResizingMedia: false})}}
									projectBackgroundId={this.state.projectBackgroundId}
									setSlideEditorSelection={this.setSlideEditorSelection}
									selectedSlideItems={this.state.selectedSlideItems}
									updateSlideElementMetadata={this.updateSlideElementMetadata}
									clearSlideElementsSelection={this.clearSlideElementsSelection}
									selectedLayoutGroup={this.state.selectedLayoutGroup}
									updateSlideElementField={this.updateSlideElementField}
									setFocusedSlideElement={this.setFocusedSlideElement}
									focusedSlideElement={this.state.focusedSlideElement}
									duplicateSlideItems={this.duplicateSlideItems}
									openSlideEditorContextMenu={this.openSlideEditorContextMenu}
									handleSetSlideEditorDragElement={this.handleSetSlideEditorDragElement}
									slideEditorDragElementId={this.state.slideEditorDragElementId}
									slideEditorDragLayoutGroupId={this.state.slideEditorDragLayoutGroupId}
									updateSlideElementTextSelectionInfo={this.updateSlideElementTextSelectionInfo}
									handleSetSlideEditorResizeElement={this.handleSetSlideEditorResizeElement}
									slideEditorResizeElementId={this.state.slideEditorResizeElementId}
									slideEditorDraggingSpacer={this.state.slideEditorDraggingSpacer}
									setSlideEditorDraggingSpacer={(value)=>{this.setState({ slideEditorDraggingSpacer: value })}}
									handleSyncSlideChanges={this.handleSyncSlideChanges}
									showCaptions={this.state.showCaptions}
									setIsDraggingToReorder={this.setIsDraggingToReorder}
									isDraggingToReorder={this.state.isDraggingToReorder}
									groupSlideItems={this.groupSlideItems}
									ungroupLayoutGroup={this.ungroupLayoutGroup}
									handleSlideDragOrResizeStart={this.handleSlideDragOrResizeStart}
									handleSlideDragOrResizeEnd={this.handleSlideDragOrResizeEnd}
									handleUpdateCanvasFromSlideEditor={this.handleUpdateCanvasFromSlideEditor}
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}
									subtitlesType={subtitlesType}
								/>		


{/*

								<EditorShortcutsPopover
			            showModal={this.state.showShortcutsPopover}   
			            closeModal={this.closeShortcutsPopover}         
			          >
			            <div className='editor-center-center-mainContainer-shortcutsPopoverTrigger' />						                  
			          </EditorShortcutsPopover>

			          */}

			          <div className='editor-center-center-detailPanelContainer'>
									<EditorDetailPanel 
										activePanelClip={this.state.activePanelClip}
										isDragResizingNumberInput={isDragResizingNumberInput}
										setIsDragResizingNumberInput={(value) => this.setState({ isDragResizingNumberInput: value })}
										updateSlideAlignment={this.updateSlideAlignment}
										selectedSlideItems={this.state.selectedSlideItems}
										groupSlideItems={this.groupSlideItems}
										handleDimensionsUpdatedFromDetailPanel={this.handleDimensionsUpdatedFromDetailPanel}
										updateSlideElementAnimationIndex={this.updateSlideElementAnimationIndex}
										updateSlideBackgroundColor={this.updateSlideBackgroundColor}
										updateClipAnimationSettings={this.updateClipAnimationSettings}
										updateClipMetadata={this.updateClipMetadata}
										updateSlideClipTextStyle={this.updateSlideClipTextStyle}
										activeZoomClip={false}
										meshWidth={meshWidth}
										meshHeight={meshHeight}									
										updateZoomValues={this.updateZoomValues}
										updateZoomBox={this.updateZoomBox}
										deleteClip={this.deleteClip}
										previewAnimation={this.previewAnimation}
										previewClip={this.state.previewClip}
										previewType={this.state.previewType}
										resetPreview={this.resetPreview}
										projectBackgroundId={this.state.projectBackgroundId}
										updateTextSlideTextColor={this.updateTextSlideTextColor}
										updateTextSlideBackgroundColor={this.updateTextSlideBackgroundColor}
										getClipForId={this.getClipForId}
										trimMode={this.state.trimMode}
										toggleTrimMode={this.toggleTrimMode}
										updateChartClip={this.updateChartClip}
										defaultMotionStyle={this.state.defaultMotionStyle}
										updateChartBackgroundColor={this.updateChartBackgroundColor}
										updateClipBackgroundColor={this.updateClipBackgroundColor}
										changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
										addSlideElement={this.addSlideElement}
										updateSlideElementMetadata={this.updateSlideElementMetadata}
										insertImageFromRecent={this.insertImageFromRecent}	
										updateSlideBackgroundColor={this.updateSlideBackgroundColor}
										updateSlideAlignment={this.updateSlideAlignment}				
										handleSlideImageFileUpload={this.handleSlideImageFileUpload}	
										updateSlideElementZOrder={this.updateSlideElementZOrder}
										selectedLayoutGroup={this.state.selectedLayoutGroup}
										updateLayoutGroupField={this.updateLayoutGroupField}
										focusedSlideElement={this.state.focusedSlideElement}
										slideElementTextSelectionInfo={this.state.slideElementTextSelectionInfo}
										ungroupLayoutGroup={this.ungroupLayoutGroup}
										projectId={this.props.projectId}
										updateSlideTextElementTextProperties={this.updateSlideTextElementTextProperties}
										showReplaceImageMediaModal={this.state.showReplaceImageMediaModal}
										handleSetShowReplaceImageMediaModal={this.handleSetShowReplaceImageMediaModal}
									/>
								</div>
							</div>
							
							{/*}
							<div className='editor-center-center-topbarContainer'>

							</div>
							*/}

							<div className='editor-center-center-toolbarContainer'>
								<EditorToolbar
									duration={duration}
									currentTime={currentTime}
									isPlaying={isPlaying}
									isPreviewing={this.state.isPreviewing}
									prePreviewState={this.state.prePreviewState}
									togglePlayPause={this.togglePlayPause}
									addSlide={this.addSlide}
									addRecordingFromVideoLibrary={this.addRecordingFromVideoLibrary}
									addZoom={this.addZoom}
									handleSeek={this.handleSeek}
									splitRecordingClip={this.splitRecordingClip}
									splitScene={this.splitScene}
									activeScreenVideoAtPlayhead={activeScreenVideoAtPlayhead}
									isEmpty={clips.length == 0}						
									handleNewVideoFileUpload={this.handleNewVideoFileUpload}
									insertImageFromRecent={this.insertImageFromRecent}
									insertVideoFromRecent={this.insertVideoFromRecent}
									userIsProMode={this.props.userIsProMode}
									isRyanDriverAI={this.props.isRyanDriverAI}
									isDnDMode={this.state.isDnDMode}
									addFreezeFrame={this.addFreezeFrame}
									addSkipSegment={this.addSkipSegment}
									addSlideElement={this.addSlideElement}
									activePanelClip={this.state.activePanelClip}
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}
									handleNewWebcamFileUpload={this.handleNewWebcamFileUpload}
									insertWebcamFromRecent={this.insertWebcamFromRecent}
									slideClipAtPlayhead={slideClipAtPlayhead}

								/>
								{this.state.trimMode && this.state.activePanelClip && (this.state.activePanelClip.captureId || this.state.activePanelClip.isBasicVideo) &&
									<EditorTrimBar 
										trimPreviewPlaying={this.state.trimPreviewPlaying}
										trimPreviewProgress={this.state.trimPreviewProgress}
										onTrimPreviewPlayPause={this.onTrimPreviewPlayPause}
										onTrimPreviewSeekChange={this.onTrimPreviewSeekChange}
										clip={this.state.activePanelClip}
										updateClipTrimValues={this.updateClipTrimValues}
									/>
								}
							</div>
						</div>


						
					</div>


					{/*}
					<div className='editor-projectIDDev'>
						{this.props.projectId}
					</div>
							
					<div className='editor-projectIDDev'>
						{this.props.projectId}
					</div>
					*/}


					
					<div style={{height: `${timelineHeight}px`}} className='editor-timelinePanel'>						
						{this.state.trimMode && 
							<div className='editor-timelinePanel-trimModeBlocker' />
						}
						
						<ScenesTimeline 
							scenes={scenes}
							pixelsPerSec={pixelsPerSec}  
							clips={clips}
							onDragStart={this.onDragStart}
							onDragEnd={this.onDragEnd}
							isDragging={this.state.isDragging}
							dragClipId={this.state.dragClipId}
							handleDragClip={this.handleDragClip}
							isDnDMode={this.state.isDnDMode}
							onResizeStart={this.onResizeStart}
							onResizeStop={this.onResizeStop}
							onResize={this.onResize}
							duration={this.state.duration}
							currentTime={currentTime}
							maxTimelineDurationSeconds={maxTimelineDurationSeconds}
							handleSeek={this.handleSeek}
							handleMouseDownProgressMarker={this.handleMouseDownProgressMarker}
							handleMouseUpProgressMarker={this.handleMouseUpProgressMarker}
							duplicateClip={this.duplicateClip}
							deleteClip={this.deleteClip}
							copyClip={this.copyClip}
							cutClip={this.cutClip}
							changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
							handleChangeSegmentPlaybackRate={this.handleChangeSegmentPlaybackRate}
							addFreezeFrame={this.addFreezeFrame}
							handleSetActivePanelClip={this.handleSetActivePanelClip}
							activePanelClip={this.state.activePanelClip}
							removeFreeze={this.removeFreeze}
							removeSkip={this.removeSkip}
							splitRecordingClip={this.splitRecordingClip}
							dragClipNewStartTime={dragClipNewStartTime}
							mergeScene={this.mergeScene}
							splitScene={this.splitScene}
							addSkipSegment={this.addSkipSegment}
							addSceneAfterScene={this.addSceneAfterScene}							
							timelineCmdIsDown={false} // temp
							toggleSkipSegment={this.toggleSkipSegment}
							handleChangeSkipSegmentDuration={this.handleChangeSkipSegmentDuration}
							isResizingSkipSegment={this.state.resizingSkipSegmentHandle!=null}
							resizingSkipSegmentHandle={this.state.resizingSkipSegmentHandle}
							handleResizeSkipSegmentStart={this.handleResizeSkipSegmentStart}
							handleResizeSkipSegmentStop={this.handleResizeSkipSegmentStop}
							preSkipResizeCurrentTime={this.state.preSkipResizeCurrentTime}
							scrollRef={this.scrollContainerRef}
							isPlaying={this.state.isPlaying}
							calculateAndSetDropLines={this.calculateAndSetDropLines}
							dropLines={this.state.dropLines}
							setIsDraggingProgressMarker={(value) => this.setState({isDraggingProgressMarker: value})}
						/>
						

						<EditorTimelineFooter 
							handleTimelineZoomChange={this.handleTimelineZoomChange}
							updateBackgroundMusicTrack={this.updateBackgroundMusicTrack}
							backgroundMusicVolume={this.state.backgroundMusicVolume}
							updateBackgroundMusicVolume={this.updateBackgroundMusicVolume}
							activeBackgroundMusicTrack = {this.state.backgroundMusicTrack}
							initialSliderValue={defaultTimelineZoom}
							onZoomToFit={this.handleTimelineZoomToFit}
							isEmpty={clips.length == 0}
							userIsProMode={this.props.userIsProMode}
							handleNewMusicFileUpload={this.handleNewMusicFileUpload}
							addSceneToTimeline={this.addSceneToTimeline}
							deleteScene={this.deleteScene}
							timelineCmdIsDown={false} // temp
							showShortcutsPopover={this.state.showShortcutsPopover}
							openShortcutsPopover={this.openShortcutsPopover}
							closeShortcutsPopover={this.closeShortcutsPopover}
						/>
					</div>      
				</div>
			</Tooltip.Provider>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const project = find(state.projects,{id:`${ownProps.projectId}`})
	let subscriptionStatus=state.organization.subscription_status 
	let userIsProMode=false 
	if(state.user && state.user.is_pro_mode){
		userIsProMode=true
	}
	let isRyanDriverAI =false 
	if(state.user && state.user.id==10){
		isRyanDriverAI=true
	}

	const exportState = state.projectExports[ownProps.projectId];
	return {
		project:project,
		subscriptionStatus:subscriptionStatus,
		userIsProMode:userIsProMode,
		isRyanDriverAI:isRyanDriverAI,
		userSettings:state.userSettings,
		user:state.user,
		copiedClip: state.clipActions.copiedClip,
		exportState: exportState
	}
}
export default connect(
		mapStateToProps,
		{
			updateProjectBackground,
			syncNewScreenRecording,
			syncNewRecording,
			updateProjectDefaultMotionStyle,
			uploadImageAndHandleResponse,
			uploadVideoAndHandleResponse,
			updateImageInsertCount,
			updateImageDefaultDisplayWidth,
			updateVideoInsertCount,
			updateVideoDefaultDisplayWidth,
			updateProMode,
			uploadMusicAndHandleResponse,
			fetchSingleProject,
			setCopiedClip,
			saveSlideAsTemplate,
			uploadWebcamVideoAndHandleResponse,
			generateSubtitles,
			updateProjectSubtitlesType
	}
)(Editor)


