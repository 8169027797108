import React, { useState } from 'react'
import * as Select from '@radix-ui/react-select';
import Icon from '../../misc/Icon';
import DPTabs from './DPTabs';
import DPAnimationPanelTransitionPopover from './DPAnimationPanelTransitionPopover'
import DPSlider from './DPSlider';
import DPNumberInput from './DPNumberInput';

const defaultStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade In", iconName: "fadeIn" },
  fadeAndMoveUp: { label: "Fade Up", iconName: "fadeUp" },
  fadeAndMoveDown: { label: "Fade Down", iconName: "fadeUp" },
  fadeAndMoveRight: { label: "Fade Left", iconName: "fadeRight" },
  fadeAndMoveLeft: { label: "Fade Right", iconName: "fadeRight" },
  fadeAndScaleUp: { label: "Scale Up", iconName: "scaleUp" },
  fadeAndScaleDown: { label: "Scale Down", iconName: "scaleDown" },  
};


const defaultEndTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade Out", iconName: "fadeIn" },
  fadeAndMoveUp: { label: "Fade Up", iconName: "fadeUp" },
  fadeAndMoveDown: { label: "Fade Down", iconName: "fadeUp" },
  fadeAndMoveRight: { label: "Fade Right", iconName: "fadeRight" },
  fadeAndMoveLeft: { label: "Fade Left", iconName: "fadeRight" },
  fadeAndScaleUp: { label: "Scale Up", iconName: "scaleDown" },
  fadeAndScaleDown: { label: "Scale Down", iconName: "scaleDown" },
};


const donutChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },  
  countUp: { label: "Count Up", iconName: "countUp" },
  fadeIn: { label: "Fade In", iconName: "fadeIn" },
  scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};

const barChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  countUp: { label: "Count Up", iconName: "countUp" },
  // fadeIn: { label: "Fade In", iconName: "fadeIn" },
  // scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};



const DPItemAnimationPanel = ({activeStartTransition,activeEndTransition,handleUpdateMetadata,itemType,animationGroupingType,item,handleUpdateChartAnimationType}) => {
  const [transitionPopoverOpen, setTransitionPopoverOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('start');

  const handleStartTransitionChange = (newTransition) => {
    const isChart = itemType=='barChart' || itemType=='donutChart'
    if(!isChart){
      handleUpdateMetadata('startTransitionType',newTransition)
    }
    else{
      handleUpdateChartAnimationType(newTransition)
    }     
    setTransitionPopoverOpen(false)
  }

  const handleEndTransitionChange = (newTransition) => {
    handleUpdateMetadata('endTransitionType',newTransition)
    setTransitionPopoverOpen(false)
  }

  const updateAnimationGrouping=(value)=>{
    handleUpdateMetadata('animationGrouping',value)
  }


  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  let activeStartTransitionConfig,activeEndTransitionConfig 
  let startTransitionConfigs,endTransitionConfigs

  if(itemType=='barChart'){
    activeStartTransitionConfig = barChartStartTransitionConfigs[activeStartTransition]
    startTransitionConfigs = barChartStartTransitionConfigs
  }else if(itemType=='donutChart'){
    activeStartTransitionConfig = donutChartStartTransitionConfigs[activeStartTransition]
    startTransitionConfigs = donutChartStartTransitionConfigs
  }else{
    activeStartTransitionConfig = defaultStartTransitionConfigs[activeStartTransition]
    activeEndTransitionConfig = defaultEndTransitionConfigs[activeEndTransition]
    startTransitionConfigs=defaultStartTransitionConfigs
    endTransitionConfigs=defaultEndTransitionConfigs
  }

  //if item type is chart we only show animate in options
  let tabOptions = [
    { id: 'start', label: 'Enter' },    
    { id: 'end', label: 'Exit' },
  ];
  if(itemType=='barChart' || itemType=='donutChart' ){
    tabOptions=[
      { id: 'start', label: 'Enter' }, 
    ]
  }

  const [showAnimationDetailsEnter, setShowAnimationDetailsEnter] = useState(false)


  const enterTransitionValueFactor = item.metadata.enterTransitionValueFactor || 0
  const enterTransitionSpeedFactor = item.metadata.enterTransitionSpeedFactor || 0
  const enterTransitionBouncinessFactor = item.metadata.enterTransitionBouncinessFactor || 0
  const wordEnterDelay = item.metadata.wordEnterDelay || 0.3 
  const lineEnterDelay = item.metadata.lineEnterDelay || 0.6

 const updateTransitionValues=(field,value)=>{
  handleUpdateMetadata(field,value)
}

  const animationSettingsAvailable = activeStartTransition !== 'none'
  const showAnimationScalarSetting = activeStartTransition !== 'fade'

  let animationScalarLabel = 'Distance Moved'  

  if(activeStartTransition === 'fadeAndScaleUp'){    
    animationScalarLabel = 'Initial Size'    
  }
  if(activeStartTransition === 'fadeAndScaleDown'){    
    animationScalarLabel = 'Initial Size'    
  }
  // console.log('activeStartTransition')
  // console.log(activeStartTransition)


  
  // const [enterGroupingWordDelay, setEnterGroupingWordDelay] = useState(0.3)
  // const [enterGroupingLineDelay, setEnterGroupingLineDelay] = useState(0.6)

  //console.log(`----->>>>animationGroupingType ${animationGroupingType}`)

  return (
    <div className='dpItemAnimationPanel'>
      <div className='dpItemAnimationPanel-header'>
        <DPTabs 
          tabOptions={tabOptions}
          activeTab={activeTab}
          onTabChange={handleTabChange}          
        />
        {/* FOR CUSTOM CONFIGS 
        <button className='dpButton dpButton--iconOnly dpButton--mid'>
          <div className='dpButton-iconContainer'>
            <Icon name='sliders' />
          </div>
        </button>
        */}
      </div>

      
        {activeTab === 'start' && 
          <div className='dpItemAnimationPanel-typeBtnContainer'>
            <DPAnimationPanelTransitionPopover 
              activeTransition={activeStartTransition}
              onTransitionChange={handleStartTransitionChange}
              transitionConfigs={startTransitionConfigs}
              showGroupingOptions={itemType === 'text'}
              animationGroupingType={animationGroupingType}
              updateAnimationGrouping={updateAnimationGrouping}
              modalMode={false} // tempted to make true for text
            >
              <button 
                onClick={() => setTransitionPopoverOpen(!transitionPopoverOpen)} 
                className='dpItemAnimationPanel-typeBtn'
              >
                <div className='dpItemAnimationPanel-typeBtn-iconContainer'>
                  <Icon name={activeStartTransitionConfig.iconName} />
                </div>
                <div className='dpItemAnimationPanel-typeBtn-label'>
                  {activeStartTransitionConfig.label}
                </div>
              </button>
            </DPAnimationPanelTransitionPopover>
          
          
          {animationSettingsAvailable &&
            <button data-active-state={showAnimationDetailsEnter ? 'active' : 'inactive'} onClick={() => setShowAnimationDetailsEnter(!showAnimationDetailsEnter)} className='dpItemAnimationPanel-typeBtnSettingsToggle'>
              <div className='dpItemAnimationPanel-typeBtnSettingsToggle-inner'>
                <Icon name='sliders' />
              </div>
            </button>          
          }
          
          
            
          {animationSettingsAvailable &&
          <div data-visibility-state={showAnimationDetailsEnter ? 'true' : 'false'} className='dpItemAnimationPanel-settingsToggleList'>              
            

            {showAnimationScalarSetting &&
            <DPSlider
              min={-1}
              max={1}          
              value={enterTransitionValueFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionValueFactor',value)}}               
              percentage
              label={animationScalarLabel}
              centerZero        
              step={0.01}
              width={228}
            />
            }

            <DPSlider
              min={-1}
              max={1}          
              value={enterTransitionSpeedFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionSpeedFactor',value)}}               
              percentage
              label='Speed'
              centerZero        
              step={0.01}
              width={228}
            />
            <DPSlider
              min={-1}
              max={1}          
              value={enterTransitionBouncinessFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionBouncinessFactor',value)}}               
              percentage
              label='Bounciness'
              centerZero        
              step={0.01}
              width={228}
            />

            {animationGroupingType === 'word' &&
              <DPSlider
                min={0}
                max={2}
                value={wordEnterDelay}
                setValue={(value)=>{updateTransitionValues('wordEnterDelay',value)}}                                
                label='Word Delay'                     
                step={0.1}
                width={228}
              />
            }

            {animationGroupingType === 'line' &&
              <DPSlider
                min={0}
                max={2}
                value={lineEnterDelay}
                setValue={(value)=>{updateTransitionValues('lineEnterDelay',value)}}   
                //setValue={(value)=>{setEnterGroupingLineDelay(value)}}                               
                label='Line Delay'                     
                step={0.1}
                width={228}
              />
            }

          </div>  
          }          
          
        </div>
      }

      <div className='dpItemAnimationPanel-typeBtnContainer'>
        {activeTab === 'end' && 
          <DPAnimationPanelTransitionPopover 
            activeTransition={activeEndTransition}
            onTransitionChange={handleEndTransitionChange}
            transitionConfigs={endTransitionConfigs}          
          >
            <button 
              onClick={() => setTransitionPopoverOpen(!transitionPopoverOpen)} 
              className='dpItemAnimationPanel-typeBtn'
            >
              <div className='dpItemAnimationPanel-typeBtn-iconContainer'>
                <Icon name={activeEndTransitionConfig.iconName} />
              </div>
              <div className='dpItemAnimationPanel-typeBtn-label'>
                {activeEndTransitionConfig.label}
              </div>
            </button>
          </DPAnimationPanelTransitionPopover>
        }
      </div>

    </div>
  );
};

export default DPItemAnimationPanel;
