import React, { useState, Suspense, useRef, useEffect} from 'react'
import ThreeCanvas from '../../../three/ThreeCanvas'
import Camera from '../../../three/Camera';
import { Canvas } from '@react-three/fiber'
import { Perf } from 'r3f-perf'
import TextSlideEditor from '../../../three/textSlide/TextSlideEditor'
import SlideEditor from '../../../three/slide/slideEditor/SlideEditor'
import CaptionsGroupGhost from '../../../three/captions/CaptionsGroupGhost'
import EditTrimVideoPlayer from './EditTrimVideoPlayer'
import { computeCursorDataForLegacyClips } from '../../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import { computeCursorDataForClips } from '../../../utils/recordings/screenRecordings/getCursorPositionAtTime'
import {getBackgroundForId} from '../../../utils/brands/getBackgroundForId'
import * as THREE from 'three';


const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080
const SCENE_ASPECT = 16/9

    
const listType=null

const VARIABLE_VALUES={
	profile_pic:'http://res.cloudinary.com/yarn/image/upload/v1714086693/image_uploads/1798620531.png'

}

const generateElementsHash = (elements) => {
  return JSON.stringify(elements.map(el => ({id: el.id, orderIndex: el.metadata.animationOrderIndex})));
};

const EditorCanvasContainer = ({
		projectBackgroundId, 
		slideClips,
		textSlideClips,
		chartClips,
		videoClips,
		zoomClips,
		imageClips,		
		currentTime,
		canvasWidth,
		canvasHeight,
		canvasX,
		canvasY,
		isPlaying,
		updateTextSlideText,
		handleSetActivePanelClip,
		updateMeshWidth,
		updateMeshHeight,
		zoomBox,
		activePanelClip,
		textSlideEditorKey,
		trimPreviewPlaying,
		trimPreviewProgress,
		onTrimPreviewPlayPause,
		onTrimPreviewSeekChange,
		trimMode,
		toggleTrimMode,
		isDraggingProgressMarker,
		testKey,
		selectSlideElement	,
		// selectedSlideElements,
		updateSlideTextElementText	,
		updateSlideElementMetadata,
		clearSlideElementsSelection,
		setSlideEditorSelection,
		selectedLayoutGroup,
		updateSlideElementField,
		setFocusedSlideElement,
		focusedSlideElement,
		audioClips,
		duplicateSlideItems,
		// handleDimensionsUpdatedOnDrag,
		openSlideEditorContextMenu,
		handleSetSlideEditorDragElement,
		slideEditorDragElementId,
		slideEditorDragLayoutGroupId,
		updateSlideElementTextSelectionInfo,
		handleSetSlideEditorResizeElement,
		slideEditorResizeElementId,
		slideEditorDraggingSpacer,
		setSlideEditorDraggingSpacer,
		selectedSlideItems,
		handleSyncSlideChanges,
		showCaptions,
		setIsDraggingToReorder,
		isDraggingToReorder,
		groupSlideItems,
		ungroupLayoutGroup,
		handleSlideDragOrResizeStart,
		handleSlideDragOrResizeEnd,
		handleUpdateCanvasFromSlideEditor,
		handleSlideImageFileUpload,
		subtitlesType

	}) => {

	
	const projectBackground=getBackgroundForId(projectBackgroundId)

	let selectedSlide=null 
	if(!isPlaying){
		slideClips.forEach((slide)=>{
			if(slide.startTime <= currentTime && (slide.startTime+slide.duration) >= currentTime){
				selectedSlide=slide
			}
		})
	}

	const handleSetLettersArray = (lettersArray,text,docJson,clipId,elementId) => {updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId)}
	const [textEditorIsFocused, setTextEditorIsFocused] = useState(false);
	const [readyVideoClips, setReadyVideoClips] = useState([]); //clips that have the cursor data calulated

	const showSlideEditor = selectedSlide && !isDraggingProgressMarker && !isPlaying
	const hideRenderedTextSlide = showSlideEditor
	// const hideRenderedTextSlide = false

	const prevClipsRef = useRef([]);
	
	useEffect(() => {
		const computeData = async () => {
			//const filteredClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam');
			const legacyClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording!=true);
			const screenClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording==true)

			//for legacy clips do computeCursorDataForLegacyClips
			//for screen clips do computeCursorDataForClips
			 try {
        // Compute cursor data for both types of clips in parallel
        const [legacyClipsData, screenClipsData] = await Promise.all([
          computeCursorDataForLegacyClips(legacyClips),
          computeCursorDataForClips(screenClips)
        ]);
        // Combine the results
        const allCalculatedClipsIds = [
          ...legacyClipsData,
          ...screenClipsData
        ];
        setReadyVideoClips(allCalculatedClipsIds);
      } catch (error) {
        console.error('Error computing cursor data:', error);
        // Handle error appropriately
      }
			//const calculatedClipsIds = await computeCursorDataForClips(filteredClips);
			//setReadyVideoClips(calculatedClipsIds); // Update the state with the IDs of ready clips
		};
		computeData();
	}, [videoClips]);

	const textEditorDevMode = true

	let selectedChartSlide=null 

	if(!isPlaying){
		chartClips.forEach((clip)=>{
			if(clip.type=='chart' && clip.startTime <= currentTime && (clip.startTime+clip.duration) >= currentTime){
				selectedChartSlide=true
			}
		})
	}

	const showChartStatic = !isDraggingProgressMarker && !isPlaying && selectedChartSlide
	const showChartAnimated = !showChartStatic


	const showSlideChartStatic = !isDraggingProgressMarker && !isPlaying && selectedSlide
	const showSlideChartAnimated = !showSlideChartStatic


	const cameraRef = useRef();

	//

	let currentTimeOverSelectedBasicVideoClip = null
	if(!isPlaying){
		videoClips.forEach((clip)=>{
			if(clip.type=='video' && clip.isBasicVideo && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedBasicVideoClip=true
			}
		})
	}

	const showBasicVideoStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedBasicVideoClip

	//

	let currentTimeOverSelectedScreenVideoClip = null
	if(!isPlaying){
		videoClips.forEach((clip)=>{
			if(clip.type=='video' && !clip.isBasicVideo && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedScreenVideoClip=true
			}
		})
	}

	const showScreenVideoStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedScreenVideoClip

	// 

	let currentTimeOverSelectedImageClip = null
	if(!isPlaying){
		imageClips.forEach((clip)=>{
			if(clip.type=='image' && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedImageClip=true
			}
		})
	}

	const showImageStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedImageClip

	const showSlideImageStatic = !isDraggingProgressMarker && !isPlaying
	const showSlideTextStatic = !isDraggingProgressMarker && !isPlaying
	const showWebcamStatic = !isDraggingProgressMarker && !isPlaying
	const showCaptionsPreview = false
	const segmentWords = 'Here is some example text';
  const wordsArray = segmentWords.split(' ');


  let selectedSlideItemId 
	if(selectedSlideItems.length==1 && selectedSlideItems[0].type=='element'){
		selectedSlideItemId = selectedSlideItems[0].id
	}


	return (            				
			<>

				

			<div style={{transform: `scale(${canvasWidth / SCENE_WIDTH})`}} className='editor-center-center-canvasContainer'>
				<div style={{ width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px`}} className='editor-center-center-canvasInnerContainer' >						 			
					
					{trimMode && activePanelClip &&
						<EditTrimVideoPlayer
							clip={activePanelClip}
							captureId={activePanelClip.captureId}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
							trimPreviewPlaying={trimPreviewPlaying}
							trimPreviewProgress={trimPreviewProgress}
							onTrimPreviewPlayPause={onTrimPreviewPlayPause}
							onTrimPreviewSeekChange={onTrimPreviewSeekChange}
						/>
					}				

					{showSlideEditor &&
						<SlideEditor
							key={`${testKey}_${selectedSlide.id}_${textSlideEditorKey}`}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}							
							canvasX={canvasX}
							canvasY={canvasY}
							invertScalar={1/(canvasWidth / 1920)}        
							slide={selectedSlide}      
							setTextEditorIsFocused={setTextEditorIsFocused}      
							handleSetActivePanelClip={handleSetActivePanelClip}   
							activePanelClip={activePanelClip}
							projectBackground={projectBackground}	
							selectSlideElement={selectSlideElement}
							handleSetLettersArray={handleSetLettersArray}
							//selectedSlideElements={selectedSlideElements}
							updateSlideElementMetadata={updateSlideElementMetadata}
							clearSlideElementsSelection={clearSlideElementsSelection}
							setSlideEditorSelection={setSlideEditorSelection}
							selectedLayoutGroup={selectedLayoutGroup}
							updateSlideElementField={updateSlideElementField}
							setFocusedSlideElement={setFocusedSlideElement}
							focusedSlideElement={focusedSlideElement}
							duplicateSlideItems={duplicateSlideItems}
						//	handleDimensionsUpdatedOnDrag={handleDimensionsUpdatedOnDrag}
							openSlideEditorContextMenu={openSlideEditorContextMenu}
							handleSetSlideEditorDragElement={handleSetSlideEditorDragElement}
							dragElementId={slideEditorDragElementId}
							dragLayoutGroupId={slideEditorDragLayoutGroupId}
							updateSlideElementTextSelectionInfo={updateSlideElementTextSelectionInfo}
							handleSetSlideEditorResizeElement={handleSetSlideEditorResizeElement}
							resizeElementId={slideEditorResizeElementId}
							slideEditorDraggingSpacer={slideEditorDraggingSpacer}
							setSlideEditorDraggingSpacer={setSlideEditorDraggingSpacer}
							selectedSlideItems={selectedSlideItems}
							handleSyncSlideChanges={handleSyncSlideChanges}
							setIsDraggingToReorder={setIsDraggingToReorder}
							isDraggingToReorder={isDraggingToReorder}
							groupSlideItems={groupSlideItems}
							ungroupLayoutGroup={ungroupLayoutGroup}
							handleSlideDragOrResizeStart={handleSlideDragOrResizeStart}
							handleSlideDragOrResizeEnd={handleSlideDragOrResizeEnd}
							handleUpdateCanvasFromSlideEditor={handleUpdateCanvasFromSlideEditor}
							handleSlideImageFileUpload={handleSlideImageFileUpload}

						/>
					}
					{showCaptionsPreview &&
						<CaptionsGroupGhost
							wordsArray={wordsArray}
							visible={true}
						/>
					}




					
					{/*}
					{showSlideEditor &&            
						<TextSlideEditor
							key={`${testKey}_${selectedTextSlide.id}_${textSlideEditorKey}`}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}							
							canvasX={canvasX}
							canvasY={canvasY}
							handleSetWordsArray={handleSetWordsArray}
							invertScalar={1/(canvasWidth / 1920)}        
							slide={selectedTextSlide}      
							setTextEditorIsFocused={setTextEditorIsFocused}      
							handleSetActivePanelClip={handleSetActivePanelClip}   
							activePanelClip={activePanelClip}
							handleDeactivateActiveClip={handleDeactivateActiveClip}
							projectBackground={projectBackground}	
						/>      
					}
					*/}
					
					<Suspense>
						<Canvas 
							style={{ width: `calc(${SCENE_WIDTH}px * 1/${canvasWidth / SCENE_WIDTH})`, height: `calc(${SCENE_HEIGHT}px * 1/${canvasWidth / SCENE_WIDTH})` }}
							 gl={{ 
						    toneMapping: THREE.NoToneMapping,
						    outputColorSpace: THREE.SRGBColorSpace
						  }}
						>
							<ThreeCanvas 	            
								currentTime={currentTime}
								slideClips={slideClips}   
								textSlideClips={textSlideClips}
								videoClips={videoClips}   
								zoomClips={zoomClips}
								chartClips={chartClips}
								textEditorIsFocused={textEditorIsFocused}    	
								updateMeshWidth={updateMeshWidth}
								updateMeshHeight={updateMeshHeight}
								zoomBox={zoomBox}		
								projectBackground={projectBackground}	
								readyVideoClips={readyVideoClips}	
								hideRenderedTextSlide={hideRenderedTextSlide}
								showChartStatic={showChartStatic}
								showChartAnimated={showChartAnimated}
								showBasicVideoStatic={showBasicVideoStatic}
								showScreenVideoStatic={showScreenVideoStatic}
								showImageStatic={showImageStatic}
								//activeBrand={activeBrand}
								testKey={testKey}
								imageClips={imageClips}
								isPlaying={isPlaying}
								variableValues={VARIABLE_VALUES}
								showSlideEditor={showSlideEditor}
								audioClips={audioClips}
								showSlideChartStatic={showSlideChartStatic}
								showSlideChartAnimated={showSlideChartAnimated}
								showSlideImageStatic={showSlideImageStatic}
								showSlideTextStatic={showSlideTextStatic}
								showWebcamStatic={showWebcamStatic}
								focusedSlideElement={focusedSlideElement}
								draggingElementId={slideEditorDragElementId}
								draggingGroupId={slideEditorDragLayoutGroupId}
								resizingElementId={slideEditorResizeElementId}
								slideEditorDraggingSpacer={slideEditorDraggingSpacer}
								showCaptions={showCaptions}
								isDraggingToReorder={isDraggingToReorder}
								selectedSlideItemId={selectedSlideItemId}
								subtitlesType={subtitlesType}
							/>                            
							<Camera ref={cameraRef} currentTime={currentTime} zoomClips={zoomClips} readyVideoClips={readyVideoClips} />
						</Canvas>
					</Suspense>
					
				</div>			
			</div>

			
			</>	
	)
	
}

export default EditorCanvasContainer

