export const adjustClipsDraggingRight=(sortedClips, draggedClipIndex, newEndTime, updatesArray,moveZoomsWithVideoClip,isAudioTrack,audioClipSpacing)=> {

	let buffer = 0 //gap between clips
	if(isAudioTrack){
		buffer = audioClipSpacing
	}

		let lastEndTime = newEndTime + buffer;
		for (let i = draggedClipIndex + 1; i < sortedClips.length; i++) {
			let currentClip = sortedClips[i];
			const initialStartTime = currentClip.relativeStartTime
				if (currentClip.startTime < lastEndTime) {
					let newStart = lastEndTime;
					currentClip.startTime = newStart;
					currentClip.pinnedStartTime = null
					updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime,pinnedStartTime:null });
					if(currentClip.type=='video') {
						const finalStartTime = currentClip.relativeStartTime
						const shiftAmount = finalStartTime -initialStartTime 
						updatesArray.push(moveZoomsWithVideoClip(currentClip.id,shiftAmount))
					}


					lastEndTime = newStart + currentClip.duration + buffer;
				}
		}
		return updatesArray;
	}




	// export const adjustClipsDraggingRight=(sortedClips, draggedClipIndex, newEndTime, updatesArray,moveZoomsWithVideoClip)=> {
	// 	let lastEndTime = newEndTime;
	// 	for (let i = draggedClipIndex + 1; i < sortedClips.length; i++) {
	// 		let currentClip = sortedClips[i];
	// 		const initialStartTime = currentClip.relativeStartTime
	// 			if (currentClip.startTime < lastEndTime) {
	// 				let newStart = lastEndTime;
	// 				currentClip.startTime = newStart;
	// 				updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
	// 				if(currentClip.type=='video') {
	// 					const finalStartTime = currentClip.relativeStartTime
	// 					const shiftAmount = finalStartTime -initialStartTime 
	// 					updatesArray.push(moveZoomsWithVideoClip(currentClip.id,shiftAmount))
	// 				}


	// 				lastEndTime = newStart + currentClip.duration;
	// 			}
	// 	}
	// 	return updatesArray;
	// }