import sortBy from 'lodash/sortBy'
import {randomID} from '../../../../../utils/randomID'
import editorSchema from '../../../../../prosemirror/schema/editorSchema'



export function makeDocJson(clips, unsortedScenes) {
    let contentArray = [];
    const scenes = unsortedScenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
    const sceneCount = scenes.length

    scenes.forEach((scene) => {
        // Create the scene header

        let title = scene.title 
        if(title=='Untitled Scene'){
            title=''
        }

        let paragraphNode
        if(title){
            paragraphNode = editorSchema.nodes.paragraph.create({}, [editorSchema.text(title)]);
        }else{
            paragraphNode = editorSchema.nodes.paragraph.create({});
        }
        
        const sceneHeaderNode = editorSchema.nodes.sceneHeader.createAndFill({ sceneId: scene.id,sceneDuration:scene.duration,sceneCount:sceneCount }, [paragraphNode]);
        contentArray.push(sceneHeaderNode);

        // Filter clips by scene
        const filteredClips = clips.filter(clip => clip.sceneId === scene.id);
        const sortedClips = sortBy(filteredClips, 'startTime');

        // If there are no clips for the scene, add a placeholder
        if (sortedClips.length === 0) {
            const clipId = randomID();
            //const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({ clipId: clipId,sceneId:scene.id });
            const paragraphNode = editorSchema.nodes.paragraph.create({});
            const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({
                clipId: clipId,
                sceneId:scene.id,
                requiresUpdate: false,
                transcriptChunkIndex: 0,
                transcriptChunkCount: 1
            }, [paragraphNode]);

            contentArray.push(transcriptChunkNode);
        } else {

            // Add clips
            sortedClips.forEach((clip, i) => {
                let paragraphNode;
                if (clip.metadata.text.trim() !== '' && clip.metadata.text.trim() !== '#') {
                    paragraphNode = editorSchema.nodes.paragraph.create({}, [editorSchema.text(clip.metadata.text)]);
                } else {

                    paragraphNode = editorSchema.nodes.paragraph.create({});
                }
                const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({
                    clipId: clip.id,
                    sceneId:scene.id,
                    // clipDuration: clip.duration,
                    requiresUpdate: clip.requiresUpdate,
                    transcriptChunkIndex: i,
                    transcriptChunkCount: sortedClips.length
                }, [paragraphNode]);
                contentArray.push(transcriptChunkNode);
            });
        }
    });

    const doc = editorSchema.nodes.doc.createAndFill({}, contentArray);
    return doc;
}











// export function makeDocJson(clips,scenes){
// 		let contentArray=[]

// 		console.log(editorSchema)

// 		scenes.forEach((scene)=>{
// 			const paragraphNode = editorSchema.nodes.paragraph.create({},[editorSchema.text('Scene Header')])
// 			const sceneHeaderNode = editorSchema.nodes.sceneHeader.createAndFill({sceneId:scene.id},[paragraphNode])
// 			contentArray.push(sceneHeaderNode)
// 		})


// 		const voiceoverClips=sortBy(clips,'startTime')

// 		const count = voiceoverClips.length;

// 		if(voiceoverClips.length>0){
// 			voiceoverClips.forEach((clip,i)=>{
// 				const clipId=clip.id
// 				let paragraphNode 
// 				if(clip.metadata.text.trim() !== ''){
// 					paragraphNode = editorSchema.nodes.paragraph.create({},[editorSchema.text(clip.metadata.text)])
// 				}else{
// 					paragraphNode = editorSchema.nodes.paragraph.create({})
// 				}
// 			const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({clipId:clipId,clipDuration:clip.duration,requiresUpdate:clip.requiresUpdate,transcriptChunkIndex:i,transcriptChunkCount:count},[paragraphNode])
// 			contentArray.push(transcriptChunkNode)
// 		})
// 		}else{ //empty default clip that is not created until start typing
// 			const clipId=randomID()
// 			const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({clipId:clipId})
// 			contentArray.push(transcriptChunkNode)
// 		}
// 		const doc=editorSchema.nodes.doc.createAndFill({},contentArray)
// 		return doc
// 	}
