import store from '../store'

export async function getAssetLibrary() {
    const state = store.getState()
    const { images, uploadedVideos: basicVideos, recordings,screenRecordings } = state

    
    // Transform images (no async operations needed)
    const imageAssets = images.map(image => ({
        type: 'image',
        libraryObj:image,
        createdBy: image.created_by,
        createdAt: image.created_at,
        height: image.original_height,
        width: image.original_width,
        title: image.original_filename,
        cloudinaryId: image.public_id
    }))

    // Transform basic videos (no async operations needed)
    const videoAssets = basicVideos.map(video => ({
        type: 'basicVideo',
        libraryObj:video,
        createdBy: video.created_by,
        createdAt: video.created_at,
        height: video.original_height,
        width: video.original_width,
        title: video.original_filename,
        cloudinaryId: `video_uploads/${video.id}`,
        duration: video.duration
    }))



    const screenRecordingAssets = screenRecordings.map(screenRecording => ({
        type: 'screenRecording',
        captureId:screenRecording.capture_id,
        libraryObj:screenRecording,
        createdBy: screenRecording.created_by,
        createdAt: screenRecording.created_at,
        height: screenRecording.height,
        width: screenRecording.width,
        title: screenRecording.display_name,
        cloudinaryId: screenRecording.cloudinary_public_id,
        duration: screenRecording.duration
    }))



    // Transform recordings with parallel metadata fetching
    const recordingPromises = recordings.map(async (video) => {
        if(video.capture_id=='80863576'){
            console.log(video)
        }



    	if(video.is_device){
    		try {
            const relativePath = `deviceRecordings/${video.capture_id}/metadata.json`
            const content = await ipcRenderer.invoke('read-file', relativePath)
            const data = JSON.parse(content)
            return {
                type: 'deviceRecording',
                captureId:video.capture_id,
                createdBy: video.created_by,
                createdAt: video.created_at,
                height: data.dimensions.height,
                width: data.dimensions.width,
                title: 'iPhone',
                cloudinaryId: `deviceRecordings/${video.capture_id}`,
                duration: data.durationSeconds,
                
            }
        } catch (error) {
            console.error(`Failed to fetch metadata for recording: ${video.original_filename}`, error)
           return
        }

    	}
    	else{


        try {
            const relativePath = `recordings/${video.capture_id}/metadata.json`
            const content = await ipcRenderer.invoke('read-file', relativePath)
            const data = JSON.parse(content)
    
            let width, height,duration

            const displayRecorder = data.recorders.find(recorder => recorder.type === 'display');
            const session = displayRecorder.sessions[0];   

				    if (displayRecorder && displayRecorder.sessions && displayRecorder.sessions[0]) {
				      const bounds = displayRecorder.sessions[0].bounds;
				      width = bounds.width 
				      height = bounds.height
				   }
				   duration = (session.processTimeEndMs - session.processTimeStartMs) / 1000  
            return {
                type: 'recording',
                captureId:video.capture_id,
                createdBy: video.created_by,
                createdAt: video.created_at,
                height: height,
                width: width,
                title: data.displayName,
                cloudinaryId: `recordings/${video.capture_id}`,
                duration: duration,
                
            }
        } catch (error) {
            console.error(`Failed to fetch metadata for recording: ${video.original_filename}`, error)
           return
        }

      }
    })

    // Wait for all recording metadata to be fetched in parallel
    const recordingAssets = await Promise.all(recordingPromises)

    // Combine all assets
    return [...imageAssets, ...videoAssets, ...screenRecordingAssets,...recordingAssets]
}