import React, { useState, useEffect, useRef } from 'react';
import { mediaGallerySizing } from './mediaGallerySizing';
import Icon from '../../../misc/Icon';

function formatDuration(seconds) {
  let roundedSeconds = Math.round(seconds);
  let minutes = Math.floor(roundedSeconds / 60);
  let secs = roundedSeconds % 60;
  let minutesStr = minutes.toString().padStart(2, '0');
  let secondsStr = secs.toString().padStart(2, '0');
  return minutesStr + ':' + secondsStr;
}

const MediaItemWebcam = ({ video, videoWidth, videoHeight, innerPadding, handleSelectItem }) => {
  console.log('media it em webcam')

 
  return (
    <button onClick={() => handleSelectItem(video)} style={{padding: `${innerPadding}px`}} className='popover--addMediaPopover-itemContainer' >
      <div className='popover--addMediaPopover-item popover--addMediaPopover-item--video popover--addMediaPopover-item--library'>
        <div className='popover--addMediaPopover-item--video-videoDuration'>
          
        </div>        
        <div className='popover--addMediaPopover-item--video-previewContainer'>
         WEBCAM
        </div>              
      </div>
    </button>          
  )  
};




const MediaItemVideo = ({ video, videoWidth, videoHeight, innerPadding, handleSelectItem }) => {

  const previewDuration = 10 // seconds
  const previewWidth = Math.ceil(videoWidth * 2) // 2 DPI 
  const previewVideoSrc = `https://res.cloudinary.com/yarn/video/upload/so_0,eo_${previewDuration},w_${previewWidth},c_scale/v${video.version}/${video.public_id}.mp4`
  const videoRef = useRef();
 

const handleMouseEnter = () => {
    // Add a small delay before playing
    setTimeout(() => {
      videoRef.current?.play().catch(error => {
        console.error("Error playing video:", error);
      });
    }, 50);
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0;  // Reset the video to the start
    }
  };


  // const handleMouseEnter = () => {
  //   videoRef.current.play();
  // };

  // const handleMouseLeave = () => {
  //   const video = videoRef.current;
  //   if (video) {
  //     video.pause();
  //     video.currentTime = 0;  // Reset the video to the start
  //   }
  // };
  return (
    <button onClick={() => handleSelectItem(video)} style={{padding: `${innerPadding}px`}} className='popover--addMediaPopover-itemContainer' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className='popover--addMediaPopover-item popover--addMediaPopover-item--video popover--addMediaPopover-item--library'>
        <div className='popover--addMediaPopover-item--video-videoDuration'>
          {formatDuration(video.duration)}
        </div>        
        <div className='popover--addMediaPopover-item--video-previewContainer'>
          <video 
            ref={videoRef} 
            style={{width: `${videoWidth}px`, height: `${videoHeight}px`}}
            src={previewVideoSrc}             
            muted
            loop
          />
        </div>              
      </div>
    </button>          
  )  
};


//image search result formal
// {
//             "id": 792381,
//             "width": 3047,
//             "height": 2362,
//             "url": "https://www.pexels.com/photo/close-up-photography-of-tiger-792381/",
//             "photographer": "GEORGE DESIPRIS",
//             "photographer_url": "https://www.pexels.com/@george-desipris",
//             "photographer_id": 228078,
//             "avg_color": "#33271B",
//             "src": {
//                 "original": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg",
//                 "large2x": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
//                 "large": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
//                 "medium": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&h=350",
//                 "small": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&h=130",
//                 "portrait": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
//                 "landscape": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
//                 "tiny": "https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280"
//             },
//             "liked": false,
//             "alt": "Close-Up Photography of Tiger"
//         },



const Row = ({ mediaItems, rowHeight, handleSelectItem }) => {

  return (
    <div className='popover--addMediaPopover-list-row' style={{ height: `${rowHeight}px` }}>
      {mediaItems.map((mediaItem, index) => {
        const mediaType = mediaItem.type
        const mediaAspect = mediaItem.original_width / mediaItem.original_height
        const outerPadding = 2
        const innerPadding = 4
        const totalPadding =  outerPadding + outerPadding + innerPadding + innerPadding
        const containerWidth = mediaItem.containerWidth - totalPadding //padding
        const containerHeight = rowHeight - totalPadding
        const containerAspect = containerWidth / containerHeight
        let mediaItemWidth
        let mediaItemHeight
        if(containerAspect < mediaAspect){
          mediaItemWidth = containerWidth
          mediaItemHeight = mediaItemWidth / mediaAspect
        }
        if(containerAspect >= mediaAspect){
          mediaItemHeight = containerHeight
          mediaItemWidth = mediaItemHeight * mediaAspect        
        }
        return (
          <div key={index} style={{ width: `${mediaItem.containerWidth}px`, height: `${rowHeight}px`, padding: `${outerPadding}px`}} className='popover--addMediaPopover-itemOuterContainer'>
            
            {mediaType === 'video' &&
              <MediaItemVideo 
                key={index}
                video={mediaItem}
                innerPadding={innerPadding}
                videoWidth={mediaItemWidth}
                videoHeight={mediaItemHeight}
                handleSelectItem={handleSelectItem}
              />

            }

            {mediaType === 'webcamVideo' &&
              <MediaItemWebcam
                key={index}
                video={mediaItem}
                innerPadding={innerPadding}
                videoWidth={mediaItemWidth}
                videoHeight={mediaItemHeight}
                handleSelectItem={handleSelectItem}
              />

            }

            {mediaType === 'image' &&
              <button style={{padding: `${innerPadding}px`}} onClick={() => handleSelectItem(mediaItem)} className='popover--addMediaPopover-itemContainer'>
                <div className='popover--addMediaPopover-item popover--addMediaPopover-item--image'>
                  <img style={{width: `${mediaItemWidth}px`, height: `${mediaItemHeight}px`}} src={mediaItem.delivery_url} />
                  {mediaItem.semi_transparent && 
                    <div style={{width: `${mediaItemWidth}px`, height: `${mediaItemHeight}px`}} className='popover--addMediaPopover-item--image-transparentBG' />
                  }
                </div>
              </button>
            }
          </div>
        );
      })}
    </div>
  );
};

function AddMediaPopoverResultsList({ media, handleSelectItem, maxRows, mediaType, rowWidth }) {
  const rowHeight = 124;

  console.log('media')
  console.log(media)
  
  const minItemWidth = 100;
  const maxItemWidth = 230;

  const [rows, setRows] = useState([]);

  const listHeight = maxRows * rowHeight;
  

  useEffect(() => {
    const rows = mediaGallerySizing(media, rowHeight, rowWidth, maxRows, minItemWidth, maxItemWidth);
    setRows(rows);
  }, [media, maxRows]);




  return (
    <div style={{ width: `${rowWidth}px`, height: `${listHeight}px` }} className='popover--addMediaPopover-list'>
      {rows.map((row, index) => (
        <Row 
          key={index} 
          mediaItems={row} 
          handleSelectItem={handleSelectItem} 
          rowHeight={rowHeight} 
          />
      ))}
    </div>
  );
}

export default AddMediaPopoverResultsList;
