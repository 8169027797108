const yarnBrand = 
  {    
    name: "Yarn",    
    font: {
      fontFamily: "YCereal",
      //fontSize: "96",
      fontSize: "124",
      fontWeight: "Bold",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    heading1Font: {
      fontFamily: "YCereal",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    heading2Font: {
      fontFamily: "YCereal",
      fontSize: "80",
      fontWeight: "Semibold",
      lineHeight: 1.4,      
      letterSpacing: -0.001,
    },    
    subFont: {
      fontFamily: "YCereal",
      fontSize: "50",
      fontWeight: "Semibold",
      lineHeight: 1.4,      
      letterSpacing: 0.04,
    },    
    bodyFont: {
      fontFamily: "YSFProDisplay",
      fontSize: "40",
      fontWeight: "Regular",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    screenVideoStyle:{
      defaultColorFrame:true
    },  
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(105, 133, 255, 1)',  isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(38, 39, 42, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(96, 180, 134, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(201, 132, 152, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(237, 238, 248, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(246, 246, 244, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '9', type: 'solid', rgba: 'rgba(66, 68, 75, 1)'},      
      { id: '10', type: 'image', src: '/bgImages/zep/grad.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
     // { id: '8', type: 'image', src: '/bgImages/celest/herogradient.png', isLightbackground: false}, 
      //{ id: '10', type: 'image', src: '/bgImages/clay/PlainPurple.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(105, 133, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(150, 154, 158, 1)' },  
      { id: '4', type: 'solid', rgba: 'rgba(80, 81, 85, 1)' },                  
      { id: '5', type: 'solid', rgba: 'rgba(20, 24, 30, 1)'},   
      { id: '6', type: 'solid', rgba: 'rgba(94, 188, 120, 1)'},      
    ]
  }

 const faireBrand = 
  {    
    name: "faire",    
    defaultTextStyle:'heading1',
    font: {
      fontFamily: "YNantes",
      fontSize: "172",
      fontWeight: "Medium",
      lineHeight: 1.18,
      letterSpacing: 0.006,
    },      
    heading1Font: {
      fontFamily: "YNantes",
      fontSize: "107",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.003,
    },        
    heading2Font: {
      fontFamily: "YGraphik",
      fontSize: "98",
      fontWeight: "Medium",
      lineHeight: 1.05,      
      letterSpacing: 0.002,
    },       
    heading3Font: {
      fontFamily: "YGraphik",
      fontSize: "68",
      fontWeight: "Medium",
      lineHeight: 1.235,      
      letterSpacing: 0.0143,
    },    
    bodyFont: {
      fontFamily: "YGraphik",
      fontSize: "44",
      fontWeight: "Medium",
      lineHeight: 1.235,      
      letterSpacing: 0.0043,
    },    
    imageStyle:{
      shadowType: 'none',
      borderRadius: 0.01
    },
    screenVideoStyle:{
      shadowOpacity: 0.68,
      shadowFrameOpacity: 0.53,
    },  
    backgrounds:[                  
      // new faire
      { id: '1', type: 'solid', rgba: 'rgba(246, 239, 226, 1)',isDefault: true, colorFrameBG:'rgba(244, 238, 234, 1)'},            
      { id: '2', type: 'solid', rgba: 'rgba(31, 31, 31, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(21, 69, 72, 1)', defaultTextColor: '3'},
      { id: '4', type: 'solid', rgba: 'rgba(88, 86, 25, 1)', defaultTextColor: '3'},

      { id: '5', type: 'solid', rgba: 'rgba(27, 40, 52, 1)', defaultTextColor: '3'},

      
      //
      // Final one
      // rgba(31,31,31,1)
      // rgba(246,239,226)
      // rgba(21,69,72)
      // rgba(88,86,25)
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(31, 31, 31, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(251, 251, 251, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(21, 69, 72, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(88, 86, 25, 1)'},      
    ]
  }

const codeAntBrand = 
  {    
    name: "codeAnt",    
    font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(25, 28, 47, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(240, 101, 47, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(0, 107, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(0, 107, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(0, 107, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(0, 107, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(60, 61, 75, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(153, 154, 161, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(240, 101, 47, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(0, 107, 255, 1)'}
    ]
  }


  const notionBrand = 
  {    
    name: "Notion",    
    font: {
      fontFamily: "YSFProDisplay",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.004,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(246, 246, 244, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(59, 135, 235, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(157, 52, 218, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }


  const fintaBrand = 
  {    
    name: "Finta",    
    font: {
      fontFamily: "YInter",
      fontSize: "94",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(38, 121, 243, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 243, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }

  const inkeepBrand = 
  {    
    name: "Inkeep",    
    font: {
      fontFamily: "YInter",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(249, 249, 249, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(38, 214, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(15, 129, 252, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(9, 14, 16, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(38, 214, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(15, 129, 252, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(103, 211, 251, 1)' },
      { id: '6', type: 'solid', rgba: 'rgba(159, 163, 165, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      // { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }


  const forgeBrand = 
  {    
    name: "Forge",    
    font: {
      fontFamily: "YOxygen",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(117, 111, 214, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(79, 70, 229, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(229, 231, 235, 1)' },
      { id: '6', type: 'solid', rgba: 'rgba(92, 102, 241, 1)' },      
      { id: '7', type: 'image', src: '/bgImages/forge/forgeHero.png', isLightbackground: true},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }

  const creoBrand = 
  {    
    name: "Creo",    
    font: {
      fontFamily: "YStabilGrotesk",
      fontSize: "94",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.009,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(11, 10, 9, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(24, 24, 24, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(240, 240, 245, 1)'},
      { id: '5', type: 'image', src: '/bgImages/creo/creoHero.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/creo/creoDots.png', isLightbackground: false},
      { id: '7', type: 'solid', rgba: 'rgba(168, 85, 247, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(168, 85, 247, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },      
      // { id: '7', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(250, 250, 250, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(163, 163, 163, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(24, 24, 24, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(250, 250, 250, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(250, 250, 250, 1)' },      
    ]
  }

  const leyaBrand = 
  {    
    name: "Leya",    
    font: {
      fontFamily: "YUncutSans",
      fontSize: "94",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.022,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(233, 240, 249, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(245, 246, 248, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(21, 21, 21, 1)'},
      { id: '6', type: 'image', src: '/bgImages/leya/peachgradient.png', isLightbackground: true},
      { id: '7', type: 'image', src: '/bgImages/leya/peachribbon.png', isLightbackground: true},
      { id: '8', type: 'image', src: '/bgImages/leya/peachribbonB.png', isLightbackground: true},
      { id: '9', type: 'image', src: '/bgImages/leya/purpleribbon.png', isLightbackground: true},
      // { id: '5', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },      
      // { id: '7', type: 'solid', rgba: 'rgba(11, 10, 9, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(21, 21, 21, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(100, 100, 107, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},        
      // { id: '4', type: 'solid', rgba: 'rgba(24, 24, 24, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(250, 250, 250, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(250, 250, 250, 1)' },      
    ]
  }

  const causalBrand = 
  {    
    name: "causal",    
    font: {
      fontFamily: "YUntitledSans",
      fontSize: "82",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'image', src: '../bgImages/causal/causalBG.png', isLightbackground: true, isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(250, 251, 254, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(29, 117, 221, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(243, 247, 253, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(34, 43, 51, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(62, 76, 91, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(240, 101, 47, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(29, 117, 221, 1)' },
    ]
  }

  const fumeDevBrand = 
  {    
    name: "fumeDev",    
    font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(136, 194, 200, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(246, 246, 246, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(33, 43, 54, 1)' },   
      { id: '5', type: 'image', src: '/bgImages/fumedev/lightgradient.png', isLightbackground: true }, 

    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },      
    ]
  }

  const useFiniBrand = 
  {    
    name: "useFini",    
    font: {
      fontFamily: "YSFProDisplay",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.002,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(13, 24, 29, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(22, 99, 241, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(246, 246, 246, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(33, 43, 54, 1)' },         
      { id: '5', type: 'solid', rgba: 'rgba(129, 215, 247, 1)' },

    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(167, 181, 195, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(204, 218, 231, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(32, 132, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },      
    ]
  }

  const alloyBrand = 
  {    
    name: "alloy",    
    font: {
      fontFamily: "YGreycliffCF",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.002,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(39, 43, 48, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(248, 249, 249, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(44, 46, 59, 1)' },         

    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(44, 46, 50, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(149, 150, 152, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(204, 218, 231, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(32, 132, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },      
    ]
  }

  const superAgentBrand = 
  {    
    name: "superagent",    
    font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(19, 20, 21, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(94, 93, 240, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(138, 143, 152, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

  const muddyBrand = 
  {    
    name: "muddy",    
    font: {
      //fontFamily: "YRelative",
      fontFamily: "YMarlideDisplay",
      fontSize: "112",
      fontWeight: "Regular",
      lineHeight: 1.1,
      letterSpacing: 0.0,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(53, 54, 52, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(245, 245, 245, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(243, 244, 242, 1)' },  
      { id: '4', type: 'solid', rgba: 'rgba(212, 213, 210, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(168, 170, 167, 1)' },  
      { id: '6', type: 'solid', rgba: 'rgba(128, 130, 125, 1)' },
      { id: '7', type: 'solid', rgba: 'rgba(92, 93, 90, 1)' },  
      { id: '8', type: 'solid', rgba: 'rgba(70, 71, 69, 1)' },
      { id: '9', type: 'solid', rgba: 'rgba(53, 54, 52, 1)' },  
      { id: '10', type: 'solid', rgba: 'rgba(18, 20, 18, 1)' },
      { id: '11', type: 'solid', rgba: 'rgba(187, 233, 240, 1)' },  
      { id: '12', type: 'solid', rgba: 'rgba(76, 196, 212, 1)' },
      { id: '13', type: 'solid', rgba: 'rgba(90, 134, 140, 1)' },  
      { id: '14', type: 'solid', rgba: 'rgba(226, 215, 242, 1)' },
      { id: '15', type: 'solid', rgba: 'rgba(186, 164, 219, 1)' },  
      { id: '16', type: 'solid', rgba: 'rgba(135, 118, 158, 1)' },      
      { id: '13', type: 'solid', rgba: 'rgba(247, 213, 205, 1)' },  
      { id: '14', type: 'solid', rgba: 'rgba(210, 91, 59, 1)' },
      { id: '15', type: 'solid', rgba: 'rgba(140, 74, 57, 1)' },        
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(212, 213, 210, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

  const furtherAIBrand = 
  {    
    name: "furtherAi",    
    font: {
      fontFamily: "YPoppins",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.018,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(248, 236, 200, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 245, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(40, 35, 14, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }


  const driverAIBrand = 
  {    
    name: "driverAI",    
    font: {
      fontFamily: "YMonaSans",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.2,
      letterSpacing: -0.020,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(240, 240, 248, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(23, 23, 23, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(10, 10, 10, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(237, 238, 250, 1)'},
      { id: '6', type: 'image', src: '/bgImages/driverAI/driverAIround.png', isLightbackground: true }, 
      // { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(71, 71, 78, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(23, 23, 23, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }  

  const whaleSyncBrand = 
  {    
    name: "whaleSync",    
    font: {
      fontFamily: "YArchivo",
      fontSize: "94",
      fontWeight: "Heavy",
      lineHeight: 1.115,
      letterSpacing: -0.002,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(238, 249, 255, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(251, 252, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(45, 191, 255, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(2, 10, 10, 1)'},      
      // { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(57, 70, 77, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(94, 100, 103, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

  const askLioBrand = 
  {    
    name: "askLio",    
    font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    backgrounds:[      
      { id: '1', type: 'image', src: '/bgImages/askLio/moodygradient.png', isLightbackground: false, isDefault:true}, 
      { id: '2', type: 'solid', rgba: 'rgba(34, 81, 197, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(17, 39, 191, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(248, 248, 255, 1)'},
      //{ id: '3', type: 'solid', rgba: 'rgba(251, 252, 255, 1)'},          
      // { id: '5', type: 'solid', rgba: 'rgba(2, 10, 10, 1)'},      
      // { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(204, 218, 231, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(24, 24, 36, 1)' },      
      { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },

      

      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

   const hostAIBrand = 
  {    
    name: "hostAI",    
    font: {
      fontFamily: "YSFProDisplay",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(247, 247, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(234, 234, 243, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(59, 158, 255, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(16, 39, 91, 1)'},
      //{ id: '1', type: 'image', src: '/bgImages/askLio/moodygradient.png', isLightbackground: false, isDefault:true}, 
      //{ id: '3', type: 'solid', rgba: 'rgba(251, 252, 255, 1)'},          
      // { id: '5', type: 'solid', rgba: 'rgba(2, 10, 10, 1)'},      
      // { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(16, 39, 91, 1)' },
      // { id: '2', type: 'solid', rgba: 'rgba(4, 4, 10, 1)' },     
      { id: '2', type: 'solid', rgba: 'rgba(59, 158, 255, 1)' }, 
      { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },

      

      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

  const pyrlsBrand = 
  {    
    name: "pyrls",    
    font: {
      fontFamily: "YInter",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(240, 243, 249, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(218, 220, 252, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(99, 202, 181, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(183, 50, 215, 1)'},
      { id: '6', type: 'image', src: '/bgImages/pyrls/bluegradient.png', isLightbackground: true}, 
      { id: '7', type: 'image', src: '/bgImages/pyrls/peachgradient.png', isLightbackground: true}, 
      //{ id: '3', type: 'solid', rgba: 'rgba(251, 252, 255, 1)'},          
      // { id: '5', type: 'solid', rgba: 'rgba(2, 10, 10, 1)'},      
      // { id: '3', type: 'solid', rgba: 'rgba(42, 42, 42, 1)' },  
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },              
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(20, 20, 30, 1)' },
      // { id: '2', type: 'solid', rgba: 'rgba(4, 4, 10, 1)' },     
      { id: '2', type: 'solid', rgba: 'rgba(47, 45, 152, 1)' },       
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },          
      //{ id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(250, 204, 20, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(94, 93, 240, 1)' },      
    ]
  }

   const infinityBrand = 
  {    
    name: "infinity",    
    font: {
      fontFamily: "YWorkSans",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.000,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(230, 219, 255, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(219, 194, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(182, 124, 250, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(22, 22, 63, 1)' },
      // { id: '2', type: 'solid', rgba: 'rgba(4, 4, 10, 1)' },     
      { id: '2', type: 'solid', rgba: 'rgba(158, 53, 253, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const abelBrand = 
  {    
    name: "abel",    
    font: {
      fontFamily: "YSpaceGrotesk",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.000,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(251, 251, 251, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(174, 194, 219, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(214, 229, 227, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(0, 23, 36, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(0, 23, 35, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const veltBrand = 
  {    
    name: "velt",    
    font: {
      fontFamily: "YUrbanist",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.007,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(20, 20, 25, 1)', isDefault: true},
      { id: '2', type: 'image', src: '/bgImages/velt/grid.png', isLightbackground: false}, 
      { id: '3', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(133, 129, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' }, 
      { id: '3', type: 'solid', rgba: 'rgba(133, 129, 255, 1)' }, 
      { id: '4', type: 'solid', rgba: 'rgba(255, 143, 197, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }


  const reploBrand = 
  {    
    name: "replo",    
    font: {
      fontFamily: "YInter",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(0, 0, 0, 1)', isDefault: true},
      //{ id: '2', type: 'image', src: '/bgImages/velt/grid.png', isLightbackground: false}, 
      { id: '2', type: 'solid', rgba: 'rgba(194, 228, 251, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(237, 234, 254, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(184, 242, 209, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(230, 214, 251, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(49, 73, 218, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(245, 188, 80, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '9', type: 'image', src: '/bgImages/replo/ipad-17-dark.jpg', isLightbackground: false}, 
      { id: '10', type: 'image', src: '/bgImages/replo/ipad-17-light.jpg', isLightbackground: false},       
      { id: '11', type: 'image', src: '/bgImages/replo/energy-1.jpg', isLightbackground: false}, 
      { id: '12', type: 'image', src: '/bgImages/replo/energy-2.jpg', isLightbackground: false}, 
      { id: '13', type: 'image', src: '/bgImages/replo/energy-3.jpg', isLightbackground: false}, 
      { id: '14', type: 'image', src: '/bgImages/replo/energy-4.jpg', isLightbackground: false}, 
      { id: '15', type: 'image', src: '/bgImages/replo/energy-5.jpg', isLightbackground: false}, 
      { id: '16', type: 'image', src: '/bgImages/replo/energy-6.jpg', isLightbackground: false}, 
      { id: '17', type: 'image', src: '/bgImages/replo/energy-7.jpg', isLightbackground: false}, 
      { id: '18', type: 'image', src: '/bgImages/replo/energy-8.jpg', isLightbackground: false}, 
      { id: '19', type: 'image', src: '/bgImages/replo/energy-9.jpg', isLightbackground: false}, 
      { id: '20', type: 'image', src: '/bgImages/replo/energy-10.jpg', isLightbackground: false}, 
      { id: '21', type: 'image', src: '/bgImages/replo/energy-11.jpg', isLightbackground: false}, 
      { id: '22', type: 'image', src: '/bgImages/replo/energy-12.jpg', isLightbackground: false}, 
      { id: '23', type: 'image', src: '/bgImages/replo/energy-13.jpg', isLightbackground: false}, 
      { id: '24', type: 'image', src: '/bgImages/replo/energy-14.jpg', isLightbackground: false}, 
      
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(133, 129, 255, 1)' }, 
      // { id: '4', type: 'solid', rgba: 'rgba(255, 143, 197, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const onboardAIBrand = 
  {    
    name: "replo",    
    font: {
      fontFamily: "YInter",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(105, 104, 247, 1)', isDefault: true},
      //{ id: '2', type: 'image', src: '/bgImages/velt/grid.png', isLightbackground: false}, 
      { id: '2', type: 'solid', rgba: 'rgba(19, 20, 21, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(242, 242, 250, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(184, 242, 209, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(230, 214, 251, 1)'},
      // { id: '6', type: 'solid', rgba: 'rgba(49, 73, 218, 1)'},
      // { id: '7', type: 'solid', rgba: 'rgba(245, 188, 80, 1)'},      
      // { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(133, 129, 255, 1)' }, 
      // { id: '4', type: 'solid', rgba: 'rgba(255, 143, 197, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

   const celestBrand = 
  {    
    name: "celest",    
    font: {
      //fontFamily: "YNunitoSans",
      fontFamily: "YPoppins",
      // fontFamily: "YGilroy",
      fontSize: "94",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.028,
    },
     backgrounds:[            
      // { id: '1', type: 'solid', rgba: 'rgba(105, 104, 247, 1)', isDefault: true},
      { id: '1', type: 'image', src: '/bgImages/celest/herogradient.png', isLightbackground: false, isDefault: true}, 
      { id: '2', type: 'solid', rgba: 'rgba(52, 163, 254, 1)'},
      { id: '3', type: 'image', src: '/bgImages/celest/circlegradientA.png', isLightbackground: false}, 
      { id: '4', type: 'image', src: '/bgImages/celest/circlegradientB.png', isLightbackground: false}, 
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(165, 209, 255, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(6, 41, 81, 1)'},
            
      //{ id: '2', type: 'image', src: '/bgImages/velt/grid.png', isLightbackground: false}, 
      // { id: '2', type: 'solid', rgba: 'rgba(2, 125, 253, 1)'},
      // { id: '3', type: 'solid', rgba: 'rgba(242, 242, 250, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(184, 242, 209, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(230, 214, 251, 1)'},
      // { id: '6', type: 'solid', rgba: 'rgba(49, 73, 218, 1)'},
      // { id: '7', type: 'solid', rgba: 'rgba(245, 188, 80, 1)'},      
      // { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(133, 129, 255, 1)' }, 
      // { id: '4', type: 'solid', rgba: 'rgba(255, 143, 197, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const luminaBrand = 
  {    
    name: "lumina",    
    font: {
      fontFamily: "YInter",
      fontSize: "96",
      fontWeight: "Bold",
      lineHeight: 1.1,
      //letterSpacing: 0.032,
      letterSpacing: -0.001,
    },
     backgrounds:[            
      // { id: '1', type: 'solid', rgba: 'rgba(105, 104, 247, 1)', isDefault: true},
      { id: '1', type: 'solid', rgba: 'rgba(189, 227, 211, 1)', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(188, 240, 218, 1)' },      
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(225, 239, 254, 1)'},
      { id: '5', type: 'image', src: '/bgImages/lumina/gradA.png', isLightbackground: true}, 
      { id: '6', type: 'image', src: '/bgImages/lumina/gradD.png', isLightbackground: true}, 
      { id: '7', type: 'image', src: '/bgImages/lumina/gradC.png', isLightbackground: true}, 
      { id: '8', type: 'image', src: '/bgImages/lumina/gradD.png', isLightbackground: true}, 
      { id: '9', type: 'image', src: '/bgImages/lumina/gradE.png', isLightbackground: true}, 
      { id: '10', type: 'image', src: '/bgImages/lumina/particles.png', isLightbackground: false}, 
      { id: '11', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      // { id: '5', type: 'image', src: '/bgImages/lumina/ .png', isLightbackground: true}, 
      // { id: '5', type: 'image', src: '/bgImages/lumina/ .png', isLightbackground: true},   
      // { id: '7', type: 'solid', rgba: 'rgba(40, 40, 83, 1)'},            
      // { id: '2', type: 'image', src: '/bgImages/velt/grid.png', isLightbackground: false}, 
      // { id: '2', type: 'solid', rgba: 'rgba(2, 125, 253, 1)'},
      // { id: '3', type: 'solid', rgba: 'rgba(242, 242, 250, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(184, 242, 209, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(230, 214, 251, 1)'},
      // { id: '6', type: 'solid', rgba: 'rgba(49, 73, 218, 1)'},
      // { id: '7', type: 'solid', rgba: 'rgba(245, 188, 80, 1)'},      
      // { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },   
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(8, 8, 10, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(3, 84, 63, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(5, 122, 85, 1)'}, 
      { id: '4', type: 'solid', rgba: 'rgba(28, 100, 242, 1)' }, 
      { id: '5', type: 'solid', rgba: 'rgba(3, 84, 63, 1)' }, 
      { id: '6', type: 'solid', rgba: 'rgba(188, 240, 218, 1)' }, 
      { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },      
      // { id: '3', type: 'solid', rgba: 'rgba(133, 129, 255, 1)' }, 
      // { id: '4', type: 'solid', rgba: 'rgba(255, 143, 197, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const safetyKitBrand = 
  {    
    name: "safetyKit",    
    font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      //letterSpacing: 0.032,
      letterSpacing: -0.018,
    },
     backgrounds:[            
      // { id: '1', type: 'solid', rgba: 'rgba(105, 104, 247, 1)', isDefault: true},
      { id: '1', type: 'solid', rgba: 'rgba(250, 230, 243, 1)', isDefault: true},            
      { id: '2', type: 'solid', rgba: 'rgba(213, 63, 140, 1)'},  
      { id: '3', type: 'solid', rgba: 'rgba(239, 181, 211, 1)'},            
      { id: '4', type: 'image', src: '/bgImages/safetykit/lightpinkgrad.png', isLightbackground: true},       
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(142, 17, 85, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(16, 24, 40, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(184, 25, 112, 1)' },            
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},                 
    ]
  }


  const govDashBrand = 
  {    
    name: "govDash",    
    font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Regular",
      lineHeight: 1.1,      
      letterSpacing: -0.018,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(247, 238, 227, 1)', isDefault: true},                  
      { id: '2', type: 'solid', rgba: 'rgba(249, 248, 245, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(242, 241, 238, 1)'},         
      { id: '4', type: 'solid', rgba: 'rgba(84, 125, 240, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(198, 223, 223, 1)'},      
      { id: '6', type: 'image', src: '/bgImages/govdash/grada.png', isLightbackground: true},       
      { id: '7', type: 'image', src: '/bgImages/govdash/gradb.png', isLightbackground: true},             
      { id: '8', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},            
      { id: '9', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},                  
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(62, 62, 63, 1)' },                  
      { id: '2', type: 'solid', rgba: 'rgba(127, 127, 127, 1)' },            
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},                 
    ]
  }

  const martiniBrand = 
  {    
    name: "martini",    
    font: {
      //fontFamily: "YNunitoSans",
      fontFamily: "YPoppins",
      // fontFamily: "YGilroy",
      fontSize: "72",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.028,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(69, 76, 111, 1)', isDefault: true},                  
      { id: '2', type: 'solid', rgba: 'rgba(123, 142, 237, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(244, 244, 251, 1)'},         
      { id: '4', type: 'solid', rgba: 'rgba(251, 251, 253, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(116, 132, 213, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(223, 228, 253, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(108, 116, 162, 1)'},            
      { id: '7', type: 'image', src: '/bgImages/martini/grad.png', isLightbackground: false},                             
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(51, 51, 51, 1)' },                  
      { id: '2', type: 'solid', rgba: 'rgba(108, 116, 162, 1)' },            
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},                 
    ]
  }

  const affinityBrand = 
  {    
    name: "affinity",    
    font: {
      //fontFamily: "YNunitoSans",
      fontFamily: "YIBMPlexSans",
      // fontFamily: "YGilroy",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.028,
    },
    screenVideoStyle:{
      defaultColorFrame:true
    },  
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(175, 113, 255, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(159, 142, 247)'},
      { id: '3', type: 'solid', rgba: 'rgba(191, 183, 251)'},
      { id: '4', type: 'solid', rgba: 'rgba(184, 231, 251)'},
      { id: '5', type: 'solid', rgba: 'rgba(204, 232, 205)'},
      { id: '6', type: 'solid', rgba: 'rgba(251, 218, 218)'},
      { id: '7', type: 'solid', rgba: 'rgba(25, 26, 69, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(0, 0, 50, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(73, 40, 143, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(114, 67, 233, 1)'},      
      { id: '11', type: 'image', src: '/bgImages/affinity/lineargrad.png', isLightbackground: false},
      { id: '12', type: 'image', src: '/bgImages/affinity/radialgrad.png', isLightbackground: false},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },                  
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 50, 1)' },                  
    ]
  }

  const thorntaleBrand = 
  {    
    name: "thorntale",    
    font: {
      //fontFamily: "YNunitoSans",
      fontFamily: "YSpaceGrotesk",
      // fontFamily: "YGilroy",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.028,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(212, 182, 242, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(176, 91, 142, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(83, 56, 126, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(208, 190, 238, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(244, 239, 254, 1)'},
      // { id: '3', type: 'solid', rgba: 'rgba(0, 0, 50, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(73, 40, 143, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(114, 67, 233, 1)'},
      // { id: '6', type: 'solid', rgba: 'rgba(25, 26, 69, 1)'},
      // { id: '7', type: 'image', src: '/bgImages/affinity/lineargrad.png', isLightbackground: false},
      // { id: '8', type: 'image', src: '/bgImages/affinity/radialgrad.png', isLightbackground: false},
    ],
    textColors:[
      //{ id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },                  
      { id: '1', type: 'solid', rgba: 'rgba(29, 12, 55, 1)' },                  
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },                  
    ]
  }

const posthogBrand = 
  {    
    name: "posthog",    
    font: {      
      fontFamily: "YMatter",
      fontSize: "72",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.01,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(238, 239, 234, 1)', isDefault: true, defaultTextColor:'1'},      
      { id: '2', type: 'solid', rgba: 'rgba(29, 31, 38, 1)', defaultTextColor:'2' },
      { id: '3', type: 'solid', rgba: 'rgba(20, 114, 255, 1)', defaultTextColor:'2' },
      { id: '4', type: 'solid', rgba: 'rgba(150, 229, 183, 1)', defaultTextColor:'1' },
      { id: '5', type: 'solid', rgba: 'rgba(242, 173, 71, 1)', defaultTextColor:'1' },
      { id: '6', type: 'solid', rgba: 'rgba(42, 216, 185, 1)', defaultTextColor:'1' },
      { id: '7', type: 'solid', rgba: 'rgba(154, 23, 188, 1)', defaultTextColor:'2' },
      { id: '8', type: 'solid', rgba: 'rgba(212, 47, 25, 1)', defaultTextColor:'2' },
      { id: '9', type: 'solid', rgba: 'rgba(67, 182, 231, 1)', defaultTextColor:'1' },
      { id: '10', type: 'solid', rgba: 'rgba(134, 103, 255, 1)', defaultTextColor:'2' },
      { id: '11', type: 'solid', rgba: 'rgba(255, 217, 159, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(220, 231, 208, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(218, 225, 252, 1)'},
      { id: '14', type: 'solid', rgba: 'rgba(253, 186, 242, 1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(21, 21, 21, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(238, 239, 234, 1)' },               
      { id: '3', type: 'solid', rgba: 'rgba(64, 64, 63, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(29, 31, 38, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(47, 128, 250, 1)' },
      { id: '6', type: 'solid', rgba: 'rgba(245, 78, 0, 1)' },
      { id: '7', type: 'solid', rgba: 'rgba(48, 171, 198, 1)' },
      { id: '8', type: 'solid', rgba: 'rgba(247, 165, 1, 1)' },      
      { id: '9', type: 'solid', rgba: 'rgba(86, 86, 85, 1)' },
    ]
  }

  const stempathBrand = 
  {    
    name: "stempath",    
    font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      //letterSpacing: 0.032,
      letterSpacing: -0.018,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(110, 128, 122, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(190, 202, 196, 1)', defaultTextColor:'2'},
      { id: '3', type: 'solid', rgba: 'rgba(29, 54, 48, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(247, 244, 238, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(225, 223, 218, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(6, 20, 14, 1)'},
      { id: '7', type: 'image', src: '/bgImages/stempath/gradA.png', isLightbackground: false},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(29, 54, 48, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(6, 20, 14, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(247, 244, 238, 1)' },                  
    ]
  }

  const osmosBrand = 
  {    
    name: "osmos",    
    font: {
      fontFamily: "YSora",
      fontSize: "76",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      //letterSpacing: 0.032,
      letterSpacing: 0.004,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(97, 97, 97, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(240, 240, 240, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(42, 44, 46, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(38, 38, 38, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(103, 103, 122, 1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(38, 38, 38, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(97, 97, 97, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(103, 103, 122, 1)' },                  
    ]
  }

  const fluentlyBrand = 
  {    
    name: "fluently",    
    font: {
      fontFamily: "YHeebo",
      fontSize: "86",
      fontWeight: "Medium",
      lineHeight: 1.1,
      //letterSpacing: 0.032,
      letterSpacing: 0.004,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(11, 13, 16, 1)', isDefault: true},
      { id: '2', type: 'image', src: '/bgImages/fluently/gradF.png', isLightbackground: false},      
      { id: '3', type: 'solid', rgba: 'rgba(3, 3, 4, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(74, 146, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(28, 43, 67, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(114, 168, 134, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(141, 149, 235, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(67, 114, 131, 1)'},    
      { id: '10', type: 'image', src: '/bgImages/fluently/gradA.png', isLightbackground: false},  
      { id: '11', type: 'image', src: '/bgImages/fluently/gradB.png', isLightbackground: false},
      { id: '12', type: 'image', src: '/bgImages/fluently/gradC.png', isLightbackground: false},
      { id: '13', type: 'image', src: '/bgImages/fluently/gradD.png', isLightbackground: false},
      { id: '14', type: 'image', src: '/bgImages/fluently/gradE.png', isLightbackground: false},      

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(74, 146, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(173, 176, 185, 1)' },      
    ]
  }

  const shibolethBrand = 
  {    
    name: "shiboleth",    
    font: {
      fontFamily: "YSFProDisplay",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: 0.004,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(244, 244, 249, 1)', defaultTextColor: '1', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(249, 225, 255, 1)', defaultTextColor: '5'},
      { id: '3', type: 'solid', rgba: 'rgba(54, 98, 227, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(131, 94, 238, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', defaultTextColor: '1'},      
      { id: '6', type: 'image', src: '/bgImages/shiboleth/gradA.png', isLightbackground: false},  
      { id: '7', type: 'image', src: '/bgImages/shiboleth/gradB.png', isLightbackground: false},    
      { id: '8', type: 'solid', rgba: 'rgba(225, 103, 80, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(241, 195, 96, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(141, 149, 235, 1)'},            

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(33, 41, 54, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(195, 70, 226, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(77, 85, 98, 1)' }, 
      { id: '5', type: 'solid', rgba: 'rgba(58, 25, 66, 1)' },      
    ]
  }

  const registerPlayBrand = 
  {    
    name: "registerPlay",    
    font: {
      fontFamily: "YGothamRounded",
      fontSize: "82",
      //fontWeight: "Medium",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.024,
      // fontFamily: "YMuli",
      // fontSize: "82",
      // fontWeight: "Bold",
      // lineHeight: 1.115,
      // letterSpacing: -0.024,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(254, 254, 254, 1)', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(247, 248, 254, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(36, 60, 112, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(96, 125, 139, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(46, 50, 61, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(9, 14, 48, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(220, 244, 243, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(14, 42, 152, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(185, 32, 37, 1)'},

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(33, 37, 41, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(133, 135, 148, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(14, 42, 152, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(34, 63, 134, 1)' },

    ]
  }


  const openmartBrand = 
  {    
    name: "openmart",    
    font: {
      fontFamily: "YPlusJakartaSans",
      fontSize: "82",
      //fontWeight: "Medium",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.024,
      // fontFamily: "YMuli",
      // fontSize: "82",
      // fontWeight: "Bold",
      // lineHeight: 1.115,
      // letterSpacing: -0.024,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(52, 108, 237, 1)', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(247, 248, 254, 1)'},              
      { id: '3', type: 'solid', rgba: 'rgba(16, 16, 16, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(247, 209, 93, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(194, 211, 250, 1)'},      

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(21, 110, 246, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(88, 88, 88, 1)' },
      
    ]
  }



const lightdashBrand = 
  {    
    name: "lightdash",    
    font: {
      fontFamily: "YSpaceGrotesk",
      fontSize: "80",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(220, 247, 236, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(230, 222, 249, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(152, 244, 209, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(204, 247, 228, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(109, 100, 238, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(24, 30, 36, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(42, 46, 51, 1)'},
      { id: '10', type: 'image', src: '/bgImages/lightdash/gradA.png', isLightbackground: false},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(13, 17, 22, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' }, 
      { id: '3', type: 'solid', rgba: 'rgba(112, 98, 255, 1)' }, 
      { id: '4', type: 'solid', rgba: 'rgba(95, 109, 129, 1)' }, 
      { id: '5', type: 'solid', rgba: 'rgba(95, 109, 129, 1)' }, 
      { id: '6', type: 'solid', rgba: 'rgba(47, 58, 74, 1)' }, 
      { id: '7', type: 'solid', rgba: 'rgba(57, 38, 97, 1)' }, 
      { id: '8', type: 'solid', rgba: 'rgba(15, 51, 37, 1)' }, 
      // { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }


  const tilBrand = 
  {    
    name: "til",    
    font: {
      fontFamily: "YGilroy",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.001,
    },
     backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(245, 245, 245, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 11, 84, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(128, 128, 128, 1)' },      
      { id: '6', type: 'solid', rgba: 'rgba(255, 28, 178, 1)' },
      { id: '7', type: 'solid', rgba: 'rgba(255, 66, 75, 1)' },      
      //{ id: '5', type: 'solid', rgba: 'rgba(158, 63, 253, 1)' },
      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' }, 
      { id: '3', type: 'solid', rgba: 'rgba(255, 11, 84, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(128, 128, 128, 1)' },      
      { id: '5', type: 'solid', rgba: 'rgba(255, 28, 178, 1)' },
      { id: '6', type: 'solid', rgba: 'rgba(255, 66, 75, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(31, 187, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(102, 112, 133, 1)' },
    ]
  }

  const parchaBrand = 
  {    
    name: "parcha",    
    font: {
      fontFamily: "YRobotoSlab",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(248, 250, 252, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(241, 245, 249, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(226, 232, 240, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(203, 213, 225, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(148, 163, 184, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(100, 116, 139, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(71, 85, 105, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(51, 65, 85, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(15, 23, 42, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(2, 6, 23, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(93, 95, 239, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(254, 251, 146, 1)'},      
      { id: '13', type: 'image', src: '/bgImages/parcha/gradA.png', isLightbackground: false},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(248, 250, 252, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(241, 245, 249, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(226, 232, 240, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(203, 213, 225, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(148, 163, 184, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(100, 116, 139, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(71, 85, 105, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(51, 65, 85, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(15, 23, 42, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(2, 6, 23, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(93, 95, 239, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(254, 251, 146, 1)'},
    ]
  }

  const botterflyBrand = 
  {    
    name: "botterfly",    
    font: {
      fontFamily: "YWixMadeforDisplay",
      fontSize: "72",
      fontWeight: "Heavy",
      lineHeight: 1.185,
      letterSpacing: 0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(73, 197, 221, 1)', defaultTextColor: '1', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(251, 133, 0, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(2, 48, 71, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(216, 239, 245, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(253, 243, 234, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(249, 224, 202, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(245, 207, 171, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(244, 198, 156, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(250, 250, 250, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(186, 199, 229, 1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(2, 48, 71, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },      
      { id: '3', type: 'solid', rgba: 'rgba(93, 55, 20, 1)' }, 
      { id: '4', type: 'solid', rgba: 'rgba(255, 183, 3, 1)'},     
    ]
  }

  const breadboardBrand = 
  {    
    name: "breadboard",    
    font: {
      fontFamily: "YPPNeueMachinaInktrap",
      fontSize: "72",
      fontWeight: "Medium",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(10,11,17,1)', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(184, 180, 249, 1)', defaultTextColor: '5'},
      { id: '3', type: 'solid', rgba: 'rgba(69,63,204,1)'},
      { id: '4', type: 'solid', rgba: 'rgba(231,115,72,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(249,250,251,1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(239,241,246,1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(222,228,237,1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(207,215,228,1)'},            
      { id: '9', type: 'solid', rgba: 'rgba(19,24,35,1)'},      
      { id: '10', type: 'solid', rgba: 'rgba(24,29,42,1)'},      
      { id: '11', type: 'solid', rgba: 'rgba(27,35,49,1)'},            
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(184, 180, 249, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(249,250,251)'},  
      { id: '3', type: 'solid', rgba: 'rgba(239,241,246)'},      
      { id: '4', type: 'solid', rgba: 'rgba(222,228,237)'},      
      { id: '5', type: 'solid', rgba: 'rgba(207,215,228)'},      
      { id: '6', type: 'solid', rgba: 'rgba(10,11,17)'},      
      { id: '7', type: 'solid', rgba: 'rgba(19,24,35)'},      
      { id: '8', type: 'solid', rgba: 'rgba(24,29,42)'},      
      { id: '9', type: 'solid', rgba: 'rgba(27,35,49)'},  
      { id: '10', type: 'solid', rgba: 'rgba(69,63,204,1)'},
      { id: '11', type: 'solid', rgba: 'rgba(231,115,72,1)'}, 

    ]
  }

  const validioBrand = 
  {    
    name: "validioBrand",    
    font: {
      fontFamily: "YManrope",
      fontSize: "72",
      fontWeight: "Regular",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(38,52,82,1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(230,220,253,1)'},
      { id: '3', type: 'solid', rgba: 'rgba(130,79,243,1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(94,26,239,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(58,27,126,1)'},
      { id: '6', type: 'solid', rgba: 'rgba(48,52,82,1)'},
      { id: '7', type: 'solid', rgba: 'rgba(74,75,110,1)'},
      { id: '8', type: 'solid', rgba: 'rgba(246,246,253,1)'},
      { id: '9', type: 'solid', rgba: 'rgba(143,143,172,1)'},
      { id: '10', type: 'solid', rgba: 'rgba(250,252,255,1)'},
      { id: '11', type: 'solid', rgba: 'rgba(176,237,228,1)'},                    
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(250, 252, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(48, 52, 82, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(130,79,243,1)'},
      
    ]
  }

  const canvasBrand = 
  {    
    name: "canvasBrand",    
    font: {
      fontFamily: "YLausanne",
      fontSize: "78",
      fontWeight: "Regular",
      lineHeight: 1.185,
      letterSpacing: -0.002,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(237,239,244,1)', isDefault: true},                      
      { id: '2', type: 'solid', rgba: 'rgba(233,238,255,1)', defaultTextColor: '6'},
      { id: '3', type: 'solid', rgba: 'rgba(255,255,255,1)'},              
      { id: '4', type: 'image', src: '/bgImages/canvas/gradA.png', isLightbackground: false},
      { id: '5', type: 'image', src: '/bgImages/canvas/gradB.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/canvas/gradC.png', isLightbackground: true},
      { id: '7', type: 'image', src: '/bgImages/canvas/gradD.png', isLightbackground: true},
      { id: '8', type: 'solid', rgba: 'rgba(13,35,72,1)'},        

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(61, 78, 108, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(84, 100, 126, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(94, 36, 255, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(46, 105, 255, 1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(134, 144, 163, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(145, 199, 255, 1)'},      
      { id: '9', type: 'solid', rgba: 'rgba(46, 105, 255, 1)'},            
    ]
  }

  const finleyBrand = 
  {    
    name: "finley",    
    font: {
      fontFamily: "YRecoleta",
      fontSize: "78",
      fontWeight: "Regular",
      lineHeight: 1.185,
      letterSpacing: -0.002,
    },
    screenVideoStyle:{
      shadowOpacity: 0.68,
      shadowFrameOpacity: 0.53,
    },  
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(235,230,225,1)', defaultTextColor: '1', isDefault: true, colorFrameBG:'rgba(244, 238, 234, 1)'},                      
      { id: '2', type: 'solid', rgba: 'rgba(249,246,241,1)', defaultTextColor: '3'},
      { id: '3', type: 'solid', rgba: 'rgba(248,250,246,1)', defaultTextColor: '3'},      
      { id: '4', type: 'solid', rgba: 'rgba(255,255,255,1)', defaultTextColor: '7'},
      { id: '5', type: 'solid', rgba: 'rgba(2,68,74,1)', defaultTextColor: '5'},                                  
      { id: '6', type: 'solid', rgba: 'rgba(14,14,14,1)', defaultTextColor: '6'},      
      { id: '7', type: 'solid', rgba: 'rgba(1,46,50,1)'},
      { id: '8', type: 'solid', rgba: 'rgba(244,198,113,1)'},
      { id: '9', type: 'solid', rgba: 'rgba(217,156,38,1)'},
      { id: '10', type: 'solid', rgba: 'rgba(64,65,62,1)'},
      { id: '11', type: 'solid', rgba: 'rgba(115,115,115,1)'},
      { id: '12', type: 'solid', rgba: 'rgba(178,179,179,1)'},
      { id: '13', type: 'solid', rgba: 'rgba(203,204,205,1)'},
      { id: '14', type: 'solid', rgba: 'rgba(229,229,232,1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(1, 46, 50, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(2, 68, 74, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(14, 14, 14, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(244, 198, 113, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(235, 179, 71, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(217, 156, 38, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(223, 228, 213, 1)'},
    ]
  }

  const pegboBrand = 
  {    
    name: "pegbo",    
    font: {
      fontFamily: "YMontserrat",
      fontSize: "68",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(255,255,255,1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(21, 27, 45, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(18, 162, 116, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(0, 128, 156, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(241, 242, 247, 1)'},      
      { id: '7', type: 'image', src: '/bgImages/pegbo/bg01.png', isLightbackground: false},
      { id: '8', type: 'image', src: '/bgImages/pegbo/bg02.png', isLightbackground: false},
      { id: '9', type: 'image', src: '/bgImages/pegbo/bg03.png', isLightbackground: false},
      { id: '10', type: 'image', src: '/bgImages/pegbo/bg04.png', isLightbackground: false},
      { id: '11', type: 'image', src: '/bgImages/pegbo/bg05.png', isLightbackground: false},              
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(21, 27, 45, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(18, 162, 116, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(0, 128, 156, 1)'},      
   
    ]
  }

  const unfairPokerBrand = 
  {    
    name: "unfairpoker",    
    font: {
      fontFamily: "YIBMPlexSans",
      fontSize: "78",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.064,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(23,23,23,1)', isDefault: true},
      { id: '2', type: 'image', src: '/bgImages/unfair/gradA.png', isLightbackground: false},        
      { id: '3', type: 'solid', rgba: 'rgba(8,8,8,1)'},
      { id: '4', type: 'solid', rgba: 'rgba(97,87,248,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(248,179,75,1)'},
      { id: '6', type: 'solid', rgba: 'rgba(0,161,193,1)'},
      { id: '7', type: 'solid', rgba: 'rgba(236,128,180,1)'},
      { id: '8', type: 'solid', rgba: 'rgba(255,255,255,1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(23, 23, 23, 1)'},   
    ]
  }

  const vapiBrand = 
  {    
    name: "vapi",    
    font: {
      fontFamily: "YSohne",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.000,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(68,74,94,1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(23,23,23,1)'},      
      { id: '3', type: 'image', src: '/bgImages/vapi/gradA.png', isLightbackground: false},
      { id: '4', type: 'image', src: '/bgImages/vapi/gradB.png', isLightbackground: false},
      { id: '5', type: 'image', src: '/bgImages/vapi/gradC.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/vapi/gradD.png', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/vapi/gradE.png', isLightbackground: false},      
      { id: '8', type: 'solid', rgba: 'rgba(255,255,255,1)'},
      { id: '9', type: 'solid', rgba: 'rgba(236,246,245,1)'},            
      { id: '10', type: 'solid', rgba: 'rgba(55,170,157,1)'},      
      { id: '11', type: 'solid', rgba: 'rgba(93,254,202,1)'},      
      { id: '12', type: 'solid', rgba: 'rgba(0,44,68,1)'},
      { id: '13', type: 'solid', rgba: 'rgba(1,36,131,1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(55,170,157,1)'},
      { id: '3', type: 'solid', rgba: 'rgba(24,27,27,1)'},
      { id: '3', type: 'solid', rgba: 'rgba(39,42,42,1)'},
      { id: '3', type: 'solid', rgba: 'rgba(65,67,67,1)'},

      
    ]
  }

  const rootlyBrand = 
  {    
    name: "rootly",    
    font: {
      fontFamily: "YSwitzer",
      fontSize: "78",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.014,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(159,122,246,1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(245,243,252,1)'},
      { id: '3', type: 'solid', rgba: 'rgba(233,226,255,1)'},
      { id: '4', type: 'solid', rgba: 'rgba(119,72,246,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(87,32,238,1)'},
      { id: '6', type: 'solid', rgba: 'rgba(251,176,64,1)'},
      { id: '7', type: 'solid', rgba: 'rgba(30,26,51,1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(255,255,255,1)'},              
      { id: '9', type: 'solid', rgba: 'rgba(240,240,239,1)'},      
      { id: '10', type: 'solid', rgba: 'rgba(252,251,250,1)'},            
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(30, 26, 51, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(119, 72, 246, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(251, 176, 64, 1)'},
      
    ]
  }

  const openCopilotBrand = 
  {    
    name: "open copilot",    
    font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(238,238,238,1)', isDefault: true},
      { id: '2', type: 'image', src: '/bgImages/opencopilot/gradA.png', isLightbackground: false},
      { id: '3', type: 'image', src: '/bgImages/opencopilot/gradB.png', isLightbackground: true},
      { id: '4', type: 'solid', rgba: 'rgba(129,129,132,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255,255,255,1)'},
      { id: '6', type: 'solid', rgba: 'rgba(9,10,15,1)'},
      { id: '7', type: 'solid', rgba: 'rgba(14,15,21,1)'},
      { id: '8', type: 'solid', rgba: 'rgba(22,23,30,1)'},
      { id: '9', type: 'solid', rgba: 'rgba(29,30,57,1)'},
      { id: '10', type: 'solid', rgba: 'rgba(31,34,67,1)'},
      { id: '11', type: 'solid', rgba: 'rgba(41,41,82,1)'},
      { id: '12', type: 'solid', rgba: 'rgba(52,53,102,1)'},
      { id: '13', type: 'solid', rgba: 'rgba(7,20,37,1)'},
      { id: '14', type: 'image', src: '/bgImages/opencopilot/gradC.png', isLightbackground: false},

      
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(19, 34, 68, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(80, 95, 122, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(107, 113, 120, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      
      
    ]
  }

  const aidevStudioBrand = 
  {    
    name: "open copilot",    
    font: {
      fontFamily: "YInter",
      fontSize: "65",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.042,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(0,0,0,1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(204, 204, 204, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(238, 120, 101, 1)'},
      { id: '4', type: 'image', src: '/bgImages/aidevStudio/gradA.png', isLightbackground: false},
      { id: '5', type: 'image', src: '/bgImages/aidevStudio/gradB.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/aidevStudio/gradC.png', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/aidevStudio/gradD.png', isLightbackground: false},
      
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(204, 204, 204, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(238, 120, 101, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(163, 163, 163, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},                  
    ]
  }

  const voltviewBrand = 
  {    
    name: "voltview",    
    font: {
      fontFamily: "YInter",
      fontSize: "65",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: 0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(245, 245, 247, 1)', defaultTextColor: '3', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(0,0,0,1)', defaultTextColor: '2'},
      { id: '3', type: 'solid', rgba: 'rgba(245, 245, 247, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(231, 231, 231, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(44, 44, 44, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(9, 155, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},                  
    ]
  }



   const cetientBrand = 
  {    
    name: "cetient",    
    font: {
      fontFamily: "YInter",
      fontSize: "71",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: 0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(129, 140, 248, 1)',isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(79, 70, 229, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(17, 24, 39, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(246, 245, 248, 1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(17, 24, 39, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(79,70,229)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},            
      { id: '4', type: 'solid', rgba: 'rgba(75, 85, 99, 1)'},
    ]
  }

   const onegrepBrand = 
  {    
    name: "onegrepbrand",    
    font: {
      fontFamily: "YInter",
      fontSize: "78",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: 0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(248, 248, 248, 1)',isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(250, 250, 250, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(240, 238, 242, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(75, 225, 236, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(203, 94, 238, 1)'},      
      { id: '6', type: 'image', src: '/bgImages/onegrep/gradA.png', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/onegrep/gradB.png', isLightbackground: true},
      { id: '8', type: 'solid', rgba: 'rgba(224, 183, 244, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(192, 239, 245, 1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(40, 42, 48, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(107,111,118)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},          
    ]
  }


  const unsupervisedBrand = 
  {    
    name: "unsupervised",    
    font: {
      fontFamily: "YUtopiaStd",
      fontSize: "90",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: 0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'image', src: '/bgImages/unsupervised/gradA.png', isDefault: true, isLightbackground: false},
      { id: '2', type: 'image', src: '/bgImages/unsupervised/gradB.png', isLightbackground: false},
      { id: '3', type: 'image', src: '/bgImages/unsupervised/gradC.png', isLightbackground: false},
      { id: '4', type: 'image', src: '/bgImages/unsupervised/gradD.png', isLightbackground: false},      
      { id: '5', type: 'solid', rgba: 'rgba(249, 175, 48, 1)', defaultTextColor: '1'},
      { id: '6', type: 'solid', rgba: 'rgba(96, 38, 220, 1)', defaultTextColor: '1'},
      { id: '7', type: 'solid', rgba: 'rgba(13, 104, 240, 1)', defaultTextColor: '1'},
      { id: '8', type: 'solid', rgba: 'rgba(104, 222, 241, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(220, 38, 127, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(49, 44, 51, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(32, 14, 56, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(251, 250, 248, 1)'},
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(251, 250, 248, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(32, 14, 56, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(49, 44, 51, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(205, 125, 5, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(7, 129, 156, 1)'},      

    ]
  }

  const spectaclesBrand = 
  {    
    name: "spectacles",    
    font: {
      fontFamily: "YInter",
      fontSize: "80",
      fontWeight: "Light",
      lineHeight: 1.1,
      letterSpacing: -0.031,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(235, 235, 255, 1)',isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(242, 242, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(248, 248, 248, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(106, 80, 243, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(6, 182, 212, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '7', type: 'image', src: '/bgImages/spectacles/gradA.png', isLightbackground: false},
      { id: '8', type: 'image', src: '/bgImages/spectacles/gradB.png', isLightbackground: false},
      { id: '9', type: 'image', src: '/bgImages/spectacles/gradC.png', isLightbackground: false},
      { id: '10', type: 'image', src: '/bgImages/spectacles/gradD.png', isLightbackground: false},
      { id: '11', type: 'image', src: '/bgImages/spectacles/gradE.png', isLightbackground: false},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(7, 3, 31, 1)'}, 
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(106, 80, 243, 1)'},

    ]
  }

  const upduoBrand = 
  {    
    name: "upduo",    
    font: {
      fontFamily: "YPPObjectSans",
      fontSize: "74",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.01,
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(212, 178, 239, 1)',isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(49, 0, 93, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(102, 21, 175, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(245, 239, 251, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(212, 178, 239, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(248, 236, 253, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(250, 247, 253, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(183, 101, 24, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(255, 166, 48, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(255, 230, 172, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(255, 239, 217, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(255, 246, 235, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(48, 48, 48, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(84, 83, 83, 1)'},
      { id: '14', type: 'solid', rgba: 'rgba(123, 123, 123, 1)'},
      { id: '15', type: 'solid', rgba: 'rgba(157, 157, 157, 1)'},
      { id: '16', type: 'solid', rgba: 'rgba(217, 217, 217, 1)'},
      { id: '17', type: 'solid', rgba: 'rgba(233, 233, 233, 1)'},
      { id: '18', type: 'solid', rgba: 'rgba(245, 245, 245, 1)'},
      { id: '19', type: 'solid', rgba: 'rgba(251, 251, 251, 1)'},
      { id: '20', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '21', type: 'image', src: '/bgImages/upduo/gradA.png', isLightbackground: false},
            
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(48, 48, 48, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(84, 83, 83, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(123, 123, 123, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(245, 245, 245, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(251, 251, 251, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      // { id: '7', type: 'solid', rgba: 'rgba(49, 0, 93, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(102, 21, 175, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(250, 247, 253, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(183, 101, 24, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(255, 166, 48, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(255, 246, 235, 1)'},
      

    ]
  }


  const stackerBrand = 
  {    
    name: "stacker",    
    font: {
      fontFamily: "YCircular",
      fontSize: "80",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.001,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(213, 214, 253, 1)', defaultTextColor: '1', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(51, 51, 255, 1)',},
      { id: '3', type: 'solid', rgba: 'rgba(244, 246, 246, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255, 242, 248, 1)', defaultTextColor: '4'},      
      { id: '6', type: 'solid', rgba: 'rgba(21, 21, 35, 1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(51, 51, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(23, 37, 43, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(107, 123, 153, 1)'}, 
      { id: '4', type: 'solid', rgba: 'rgba(255, 0, 116, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(255, 255, 116, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(156, 159, 157, 1)'},    
      { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
    ]
  }

  const lexolveBrand = 
  {    
    name: "lexolve",    
    font: {
      fontFamily: "YIBMPlexSans",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.021,
    },
     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(221, 249, 241,1)', defaultColor: '9', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(15, 66, 52, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(45, 134, 110, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(28, 100, 81,1)'},
      { id: '5', type: 'solid', rgba: 'rgba(114, 159, 147,1)'},
      { id: '6', type: 'solid', rgba: 'rgba(159, 210, 196,1)'},            
      { id: '7', type: 'solid', rgba: 'rgba(194, 229, 220,1)'},
      { id: '8', type: 'solid', rgba: 'rgba(234, 251, 246,1)'},
      { id: '9', type: 'solid', rgba: 'rgba(244, 119, 81,1)'},
      { id: '10', type: 'solid', rgba: 'rgba(210, 95, 60,1)'},
      { id: '11', type: 'solid', rgba: 'rgba(243, 166, 143,1)'},
      { id: '12', type: 'solid', rgba: 'rgba(248, 204, 191,1)'},
      { id: '13', type: 'solid', rgba: 'rgba(250, 227, 220,1)'},
      { id: '14', type: 'solid', rgba: 'rgba(55, 65, 82, 1)'},
      { id: '15', type: 'solid', rgba: 'rgba(99, 111, 133, 1)'},
      { id: '16', type: 'solid', rgba: 'rgba(196, 207, 233)'},            
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(45, 134, 110, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(15, 66, 52, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(244, 119, 81)'},
      { id: '6', type: 'solid', rgba: 'rgba(210, 95, 60)'},
      { id: '7', type: 'solid', rgba: 'rgba(55, 65, 82, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(99, 111, 133, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(102, 102, 102, 1)'},
    ]
  }

    const datricsBrand = 
  {    
    name: "datrics",    
    font: {
      fontFamily: "YInter",
      fontSize: "100",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "62",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },    
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(224, 222, 217, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(241, 239, 232, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(238, 240, 244, 1)'},  
      { id: '4', type: 'solid', rgba: 'rgba(35, 37, 40, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(51, 51, 51, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(229, 59, 63, 1)'},    
      { id: '7', type: 'solid', rgba: 'rgba(204, 61, 57, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(16, 24, 40, 1)' },      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(204, 61, 57, 1)' },
    ]
  }

    const twineBrand = 
  {    
    name: "twine",    
    
    font: {
      fontFamily: "YDMSans",
      fontSize: "114",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading1Font: {
      fontFamily: "YDMSans",
      fontSize: "86",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.028,
    },    
    heading2Font: {
      fontFamily: "YDMSans",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.028,
    },    
    // bodyFont: {
    //   fontFamily: "YDMSans",
    //   fontSize: "64",
    //   fontWeight: "Semibold",
    //   lineHeight: 1.1,
    //   letterSpacing: -0.028,
    // },  


    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(239, 255, 240, 1)', defaultTextColor: '1', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(45, 158, 70, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(247, 247, 247, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(245, 245, 245, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(235, 236, 237, 1)'},        
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(0, 0, 0, 1)' },      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(31, 126, 52, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(45, 158, 70, 1)' },
    ]
  }

    const mercuryBrand = 
  {    
    name: "mercury",    
    font: {
      fontFamily: "YNeueMontreal",
      fontSize: "72",
      fontWeight: "Regular",
      lineHeight: 1.1,
      letterSpacing: 0.017,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(231, 230, 238, 1)', defaultTextColor: '1', isDefault:true  },        
      { id: '2', type: 'solid', rgba: 'rgba(204, 222, 218, 1)', defaultTextColor: '2'},        
      { id: '3', type: 'solid', rgba: 'rgba(23, 21, 35, 1)', defaultTextColor: '3'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(39, 36, 61, 1)' },            
      { id: '2', type: 'solid', rgba: 'rgba(51, 96, 93, 1)' },  
      { id: '3', type: 'solid', rgba: 'rgba(230, 229, 240, 1)' },  

    ]
  }

  const jsonifyBrand = 
  {    
    name: "jsonify",    
    font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.027,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(243, 242, 238, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(250, 250, 248, 1'},
      { id: '3', type: 'solid', rgba: 'rgba(71, 38, 225, 1'},
      { id: '4', type: 'solid', rgba: 'rgba(18, 18, 18, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(158, 207, 119, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(213, 172, 58, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(134, 65, 195, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},        
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' }, 
      { id: '2', type: 'solid', rgba: 'rgba(18, 18, 18, 1)' },            
      { id: '3', type: 'solid', rgba: 'rgba(127, 251, 174, 1)' },       
    ]
  }

  
  const sevnBrand = 
  {    
    name: "senv",    
    font: {
      fontFamily: "YPlusJakartaSans",
      fontSize: "72",      
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.034,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(31, 33, 45, 1)', defaultTextColor: '1', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(24, 25, 32, 1)'},              
      { id: '3', type: 'solid', rgba: 'rgba(53, 55, 67, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(148, 124, 247, 1)'},            
      { id: '5', type: 'solid', rgba: 'rgba(38, 48, 53, 1)'},          
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(215, 209, 251, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(230, 227, 253, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(21, 110, 246, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(126, 255, 231, 1)' },      
    ]
  }

  const howieBrand = 
  {    
    name: "senv",    
    font: {
      fontFamily: "YPlusJakartaSans",
      fontSize: "72",      
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.005,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(229, 231, 235, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(255, 237, 138, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(252, 238, 151, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(250, 229, 245, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(246, 202, 181, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(192, 225, 246, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(223, 248, 252, 1)'},
      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(38, 38, 38, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(100, 100, 120, 1)' },      
    ]
  }

  const signalBrand = 
  {    
    name: "signal",    
    font: {
      fontFamily: "YOakesGrotesk",
      fontSize: "72",      
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.005,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(100, 162, 243, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(73, 131, 223, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(244, 246, 248, 1)'},            
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(95, 108, 122, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(12, 39, 83, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(26, 31, 34, 1)' },
    ]
  }

  const edgarBrand = 
  {    
    name: "edgar",    
    font: {
      fontFamily: "YBasierCircle",
      fontSize: "72",      
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.005,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(222, 242, 212, 1)', isDefault: true},      
      { id: '2', type: 'solid', rgba: 'rgba(249, 254, 246, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(246, 253, 242, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(160, 223, 147, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(101, 200, 91, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(41, 179, 41, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(16, 162, 41, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(12, 141, 47, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(20, 123, 48, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(17, 105, 54, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(13, 79, 44, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(0, 51, 37, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '14', type: 'solid', rgba: 'rgba(251, 251, 251, 1)'},
      { id: '15', type: 'solid', rgba: 'rgba(238, 237, 236, 1)'},
      { id: '16', type: 'solid', rgba: 'rgba(217, 214, 213, 1)'},
      { id: '17', type: 'solid', rgba: 'rgba(57, 48, 45, 1)'},
      { id: '18', type: 'solid', rgba: 'rgba(42, 37, 35, 1)'},
      { id: '19', type: 'solid', rgba: 'rgba(24, 21, 20, 1)'},
      { id: '20', type: 'solid', rgba: 'rgba(0, 48, 51, 1)'},
      { id: '21', type: 'solid', rgba: 'rgba(250, 248, 245, 1)'},
      { id: '22', type: 'solid', rgba: 'rgba(236, 233, 227, 1)'},
      { id: '23', type: 'solid', rgba: 'rgba(218, 211, 200, 1)'},
      { id: '24', type: 'solid', rgba: 'rgba(143, 125, 96, 1)'},
      { id: '25', type: 'image', src: '/bgImages/edgar/gradA.png', isLightbackground: false},
      { id: '26', type: 'image', src: '/bgImages/edgar/gradB.png', isLightbackground: false},
      { id: '27', type: 'image', src: '/bgImages/edgar/gradC.png', isLightbackground: false},
      { id: '28', type: 'image', src: '/bgImages/edgar/gradD.png', isLightbackground: true},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(0, 48, 51, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(13, 79, 44, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(0, 51, 37, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
    ]
  }

  const inyougoBrand = 
  {    
    name: "signal",    
    font: {
      fontFamily: "YAeonik",
      fontSize: "72",      
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: 0.005,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(233, 228, 226, 1)', isDefault: true},
      { id: '2', type: 'image', src: '/bgImages/inyougo/gradA.png', isLightbackground: true},
      { id: '3', type: 'image', src: '/bgImages/inyougo/gradB.png', isLightbackground: false},
      { id: '4', type: 'solid', rgba: 'rgba(254, 252, 251, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(246, 244, 244, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(210, 202, 198, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(187, 176, 170, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(163, 151, 143, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(137, 124, 118, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(129, 117, 111, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(112, 99, 92, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(87, 73, 66, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(69, 55, 48, 1)'},
      { id: '14', type: 'solid', rgba: 'rgba(51, 36, 31, 1)'},
      { id: '15', type: 'solid', rgba: 'rgba(41, 18, 10, 1)'},
      { id: '16', type: 'solid', rgba: 'rgba(250, 249, 251, 1)'},
      { id: '17', type: 'solid', rgba: 'rgba(245, 243, 246, 1)'},
      { id: '18', type: 'solid', rgba: 'rgba(224, 221, 228, 1)'},
      { id: '19', type: 'solid', rgba: 'rgba(204, 199, 209, 1)'},
      { id: '20', type: 'solid', rgba: 'rgba(171, 165, 177, 1)'},
      { id: '21', type: 'solid', rgba: 'rgba(133, 126, 139, 1)'},
      { id: '22', type: 'solid', rgba: 'rgba(103, 96, 108, 1)'},
      { id: '23', type: 'solid', rgba: 'rgba(53, 45, 57, 1)'},
      { id: '24', type: 'solid', rgba: 'rgba(41, 31, 46, 1)'},
      { id: '25', type: 'solid', rgba: 'rgba(31, 20, 36, 1)'},
      { id: '26', type: 'solid', rgba: 'rgba(22, 14, 27, 1)'},
      { id: '27', type: 'solid', rgba: 'rgba(214, 218, 255, 1)'},
      { id: '28', type: 'solid', rgba: 'rgba(168, 174, 255, 1)'},
      { id: '29', type: 'solid', rgba: 'rgba(122, 127, 255, 1)'},
      { id: '30', type: 'solid', rgba: 'rgba(90, 87, 255, 1)'},
      { id: '31', type: 'solid', rgba: 'rgba(247, 199, 162, 1)'},
      { id: '32', type: 'solid', rgba: 'rgba(247, 218, 162, 1)'},   
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(41, 31, 46, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(53, 45, 57, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(31, 20, 36, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(22, 14, 27, 1)' },
      { id: '5', type: 'solid', rgba: 'rgba(69, 55, 48, 1)' },      
      { id: '6', type: 'solid', rgba: 'rgba(51, 36, 31, 1)' },      
      { id: '7', type: 'solid', rgba: 'rgba(41, 18, 10, 1)' },      
      { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ]
  }

   const catalistBrand = 
  {    
    name: "catalist",    
    font: {
      fontFamily: "YAGP",
      fontSize: "72",      
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.005,      
    },
     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(229, 229, 253, 1)', isDefault: true},          
      { id: '2', type: 'solid', rgba: 'rgba(235, 237, 250, 1)'},          
      { id: '3', type: 'solid', rgba: 'rgba(245, 246, 254, 1)'},                     
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(98, 80, 242, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(14, 12, 39, 1)'},
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(98, 80, 242, 1)' },           
      { id: '2', type: 'solid', rgba: 'rgba(12, 9, 38, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
    ]
  }

    const a16zBrand = 
  {    
    name: "a16z",    
    font: {
      fontFamily: "YProximaNova",
      fontSize: "72",      
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.005,      
    },
     backgrounds:[                              
      { id: '1', type: 'image', src: '/bgImages/a16z/gradA.png', isLightbackground: true, isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(75, 80, 88, 1)'},      
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(237, 140, 0, 1)' },      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },      
    ]
  }

  const greenliteBrand = 
  {    
    name: "greenlite",    
    font: {
      fontFamily: "YRaptor",
      fontSize: "72",      
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.015,      
    },
    heading1Font: {
      fontFamily: "YRaptor",
      fontSize: "84",      
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.015,      
    },  
     backgrounds:[                              
      { id: '1', type: 'solid', rgba: 'rgba(29, 90, 87, 1)', isDefault: true},          
      { id: '2', type: 'solid', rgba: 'rgba(85, 131, 129, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(142, 172, 172, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(198, 214, 213, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(40, 149, 117, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(95, 174, 152, 1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(148, 202, 186, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(202, 229, 220, 1)'},      
      { id: '9', type: 'solid', rgba: 'rgba(1, 228, 107, 1)'},      
      { id: '10', type: 'solid', rgba: 'rgba(64, 234, 145, 1)'},      
      { id: '11', type: 'solid', rgba: 'rgba(127, 241, 181, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(192, 248, 219, 1)'},      
      { id: '13', type: 'solid', rgba: 'rgba(148, 255, 209, 1)'},      
      { id: '14', type: 'solid', rgba: 'rgba(174, 255, 222, 1)'},      
      { id: '15', type: 'solid', rgba: 'rgba(201, 255, 232, 1)'},      
      { id: '16', type: 'solid', rgba: 'rgba(229, 255, 244, 1)'},            
    ],
    textColors:[            
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(29, 90, 87, 1)' },   
      { id: '3', type: 'solid', rgba: 'rgba(40, 149, 117, 1)' },       
    ]
  }

  const wealthieeBrand = 
  {    
    name: "wealthiee",    
    font: {
      fontFamily: "YInter",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(236, 240, 243, 1)', isDefault:true  },
      { id: '2', type: 'solid', rgba: 'rgba(18, 3, 58, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(0, 71, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(9, 14, 16, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(0, 17, 34, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 214, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(15, 129, 252, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(103, 211, 251, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(159, 163, 165, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      // { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }  

  const keeperBrand = 
  {    
    name: "keeper",    
    font: {
      fontFamily: "YMontserrat",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    heading1Font: {
      fontFamily: "YMontserrat",
      fontSize: "72",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(230, 234, 253, 1)', isDefault:true, defaultTextColor: '1'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', defaultTextColor: '1'  },
      { id: '3', type: 'image', src: '/bgImages/keeper/gradA.png', isLightbackground: true, defaultTextColor: '1'},
      { id: '4', type: 'image', src: '/bgImages/keeper/gradB.png', isLightbackground: true, defaultTextColor: '1'},
      { id: '5', type: 'solid', rgba: 'rgba(86, 78, 255, 1)', isDefault:true  },
      { id: '6', type: 'solid', rgba: 'rgba(30, 3, 79, 1)', isDefault:true  },
      // { id: '3', type: 'solid', rgba: 'rgba(0, 71, 255, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(9, 14, 16, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(37, 8, 128, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(254, 44, 222, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(30, 3, 79, 1)' },
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 214, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(15, 129, 252, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(103, 211, 251, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(159, 163, 165, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      // { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }


  const upkeepBrand = 
  {    
    name: "upkeep",    
    font: {
      fontFamily: "YGilroy",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.032,
    },
    // heading1Font: {
    //   fontFamily: "YMontserrat",
    //   fontSize: "72",
    //   fontWeight: "Bold",
    //   lineHeight: 1.115,
    //   letterSpacing: -0.032,
    // },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(237, 241, 247, 1)', isDefault:true, defaultTextColor: '1'},
      { id: '2', type: 'solid', rgba: 'rgba(0, 124, 176, 1)', defaultTextColor: '1'  },
      // { id: '3', type: 'image', src: '/bgImages/keeper/gradA.png', isLightbackground: true, defaultTextColor: '1'},
      // { id: '4', type: 'image', src: '/bgImages/keeper/gradB.png', isLightbackground: true, defaultTextColor: '1'},
      // { id: '5', type: 'solid', rgba: 'rgba(86, 78, 255, 1)', isDefault:true  },
      // { id: '6', type: 'solid', rgba: 'rgba(30, 3, 79, 1)', isDefault:true  },
      // { id: '3', type: 'solid', rgba: 'rgba(0, 71, 255, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(9, 14, 16, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(6, 23, 33, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(0, 107, 156, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(30, 3, 79, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 214, 255, 1)' },
      // { id: '4', type: 'solid', rgba: 'rgba(15, 129, 252, 1)' },
      // { id: '5', type: 'solid', rgba: 'rgba(103, 211, 251, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(159, 163, 165, 1)' },
      // { id: '3', type: 'solid', rgba: 'rgba(38, 121, 218, 1)'},        
      // { id: '4', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }

  const aisBrand = 
  {    
    name: "upkeep",    
    font: {
      fontFamily: "YClashGrotesk",
      fontSize: "84",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.024,
    },
    heading1Font: {
      fontFamily: "YClashGrotesk",
      fontSize: "72",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.018,
    },
    heading2Font: {
      fontFamily: "YClashGrotesk",
      fontSize: "64",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(243, 243, 243, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(24, 24, 24, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(174, 30, 35, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(24, 24, 24, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(174, 30, 35, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },          
    ]
  }


    const artisanBrand = 
  {    
    name: "artisan",    
    font: {
      fontFamily: "YOutfit",
      fontSize: "128",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.03,
    },
    heading1Font: {
      fontFamily: "YOutfit",
      fontSize: "96",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    heading2Font: {
      fontFamily: "YOutfit",
      fontSize: "84",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    backgrounds:[      
      { id: '1', type: 'image', src: '/bgImages/artisan/gradA.png', isDefault:true, isLightbackground: true},
      { id: '2', type: 'image', src: '/bgImages/artisan/gradB.png', isLightbackground: true},
      { id: '3', type: 'solid', rgba: 'rgba(125, 55, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(63, 232, 255, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(244, 242, 255, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(236, 231, 255, 1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(217, 210, 255, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(190, 174, 255, 1)'},      
      { id: '9', type: 'solid', rgba: 'rgba(159, 144, 255, 1)'},      
      { id: '10', type: 'solid', rgba: 'rgba(130, 77, 255, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(101, 22, 235, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(85, 18, 197, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(70, 17, 161, 1)'},
      { id: '14', type: 'solid', rgba: 'rgba(41, 7, 110, 1)'},      
      { id: '15', type: 'solid', rgba: 'rgba(34, 9, 100, 1)'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(34, 9, 100, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(125, 55, 255, 1)' },                
      { id: '3', type: 'solid', rgba: 'rgba(63, 232, 255, 1)' },      
    ]
  }

   const leonisBrand = 
  {    
    name: "upkeep",    
    font: {
      fontFamily: "YInter",
      fontSize: "84",
      fontWeight: "Semibold",
      lineHeight: 1.22,
      letterSpacing: -0.022,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "72",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    bodyFont: {
      fontFamily: "YInter",
      fontSize: "48",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.024,
    },
    // heading1Font: {
    //   fontFamily: "YMontserrat",
    //   fontSize: "72",
    //   fontWeight: "Bold",
    //   lineHeight: 1.115,
    //   letterSpacing: -0.032,
    // },
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(23, 27, 37, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(13, 17, 28, 1)', defaultTextColor: '1'  },      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(245, 245, 246, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(163, 187, 253, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(148, 150, 156, 1)' },      
    ]
  }

  const frontierBrand = 
  {    
    name: "frontier",    
    font: {
      fontFamily: "YOnest",
      fontSize: "110",
      fontWeight: "Bold",
      lineHeight: 1.14,
      letterSpacing: -0.002,
    },
    heading1Font: {
      fontFamily: "YOnest",
      fontSize: "94",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YOnest",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.024,
    },
    // heading1Font: {
    //   fontFamily: "YMontserrat",
    //   fontSize: "72",
    //   fontWeight: "Bold",
    //   lineHeight: 1.115,
    //   letterSpacing: -0.032,
    // },
    screenVideoStyle:{
      shadowOpacity: 0.85,
      // shadowFrameOpacity: 0.53,
    },  
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(32, 32, 35, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(21, 21, 25, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(13, 13, 14, 1)'},      
      { id: '4', type: 'image', src: '/bgImages/befrontier/gradA.png', isLightbackground: false},
      { id: '5', type: 'image', src: '/bgImages/befrontier/gradB.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/befrontier/gradC.png', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/befrontier/gradD.png', isLightbackground: false},
      { id: '8', type: 'image', src: '/bgImages/befrontier/gradE.png', isLightbackground: false},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(192, 192, 200, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(235, 83, 104, 1)' },      
    ]
  }

  const smobiBrand = 
  {    
    name: "smobi",    
    font: {
      fontFamily: "YOutfit",
      fontSize: "110",
      fontWeight: "Semibold",
      lineHeight: 1.14,
      letterSpacing: 0.002,
    },
    heading1Font: {
      fontFamily: "YOutfit",
      fontSize: "94",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: 0.004,
    },
    heading2Font: {
      fontFamily: "YOutfit",
      fontSize: "84",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: 0.006,
    },
    
    screenVideoStyle:{
      // shadowOpacity: 0.85,
      // shadowFrameOpacity: 0.53,
    },  
    backgrounds:[      
      { id: '1', type: 'solid', rgba: 'rgba(16, 122, 229, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(45, 143, 240, 1)', isDefault:true, defaultTextColor: '1'},      
      { id: '3', type: 'solid', rgba: 'rgba(73, 158, 242, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(121, 184, 246, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(160, 204, 248, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(58, 64, 71, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(58, 64, 71, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(67, 80, 88, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(121, 133, 141, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(189, 195, 199, 1)'},      
      { id: '11', type: 'solid', rgba: 'rgba(232, 232, 237, 1)', colorFrameBG:'rgba(240, 240, 248, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(242, 242, 238, 1)', colorFrameBG:'rgba(240, 240, 248, 1)'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(237, 237, 233, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(16, 122, 229, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(45, 143, 240, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(73, 158, 242, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(121, 184, 246, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(160, 204, 248, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(0, 23, 36, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(58, 64, 71, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(67, 80, 88, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(121, 133, 141, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(189, 195, 199, 1)'},      
      { id: '12', type: 'solid', rgba: 'rgba(242, 242, 238, 1)'},      
    ]
  }

  const stackBrand = 
  {    
    name: "stack",    
    font: {
      fontFamily: "YInter",
      fontSize: "124",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "96",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "80",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    
    screenVideoStyle:{
      // shadowOpacity: 0.85,
      // shadowFrameOpacity: 0.53,
    },  
    backgrounds:[      
      { id: '1', type: 'image', src: '/bgImages/stack/gradA.png', isDefault:true, isLightbackground: true, smallSrc: '/bgImages/stack/gradA-small.png'},
      //{ id: '2', type: 'image', src: '/bgImages/stack/guide.png', isLightbackground: false},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'}, 
            
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(17, 24, 39, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(28, 100, 242, 1)'},
      
    ]
  }

  const brightCanaryBrand = 
  {    
    name: "brightCanary",    
    font: {
      fontFamily: "YPoppins",
      fontSize: "104",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    heading1Font: {
      fontFamily: "YPoppins",
      fontSize: "96",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YPoppins",
      fontSize: "80",
      fontWeight: "Bold",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },      
    backgrounds:[                
      { id: '1', type: 'solid', rgba: 'rgba(251, 238, 201, 1)', isDefault:true, defaultTextColor: '1'},      
      { id: '2', type: 'solid', rgba: 'rgba(236, 246, 255, 1)', defaultTextColor: '1'},      
      { id: '3', type: 'solid', rgba: 'rgba(252, 251, 251, 1)', defaultTextColor: '1'},      
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', defaultTextColor: '1'},
      { id: '5', type: 'solid', rgba: 'rgba(250, 73, 99, 1)', defaultTextColor: '2' },
      { id: '6', type: 'solid', rgba: 'rgba(9, 11, 50, 1)', defaultTextColor: '2'},
      { id: '7', type: 'solid', rgba: 'rgba(255, 206, 51, 1)', defaultTextColor: '2'},
      { id: '8', type: 'solid', rgba: 'rgba(52, 169, 255, 1)', defaultTextColor: '2'},      
            
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(10, 11, 51, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(250, 73, 99, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 206, 51, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(52, 169, 255, 1)'},
    ]
  }

  const fractBrand = 
  {    
    name: "ai fract",    
    font: {
      fontFamily: "YNotoSansJP",
      fontSize: "112",
      fontWeight: "Medium",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    heading1Font: {
      fontFamily: "YNotoSansJP",
      fontSize: "96",
      fontWeight: "Regular",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YNotoSansJP",
      fontSize: "80",
      fontWeight: "Regular",
      lineHeight: 1.1,
      letterSpacing: -0.022,
    },      
    backgrounds:[                
      { id: '1', type: 'solid', rgba: 'rgba(168, 168, 168, 1)', isDefault:true, defaultTextColor: '2'},      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', defaultTextColor: '1'},      
      { id: '3', type: 'solid', rgba: 'rgba(248, 248, 248, 1)', defaultTextColor: '1'},                        
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(10, 11, 51, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},            
    ]
  }

  const mavenAgiBrand = 
  {    
    name: "mavenAgi",    
    font: {
      fontFamily: "YMontserrat",
      fontSize: "94",
      fontWeight: "Heavy",
      lineHeight: 1.185,
      letterSpacing: -0.014,
    },
    heading1Font: {
      fontFamily: "YMontserrat",
      fontSize: "72",
      fontWeight: "Bold",
      lineHeight: 1.185,
      letterSpacing: -0.014,
    },
    heading2Font: {
      fontFamily: "YMontserrat",
      fontSize: "52",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },

     backgrounds:[                  
      { id: '1', type: 'solid', rgba: 'rgba(238, 239, 244, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(21, 27, 45, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(126, 58, 242, 1)'},   
      { id: '5', type: 'image', src: '/bgImages/mavenagi/gradA.png', isLightbackground: true},
      { id: '6', type: 'image', src: '/bgImages/mavenagi/gradB.png', isLightbackground: false},   
    ],
    textColors:[            
      { id: '1', type: 'solid', rgba: 'rgba(21, 27, 45, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(107, 114, 128, 1)'},      
   
    ]
  }





  const axleBrand = 
  {    
    name: "axleBrand",    
    font: {
      fontFamily: "YSuisseIntl",
      fontSize: "78",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.014,
    },
    heading1Font: {
      fontFamily: "YSuisseIntl",
      fontSize: "64",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.014,
    },
    heading2Font: {
      fontFamily: "YSuisseIntl",
      fontSize: "52",
      fontWeight: "Semibold",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },

     backgrounds:[                        
      { id: '1', type: 'solid', rgba: 'rgba(245, 245, 245, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(250, 250, 250, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(234, 234, 236, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(39, 39, 42, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(16, 185, 129, 1)'},      
    ],
    textColors:[            
      { id: '1', type: 'solid', rgba: 'rgba(39, 39, 42, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(82, 82, 91, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(250, 250, 250, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(16, 185, 129, 1)'},      
   
    ]
  }

   const heydayBrand = 
  {    
    name: "heydayBrand",    
    font: {
      fontFamily: "YGraphik",
      fontSize: "102",
      fontWeight: "Regular",
      lineHeight: 1.185,
      letterSpacing: -0.044,
    },
    heading1Font: {
      fontFamily: "YGraphik",
      fontSize: "84",
      fontWeight: "Medium",
      lineHeight: 1.185,
      letterSpacing: -0.024,
    },
    heading2Font: {
      fontFamily: "YGraphik",
      fontSize: "64",
      fontWeight: "Medium",
      lineHeight: 1.185,
      letterSpacing: -0.008,
    },


     backgrounds:[                              
      { id: '1', type: 'solid', rgba: 'rgba(244, 244, 244, 1)', isDefault: true},
      { id: '2', type: 'solid', rgba: 'rgba(2, 127, 48, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},            
      { id: '5', type: 'solid', rgba: 'rgba(224, 224, 224, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(126, 126, 126, 1)'},      
    ],
    textColors:[                  
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(244, 244, 244, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(224, 224, 224, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(126, 126, 126, 1)'},      
   
    ]
  }

  const cartageBrand = 
  {    
    name: "cartage",    
    font: {
      fontFamily: "YInter",
      fontSize: "76",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "70",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "60",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    
    screenVideoStyle:{
      // shadowOpacity: 0.85,
      // shadowFrameOpacity: 0.53,
    },  
    backgrounds:[      
      { id: '1', type: 'solid', rgba: 'rgba(217, 230, 242, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(246, 248, 248, 1)'}, 
      //{ id: '2', type: 'image', src: '/bgImages/stack/guide.png', isLightbackground: false},
      { id: '3', type: 'solid', rgba: 'rgba(11, 43, 64, 1)'}, 
      { id: '4', type: 'solid', rgba: 'rgba(22, 71, 114, 1)'}, 
      { id: '5', type: 'solid', rgba: 'rgba(76, 138, 193, 1)'}, 
      { id: '6', type: 'solid', rgba: 'rgba(23, 69, 69, 1)'}, 
      { id: '7', type: 'solid', rgba: 'rgba(59, 139, 110, 1)'}, 
      { id: '8', type: 'solid', rgba: 'rgba(137, 217, 156, 1)'}, 
      { id: '9', type: 'solid', rgba: 'rgba(216, 243, 223, 1)'}, 
      { id: '10', type: 'solid', rgba: 'rgba(11, 43, 64, 1)'}, 
      { id: '11', type: 'solid', rgba: 'rgba(11, 43, 64, 1)'}, 
      { id: '12', type: 'solid', rgba: 'rgba(11, 43, 64, 1)'}, 
            
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(34, 38, 42, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      
    ]
  }

  const acmeBrand = 
  {    
    name: "Acme",          
    font: {
      fontFamily: "YLabGrotesque",
      fontSize: "96",
      fontWeight: "Bold",
      lineHeight: 1.1,      
      letterSpacing: 0.001,
    },    
    heading1Font: {
      fontFamily: "YSFProDisplay",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    heading2Font: {
      fontFamily: "YCereal",
      fontSize: "80",
      fontWeight: "Semibold",
      lineHeight: 1.4,      
      letterSpacing: -0.001,
    },    
    bodyFont: {
      fontFamily: "YSFProDisplay",
      fontSize: "40",
      fontWeight: "Regular",
      lineHeight: 1.1,      
      letterSpacing: -0.001,
    },    
    screenVideoStyle:{
      shadowOpacity: 0.68,
      shadowFrameOpacity: 0.53,
    },  
    backgrounds:[
      { id: '1', type: 'solid', rgba: 'rgba(235, 221, 191, 1)', isDefault:true, defaultTextColor: '4'  },
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(226, 234, 253, 1)', defaultTextColor: '5' },
      { id: '4', type: 'solid', rgba: 'rgba(219, 238, 220, 1)', defaultTextColor: '3' },
      { id: '5', type: 'solid', rgba: 'rgba(229, 219, 238, 1)', defaultTextColor: '6' },
      { id: '6', type: 'solid', rgba: 'rgba(238, 219, 219, 1)', defaultTextColor: '7' },
      // { id: '5', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
      // { id: '6', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
      // { id: '7', type: 'solid', rgba: 'rgba(68, 147, 202, 1)' },
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(1, 1, 1, 1)' },
      { id: '2', type: 'solid', rgba: 'rgba(255, 255, 255, 1)' },
      { id: '3', type: 'solid', rgba: 'rgba(21, 48, 23, 1)'},        
      { id: '4', type: 'solid', rgba: 'rgba(48, 43, 21, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(24, 21, 56, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(38, 21, 48, 1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(48, 21, 21, 1)'},      
      // { id: '5', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
      // { id: '6', type: 'solid', rgba: 'rgba(58, 129, 231, 1)' },      
    ]
  }

  const aidyBrand = 
  {    
    name: "aidy",    
    font: {
      fontFamily: "YPlayfairDisplay",
      fontSize: "92",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: 0.002,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "70",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "64",
      fontWeight: "Semibold",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    bodyFont: {
      fontFamily: "YInter",
      fontSize: "60",
      fontWeight: "Medium",
      lineHeight: 1.1,
      letterSpacing: -0.032,
    },
    
    screenVideoStyle:{
      // shadowOpacity: 0.85,
      // shadowFrameOpacity: 0.53,
    },  
    backgrounds:[      
      { id: '1', type: 'solid', rgba: 'rgba(250, 242, 226, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(246, 248, 248, 1)'}, 
      { id: '3', type: 'solid', rgba: 'rgba(234, 234, 236, 1)'},            
      { id: '4', type: 'solid', rgba: 'rgba(40, 40, 50, 1)'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(34, 38, 42, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(64, 68, 72, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(139, 138, 133, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      
    ]
  }

   const intrycBrand = 
  {    
    name: "intryc",    
    font: {
      fontFamily: "YInter",
      fontSize: "82",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.028,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "74",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.010,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "64",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
    bodyFont: {
      fontFamily: "YInter",
      fontSize: "54",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
      
    backgrounds:[      
      { id: '1', type: 'solid', rgba: 'rgba(166, 221, 233, 1)', defaultTextColor: '1', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(129, 189, 203, 1)', defaultTextColor: '3' }, 
      { id: '3', type: 'solid', rgba: 'rgba(240, 240, 248, 1)'},            
      { id: '4', type: 'solid', rgba: 'rgba(20, 30, 35, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(22, 60, 69, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(34, 38, 42, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(166, 221, 233, 1)'},            
    ]
  }


  const clayBrand = 
  {    
    name: "clay",    
    font: {
      fontFamily: "YInter",
      fontSize: "112",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.014,
    },
    heading1Font: {
      fontFamily: "YInter",
      fontSize: "160",
      fontWeight: "Semibold",
      lineHeight: 1.112,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YInter",
      fontSize: "124",   
      fontWeight: "Semibold",
      lineHeight: 1.112,
      letterSpacing: -0.020,
    },
    heading3Font: {
      fontFamily: "YInter",
      fontSize: "104",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
    bodyFont: {
      fontFamily: "YInter",
      fontSize: "46",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    subFont: {
      fontFamily: "YInter",
      fontSize: "74",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: -0.014,
    },
    clayFrameTitleFont: {
      fontFamily: "YInter",
      fontSize: "18.75",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    // clayFrameTitleBigFont: {
    //   fontFamily: "YInter",
    //   fontSize: "38",
    //   fontWeight: "Medium",
    //   lineHeight: 1.115,
    //   letterSpacing: -0.004,
    // },
    // claySquareNameFont: {
    //   fontFamily: "YInter",
    //   fontSize: "36",
    //   fontWeight: "Medium",
    //   lineHeight: 1.115,
    //   letterSpacing: 0.0,
    // },
    // claySquareTitleFont: {
    //   fontFamily: "YInter",
    //   fontSize: "94",
    //   fontWeight: "Semibold",
    //   lineHeight: 1.115,
    //   letterSpacing: -0.012,
    // },    
    screenVideoStyle:{
      // shadowOpacity: 0.68,
      // shadowFrameOpacity: 0.53,
    },    
    backgrounds:[      
      { id: '1', type: 'image', src: '/bgImages/clay/PlainGray.png', isLightbackground: true, isDefault:true, colorFrameBG:'rgba(84, 177, 247, 1)', defaultTextColor: '2'},
      { id: '2', type: 'image', src: '/bgImages/clay/PlainPurple.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
      { id: '3', type: 'image', src: '/bgImages/clay/PlainBlue.png', isLightbackground: false, colorFrameBG:'rgba(84, 177, 247, 1)'},
      { id: '4', type: 'image', src: '/bgImages/clay/PlainGreen.png', isLightbackground: false, colorFrameBG:'rgba(81, 225, 149, 1)'},
      { id: '5', type: 'image', src: '/bgImages/clay/PlainOrange.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},
      { id: '6', type: 'image', src: '/bgImages/clay/PlainNavy.png', isLightbackground: false, colorFrameBG:'rgba(20, 89, 148, 1)'},
      { id: '7', type: 'image', src: '/bgImages/clay/PlainRed.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},      
      //{ id: '8', type: 'image', src: '/bgImages/clay/PlainPurple.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
      { id: '9', type: 'image', src: '/bgImages/clay/BottomScreenBlue.png', isLightbackground: false, colorFrameBG:'rgba(84, 177, 247, 1)'},
      { id: '10', type: 'image', src: '/bgImages/clay/BottomScreenGreen.png', isLightbackground: false, colorFrameBG:'rgba(81, 225, 149, 1)'},
      { id: '11', type: 'image', src: '/bgImages/clay/BottomScreenOrange.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},
      { id: '12', type: 'image', src: '/bgImages/clay/BottomScreenNavy.png', isLightbackground: false, colorFrameBG:'rgba(20, 89, 148, 1)'},
      { id: '13', type: 'image', src: '/bgImages/clay/BottomScreenRed.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},      
      { id: '14', type: 'image', src: '/bgImages/clay/BottomScreenPurple.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
      // { id: '15', type: 'image', src: '/bgImages/clay/RightScreenBlue.png', isLightbackground: false, colorFrameBG:'rgba(84, 177, 247, 1)'},
      // { id: '16', type: 'image', src: '/bgImages/clay/RightScreenGreen.png', isLightbackground: false, colorFrameBG:'rgba(81, 225, 149, 1)'},
      // { id: '17', type: 'image', src: '/bgImages/clay/RightScreenOrange.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},
      // { id: '18', type: 'image', src: '/bgImages/clay/RightScreenNavy.png', isLightbackground: false, colorFrameBG:'rgba(20, 89, 148, 1)'},
      // { id: '19', type: 'image', src: '/bgImages/clay/RightScreenRed.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},
      // { id: '20', type: 'image', src: '/bgImages/clay/LightBlue.png', isLightbackground: true, colorFrameBG:'rgba(84, 177, 247, 1)'},
      { id: '21', type: 'image', src: '/bgImages/clay/TitleBGBlueNew.png', isLightbackground: false, colorFrameBG:'rgba(84, 177, 247, 1)'},      
      { id: '22', type: 'image', src: '/bgImages/clay/TitleBGGreenNew.png', isLightbackground: false, colorFrameBG:'rgba(81, 225, 149, 1)'},
      { id: '23', type: 'image', src: '/bgImages/clay/TitleBGOrangeNew.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},
      { id: '24', type: 'image', src: '/bgImages/clay/TitleBGNavyNew.png', isLightbackground: false, colorFrameBG:'rgba(20, 89, 148, 1)'},
      { id: '25', type: 'image', src: '/bgImages/clay/TitleBGRedNew.png', isLightbackground: false, colorFrameBG:'rgba(255, 163, 141, 1)'},      
      { id: '26', type: 'image', src: '/bgImages/clay/TitleBGPurpleNew.png', isLightbackground: false,  colorFrameBG:'rgba(205, 157, 255, 1)'},
      { id: '27', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(245, 245, 250, 1)'},      
      { id: '28', type: 'solid', rgba: 'rgba(0, 0, 0, 1)', colorFrameBG:'rgba(40, 40, 50, 1)'},
      { id: '29', type: 'solid', rgba: 'rgba(230, 230, 233, 1)', colorFrameBG:'rgba(255, 255, 255, 1)'},
      { id: '30', type: 'solid', rgba: 'rgba(2, 58, 105, 1)'},      
      { id: '31', type: 'solid', rgba: 'rgba(3, 143, 247, 1)'},  
      { id: '32', type: 'solid', rgba: 'rgba(255, 174, 19, 1)'},
      { id: '33', type: 'solid', rgba: 'rgba(170, 112, 230, 1)'},        
      { id: '34', type: 'solid', rgba: 'rgba(237, 79, 106, 1)'},      
      { id: '35', type: 'solid', rgba: 'rgba(43, 191, 113, 1)'},     
      { id: '36', type: 'image', src: '/bgImages/clay/macOS.png', isLightbackground: true, colorFrameBG:'rgba(255, 255, 255, 1)', colorFrameMaxOpacity: 0.55},
      { id: '37', type: 'image', src: '/bgImages/clay/macOSClay.png', isLightbackground: true},
      { id: '38', type: 'image', src: '/bgImages/clay/macOSClayLight.png', isLightbackground: true},
      { id: '39', type: 'solid', rgba: 'rgba(231, 232, 236, 1)'},     
      { id: '40', type: 'solid', rgba: 'rgba(162, 115, 223, 1)'},     
      { id: '41', type: 'solid', rgba: 'rgba(69, 132, 234, 1)'},     

      // { id: '8', type: 'image', src: '/bgImages/clay/grayplain.png', isLightbackground: true, colorFrameBG:'rgba(211, 217, 255, 1)'},
      // { id: '9', type: 'solid', rgba: 'rgba(3, 143, 247, 1)', colorFrameBG:''},
      // { id: '10', type: 'solid', rgba: 'rgba(43, 191, 103, 1)', colorFrameBG:''},
      // { id: '11', type: 'solid', rgba: 'rgba(170, 112, 230, 1)', colorFrameBG:''},
      // { id: '12', type: 'solid', rgba: 'rgba(255, 122, 89, 1)', colorFrameBG:'rgba(255, 163, 141, 1)'},
      // { id: '13', type: 'solid', rgba: 'rgba(237, 79, 106, 1)', colorFrameBG:'rgba(255, 136, 157, 1)'},
      // { id: '14', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(20, 89, 148, 1)'},
      // { id: '15', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(84, 177, 247, 1)'},
      // { id: '16', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(81, 225, 149, 1)'},
      // { id: '17', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(205, 157, 255, 1)'},
      // { id: '18', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(255, 163, 141, 1)'},
      // { id: '19', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(255, 136, 157, 1)'},
      // { id: '20', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(20, 89, 148, 1)'},  
      //{ id: '8', type: 'image', src: '/bgImages/clay/grayplain.png', isLightbackground: true, colorFrameBG:'rgba(211, 217, 255, 1)'},

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(29, 32, 38, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(2, 58, 105, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(0, 85, 151, 1)'},      
      { id: '5', type: 'solid', rgba: 'rgba(3, 143, 247, 1)'},      
      { id: '6', type: 'solid', rgba: 'rgba(171, 201, 254, 1)'},      
      { id: '7', type: 'solid', rgba: 'rgba(72, 42, 103, 1)'},      
      { id: '8', type: 'solid', rgba: 'rgba(170, 112, 230, 1)'},      
      { id: '9', type: 'solid', rgba: 'rgba(237, 128, 0, 1)'},      
      { id: '10', type: 'solid', rgba: 'rgba(255, 174, 19, 1)'},      
      { id: '11', type: 'solid', rgba: 'rgba(105, 23, 40, 1)'},      
      { id: '12', type: 'solid', rgba: 'rgba(237, 79, 106, 1)'},      
      { id: '13', type: 'solid', rgba: 'rgba(206, 80, 49, 1)'},      
      { id: '14', type: 'solid', rgba: 'rgba(255, 122, 89, 1)'},      
      { id: '15', type: 'solid', rgba: 'rgba(2, 94, 56, 1)'},      
      { id: '16', type: 'solid', rgba: 'rgba(43, 191, 113, 1)'},      
      { id: '17', type: 'solid', rgba: 'rgba(69, 132, 234, 1)'},
      // { id: '16', type: 'solid', rgba: 'rgba(237, 79, 106, 1)'},      
      //{ id: '4', type: 'solid', rgba: 'rgba(161, 115, 223, 1)'},            
    ]
  }

    const elevenLabsBrand = 
  {    
    name: "elevenLabs",    
    font: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "130",
      fontWeight: "Bold",
      lineHeight: 0.98,
      letterSpacing: -0.014,
    },
    heading1Font: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "160",
      fontWeight: "Semibold",
      lineHeight: 1.112,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "105",   
      fontWeight: "Bold",
      lineHeight: 0.98,
      letterSpacing: -0.020,
    },
    heading3Font: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "104",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.012,
    },
    bodyFont: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "46",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: 0.0,
    },
    subFont: {
      fontFamily: "YWaldenburgCondensed",
      fontSize: "60",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.014,
    },    
    
    screenVideoStyle:{
      // shadowOpacity: 0.68,
      shadowFrameOpacity: 0.64,
    },    
    backgrounds:[            
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true, colorFrameBG:'rgba(255, 255, 255, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(0, 0, 0, 1)', colorFrameBG:'rgba(205, 157, 255, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(250, 250, 255, 1)', colorFrameBG:'rgba(205, 157, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(59, 92, 245, 1)', colorFrameBG:'rgba(205, 157, 255, 1)'},

    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      
      { id: '2', type: 'solid', rgba: 'rgba(29, 32, 38, 1)'},      
      { id: '3', type: 'solid', rgba: 'rgba(120, 120, 125, 1)'},      
      { id: '4', type: 'solid', rgba: 'rgba(140, 140, 145, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(160, 160, 165, 1)'},
]}
  const hyparBrand = 
  {    
    name: "hypar",    
    font: {
      fontFamily: "YAeonik",
      fontSize: "84",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.002,
    },
    heading1Font: {
      fontFamily: "YAeonik",
      fontSize: "120",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.010,
    },
    heading2Font: {
      fontFamily: "YAeonik",
      fontSize: "74",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
    heading3Font: {
      fontFamily: "YAeonik",
      fontSize: "60",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
    bodyFont: {
      fontFamily: "YAeonik",
      fontSize: "50",
      fontWeight: "Regular",
      lineHeight: 1.115,
      letterSpacing: -0.004,
    },
    
    backgrounds:[      
      { id: '1', type: 'solid', rgba: 'rgba(247, 247, 247, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(255, 243, 245, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(254, 192, 204, 1)'}, 
      { id: '4', type: 'solid', rgba: 'rgba(249, 253, 255, 1)'},            
      { id: '5', type: 'solid', rgba: 'rgba(226, 246, 248, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(241, 92, 107, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(160, 54, 72, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(132, 196, 213, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(65, 110, 138, 1)'},
      { id: '10', type: 'solid', rgba: 'rgba(234, 244, 255, 1)'},
      { id: '11', type: 'solid', rgba: 'rgba(222, 242, 230, 1)'},
      { id: '12', type: 'solid', rgba: 'rgba(255, 239, 240, 1)'},
      { id: '13', type: 'solid', rgba: 'rgba(255, 243, 228, 1)'},      
    ],
    textColors:[
      { id: '1', type: 'solid', rgba: 'rgba(44, 49, 59, 1)'},
      { id: '2', type: 'solid', rgba: 'rgba(241, 92, 107, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(86, 90, 98, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(128, 131, 137, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(171, 173, 177, 1)'},
      { id: '6', type: 'solid', rgba: 'rgba(202, 202, 206, 1)'},
      { id: '7', type: 'solid', rgba: 'rgba(234, 235, 236, 1)'},
      { id: '8', type: 'solid', rgba: 'rgba(247, 247, 247, 1)'},
      { id: '9', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},      

    ]
  }


  const zepBrand = 
  {    
    name: "zep",    
    font: {
      fontFamily: "YRaleway",
      fontSize: "150",
      fontWeight: "Semibold",
      lineHeight: 1.115,
      letterSpacing: -0.024,
    },
    heading1Font: {
      fontFamily: "YRaleway",
      fontSize: "112",
      fontWeight: "Semibold",
      lineHeight: 1.112,
      letterSpacing: -0.022,
    },
    heading2Font: {
      fontFamily: "YRaleway",
      fontSize: "67",   
      fontWeight: "Bold",
      lineHeight: 1.112,
      letterSpacing: -0.0,
    },
    bodyFont: {
      fontFamily: "YRaleway",
      fontSize: "42",
      fontWeight: "Semibold",
      lineHeight: 1.40,
      letterSpacing: -0.014,
    },
    subFont: {
      fontFamily: "YRaleway",
      fontSize: "46",
      fontWeight: "Bold",
      lineHeight: 1.115,
      letterSpacing: -0.0,
    },

    backgrounds:[                
      { id: '1', type: 'solid', rgba: 'rgba(244, 244, 244, 1)', isDefault:true },   
      { id: '2', type: 'solid', rgba: 'rgba(248, 226, 239, 1)'},
      { id: '3', type: 'solid', rgba: 'rgba(242, 38, 170, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(213, 71, 157, 1)'},      
      { id: '5', type: 'image', src: '/bgImages/zep/grad.png', isLightbackground: false},
      { id: '6', type: 'image', src: '/bgImages/zep/gradb.png', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/zep/gradc.png', isLightbackground: false},
      { id: '8', type: 'image', src: '/bgImages/zep/gradd.png', isLightbackground: false},
      { id: '9', type: 'image', src: '/bgImages/zep/grade.png', isLightbackground: true},
      { id: '10', type: 'image', src: '/bgImages/zep/gradf.png', isLightbackground: true},      
      { id: '11', type: 'solid', rgba: 'rgba(66, 31, 203, 1)'}, 
      { id: '12', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'}, 
      { id: '12', type: 'solid', rgba: 'rgba(80, 80, 80, 1)'}, 
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(44, 16, 51, 1)'},  
      { id: '2', type: 'solid', rgba: 'rgba(244, 244, 244, 1)'},   
      { id: '3', type: 'solid', rgba: 'rgba(248, 226, 239, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(242, 38, 170, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(213, 71, 157, 1)'},                  
      { id: '6', type: 'solid', rgba: 'rgba(66, 31, 203, 1)'}, 
      { id: '7', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
    ]
  }

  const incidentBrand = 
  {    
    name: "incidentBrand",    
    font: {
      fontFamily: "YUntitledSans",
      fontSize: "110",
      fontWeight: "Semibold",
      lineHeight: 0.995,
      letterSpacing: -0.019,
    },
    heading1Font: {
      fontFamily: "YUntitledSans",
      fontSize: "100",
      fontWeight: "Semibold",
      lineHeight: 0.995,
      letterSpacing: -0.019,
    },
    heading2Font: {
      fontFamily: "YUntitledSans",
      fontSize: "67",   
      fontWeight: "Semibold",
      lineHeight: 1.112,
      letterSpacing: -0.0,
    },
    bodyFont: {
      fontFamily: "YUntitledSans",
      fontSize: "40",
      fontWeight: "Regular",
      lineHeight: 1.20,
      letterSpacing: -0.004,
    },
    subFont: {
      fontFamily: "YGeistMono",
      fontSize: "46",
      fontWeight: "Medium",
      lineHeight: 1.115,
      letterSpacing: 0.2,
    },

    backgrounds:[                
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)', isDefault:true},
      { id: '2', type: 'solid', rgba: 'rgba(21, 23, 28, 1)', colorFrameBG:'rgba(112, 64, 58, 1)'},   
      { id: '3', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},
      { id: '4', type: 'solid', rgba: 'rgba(242, 85, 51, 1)'},
      { id: '5', type: 'solid', rgba: 'rgba(33, 33, 38, 1)'},
      // { id: '3', type: 'solid', rgba: 'rgba(242, 38, 170, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(213, 71, 157, 1)'},      
      { id: '6', type: 'image', src: '/bgImages/incident/bga.png', colorFrameBG:'rgba(112, 64, 58, 1)', isLightbackground: false},
      { id: '7', type: 'image', src: '/bgImages/incident/bgb.png', colorFrameBG:'rgba(112, 64, 58, 1)', isLightbackground: false},
      // { id: '7', type: 'image', src: '/bgImages/zep/gradc.png', isLightbackground: false},
      // { id: '8', type: 'image', src: '/bgImages/zep/gradd.png', isLightbackground: false},
      // { id: '9', type: 'image', src: '/bgImages/zep/grade.png', isLightbackground: true},
      // { id: '10', type: 'image', src: '/bgImages/zep/gradf.png', isLightbackground: true},      
      // { id: '11', type: 'solid', rgba: 'rgba(66, 31, 203, 1)'}, 
      // { id: '12', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'}, 
      // { id: '12', type: 'solid', rgba: 'rgba(80, 80, 80, 1)'}, 
    ],
    textColors:[      
      { id: '1', type: 'solid', rgba: 'rgba(255, 255, 255, 1)'},  
      { id: '2', type: 'solid', rgba: 'rgba(23, 33, 47, 1)'},   
      { id: '3', type: 'solid', rgba: 'rgba(242, 85, 51, 1)'},
      // { id: '4', type: 'solid', rgba: 'rgba(242, 38, 170, 1)'},
      // { id: '5', type: 'solid', rgba: 'rgba(213, 71, 157, 1)'},                  
      // { id: '6', type: 'solid', rgba: 'rgba(66, 31, 203, 1)'}, 
      // { id: '7', type: 'solid', rgba: 'rgba(0, 0, 0, 1)'},
    ]
  }




const brands = {
    "yarn.so": yarnBrand,    
    "codeant.ai": codeAntBrand,
    "notion": notionBrand,
    "finta.com": fintaBrand,
    "inkeep.com": inkeepBrand,
    "forgehq.com": forgeBrand,
    "trycreo.com": creoBrand,
    "causal.app": causalBrand,
    "leya.law": leyaBrand,
    "fumedev.com": fumeDevBrand,
    "usefini.com": useFiniBrand,
    "runalloy.com": alloyBrand,
    "superagent.sh": superAgentBrand,
    "feelmuddy.com": muddyBrand,
    "furtherai.com": furtherAIBrand,
    "asklio.ai": askLioBrand,
    "pyrls.com": pyrlsBrand,
    "hostai.app": hostAIBrand,
    "infinityapp.in": infinityBrand,
    "tryabel.com": abelBrand,
    "velt.dev": veltBrand,    
    "driverai.com": driverAIBrand, 
    "replo.app": reploBrand,
    "getonboard.dev": onboardAIBrand,
    "pyrls.com": pyrlsBrand,
    "cosmashealth.com":pyrlsBrand,
    "celest.dev": celestBrand,
    "lumina-chat.com": luminaBrand,
    "safetykit.com": safetyKitBrand,
    "govdash.com": govDashBrand,
    "martini.ai": martiniBrand,
    "itsaffinity.com": affinityBrand,    
    "thorntale.com": thorntaleBrand,
    "posthog.com": posthogBrand,
    "stempath.com": stempathBrand,
    //"osmoslearn.com": osmosBrand,
    "getfluently.app": fluentlyBrand,
    "shiboleth.ai": shibolethBrand,
    "registerplay.com": registerPlayBrand,
    "openmart.ai": openmartBrand,
    "lightdash.com": lightdashBrand,
    "learnontil.com": tilBrand,
    "parcha.ai": parchaBrand,
    "botterfly.app": botterflyBrand,
    "breadboard.com": breadboardBrand,
    "validio.io": validioBrand,
    "canvasapp.com": canvasBrand,
    "finleycms.com": finleyBrand,
    "pegbo.com": pegboBrand,
    "unfair.poker": unfairPokerBrand,
    "vapi.ai": vapiBrand,
    "rootly.com": rootlyBrand,
    "opencopilot.so": openCopilotBrand,
    "aidevstudio.ai": aidevStudioBrand,
    "voltview.co.uk": voltviewBrand,
    "faire.com": faireBrand,
    "cetient.com": cetientBrand,
    "onegrep.dev": onegrepBrand,
    "unsupervised.com": unsupervisedBrand,
    "spectacles.dev": spectaclesBrand,
    "upduo.com": upduoBrand,
    "stackerhq.com": stackerBrand,
    "lexolve.com": lexolveBrand,
    "datrics.ai": datricsBrand,
    "usetwine.com": twineBrand,
    "mercury.com": mercuryBrand,
    "jsonify.co": jsonifyBrand,
    "sevn.ai": sevnBrand,
    "howie.ai": howieBrand,
    "thesignalgroup.com": signalBrand,
    "signalmaritime.com": signalBrand,
    "positivesumlabs.com": edgarBrand,
    "charleswilliamson.io": inyougoBrand,
    "catalistai.com": catalistBrand,
    "a16z.com": a16zBrand,
    "greenlite.ai": greenliteBrand,
    "getsavings.app": wealthieeBrand,
    "keeper.app": keeperBrand,
    "upkeep.com": upkeepBrand,
    "alphainfoscience.com": aisBrand,
    "artisan.co": artisanBrand,    
    "leoniscap.com": leonisBrand,
    "befrontier.com": frontierBrand,    
    "smobi.com": smobiBrand,    
    "stack-ai.com": stackBrand,   
    "brightcanary.io": brightCanaryBrand, 
    "ai-fract.com": fractBrand, 
    "mavenagi.com": mavenAgiBrand, 
    "axle.insure": axleBrand, 
    "heyday.xyz": heydayBrand, 
    "cartage.ai": cartageBrand,
    "jasperdstory": acmeBrand,
    "aidygov.com": aidyBrand,
    "intryc.com": intrycBrand,  
    "clay.com":clayBrand, 
    "hypar.io":hyparBrand,
    "getzep.com":zepBrand,
    "incident.io":incidentBrand,
};

export function getBrandForDomain(domain) {    
  //console.log(`get brand for ${domain}`)
  // return brands['clay.com'];
  return brands[domain] || brands['yarn.so'];   
  // return clayBrand
}



