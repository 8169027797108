import store from '../store'


export function getRecentMedia() {
    const state = store.getState();

     const imagesWithType = state.uploadedImages.map(image => ({ ...image, type: 'image' })).filter(image => {
        const imageStatus = state.imageDownloads[image.id];
        //return !(imageStatus || imageStatus === 'downloading');
           return !imageStatus || imageStatus != 'downloading';
    });

    const videosWithType = state.uploadedVideos.map(video => ({ ...video, type: 'video' })).filter(video => {
        const videoStatus = state.basicVideoDownloads[video.id];

        return !videoStatus || videoStatus != 'downloading';
    });

    
    const webcamVideosWithType = state.webcamVideos.map(video => ({ ...video, type: 'webcamVideo' })).filter(video => {
        const videoStatus = state.webcamDownloads[video.capture_id];
        return !videoStatus || videoStatus != 'downloading';
    });



    const starredItems = [...imagesWithType, ...videosWithType].filter(item => item.starred);
    const nonStarredItems = [...imagesWithType, ...videosWithType,...webcamVideosWithType].filter(item => !item.starred);


    // Sort non-starred items by created_at in descending order
    const sortedNonStarredItems = nonStarredItems.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA; // For descending order
    });


 const combinedMedia = [...starredItems, ...sortedNonStarredItems];


    return combinedMedia.slice(0, 100);
}


