import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import * as THREE from 'three';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import WebcamVideoVideoMaterial from './WebcamVideoVideoMaterial'
import ExportWebcamVideoMaterial from './ExportWebcamVideoMaterial'
import { createDropShadow } from './WebcamVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import renderOrders from '../renderOrders'


const WebcamVideoVideo = ({clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType,showWebcamStatic, FPS, isPlaying, videoDisplayWidth, videoDisplayHeight, videoDisplayRadius}) => {
 
    
 

  const meshWidth = videoDisplayWidth
  const meshHeight = videoDisplayHeight
  const meshRadius = videoDisplayRadius

  const originalVideoWidth = clip.metadata.originalWidth
  const originalVideoHeight = clip.metadata.originalHeight

  const deviceRef = useRef();  
  const shadowRef = useRef();  

  const [opacity, setOpacity] = useState(1);
  //
  // DEVICE GEOMETRY  
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [meshWidth, meshHeight, meshRadius]);


  //
  // SHADOW SETUP
  // const shadowXOffset = 0;
  // const shadowYOffset = -8;
  // const shadowBlur = 20;
  // const shadowSpread = -15;
  // // const shadowOpacity = 0.68;
  // let shadowOpacity = 0.84;  

  const shadowXOffset = 0;
  const shadowYOffset = -8;
  const shadowBlur = 10;
  const shadowSpread = -14;
  // const shadowOpacity = 0.68;
  let shadowOpacity = 0.44;  


  const shadowMesh = useMemo(() => {
    const shadowOptions = {
      shadowXOffset, // X offset from Leva
      shadowYOffset, // Y offset from Leva
      shadowBlur,    // shadowBlur from Leva
      shadowSpread,  // shadowSpread (scale) from Leva
      shadowColor: 'rgba(0,0,0,1)',
      shadowOpacity, // Opacity from Leva
    };
    return createDropShadow(meshWidth, meshHeight, shadowOptions);
  }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);


  // console.log('animationStates')
  // console.log(animationStates)
  // console.log('----')
  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showWebcamStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              //deviceRef.current.material.opacity = state;            
              setOpacity(state);
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showWebcamStatic]);

    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (shadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + shadowXOffset;
        shadowRef.current.position.y = animatedPosY + shadowYOffset;
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);

        if(showWebcamStatic){
          shadowRef.current.position.x = shadowXOffset
          shadowRef.current.position.y = shadowYOffset
          shadowRef.current.position.z = 0
          shadowRef.current.material.opacity = shadowOpacity          
        }
      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, meshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showWebcamStatic]);

  
    // console.log('WEBCAMCLIP')
    // console.log(clip)


  return (
    <>      

     
        <>
          
          <primitive 
            object={shadowMesh}
            ref={shadowRef} 
            visible={visible}     
            renderOrder={renderOrders.webcamVideoShadow}
          /> 
              

          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}            
            renderOrder={renderOrders.webcamVideo}
          >

         {clip.isExport &&
           <ExportWebcamVideoMaterial 
              codecVideoClip={clip}  
              meshWidth={meshWidth}
              meshHeight={meshHeight}
              zoom={clip.metadata.zoom}
              xOffset={clip.metadata.xOffset}
              yOffset={clip.metadata.yOffset}
              colorAdjustments={clip.metadata.colorAdjustments}
              renderOrder={renderOrders.webcamVideo}
              videoAssetWidth={clip.metadata.originalWidth}
              videoAssetHeight={clip.metadata.originalHeight}
              opacity={opacity}
            />             


          }

          {!clip.isExport &&
            <WebcamVideoVideoMaterial 
              videoElement={clip.video}
              
              meshWidth={meshWidth}
              meshHeight={meshHeight}
              zoom={clip.metadata.zoom}
              xOffset={clip.metadata.xOffset}
              yOffset={clip.metadata.yOffset}
              colorAdjustments={clip.metadata.colorAdjustments}
              renderOrder={renderOrders.webcamVideo}
              videoAssetWidth={clip.metadata.originalWidth}
              videoAssetHeight={clip.metadata.originalHeight}
             // videoAssetWidth={200}
             // videoAssetHeight={400}
              opacity={opacity} // update to animated
              //lutPath={'/luts/bwLUT.png'}
              //lutPath={'/luts/nightLUT.png'}
              //lutPath={'/luts/neutralLUT.png'}
              //lutPath={'/luts/Bourbon64.cube'}
              //lutPath={'/luts/Chemical168.cube'}
              //lutPath={'/luts/Portrait10.cube'}
            />              
          }



          </mesh>

        </>
      

    </>
  );
};

export default WebcamVideoVideo;
