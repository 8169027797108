const pronunciationDictionary = [
    {
        word: 'Replo',
        ph: "/'rɛpː.loʊ/",
        alt: "rep-low"
    },
    {
        word: 'Claygent',
        // alt: "Clayjent"
        alt: "Clayjint"
    },
    {
        word: 'baseten',
        alt: "base ten"
    }
];

export default pronunciationDictionary;