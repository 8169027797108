import React from 'react'
import { Canvas } from '@react-three/fiber';
import TextSlideInputCanvas from './TextSlideInputCanvas'
import TextSlideInputTextArea from './TextSlideInputTextArea'
import { getBackgroundForId } from '../../utils/brands/getBackgroundForId'
import * as THREE from 'three';

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080
const SCENE_ASPECT = 16/9

class TextSlideEditor extends React.PureComponent{  

  render(){       
		const {canvasWidth, canvasHeight,slide,hidden,projectBackground} = this.props	
    const scalar = canvasWidth / 1920            


		const backgroundId = slide.metadata.backgroundId
		let background = projectBackground
		if(backgroundId && backgroundId!=='none'){
			background=getBackgroundForId(backgroundId)
		}		

		return (            				
			<>
				<div data-state={hidden ? 'hidden' : 'visible'} style={{width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px`}} className='editor-textSlideCanvasInnerContainer editor-textSlideCanvasInnerContainer--inputPreview'>		        
					<Canvas className='editor-textSlideCanvas' style={{ width: `calc(${SCENE_WIDTH}px * 1/${scalar})`, height: `calc(${SCENE_HEIGHT}px * 1/${scalar})` }} gl={{ alpha: true, toneMapping: THREE.NoToneMapping }}>	        	
	          <TextSlideInputCanvas
	          	wordsArray={slide.wordsArray}
	          	fontFamily={slide.metadata.fontFamily}
	          	fontWeight={slide.metadata.fontWeight}	          	
	          	fontSize={slide.metadata.fontSize}
	          	listType={slide.metadata.listType}
	          	letterSpacing={slide.metadata.letterSpacing}
	          	slide={slide}    
	          	background={background}    	
	          />
	      	</Canvas>				
				</div>

				<div style={{width: `${SCENE_WIDTH}px`, height: `${SCENE_HEIGHT}px`}} className='editor-textSlideCanvasInnerContainer editor-textSlideCanvasInnerContainer--input'>
					<TextSlideInputTextArea
						key={slide.id}
						slideId={slide.id}
						slide={slide}
						fontFamily={slide.metadata.fontFamily}
	          fontWeight={slide.metadata.fontWeight}
	          fontSize={slide.metadata.fontSize}	          
	          textAlign={slide.metadata.textAlign}
	          listType={slide.metadata.listType}
	          letterSpacing={slide.metadata.letterSpacing}
	          lineHeight={slide.metadata.lineHeight}
	          canvasX={this.props.canvasX}
	          canvasY={this.props.canvasY}
	          handleSetWordsArray={this.props.handleSetWordsArray}
	          invertScalar={1/scalar}
	          initialValue={slide.metadata.docJson}	          	         
	          setTextEditorIsFocused={this.props.setTextEditorIsFocused}
	          handleSetActivePanelClip={this.props.handleSetActivePanelClip}
	          activePanelClip={this.props.activePanelClip}
	          handleDeactivateActiveClip={this.props.handleDeactivateActiveClip}
	          slideBG={background}
					/>
				</div>			
									
			</>
		)
  }
}

export default TextSlideEditor


