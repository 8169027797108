import React, { useMemo } from 'react';
import * as THREE from 'three';


//const imageSrc='https://res.cloudinary.com/yarn/image/upload/v1695846058/user_avatars/1310654799.png'


const MobileVideoDeviceImageMaterial = ({  opacity,imgSrc }) => {
  

  // Use useMemo to load the image texture and ensure it's only loaded once or when imageSrc changes
  const texture = useMemo(() => {
    const loader = new THREE.TextureLoader();
    // Load the image texture from the provided URL

    const texture = loader.load(imgSrc, (tex) => {
      // This callback function is executed when the texture is successfully loaded
      tex.encoding = THREE.SRGBColorSpace; // Set the texture encoding to sRGB
    });

    return texture
   // return loader.load(imgSrc);
  }, [imgSrc]);

  // Create the material with the image texture
  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: texture, // Use the loaded image texture
      transparent: true, // Allow transparency to handle any PNG transparency or similar effects
      opacity: 0.5, // Control the opacity of the texture
    });
  }, [texture, opacity]);




  return <primitive object={material} attach="material" />;
};

export default MobileVideoDeviceImageMaterial;
