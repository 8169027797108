export function scaleLogoVariableImage(element) {
  const originalElementHeight = element.height;
  const originalElementWidth = element.width;
  const logoWidth = element.metadata.original.width;
  const logoHeight = element.metadata.original.height;

  // Calculate the original area
  const originalArea = originalElementHeight * originalElementWidth;

  // Calculate the aspect ratio of the logo
  const logoAspectRatio = logoWidth / logoHeight;

  // Calculate new dimensions that maintain the original area and logo's aspect ratio
  const newHeight = Math.sqrt(originalArea / logoAspectRatio);
  const newWidth = newHeight * logoAspectRatio;

  // Update the element's dimensions
  element.height = Math.round(newHeight);
  element.width = Math.round(newWidth);

  console.log(`Original dimensions: ${originalElementWidth}x${originalElementHeight}`);
  console.log(`New dimensions: ${element.width}x${element.height}`);
}