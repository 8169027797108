import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import CameraControls from 'camera-controls'
import { Text } from '@react-three/drei'
import { calculateParagraphLayout } from '../utils/calculateParagraphLayout'
import {getTextColorForId} from '../../utils/brands/getTextColorForId'
import {getTextColorForBackgroundId} from '../../utils/brands/getTextColorForBackgroundId'



const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

CameraControls.install({ THREE });

const TextSlideInputCanvasWord = ({wordValue, position, fontFamily, fontWeight, fontSize, devMode, textColor, letterSpacing}) => {
  return (
    <>      
      <Text        
        anchorX="left"
        anchorY="top"
        fontSize={fontSize}                
        textAlign="left"
        letterSpacing={letterSpacing}
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={position}
      >
        <meshBasicMaterial color={devMode ? "red" : textColor} />
         {wordValue}
      </Text>       
    </>
  );
};



const TextSlideInputListDot = ({position, fontFamily, fontWeight, fontSize, devMode, textColor}) => {

  return (
    <>      
      <Text        
        anchorX="left"
        anchorY="top"
        fontSize={fontSize}                
        textAlign="left"
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={position}
      >
        <meshBasicMaterial color={devMode ? "red" : textColor} />
         •
      </Text>     
    </>
  );
};


const TextSlideInputListNumber = ({value, position, fontFamily, fontWeight, fontSize, devMode, textColor}) => {

  return (
    <>      
      <Text        
        anchorX="left"
        anchorY="top"
        fontSize={fontSize}                
        textAlign="right"
        font={`../fonts/${fontFamily}/${fontFamily}-${fontWeight}.woff`}
        position={position}
      >
        <meshBasicMaterial color={devMode ? "red" : textColor} />
         {value}
      </Text>       
    </>
  );
};







const TextSlideInputCanvas = ({ wordsArray, deviceWidth, deviceHeight, deviceImg, fontFamily, fontWeight, textAlign, fontSize, textValue, maxWidth, headlineInputFocused, showText,slideClips, currentTime, lineHeight, listType,slide, letterSpacing,background }) => {
	const { camera, gl, scene } = useThree();
	const cameraControlsRef = useRef(null);
	// Initialize camera controls on mount
	useEffect(() => {
		cameraControlsRef.current = new CameraControls(camera, gl.domElement);
	}, [camera, gl.domElement]);

	useEffect(() => { //component did mount
			const box = new THREE.Box3().setFromCenterAndSize(
			new THREE.Vector3(0, 0, 0), // The main plane is centered at (0, 0, 0)
			new THREE.Vector3(SCENE_WIDTH, SCENE_HEIGHT, 0) // The size of the main plane
		);
		// Fit the camera to the bounding box of the main plane
		cameraControlsRef.current.fitToBox(box, false);
	}, []);

	// Animate camera controls on each frame
	useFrame((_, delta) => {
    if(cameraControlsRef &&cameraControlsRef.current ){
      cameraControlsRef.current.update(delta);
    }
	});

	const paragraphs = useMemo(() => calculateParagraphLayout(wordsArray), [wordsArray]);
	const devMode = false


	const textColorIsAuto = !slide.metadata.textColorId
  let textColor 
  if(textColorIsAuto){
    textColor = getTextColorForBackgroundId(background.id).rgba
  }else{
    textColor = getTextColorForId(slide.metadata.textColorId).rgba
  }

  // console.log('in input canvas')
  // console.log(background)
  // console.log(textColor)
	// const textColor = slide.metadata.textColor.rgba
	//let textColor = getTextColorForId(slide.metadata.textColorId).rgba
	return (
		<>

		
		{listType && listType === 'bullet' && Object.entries(paragraphs).map(([index, paragraph]) => (
	      <TextSlideInputListDot
	        key={index}
	        position={[-760, paragraph.minTop, 10]}
	        fontSize={fontSize}
	      	fontFamily={fontFamily}
	      	fontWeight={fontWeight}  
	      	devMode={devMode}    
	      	textColor={textColor}
	      />
	    ))
  	}

  	{listType && listType === 'number' && Object.entries(paragraphs).map(([index, paragraph]) => (
	      <TextSlideInputListNumber
	        key={index}
	        value={`${parseInt(index) + 1}.`}
	        position={[-800, paragraph.minTop, 10]}
	        fontSize={fontSize}
	      	fontFamily={fontFamily}
	      	fontWeight={fontWeight}          
	      	devMode={devMode}
	      	textColor={textColor}
	      />
	    ))
  	}
		
		{wordsArray.map((wordObj, index) => (  
      <TextSlideInputCanvasWord
        key={index}
        wordValue={wordObj.word}
        position={[wordObj.rect.normalLeft, wordObj.rect.normalTop, 10]}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontWeight={fontWeight}   
        letterSpacing={letterSpacing} 
        devMode={devMode}
        textColor={textColor}  
      />
    ))
		}

		</>
	);
};

export default TextSlideInputCanvas