import React, {useState} from 'react'
import Icon from '../../../misc/Icon';
import SelectVoicePopover from './selectVoicePopover/SelectVoicePopover'
import SelectCaptionsPopover from './selectCaptionsPopover/SelectCaptionsPopover'
import * as Tooltip from '@radix-ui/react-tooltip';

//subtitlesType is null, standard or captions

function TranscriptPanelTopBar({ subtitlesType,handleChangeSubtitlesType,handleGenerateSRTFile,speakerName, handleChangeActiveVoice ,activeSpeakerVoice,handleChangeVoiceoverPlaybackRate,activeVoiceoverPlaybackRate, hideTranscriptPanel,showCaptions,toggleShowCaptions,generateSubtitles,regenerateAllSubtitles}) {
  
  const [voiceDropdownOpen, setVoiceDropdownOpen] = useState(false)
  const [captionsDropdownOpen, setCaptionsDropdownOpen] = useState(false)
  // const [activeCaptionStyle, setActiveCaptionStyle] = useState('standard')

  const handleCaptionStyleChange = (style) => {
    let newSubtitlesType 
    if(style=='Standard'){
      newSubtitlesType='standard'
    }if(style=='Pop'){
      newSubtitlesType='captions'
    }
    if(newSubtitlesType==subtitlesType){
      newSubtitlesType=null
    }
    handleChangeSubtitlesType(newSubtitlesType);
  }

  const handleCaptionsClick = () => {    
    setCaptionsDropdownOpen(!captionsDropdownOpen);
  }

  let activeStyle 
  if(subtitlesType=='standard'){
    activeStyle='Standard'
  }
  if(subtitlesType=='captions'){
    activeStyle='Pop'
  }
 // console.log(`in top bar subtitlesType ${subtitlesType}`)

  let captionsTooltip = 'Show captions'  
  if(subtitlesType=='standard'){
    captionsTooltip='Edit captions'
  }
  if(subtitlesType=='captions'){
    captionsTooltip='Edit captions'
  }

  return (
    <div className='editor-transcriptPanel-topBar'>          
        


      <button onClick={hideTranscriptPanel} className='editor-transcriptPanel-topBar-closeBtn'>      
        <Icon name='doubleChevronLeft' />        
      </button>


     




      <div className='editor-transcriptPanel-topBar-hSpacer' />


      {/*}
       <button onClick={generateSubtitles} className='editor-transcriptPanel-topBar-closeBtn' style={{background:'red'}}>      
        Generate Subtitles       
      </button>
      */}




       <div className='editor-transcriptPanel-topBar-hSpacer' />
      

      <SelectVoicePopover        
        activeSpeakerId={activeSpeakerVoice}
        handleChangeActiveVoice={handleChangeActiveVoice}
        //handleChangeActiveVoice={}
        handleClosePopover={() => setVoiceDropdownOpen(false)}   
        handleChangeVoiceoverPlaybackRate={handleChangeVoiceoverPlaybackRate}    
        activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate} 
      >
        <button onClick={() => setVoiceDropdownOpen(!voiceDropdownOpen)} className='uiButton uiButton--speakerDropdown'>
          <div className='uiButton-iconContainer'>
            <Icon name='waveform' />
          </div>
          <div className='uiButton-label'>
            {speakerName}            
          </div>     
          <div className='uiButton--speakerDropdown-tooltipContainer'>
            <div className='tooltip tooltip--transcriptPanelTopBar'>
              Edit voice
            </div>
          </div>     
        </button>           
      </SelectVoicePopover>

      
      {/* ADD IN 
      <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild> 
            <button data-captions-active-state={showCaptions ? 'active' : 'inactive'} onClick={toggleShowCaptions} className='uiButton uiButton--captionsDropdown'>
                <div className='uiButton-iconContainer'>
                  {showCaptions ? <Icon name='captionsFillRegular' /> : <Icon name='captionsOutlineRegular' />}
                </div>
              </button>
          </Tooltip.Trigger>        
          <Tooltip.Content side="bottom" className="tooltip">
            {showCaptions && 
              <span>Hide Captions</span>
            }
            {!showCaptions && 
              <span>Show Captions</span>
            }

          </Tooltip.Content>                          
        </Tooltip.Root>    
        */}
      

        {/*}
        <button onClick={regenerateAllSubtitles} className='uiButton uiButton--speakerDropdown'>
          <div className='uiButton-iconContainer'>
            <Icon name='waveform' />
          </div>
          <div className='uiButton-label'>
            REGEN
          </div>     
        </button>    

      {/*} */}
       
      <SelectCaptionsPopover                
        handleClosePopover={() => setCaptionsDropdownOpen(false)}
        activeStyle={activeStyle}
        onStyleChange={handleCaptionStyleChange}
        handleGenerateSRTFile={handleGenerateSRTFile}
      >
        <button 
          data-captions-active-state={activeStyle ? 'active' : 'inactive'} 
          onClick={handleCaptionsClick} 
          className='uiButton uiButton--captionsDropdown'
        >
          <div className='uiButton-iconContainer'>
            {activeStyle ? <Icon name='captionsFillRegular' /> : <Icon name='captionsOutlineRegular' />}
          </div>
          <div className='uiButton--captionsDropdown-tooltipContainer'>
            <div className='tooltip tooltip--transcriptPanelTopBar'>
              {captionsTooltip}
            </div>
          </div>
        </button>

      </SelectCaptionsPopover>
      

{/*}
          </div>
          
          <div className='uiButton-leftTooltip'>
            Select Voice
          </div>
         

        </button>   
      </SelectVoicePopover> */}


      {/*}
      <div className='editor-topBar-smallSpacer'/>

      <button className='uiButton uiButton--iconOnly'>
        <div className='uiButton-iconContainer'>
          <Icon name='ellipses' />
        </div>
      </button>   
      */}

      
      
    </div>
  );
}

export default TranscriptPanelTopBar;
