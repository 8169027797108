import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';

const DPSlider = ({
  min,
  max,
  value,
  setValue,
  setIsDragResizingNumberInput,
  label,
  disabled,
  percentage,
  centerZero,
  whiteBalance,
  step = 0.01,
  width = 222,
}) => {  

  const [isDragging, setIsDragging] = useState(false);

  const getDecimalPlaces = (step) => {
    if (Math.floor(step) === step) return 0;
    return step.toString().split(".")[1].length || 0;
  }

  const roundToStep = (value, step) => {
    const decimalPlaces = getDecimalPlaces(step);
    const inverse = 1 / step;
    return Math.round(value * inverse) / inverse;
  }

  const handleSliderChange = (newValue) => {
    const roundedValue = roundToStep(newValue, step);
    setValue(roundedValue);
  };

  const handleDragStart = () => {
    setIsDragging(true);
    //setIsDragResizingNumberInput(true); // problem is we don't want ew-cursor
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    //setIsDragResizingNumberInput(false);
  };

  useEffect(() => {
    const handleWindowBlur = () => {
      if (isDragging) {
        setIsDragging(false);
        setIsDragResizingNumberInput(false);
      }
    };

    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [isDragging, setIsDragResizingNumberInput]);

  const decimalPlaces = getDecimalPlaces(step);

  let formattedValue = value.toFixed(decimalPlaces);
  if (percentage && centerZero) {
    if (value > 0) {
      formattedValue = `+${Math.round(value * 100)}%`;
    } else if (value < 0) {
      formattedValue = `${Math.round(value * 100)}%`;
    } else {
      formattedValue = '0%';
    }
  }

  let normalizedLeftZeroValue = (value + 1) / 2

  let mainBarStyle
  

  if(value > 0 && centerZero){
    mainBarStyle = {
      left: '50%',
      width: `${(normalizedLeftZeroValue - 0.5) * width}px`
    }    
  }
  if(value < 0 && centerZero){
    mainBarStyle = {
      right: '50%',
      width: `${(0.5 - normalizedLeftZeroValue) * width}px`
    }    
  }

  if(value === 0 && centerZero){
    mainBarStyle = {
      left: '50%',          
    } 
  }

  //
  // NOT CENTER ZERO

  if(!centerZero){
    normalizedLeftZeroValue = value / max
  }

  if(!centerZero){
    mainBarStyle = {
      left: '0%',
      width: `${normalizedLeftZeroValue * width}px`
    }    
  }


  return (
    <div data-dragging-state={isDragging ? 'dragging' : 'notDragging'} data-white-balance={whiteBalance ? 'true' : 'false'} style={{width: `${width}px`}} className="dpSlider">
      <div className="dpSlider-label dpSlider-label--title">
        {label}
      </div>      
      <div data-value-state={value < 0 ? 'negative' : 'positive'} style={{left: `${normalizedLeftZeroValue * 100}%`}} className="dpSlider-valueBar" />
          
      <div data-value-state={value < 0 ? 'negative' : 'positive'} style={mainBarStyle} className="dpSlider-mainBar" />      

      <div className="dpSlider-sliderContainer">
        <ReactSlider
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={handleSliderChange}
          onBeforeChange={handleDragStart}
          onAfterChange={handleDragEnd}
          disabled={disabled}
          className="dpSlider-slider"   
          trackClassName="dpSlider-slider-track"
          thumbClassName="dpSlider-slider-thumb"
        />
      </div>
      <div className="dpSlider-label dpSlider-label--value">
        {formattedValue}
      </div>
    </div>
  );
};

export default DPSlider;