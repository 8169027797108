import {getOrgDomain} from '../getOrgDomain'

const standardPlan={
	price:22,
	priceId:process.env.REACT_APP_STRIPE_STANDARD_PRICE_ID,
	isMidmarketPlan:false
}

const midmarketPlan={
	price:70,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_ID,
	isMidmarketPlan:true
}







const midmarketPlan2={
	price:90,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_2_ID,
	isMidmarketPlan:true
}



const midmarketPlan3={
	price:120,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_3_ID,
	isMidmarketPlan:true
}


const midmarketPlan4={
	price:60,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_ID,
	isMidmarketPlan:true
}

const midmarketPlan5={
	price:50,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_ID,
	isMidmarketPlan:true
}





const growthPlan={
	price:450,
	priceId:process.env.REACT_APP_STRIPE_GROWTH_PLAN_ID,
	isGrowthPlan:true,
	seatsCount:5
}

const growthPlan2={
	price:270,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_2_ID,
	isGrowthPlan:true,
	seatsCount:3
}


//Rootly
const growthPlan3={ //dont want this to be called growth
	price:360,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_3_ID,
	isGrowthPlan:true,
	label:'Yarn Standard',
	seatsCount:3
}


//Rootly
const growthPlan4={ //dont want this to be called growth
	price:220,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_3_ID,
	isGrowthPlan:true,
	label:'Yarn Standard',
	seatsCount:2
}


const growthPlan5={ //dont want this to be called growth
	price:150,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_3_ID,
	isGrowthPlan:true,
	label:'Yarn Standard',
	seatsCount:2
}




const growthPlan6={
	price:320,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_2_ID,
	isGrowthPlan:true,
	seatsCount:2,
	label:'Yarn Standard',
}


const unlimitedSeatsPlan={
	price:220,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_2_ID,
	isGrowthPlan:true,
	label:'Yarn Team',
}


const clayPlan={
	price:220,
	priceId:process.env.REACT_APP_STRIPE_MID_MARKET_PRICE_2_ID,
	isGrowthPlan:true,
	label:'Yarn Clay',
}





//const midmarketDomains=["leya.law","forgehq.com","driverai.com"]

//TODO check this list with jasper

const plans = {
    "codeant.ai": standardPlan,
    //"runalloy.com": standardPlan,
    "feelmuddy.com": standardPlan,
    "asklio.ai": midmarketPlan,
    "pyrls.com": standardPlan,
    // "velt.dev": standardPlan,    
    "getonboard.dev": midmarketPlan2,
    //"yarn.so":midmarketPlan2,
    "martini.ai":midmarketPlan2,
    "posthog.com":growthPlan,
    "yarn.so":growthPlan4,
    //"yarn.so":growthPlan2,
    "registerplay.com":midmarketPlan3,
    "learnontil.com'":midmarketPlan2,
    "getsavings.app'":midmarketPlan2,
    "catalistai.com'":midmarketPlan,
    "stempath.com":midmarketPlan,
    "lightdash.com":midmarketPlan3,
    "botterfly.app":midmarketPlan,
    "breadboard.com":midmarketPlan2,
    "validio.io":midmarketPlan3,
    "canvasapp.com":growthPlan2,
    "finleycms.com":growthPlan,
    "pegbo.com":midmarketPlan3,
    "unfair.poker":midmarketPlan3,
    'vapi.ai':midmarketPlan,
    'rootly.com':growthPlan3,
    "opencopilot.so":midmarketPlan2,
    "parcha.ai":growthPlan4,
    "upduo.com":growthPlan2,
    "unsupervised.com":growthPlan,
    "spectacles.dev":growthPlan5,
    "datrics.ai":growthPlan5,
    "lexolve.com":midmarketPlan3,
    "usetwine.com":growthPlan5,
    "sevn.ai":midmarketPlan3,
    "catalistai.com":midmarketPlan2,
    "wealthiee.com":midmarketPlan2,
    "upkeep.com":growthPlan,
    "alphainfoscience.com":growthPlan4,
    "stack-ai.com":growthPlan4,
    "keeper.app":growthPlan6,
    "brightcanary.io":midmarketPlan,
    // "hypar.io":growthPlan,
    "mavenagi.com":growthPlan5,
    "axle.insure":midmarketPlan,
    "celest.dev":midmarketPlan,
    "heyday.xyz":midmarketPlan,
    "cartage.ai":midmarketPlan4,
    "replo.app":midmarketPlan,
    "intryc.com":midmarketPlan5,
    "tankpayments.com":midmarketPlan,
    "driverai.com":midmarketPlan,
    "hypar.io":growthPlan5,
    "incident.io":unlimitedSeatsPlan,
    "clay.com":clayPlan
    //  "yarn.so":unlimitedSeatsPlan
  	//"yarn.so":midmarketPlan

};




export function getPricingPlan(){
	const domain = getOrgDomain()

	// console.log(domain)
	let plan = plans[domain] || midmarketPlan2
	return plan
	//  if (midmarketDomains.includes(domain)) {
    //     return midmarketPlan;
    // } else {
    //     return standardPlan;
    // }

}

 