import React, {useState, useEffect} from 'react'

const PMPanel = ({pmDoc}) => {
  return (

    <div className='editor-PMPanelContainer'>
      <div className='editor-PMPanel'>

        <pre>
        <code>
        {JSON.stringify(pmDoc, null, 2)}
        </code>
        </pre>
      </div>

    </div>
  );
}

export default PMPanel;
