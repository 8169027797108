import React, { useState, useEffect } from 'react';
import DPSelectMenu from './DPSelectMenu';
import DPNumberInput from './DPNumberInput';
import DPFontFamilyPickerPopover from './DPFontFamilyPickerPopover';
import fontFamiliesData from '../../../three/utils/fontFamilies.json'

// const fontWeights = [
//   { value: 'light', label: 'Light' },
//   { value: 'regular', label: 'Regular' },
//   { value: 'medium', label: 'Medium' },
//   { value: 'semibold', label: 'Semibold' },
//   { value: 'bold', label: 'Bold' },
//   { value: 'heavy', label: 'Heavy' },
//   { value: 'black', label: 'Black' },
// ];


const MAX_LINE_HEIGHT=1.4
const MIN_LINE_HEIGHT=0.6 
const MAX_LETTER_SPACING = 0.1 
const MIN_LETTER_SPACING = -0.1

const fontWeights = [
  { value: 'Light', label: 'Light' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Semibold', label: 'Semibold' },
  { value: 'Bold', label: 'Bold' },
  { value: 'Heavy', label: 'Heavy' },
  { value: 'Black', label: 'Black' },
];

const EditorDetailPanelCustomTextStyle = (props) => {
  const [fontFamilyPopoverOpen, setFontFamilyPopoverOpen] = useState(false)
  const {element,updateSlideElementMetadata,clipId} = props

  const textProperties = element.metadata.textProperties
  const {fontFamily,fontSize,fontWeight,letterSpacing,lineHeight} = textProperties


  const convertToPercentage = (value, min, max) => {
    return ((value - min) / (max - min)) * 200;
  }

  const convertFromPercentage = (percentage, min, max) => {
    return (percentage / 200) * (max - min) + min;
  }

 

  const handleChangeTextProperty = (key,newValue) => {
    let finalValue = newValue;
    if (key === 'lineHeight') {
      finalValue = convertFromPercentage(newValue, MIN_LINE_HEIGHT, MAX_LINE_HEIGHT);
    } else if (key === 'letterSpacing') {
      finalValue = convertFromPercentage(newValue, MIN_LETTER_SPACING, MAX_LETTER_SPACING);
    }
    let newTextProperties = {...element.metadata.textProperties}
    newTextProperties[key]=finalValue
    props.updateSlideTextElementTextProperties(clipId,element.id,'custom',newTextProperties)


    // updateSlideElementMetadata(clipId, element.id, {
    //   ...element.metadata,
    // textProperties:newTextProperties
    // });
  };


   const getPercentageValue = (key) => {
    if (key === 'lineHeight') {
      return convertToPercentage(lineHeight, MIN_LINE_HEIGHT, MAX_LINE_HEIGHT);
    } else if (key === 'letterSpacing') {
      return convertToPercentage(letterSpacing, MIN_LETTER_SPACING, MAX_LETTER_SPACING);
    }
    return textProperties[key];
  };


  return (
    <>
      <div className='editor-detailPanel-row editor-detailPanel-row--customTextControls'>        
        <DPFontFamilyPickerPopover 
          selectedFontFamily={fontFamily}
          onFontFamilyChange={(value)=>{handleChangeTextProperty('fontFamily',value)}}      
          availableFontFamilies={fontFamiliesData}
        >
          <button onClick={() => setFontFamilyPopoverOpen(!fontFamilyPopoverOpen)} className='dpFontFamilyPickerBtn'>
            {fontFamiliesData.find(font => font.value === fontFamily)?.label}
          </button>
        </DPFontFamilyPickerPopover>
      </div>

      <div className='editor-detailPanel-row editor-detailPanel-row--customTextControls'>        
        
        <DPSelectMenu
          value={fontWeight}
          onValueChange={(value)=>{handleChangeTextProperty('fontWeight',value)}}
          options={fontWeights}
          width={106}
        />        
              
        <DPNumberInput           
          percentage
          min={0}
          max={400}
          value={getPercentageValue('letterSpacing')}
          //value={letterSpacing}
          setValue={(value)=>{handleChangeTextProperty('letterSpacing',value)}}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrow'    
          width={106}
          relaxedWidth
        />       
      </div>

      <div className='editor-detailPanel-row editor-detailPanel-row--customTextControls'>        
        <DPNumberInput           
          min={10}
          max={300}
          value={Number(fontSize)}
          setValue={(value)=>{handleChangeTextProperty('fontSize',value)}}
          //setValue={setFontSize}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='aaText' 
          width={106}
          relaxedWidth
        /> 

        <DPNumberInput           
          percentage
          min={0}
          max={200}
          value={getPercentageValue('lineHeight')}
          //value={lineHeight}
          setValue={(value)=>{handleChangeTextProperty('lineHeight',value)}}
         // setValue={setLineHeight}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='doubleEndedArrowVertical'   
          width={106}    
          relaxedWidth       
        />   
      </div>


      <div className='editor-detailPanel-row--customTextControls-bottomSpacer' />     

    </>
  );
};

export default EditorDetailPanelCustomTextStyle;