import store from '../../store'
import filter from 'lodash/filter'



export function getVoicesForOrg(){
	const state = store.getState()
	const org=state.organization
	let orgId
	if(org){
		orgId=org.id
	}
	
	let orgVoices = [];
    if (orgId) {
        orgVoices = filter(orgSpecificVoices, { orgId: orgId });
    }

    // Combine the general voices with the filtered org-specific voices
    const combinedVoices = orgVoices.concat(voices);

    return combinedVoices;

}



const orgSpecificVoices=[
	// {   
	// 	id:'100',
	// 	name: "Alex Draft A", 
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "61KeU6O9fwH2MuzIpzKN",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.3,
	// 	similarityBoost: 0.88,
	// 	styleExaggeration: 0.4,		
	// },
	// {   
	// 	id:'101',
	// 	name: "Alex Draft B", 
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "61KeU6O9fwH2MuzIpzKN",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.6,
	// 	similarityBoost: 0.95,
	// 	styleExaggeration: 0.45,		
	// },
	// {   
	// 	id:'102',
	// 	name: "Alex Draft C", 
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "wN8KQlFV9xcOkqbXRxuE",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.5,
	// 	similarityBoost: 0.88,
	// 	styleExaggeration: 0.4,		
	// },
	// {   
	// 	id:'103',
	// 	name: "Alex Draft D", 
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "y58vKgESqAcQbhCr5x42",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.45,
	// 	similarityBoost: 0.88,
	// 	styleExaggeration: 0.4,		
	// },
	// {   
	// 	id:'104',
	// 	name: "Alex Draft E", 
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "BWLhghut7KpPnGDaMcsY",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.33,
	// 	similarityBoost: 0.91,
	// 	styleExaggeration: 0.46,		
	// },
	// {   
	// 	id:'105',
	// 	name: "Alex Draft F", 
	// 	orgId:'24',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "M3rJb3lns7nHnoiiFxOy",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.36,
	// 	similarityBoost: 0.91,
	// 	styleExaggeration: 0.46,
	// },
	{   
		id:'99999822',
		name: "Jasper", 
		orgId:'1',
		// orgId:'1',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Jasper PVC",
		providerId: "vsdUilEpMZgh0uUGu0ME",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'106',
		name: "Alex Draft G", 
		orgId:'24',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "M3rJb3lns7nHnoiiFxOy",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.91,
		styleExaggeration: 0.2,
	},
	{   
		id:'107',
		name: "Taimur A", 
		orgId:'16',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "PE0rCDvCLM29UjpndFfC",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.42,
		similarityBoost: 0.83,
		styleExaggeration: 0.35,
	},
	{   
		id:'108',
		name: "Taimur B", 
		orgId:'16',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "PE0rCDvCLM29UjpndFfC",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.95,
		styleExaggeration: 0.25,
	},
	{   
		id:'109',
		name: "Taimur C", 
		orgId:'16',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "pNsJtYRBqdjWGLkvUHyI",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.32,
		similarityBoost: 0.95,
		styleExaggeration: 0.3,
	},
	{   
		id:'110',
		name: "Taimur D", 
		orgId:'16',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "pNsJtYRBqdjWGLkvUHyI",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.75,
		styleExaggeration: 0,
	},
	{   
		id:'110',
		name: "Ryan A", 
		orgId:'43',
		//orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "vr42LPfo088gnh3rbRGY",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.4,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'111',
		name: "Ryan B", 
		orgId:'43',
		// orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "vr42LPfo088gnh3rbRGY",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.4,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'112',
		name: "Ryan C", 
		orgId:'43',
		// orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "vr42LPfo088gnh3rbRGY",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.4,
		modelId:"eleven_multilingual_v2",
	},

	{   
		id:'120',
		name: "Ryan D", 
		orgId:'43',
		// orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "g5EvzcVTb6W6hDoI1oeO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.8,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'121',
		name: "Ryan E", 
		orgId:'43',
		// orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "g5EvzcVTb6W6hDoI1oeO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.75,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'122',
		name: "Ryan F", 
		orgId:'43',
		// orgId:'1',
		domain:'yarn.so',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "g5EvzcVTb6W6hDoI1oeO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.9,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	// VOLTVIEW - BRITISH
	{   
		id:'410',
		name: "Victoria", 
		orgId:'51',
		// orgId:'1',
		domain:'voltview.co.uk',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Alice",
		providerId: "Xb7hH8MSUJpSbSDYk0k2",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'411',
		name: "Greg", 
		orgId:'51',
		// orgId:'1',
		domain:'voltview.co.uk',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"George",
		providerId: "JBFqnCBsd6RMkjVDRZzb",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'412',
		name: "Sandra", 
		orgId:'51',
		// orgId:'1',
		domain:'voltview.co.uk',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Lily",
		providerId: "pFZP5JQG7iQjIQuC4Bku",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'413',
		name: "Anne", 
		orgId:'51',
		// orgId:'1',
		domain:'voltview.co.uk',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Victoria",
		providerId: "lB2hoAn7jhddedhdpZcV",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	// DANIEL ZEP
	{   
		id:'510',
		name: "Daniel A", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel Raw",
		providerId: "huFqC40QNepLSlOJyvnb",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'511',
		name: "Daniel B", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel Raw",
		providerId: "huFqC40QNepLSlOJyvnb",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'512',
		name: "Daniel C", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel Raw",
		providerId: "huFqC40QNepLSlOJyvnb",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},		
	{   
		id:'513',
		name: "Daniel D", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel 50 Enhance",
		providerId: "id5igPVrcstPPLtYyFFm",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'514',
		name: "Daniel E", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel 50 Enhance",
		providerId: "id5igPVrcstPPLtYyFFm",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.35,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'515',
		name: "Daniel F", 
		orgId:'12',
		//orgId:'1',
		domain:'getzep.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Daniel 50 Enhance",
		providerId: "id5igPVrcstPPLtYyFFm",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},

	// TYLER WILLIS
	{   
		id:'610',
		name: "Tyler", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler Raw",
		providerId: "7bo9ZAzsG1aLK8cKjAUI",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.25,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'611',
		name: "Tyler Enhanced", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler 80 Enhance",
		providerId: "7wO1WhE1cZZisQfCz6Q9",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.35,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'612',
		name: "Tyler (Low Exp)", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler Raw",
		providerId: "7bo9ZAzsG1aLK8cKjAUI",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.15,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'613',
		name: "Tyler Enhanced (Low Exp)", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler 80 Enhance",
		providerId: "7wO1WhE1cZZisQfCz6Q9",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.25,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'614',
		name: "Tyler (High Exp)", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler Raw",
		providerId: "7bo9ZAzsG1aLK8cKjAUI",
		stability: 0.5,
		similarityBoost: 0.7,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},			
	{   
		id:'615',
		name: "Tyler Enhanced (High Exp)", 
		orgId:'54',
		// orgId:'1',
		domain:'unsupervised.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Tyler 80 Enhance",
		providerId: "7wO1WhE1cZZisQfCz6Q9",
		stability: 0.4,
		similarityBoost: 0.7,
		styleExaggeration: 0.5,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'700',
		name: "Cali", 
		orgId:'30',
		domain:'registerplay.com',
		isVoiceMatch:'false',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "1DsCcFEkjrRoCB7vz6Xn",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		// stability: 0.3,
		// similarityBoost: 0.88,
		// styleExaggeration: 0.4,		
	},

	// // Pete 
	// {   
	// 	id:'888',
	// 	name: "Pete", 
	// 	// orgId:'43',
	// 	orgId:'1',
	// 	domain:'yarn.so',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Valley Girl",
	// 	providerId: "sm53Yh2RLBBPb9OcVO4A",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.4,
	// 	similarityBoost: 0.95,
	// 	styleExaggeration: 0.4,
	// 	modelId:"eleven_multilingual_v2",
	// },

	// {   
	// 	id:'800',
	// 	name: "Ben 3 (L Var)", 
	// 	orgId:'1',
	// 	//domain:'registerplay.com',
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Cali",
	// 	providerId: "i2OjGwFaKiIZClkZcyuO",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.3,
	// 	similarityBoost: 0.95,
	// 	styleExaggeration: 0.45,		
	// },

	
	{   
		id:'800',
		name: "Ben A (XL Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "wGuXzTE7Uq8u7jI51lVD",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.99,
		styleExaggeration: 0.55,		
	},
	{   
		id:'801',
		name: "Ben A (L Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "wGuXzTE7Uq8u7jI51lVD",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.45,		
	},
	{   
		id:'802',
		name: "Ben A (M Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "wGuXzTE7Uq8u7jI51lVD",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.35,		
	},
	{   
		id:'803',
		name: "Ben A (S Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "wGuXzTE7Uq8u7jI51lVD",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.35,
		similarityBoost: 0.99,
		styleExaggeration: 0.25,		
	},
	{   
		id:'804',
		name: "Ben B (XL Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "i2OjGwFaKiIZClkZcyuO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.99,
		styleExaggeration: 0.55,		
	},
	{   
		id:'805',
		name: "Ben B (L Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "i2OjGwFaKiIZClkZcyuO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.45,		
	},
	{   
		id:'806',
		name: "Ben B (M Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "i2OjGwFaKiIZClkZcyuO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.35,		
	},
	{   
		id:'807',
		name: "Ben B (S Var)", 
		orgId:'76',
		//domain:'registerplay.com',
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "i2OjGwFaKiIZClkZcyuO",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.35,
		similarityBoost: 0.99,
		styleExaggeration: 0.25,		
	},

	//
	// ARTISAN

	// {   
	// 	id:'1106',
	// 	name: "Jaspar", 
	// 	orgId:'1',		
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Cali",
	// 	providerId: "POPKeBzmBnFsyT3TUUra",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.4,
	// 	similarityBoost: 0.99,
	// 	styleExaggeration: 0.4,		
	// },
	// {   
	// 	id:'1107',
	// 	name: "Sam", 
	// 	orgId:'1',		
	// 	isVoiceMatch:'true',
	// 	provider:"elevenLabs",
	// 	providerName:"Cali",
	// 	providerId: "Vnfx1dtU2yRKayeD5u8U",
	// 	//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
	// 	stability: 0.35,
	// 	similarityBoost: 0.99,
	// 	styleExaggeration: 0.4,		
	// },

	{   
		id:'1108',
		name: "Jaspar", 
		orgId:'78',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "POPKeBzmBnFsyT3TUUra",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.99,
		styleExaggeration: 0.4,		
	},
	{   
		id:'1109',
		name: "Sam", 
		orgId:'78',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "Vnfx1dtU2yRKayeD5u8U",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.35,
		similarityBoost: 0.99,
		styleExaggeration: 0.4,		
	},

	{   
		id:'1808',
		name: "Sanjay", 
		orgId:'20',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"Cali",
		providerId: "rBDM4ajvEzL4atiAkE7Z",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.8,		
		modelId:"eleven_turbo_v2_5",
	},

	{   
		id:'1809',
		name: "Elias 3", 
		orgId:'20',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"elias3",
		providerId: "qSpUh8FPc5itNDFXDjXA",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.8,
		styleExaggeration: 0.2,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'1810',
		name: "Elias 2", 
		orgId:'20',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"elias2",
		providerId: "i4ApfhYEFyHTQlf6TK5V",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.8,
		styleExaggeration: 0.2,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'1811',
		name: "Elias 4", 
		orgId:'20',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"elias4",
		providerId: "Ad66c0pfvRxvxFtZwNNE",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.8,
		styleExaggeration: 0.2,
		modelId:"eleven_multilingual_v2",
	},
	{   
		id:'1812',
		name: "Elias New", 
		orgId:'20',		
		isVoiceMatch:'true',
		provider:"elevenLabs",
		providerName:"elias3",
		providerId: "aJROMXkntiunARBtaQXM",
		//sampleAudio:"/voiceoverSamples/sarah_sample.mp3",
		stability: 0.7,
		similarityBoost: 0.98,
		styleExaggeration: 0.14,
		modelId:"eleven_multilingual_v2",		
	},





	
]


const voices=[
	{   
		id:'1',
		name: "Sarah", 
		provider:"elevenLabs",
		providerName:"Valley Girl",
		providerId: "Io0CZSZh6JLTZOA1dVGo",
		sampleAudio:"/voiceoverSamples/sarah_sample.mp3",		
		// modelId:"eleven_multilingual_v2",

		// // Sounds good with higher variation
		// stability: 0.5,
		// similarityBoost: 0.98,
		// styleExaggeration: 0.45,
	},
	// {   
	// 	id:'2',
	// 	name: "Francesca", 
	// 	provider:"elevenLabs",
	// 	providerName:"Francesca",
	// 	providerId: "nXEAyMBmKh9JCMIRR0FZ",
	// 	sampleAudio:"/voiceoverSamples/francesca_sample.mp3",
	// 	modelId:"eleven_multilingual_v2",
	// 	stability: 0.8,
	// 	similarityBoost: 0.98,
	// 	styleExaggeration: 0.45,		
	// },
	{   
		id:'3',
		name: "Emily [Hide]", 
		provider:"elevenLabs",
		providerName:"Emily",
		providerId: "QM5xMdJDztf1oT2BCgKv",
		sampleAudio:"/voiceoverSamples/emily_sample.mp3",
		stability: 0.35,
		similarityBoost: 0.80,
		styleExaggeration: 0.45,
		speakerBoost: false,	
	},
	{   
		id:'4',
		name: "Kiera [No?]", 
		provider:"elevenLabs",
		providerName:"Paola",
		providerId: "PwRc6kyhPFrSr9zeq5aS",
		sampleAudio:"/voiceoverSamples/kiera_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.95,
		styleExaggeration: 0.48,
	},	
	{   
		id:'5',
		name: "Jada", 
		provider:"elevenLabs",
		providerName:"B",
		providerId: "kQwC2rkdNm44J1JTkuc6",
		sampleAudio:"/voiceoverSamples/jada_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.99,
		styleExaggeration: 0.45,				
	},

	{   
		id:'6',
		name: "Serena", 
		provider:"elevenLabs",
		providerName:"Serena",
		providerId: "FNXrVxcT2VlMDZIURdXz",
		sampleAudio:"/voiceoverSamples/serena_sample.mp3",
		stability: 0.9,
		similarityBoost: 0.98,
		styleExaggeration: 0.2,
		speakerBoost: false,
	},	
	{   
		id:'7',
		name: "Kendra", 
		provider:"elevenLabs",
		providerName:"Kendra",
		providerId: "ZPIe5oNYKVKcme9Gycz4",
		sampleAudio:"/voiceoverSamples/kendra_sample.mp3",
		stability: 0.4,
		similarityBoost: 0.98,
		styleExaggeration: 0.48,		
	},
	
	{   
		id:'8',
		name: "Miranda", 
		provider:"elevenLabs",
		providerName:"Miranda",
		providerId: "teodtXFu8zzgfhlNI4rG",
		sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,		
	},

	// MAYBE YES

	// {   
	// 	id:'9',
	// 	name: "Alana", 
	// 	provider:"elevenLabs",
	// 	providerName:"Alana",
	// 	providerId: "uI1WgTf1WuZz0jL0kqJ3",
	// 	sampleAudio:"/voiceoverSamples/alana_sample.mp3",
	// 	stability: 0.6,
	// 	similarityBoost: 0.95,
	// 	styleExaggeration: 0.48,		
	// },
	{   
		id:'10',
		name: "Theresa", 
		provider:"elevenLabs",
		providerName:"Theresa",
		providerId: "K2kiujEl91xzjSjOHUYz",
		sampleAudio:"/voiceoverSamples/theresa_sample.mp3",
		stability: 0.3,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,		
	},
	{   
		id:'11',
		name: "Briana", 
		provider:"elevenLabs",
		providerName:"Briana",
		providerId: "ewGSJa1MZ2rMRdzJ1nmz",
		sampleAudio:"/voiceoverSamples/briana_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,		
	},

	{   
		id:'12',
		name: "Brynn", 
		provider:"elevenLabs",
		providerName:"Brynn",
		providerId: "qctQufn21LmnziYhB5Uh",
		sampleAudio:"/voiceoverSamples/brynn_sample.mp3",
		stability: 0.5,
		similarityBoost: 0.99,
		styleExaggeration: 0.45,		
	},

	{   
		id:'13',
		name: "Cassidy", 
		provider:"elevenLabs",
		providerName:"Cassidy",
		providerId: "2gjPSiA1wjRWFDHSl8KR",
		sampleAudio:"/voiceoverSamples/cassidy_sample.mp3",
		stability: 0.32,
		similarityBoost: 0.99,
		styleExaggeration: 0.48,	
	},
	{   
		id:'14',
		name: "Todd", 
		provider:"elevenLabs",
		providerName:"Todd",
		providerId: "fBUfbNHKol19L6bT5OGB",
		sampleAudio:"/voiceoverSamples/todd_sample.mp3",
		//stability: 0.6,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,
	},

	{   
		id:'1590474050',
		name: "Tom", 
		provider:"elevenLabs",
		providerName:"Chris",
		providerId: "iP95p4xoKVk53GoZ742B",
		sampleAudio:"/voiceoverSamples/tom_sample.mp3",
		isPremade:true,
		modelId:"eleven_turbo_v2",
		styleExaggeration: 0.12,
	},

	{   
		id:'825438553',
		name: "Robert", 
		provider:"elevenLabs",
		providerName:"Adam",
		providerId: "pNInz6obpgDQGcFmaJgB",
		sampleAudio:"/voiceoverSamples/robert_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v2",
		styleExaggeration: 0.12,
	},
	{   
		id:'172932586',
		name: "James", 
		provider:"elevenLabs",
		providerName:"Antoni",
		providerId: "ErXwobaYiN019PkySvjV",
		sampleAudio:"/voiceoverSamples/james_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v2",
		styleExaggeration: 0.12,
	},
	{   
		id:'1053406996',
		name: "John [Hide]", 
		provider:"elevenLabs",
		providerName:"Arnold",
		providerId: "VR6AewLTigWG4xSOukaG",
		sampleAudio:"/voiceoverSamples/john_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v2",
		styleExaggeration: 0.12,	
	},
	{   
		id:'524380851',
		name: "Sofia", 
		provider:"elevenLabs",
		providerName:"Dorothy",
		providerId: "ThT5KcBeYPX3keUQqHPh",
		sampleAudio:"/voiceoverSamples/sofia_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v1",
		country: 'uk',
	},
	{   
		id:'2053066391',
		name: "Jeff", 
		provider:"elevenLabs",
		providerName:"Liam",
		providerId: "TX3LPaxmHKxFdv7VOQHJ",
		sampleAudio:"/voiceoverSamples/jeff_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v1",		
	},
	{   
		id:'1913809790',
		name: "David", 
		provider:"elevenLabs",
		providerName:"Michael",
		providerId: "flq6f7yk4E4fJM5XTYuZ",
		sampleAudio:"/voiceoverSamples/david_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v1",		
	},

    {   
		id:'1050897329',
		name: "Charlotte", 
		provider:"elevenLabs",
		providerName:"Rachel",
		providerId: "21m00Tcm4TlvDq8ikWAM",
		sampleAudio:"/voiceoverSamples/charlotte_sample.mp3",
		isPremade:true,
		modelId:"eleven_multilingual_v2",
		styleExaggeration: 0.12,
	},
	{   
		id:'1050897321',
		name: "Tim", 
		provider:"elevenLabs",
		providerName:"Tyler",
		providerId: "raMcNf2S8wCmuaBcyI6E",
		sampleAudio:"/voiceoverSamples/tim_sample.mp3",
		isPremade:false,
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.25,				
		modelId:"eleven_multilingual_v2",
	},

	{   
		id:'2220',
		name: "Fay", 				
		provider:"elevenLabs",		
		providerId: "9OJy2ea6v4l5ZoMPNNk0",
		sampleAudio:"/voiceoverSamples/fay_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'uk',
	},
	// {   
	// 	id:'2221',
	// 	name: "Celine", 					
	// 	provider:"elevenLabs",		
	// 	providerId: "R6eR6IR1JzKTAyu3Itp6",
	// 	sampleAudio:"/voiceoverSamples/celine_sample.mp3",
	// 	// stability: 0.4,
	// 	// similarityBoost: 0.9,
	// 	// styleExaggeration: 0.45,
	// 	modelId:"eleven_multilingual_v2",
	// 	country: 'fr',
	// },
	{   
		id:'2222',
		name: "Coco", 				
		provider:"elevenLabs",		
		providerId: "MNiuKciqE420DCRJtdeb",
		sampleAudio:"/voiceoverSamples/coco_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'fr',
		lang: 'fr',
	},	
	{   
		id:'2223',
		name: "Ana", 				
		provider:"elevenLabs",		
		providerId: "eWzPDynr2HAv14m40xnj",
		sampleAudio:"/voiceoverSamples/ana_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'de',
		lang: 'de',
	},
	{   
		id:'2224',
		name: "Laura", 		
		provider:"elevenLabs",		
		providerId: "gC9jy9VUxaXAswovchvQ",
		sampleAudio:"/voiceoverSamples/laura_sample.mp3",
		// stability: 0.8,
		// similarityBoost: 0.5,
		// styleExaggeration: 0.2,
		modelId:"eleven_multilingual_v2",
		country: 'nl',
		lang: 'nl',
	},
	{   
		id:'2225',
		name: "Haruto", 		
		provider:"elevenLabs",	
		provideName:"Hinata",		
		providerId: "j210dv0vWm7fCknyQpbA",
		sampleAudio:"/voiceoverSamples/haruto_sample.mp3",
		// stability: 0.8,
		// similarityBoost: 0.5,
		// styleExaggeration: 0.2,
		modelId:"eleven_multilingual_v2",
		country: 'jp',
		lang: 'jp',
	},

	// NEW TESTS
	// {   
	// 	id:'2226',
	// 	name: "Archie [No?]", 
	// 	provider:"elevenLabs",
	// 	providerName:"Archie",
	// 	providerId: "kmSVBPu7loj4ayNinwWM",
	// 	sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
	// 	modelId:"eleven_multilingual_v2",
	// 	stability: 0.4,
	// 	similarityBoost: 0.98,		
	// },
	{   
		id:'2227',
		name: "Femi", 
		provider:"elevenLabs",
		providerName:"Moyo",
		providerId: "z6yYK34YTAxlFskPdVbA",
		sampleAudio:"/voiceoverSamples/femi_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.6,
		similarityBoost: 0.55,	
		country: 'ng',	
	},
	{   
		id:'2228',
		name: "Annie", 
		provider:"elevenLabs",
		providerName:"Hope - natural conversations",
		providerId: "OYTbf65OHHFELVut7v2H",
		sampleAudio:"/voiceoverSamples/annie_sample.mp3",
		modelId:"eleven_turbo_v2_5",
		stability: 0.86,
		similarityBoost: 0.75,		
	},
	// {   
	// 	id:'2230',
	// 	name: "Soothing Narrator [No?]", 
	// 	provider:"elevenLabs",
	// 	providerName:"Soothing Narrator",
	// 	providerId: "pVnrL6sighQX7hVz89cp",
	// 	sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
	// 	modelId:"eleven_turbo_v2",
	// 	stability: 0.4,
	// 	similarityBoost: 0.95	
	// },
	// {   
	// 	id:'2231',
	// 	name: "Young Jamal [No?]", 
	// 	provider:"elevenLabs",
	// 	providerName:"Young Jamal",
	// 	providerId: "6OzrBCQf8cjERkYgzSg8",
	// 	sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
	// 	modelId:"eleven_multilingual_v2",
	// 	stability: 0.37,
	// 	similarityBoost: 0.67
	// },
	{   
		id:'2232',
		name: "Jacob", 
		provider:"elevenLabs",
		providerName:"Ryan Kurk",
		providerId: "rU18Fk3uSDhmg5Xh41o4",
		sampleAudio:"/voiceoverSamples/jacob_sample.mp3",
		modelId:"eleven_multilingual_v1",
		stability: 0.40,
		similarityBoost: 0.75
	},
	// {   
	// 	id:'2233',
	// 	name: "AMHN [No?]", 
	// 	provider:"elevenLabs",
	// 	providerName:"American Male Health Narrator",
	// 	providerId: "UDlQ7nW43xsk7zJaku8a",
	// 	sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
	// 	modelId:"eleven_turbo_v2",
	// 	stability: 0.59,
	// 	similarityBoost: 0.70
	// },
	// {   
	// 	id:'2234',
	// 	name: "Daniel", 
	// 	provider:"elevenLabs",
	// 	providerName:"Justin Time",
	// 	providerId: "uFIXVu9mmnDZ7dTKCBTX",
	// 	sampleAudio:"/voiceoverSamples/daniel_sample.mp3",
	// 	modelId:"eleven_multilingual_v2",
	// 	stability: 0.5,
	// 	similarityBoost: 0.75
	// },
	// {   
	// 	id:'2235',
	// 	name: "Nick Colter [No?]", 
	// 	provider:"elevenLabs",
	// 	providerName:"Nick Colter",
	// 	providerId: "pUT9rBL4dkCeuqxqLWVZ",
	// 	sampleAudio:"/voiceoverSamples/miranda_sample.mp3",
	// 	modelId:"eleven_multilingual_v1",
	// 	stability: 0.3,
	// 	similarityBoost: 0.89
	// },

	//
	// MORE MULTI
	{   
		id:'2236',
		name: "Luca", 				
		provider:"elevenLabs",
		providerName:"Giovanni",
		providerId: "zcAOhNBS3c14rBihAFp1",
		sampleAudio:"/voiceoverSamples/luca_sample.mp3",
		modelId:"eleven_multilingual_v1",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,
		country: 'it',
		lang: 'it',		
	},
	{   
		id:'2237',
		name: "Lorenzo", 			
		provider:"elevenLabs",
		providerName:"Dante",
		providerId: "F7eI6slaNFiCSAjYVX5H",
		sampleAudio:"/voiceoverSamples/lorenzo_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'it',
		lang: 'it',			
	},
	// {   
	// 	id:'2238',
	// 	name: "Matteo", 			
	// 	provider:"elevenLabs",
	// 	providerName:"Franesco",
	// 	providerId: "GOAZNavLupajyL3YafaD",
	// 	sampleAudio:"/voiceoverSamples/matteo_sample.mp3",
	// 	modelId:"eleven_multilingual_v2",
	// 	stability: 0.5,
	// 	similarityBoost: 0.98,
	// 	styleExaggeration: 0.45,	
	// 	country: 'it',
	// 	lang: 'it',			
	// },
	{   
		id:'2239',
		name: "Chiara",
		provider:"elevenLabs",	
		// same as coco french above	
		providerId: "MNiuKciqE420DCRJtdeb",
		sampleAudio:"/voiceoverSamples/chiara_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'it',
		lang: 'it'
	},	
	{   
		id:'2240',
		name: "Erik", 		
		provider:"elevenLabs",
		providerName:"Adam",
		providerId: "x0u3EW21dbrORJzOq1m9",
		sampleAudio:"/voiceoverSamples/erik_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'se',
		lang: 'se'	
	},
	{   
		id:'2241',
		name: "Lars", 					
		provider:"elevenLabs",
		providerName:"Jonas",
		providerId: "e6OiUVixGLmvtdn2GJYE",
		sampleAudio:"/voiceoverSamples/lars_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'se',
		lang: 'se'		
	},
	{   
		id:'2242',
		name: "Annika", 				
		provider:"elevenLabs",	
		// anna from germany	
		providerId: "eWzPDynr2HAv14m40xnj",
		sampleAudio:"/voiceoverSamples/annika_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'se',
		lang: 'se',
	},
	{   
		id:'2243',
		name: "Mikkel", 		
		provider:"elevenLabs",
		providerName:"Adam",
		providerId: "x0u3EW21dbrORJzOq1m9",
		sampleAudio:"/voiceoverSamples/mikkel_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'dn',
		lang: 'dn'	
	},	
	{   
		id:'2245',
		name: "Mette", 				
		provider:"elevenLabs",	
		// anna from germany	
		providerId: "eWzPDynr2HAv14m40xnj",
		sampleAudio:"/voiceoverSamples/mette_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v2",
		country: 'dn',
		lang: 'dn',
	},	
	{   
		id:'2247',
		name: "Alejandro", 		
		provider:"elevenLabs",
		providerName:"David Martin",
		providerId: "Nh2zY9kknu6z4pZy6FhD",
		sampleAudio:"/voiceoverSamples/alejandro_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'es',
		lang: 'es',		
	},
	{   
		id:'2248',
		name: "Sergio", 		
		provider:"elevenLabs",
		providerName:"David Martin 2",
		providerId: "y6WtESLj18d0diFRruBs",
		sampleAudio:"/voiceoverSamples/sergio_sample.mp3",
		modelId:"eleven_multilingual_v2",
		stability: 0.5,
		similarityBoost: 0.98,
		styleExaggeration: 0.45,	
		country: 'es',
		lang: 'es',		
	},
	{   
		id:'2249',
		name: "Maria", 				
		provider:"elevenLabs",
		// sarah
		providerId: "Io0CZSZh6JLTZOA1dVGo",
		sampleAudio:"/voiceoverSamples/maria_sample.mp3",
		modelId:"eleven_multilingual_v1",		
		country: 'es',
		lang: 'es'
	},
	{   
		id:'2250',
		name: "Raquel",
		provider:"elevenLabs",	
		// same as sofia
		providerId: "ThT5KcBeYPX3keUQqHPh",
		sampleAudio:"/voiceoverSamples/raquel_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.45,
		modelId:"eleven_multilingual_v1",
		country: 'es',
		lang: 'es',		
	},	

	{   
		id:'2251',
		name: "Antoine",
		provider:"elevenLabs",	
		// antoine
		providerId: "1a3lMdKLUcfcMtvN772u",
		sampleAudio:"/voiceoverSamples/antoine_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.25,
		modelId:"eleven_multilingual_v1",
		country: 'fr',
		lang: 'fr',		
	},	
	{   
		id:'2252',
		name: "Theo",
		provider:"elevenLabs",	
		// tenko
		providerId: "0bKGtCCpdKSI5NjGhU3z",
		sampleAudio:"/voiceoverSamples/theo_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.25,
		modelId:"eleven_multilingual_v1",
		country: 'fr',
		lang: 'fr',
	},	
	{   
		id:'2253',
		name: "Jeanne",
		provider:"elevenLabs",	
		// jeanne
		providerId: "txtf1EDouKke753vN8SL",
		sampleAudio:"/voiceoverSamples/jeanne_sample.mp3",
		// stability: 0.4,
		// similarityBoost: 0.9,
		// styleExaggeration: 0.25,
		modelId:"eleven_multilingual_v1",
		country: 'fr',
		lang: 'fr',		
	},	



	

]

// export default voices