export const adjustClipsDraggingLeft=(sortedClips, draggedClipIndex, newStartTime, updatesArray,moveZoomsWithVideoClip,isAudioTrack,audioClipSpacing,shiftAmount)=> {
		
	let buffer = 0 //gap between clips
	if(isAudioTrack){
		buffer = audioClipSpacing
	}

	let stopAdjusting = false;
	let previousClipEndTime = newStartTime - buffer;

	for (let i = draggedClipIndex - 1; i >= 0 && !stopAdjusting; i--) {
		let currentClip = sortedClips[i];
		const initialStartTime = currentClip.relativeStartTime
		let currentClipEndTime = currentClip.startTime + currentClip.duration;
		if (currentClipEndTime > previousClipEndTime) {
		let newStart = previousClipEndTime - currentClip.duration;
		if (newStart < 0) {
			stopAdjusting = true;
			newStart = 0;
		}
		previousClipEndTime = newStart - buffer;
		currentClip.startTime = newStart;
		currentClip.pinnedStartTime = null //for audio clips
		updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime,pinnedStartTime:null });

		if(currentClip.type=='video') {
			const finalStartTime = currentClip.relativeStartTime
			const shiftAmount = finalStartTime -initialStartTime 
			updatesArray.push(moveZoomsWithVideoClip(currentClip.id,shiftAmount))
		}
		}
	}

	//if its an audio clip then we pull clips to the right with it
	if(isAudioTrack){
		let stopAdjusting=false 
		for (let i = draggedClipIndex +1; i < sortedClips.length && !stopAdjusting; i++) {
		let currentClip = sortedClips[i];
		if(currentClip.pinnedStartTime){
			stopAdjusting = true
		}else{
			const newStart = currentClip.startTime + shiftAmount
			currentClip.startTime = newStart;
			updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime })
		}
		}
	}

	return updatesArray;
}




// export const adjustClipsDraggingLeft=(sortedClips, draggedClipIndex, newStartTime, updatesArray,moveZoomsWithVideoClip)=> {
// 		let stopAdjusting = false;
// 		let previousClipEndTime = newStartTime;
// 		for (let i = draggedClipIndex - 1; i >= 0 && !stopAdjusting; i--) {
// 			let currentClip = sortedClips[i];
// 			const initialStartTime = currentClip.relativeStartTime
// 			let currentClipEndTime = currentClip.startTime + currentClip.duration;
// 			if (currentClipEndTime > previousClipEndTime) {
// 				let newStart = previousClipEndTime - currentClip.duration;
// 				if (newStart < 0) {
// 					stopAdjusting = true;
// 					newStart = 0;
// 				}
// 				previousClipEndTime = newStart;
// 				currentClip.startTime = newStart;
// 				updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
				
// 				if(currentClip.type=='video') {
// 					const finalStartTime = currentClip.relativeStartTime
// 					const shiftAmount = finalStartTime -initialStartTime 
// 					updatesArray.push(moveZoomsWithVideoClip(currentClip.id,shiftAmount))
// 				}
// 			}
// 		}
// 		return updatesArray;
// 	}

