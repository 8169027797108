import React, { useState, useEffect,useRef } from 'react';
import Icon from '../misc/Icon';
import {cancelRecording} from '../../utils/recordingUtils/cancelRecording'
import {startRecording} from '../../utils/recordingUtils/startRecording'
import {stopRecording} from '../../utils/recordingUtils/stopRecording'
import {resizeRecordingWindow} from '../../utils/recordingUtils/resizeRecordingWindow'
import { useSpring, animated } from 'react-spring';


const RecordingControls = () => {
    const [recordingActive, setRecordingActive] = useState(false);
    const [windowHasBeenResized, setWindowHasBeenResized] = useState(false);
  
    const [isPreparingToRecord, setIsPreparingToRecord] = useState(false);
    const [prepareSuccess, setPrepareSuccess] = useState(false);
    const [failedToPrepare, setFailedToPrepare] = useState(false);

    const [countdownActive, setCountdownActive] = useState(false);


    const countdownRef = useRef(false);
    const prepareSuccessRef = useRef(false)


    const COUNTDOWN_DURATION = 800; // 2 seconds in milliseconds
  
    const recordStartSound = new Audio('/recordStart.mp3'); // Adjust the path as needed
    recordStartSound.volume = 0.4; // Set volume to 50%
    const recordEndSound = new Audio('/recordEnd.mp3'); // Adjust the path as needed
    recordEndSound.volume = 0.4; // Set volume to 50%

    const [elapsedTime, setElapsedTime] = useState(0);

    const prepareToRecord = async () => {
      console.log('prepare to record here')
      setIsPreparingToRecord(true);
      setFailedToPrepare(false);
      try {
        const success = await window.ipcRenderer.invoke('prepare-to-record-window');
        console.log(success)
        if (success) {
          console.log('SUCESSSSSSSS')
          prepareSuccessRef.current = true
          // setPrepareSuccess(true);
          console.log(`countdownActive ${countdownRef.current}`)
          if (!countdownRef.current) {
            actuallyStartRecording();
          }
        } else {
          setFailedToPrepare(true);
          cancelCountdown();
        }
      } catch (error) {
        console.log('error')
        console.log(error)
        setFailedToPrepare(true);
        cancelCountdown();
     }
    };

    const checkPreparationAndStartRecording = () => {
      console.log('checkPreparationAndStartRecordingcheckPreparationAndStartRecording')
      console.log(prepareSuccessRef.current)
      console.log(isPreparingToRecord)
      if (prepareSuccessRef.current) {
        setIsPreparingToRecord(false);
        actuallyStartRecording();
      } else if (isPreparingToRecord) {
        console.log('still preparing to record');
      }
    };


    const actuallyStartRecording = () => {
      console.log('ACTUALLY START RECORDING')
      recordStartSound.play();
      startRecording();
      setRecordingActive(true);
    };

    const startCountdown = () => {
      console.log('start countdown')
      prepareToRecord();
      countdownRef.current = true
      setCountdownActive(true);   
      setTimeout(() => {
        checkPreparationAndStartRecording()
        countdownRef.current = false
        setCountdownActive(false);   
      }, COUNTDOWN_DURATION);
    };

    const cancelRecordingAndReset = () => {
      cancelRecording();
      setRecordingActive(false);
      countdownRef.current = false
      setElapsedTime(0);
      setWindowHasBeenResized(false);
      setIsPreparingToRecord(false);
      //setPrepareSuccess(false);
      prepareSuccessRef.current = false
      setFailedToPrepare(false);
    };


    const handleStopRecording = () => {
      if(elapsedTime < 3){
        console.log('OOOOO WAS LESS THAN 3 Seconds!!!')
        cancelRecordingAndReset()
      }
      else{
        stopRecording()
        recordEndSound.play(); // Play the sound when recording starts
        setRecordingActive(false);
        setCountdownActive(false);      
        setWindowHasBeenResized(false)  
      }    
    };


    const cancelCountdown = () => {
      setRecordingActive(false);
      setCountdownActive(false);
      setWindowHasBeenResized(false);
      setIsPreparingToRecord(false);
      setPrepareSuccess(false);
      prepareSuccessRef.current = false
      countdownRef.current = false
    };

    const resizeWindow = () => {
      resizeRecordingWindow()
      setWindowHasBeenResized(true)
    };


     useEffect(() => {
        let interval;
        if (recordingActive) {
            interval = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
            setElapsedTime(0);
        };
    }, [recordingActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };
    
    
    return (
        <div className={'recordingWindow ' + (countdownActive ? ' recordingWindow--countdownActive ' : '') + (recordingActive ? ' recordingWindow--recordingActive ' : '')}>
          
          <button className='recordingWindow-smallBtn recordingWindow-smallBtn--cancel' onClick={cancelRecordingAndReset}>
            <div className='recordingWindow-smallBtn-label'>
              Cancel
            </div>
          </button>
                    

          {!countdownActive && !recordingActive && 
          <button className='recordingWindow-primaryBtn recordingWindow-primaryBtn--record' onClick={startCountdown} >
            Record
          </button>
          }

          {countdownActive && !recordingActive && 
            <button onClick={cancelCountdown} className='recordingWindow-primaryBtn recordingWindow-primaryBtn--countdownActive' >
              <div className='recordingWindow-primaryBtn--countdownActive-progressBar'>
                <div className='recordingWindow-primaryBtn--countdownActive-progressBar-bar' />
                <div className='recordingWindow-primaryBtn--countdownActive-progressBar-track' />
              </div>
            </button>
          }
          

          {recordingActive && !countdownActive &&
            <button onClick={handleStopRecording} className='recordingWindow-primaryBtn recordingWindow-primaryBtn--stop' >
              <div className='recordingWindow-primaryBtn--stop-square' />
            </button>
          }


          {!recordingActive &&  
            <button disabled={windowHasBeenResized} className='recordingWindow-smallBtn recordingWindow-smallBtn--resize' onClick={resizeWindow} >
              <div className='recordingWindow-smallBtn-iconContainer'>
                <Icon name='window' />
              </div>
              <div className='recordingWindow-smallBtn-label'>
                Resize
              </div>
            </button>
          }

            {recordingActive && 
              <button className='recordingWindow-smallBtn recordingWindow-smallBtn--counter'>
                <div className='recordingWindow-smallBtn-label'>
                  {formatTime(elapsedTime)}
                </div>
              </button>
            }


        </div>
    );
};

export default RecordingControls;
