const SceneHeaderNodeSpec ={
    group: "block",
    attrs: {
        sceneId:{default:'defaultID'},
        // sceneDuration:{default:0},
        sceneCount:{default:1}
    },
    content: "paragraph",
    selectable:false,
  //  defining:true,
    isolating:true, //take this out so can backspace to delete header
  	toDOM(node) {return ['sceneHeader',0]},
    parseDOM: [{tag: 'sceneHeader'}],


}
    

 export default SceneHeaderNodeSpec
 
