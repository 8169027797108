import React, {Component} from 'react';
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {history,undo,redo} from "prosemirror-history"
import {keymap} from "prosemirror-keymap"
import {baseKeymap} from "prosemirror-commands"
import textSlideSchema from '../../../prosemirror/textSlide/schema/textSlideSchema'
import { fontWeightToNumber } from '../../utils/fontWeightToNumber';
import fontFamiliesData from '../../utils/fontFamilies.json';
import variableHighlightPlugin from '../../../prosemirror/textSlide/plugins/variableHighlightPlugin'
import {marksInSelectionPlugin} from '../../../prosemirror/textSlide/plugins/marksInSelectionPlugin'
import {selectionStylingPlugin} from '../../../prosemirror/textSlide/plugins/selectionStylingPlugin'
import {getTextColorForId} from '../../../utils/brands/getTextColorForId'
import {getTextColorForBackgroundId} from '../../../utils/brands/getTextColorForBackgroundId'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import SlideEditorTextInputHiddenFontPreloader from './SlideEditorTextInputHiddenFontPreloader'

const maxWidth=1000

const DEFAULT_JSON=`{"type":"doc","content":[{"type":"paragraph","attrs":{"indentLevel":0},"content":[{"type":"text","text":"Headline"}]}]}`
const DEFAULT_EMPTY_JSON=`{"type":"doc","content":[{"type":"paragraph","attrs":{"indentLevel":0},"content":[{"type":"text","text":"Text"}]}]}`



const TEST=`{"type":"doc","content":[{"type":"paragraph","attrs":{"indentLevel":0},"content":[]}`


const areLettersArraysEqual = (arr1, arr2, epsilon = 0.001) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    const l1 = arr1[i];
    const l2 = arr2[i];
    if (l1.letter !== l2.letter ||
        l1.colorId !== l2.colorId ||
        l1.isVariableColor !== l2.isVariableColor ||
        l1.opacity !== l2.opacity ||
        l1.paragraphIndex !== l2.paragraphIndex ||
        l1.wordIndex !== l2.wordIndex ||
        Math.abs(l1.rect.normalLeft - l2.rect.normalLeft) > epsilon ||
        Math.abs(l1.rect.normalTop - l2.rect.normalTop) > epsilon) {
      // console.log('Difference found at index', i, ':', l1, l2);
      return false;
    }
  }
  return true;
};


class SlideEditorTextInput extends React.Component {
  
  constructor(props) {
    super(props);
    this.previousLettersArray = [];
    if(props.element.metadata && props.element.metadata.lettersArray){
      this.previousLettersArray = props.element.metadata.lettersArray
    }
  }

  componentDidMount() {  
    this.loadView()    
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      return false;
    }    
    return true;
  }


  preloadFontWeights = async () => {
    const { fontFamily } = this.props.element.metadata.textProperties;
    await preloadFontWeights(fontFamily);
  };


  hasRelevantPropsChanged(prevProps){
    if(
      this.props.isFontLoaded != prevProps.isFontLoaded ||
      this.props.elementWidth != prevProps.elementWidth ||
      this.props.textStyle !=prevProps.textStyle ||
      this.props.text !=prevProps.text ||
      this.props.textHAlign !=prevProps.textHAlign ||
      this.props.textPropertiesString !=prevProps.textPropertiesString ||
      this.props.textResizeType !=prevProps.textResizeType ||
      this.props.textVAlign !=prevProps.textVAlign ||
      this.props.canvasX !=prevProps.canvasX||
      this.props.canvasY !=prevProps.canvasY||
      this.props.canvasHeight !=prevProps.canvasHeight||
      this.props.canvasWidth !=prevProps.canvasWidth||
      this.props.hAlign !=prevProps.hAlign||
      this.props.vAlign !=prevProps.vAlign||
      this.props.invertScalar !=prevProps.invertScalar)
    {
      return true
    }else{
      return false
    }
  }

  componentDidUpdate(prevProps) {
    const shouldRecalculateLettersArray=this.hasRelevantPropsChanged(prevProps,this.props)
    if (shouldRecalculateLettersArray) {
      setTimeout(() => {
        this.calcLettersArray()
      }, 30);    
    }
    if(prevProps.initialValue !== this.props.initialValue){
      this.loadView()
    }

  }

  componentWillUnmount(){
    if(this.view){
      this.view.destroy()
      this.view=null
      if(!this.props.isGhostCalc){
        window.textEditView=null
      }
    }
  }

  handleEditorBlur = () => {
    //if the text is empty then set it to say "Text" on blur
   const isEmpty = this.view.state.doc.textContent.trim() === '';
   if (isEmpty) {
      // Create a new transaction to replace the empty content with "Text"
      const tr = this.view.state.tr.replaceWith(
        0,
        this.view.state.doc.content.size,
        textSlideSchema.text('Text')
      );
      this.view.dispatch(tr);
    }

    // Introduce a small delay before setting the focused element to null
    setTimeout(() => {
      if(this.props.focusedSlideElement == this.props.elementId){
        this.props.setFocusedSlideElement(null)
      }
    }, 100);  // 100ms delay, adjust if needed
  }


  handleEditorFocus = () => {
    this.props.setFocusedSlideElement(this.props.elementId)
    if (this.view) {
      const currentText = this.view.state.doc.textContent.trim();
      if (currentText === "Text") {
        const tr = this.view.state.tr.delete(0, this.view.state.doc.content.size);
        this.view.dispatch(tr);
      }
    }
  }

  loadView(defaultJson){
    if(this.view){
      this.view.destroy()
    }
    const {elementId} = this.props
    let divId='slide_pm_input'
    if(elementId){
      divId=`slide_pm_input_${elementId}`
    }
    const handleEditorBlur = this.handleEditorBlur
    const handleEditorFocus = this.handleEditorFocus
    const updateSlideElementTextSelectionInfo = this.props.updateSlideElementTextSelectionInfo

    this.view = new EditorView(document.querySelector(`#${divId}`), {
      attributes: {               
        spellCheck: false,
      },
      state: EditorState.create({
      doc: this.makePMDoc(defaultJson),
      plugins:[
        keymap(baseKeymap),
        variableHighlightPlugin,
        marksInSelectionPlugin(updateSlideElementTextSelectionInfo),
        selectionStylingPlugin()
      ],  
    }),
     handleDOMEvents: { //we disable all of these events
      blur(view, event) { handleEditorBlur()},
      focus(view, event) { handleEditorFocus()},
    },
    handleKeyDown:this.handleKeyDown,
    dispatchTransaction: transaction => { //for resizing inputbox -- maybe throttle?
      if (!this.view || !this.view.state) {
        //console.warn('View or view state is null. Skipping transaction.');
        return;
      }  
      const { state, transactions } = this.view.state.applyTransaction(transaction)
      this.view.updateState(state)
      if(transaction.docChanged){
        this.calcLettersArray();
      }
    },
    })
    
    if(!this.props.isGhostCalc){
      window[`textEditView_${elementId}`]=this.view      
    }

    if(this.props.isGhostCalc){      
      setTimeout(() => {
        this.calcLettersArray();
      }, 500);
    } else {
       setTimeout(() => {
        this.calcLettersArray()
      }, 50);    
    }
  }

  makePMDoc=(json)=>{
    const jsonDoc = json || this.props.initialValue
    if (jsonDoc) {
      return textSlideSchema.nodeFromJSON(JSON.parse(jsonDoc));
    }
    const para = textSlideSchema.nodes.paragraph.createAndFill(null, [textSlideSchema.text('Headline')]);
    return textSlideSchema.nodes.doc.createAndFill(null, [para]);
  }


  calcLettersArray=()=> {
    const {isExport,variableTextColorId} = this.props //for putting variable colors in export
    let wordsArray = [];
    let lettersArray=[]
    let wordBounds = [];
    if(this.view){
      const { doc } = this.view.state;
      doc.descendants((node, pos) => {
        if (node.isText) {
        const text = node.text;
        let match;
        const regex = /[^\s]+/g;
        while ((match = regex.exec(text)) !== null) {
          const start = pos + match.index;
          const end = start + match[0].length;
          wordBounds.push({start, end, index: wordsArray.length - 1});
        }
      }        
    });

   let paragraphIndex=-1
    
    doc.descendants((node, pos) => {
      if (node.type.name === "paragraph") {
        paragraphIndex++; // Increment the paragraph index
      } 
      if (node.isText) {
        const text = node.text;
        let match;
        const regex = /\S/g;
        while ((match = regex.exec(text)) !== null) {
        const charPos = pos + match.index;
        let wordIndex = wordBounds.findIndex(w => charPos >= w.start && charPos < w.end);
        const start = pos + match.index;
        const end = start + match[0].length;
        const startCoords = this.view.coordsAtPos(start);
        const endCoords = this.view.coordsAtPos(end);
        const horizontalCenter = 1920 / 2;
        const verticalCenter = 1080 / 2;
        const marks=node.marks
        let colorId = null;
        let rgba = null;
        let isVariableColor=false
        let opacity = null; // Default opacity
        const textColorMark = marks.find(mark => mark.type.name === 'textColor');
        const textOpacityMark = marks.find(mark => mark.type.name === 'textOpacity');
        if (textOpacityMark) {
          opacity = textOpacityMark.attrs.opacity;
        }
        if (textColorMark) {
          colorId = textColorMark.attrs.colorId;
          isVariableColor = textColorMark.attrs.isVariable
        }

        const {element, invertScalar} = this.props 

        const currentFontFamily = this.props.element.metadata.textProperties.fontFamily;
        const fontFamilyData = fontFamiliesData.find(font => font.value === currentFontFamily);
        const vScalar = fontFamilyData ? fontFamilyData.vScalar : 0;
        const vScalarOffset = (vScalar / 100) * this.props.element.metadata.textProperties.fontSize

        // console.log('fontFamilyData', fontFamilyData)
        // console.log('vScalar', vScalar)
        // console.log('vScalarOffset', vScalarOffset)

        let manualHOffset = 0; // Same offset handling as before
        
        
        const deltaX = 1.4 * invertScalar 
        // letters array adjusts very slightly on larger/smaller scale items. Not sure why. 
        // real solution would be to use some sort of off-screen measurer at size independent to screen size        

        const letterX = (startCoords.left - deltaX - this.props.canvasX) * invertScalar                
        const letterY = (startCoords.top - this.props.canvasY) * invertScalar + vScalarOffset
        const relativeToElementX = letterX - element.x
        const relativeToElementY = letterY - element.y
        // Calculate position relative to the element's top-left corner
        const relativeLetterX = element.x - (startCoords.left * invertScalar);
        const relativeLetterY = (startCoords.top - element.y) * invertScalar;
        // Transform to center-center coordinates within the parent
        const rect = {
          normalLeft: (relativeToElementX - element.width / 2) ,
          normalTop: (element.height / 2 - relativeToElementY) , // Invert Y-axis
        };
        if(isVariableColor && isExport && variableTextColorId){
          colorId = variableTextColorId
        }

        let isInView = true // used for variable text no wrap

        let variableTextNoWrap
        if(element.metadata.variableTextOptions && element.metadata.variableTextOptions.noWrap){
          variableTextNoWrap = true
        }
        
        const letterEndX = (endCoords.left - deltaX - this.props.canvasX) * invertScalar                
        const relativeToElementEndX = letterEndX - element.x

        if(variableTextNoWrap && relativeToElementEndX > element.width){
          isInView = false          
        }

        if(isInView){
          lettersArray.push({ 
            letter: match[0], 
            colorId:colorId,
            isVariableColor:isVariableColor,
            opacity: opacity,
            rect: rect,
            paragraphIndex: paragraphIndex,
            wordIndex: wordIndex >= 0 ? wordIndex : null 
          });
        }

        
    }
  }
});
  let text = '';
  doc.forEach((node, _, index) => {
  if (node.type.name === 'paragraph') {
    // Add a line break before the paragraph text, unless it's the first paragraph
    if (index > 0) {
      text += '\n';
    }
    text += node.textContent;
  }
});

  const docJson= JSON.stringify(doc.toJSON()); 

  if (!areLettersArraysEqual(this.previousLettersArray, lettersArray)) {
    for (let i = 0; i < Math.max(this.previousLettersArray.length, lettersArray.length); i++) {
      if (!isEqual(this.previousLettersArray[i], lettersArray[i])) {
        break;
      }
    }
    if (this.props.handleSetLettersArray) {
      this.props.handleSetLettersArray(lettersArray, text, docJson, this.props.clipId, this.props.elementId);
    }
    this.previousLettersArray = cloneDeep(lettersArray);
  } else {
   // console.log('Letters array is unchanged');
  }
    // if(this.props.handleSetLettersArray){
    //    this.props.handleSetLettersArray(lettersArray,text,docJson,this.props.clipId,this.props.elementId)
    // }
  }
  }


  render() {
    const {elementId, element,slide,slideBG, hAlign, isDraggingElement} = this.props
    const textStyle = element.metadata.textStyle 
    let style={}

    let variableTextNoWrap
    if(element.metadata.variableTextOptions && element.metadata.variableTextOptions.noWrap){
      variableTextNoWrap = true
    }    

    if(textStyle){
      const values = element.metadata.textProperties
      style = {     
        fontFamily: `${values.fontFamily}`,
        fontSize: `${values.fontSize}px`,
        textAlign: `${values.textAlignStyle}`,
        fontWeight: `${fontWeightToNumber(values.fontWeight)}`,       
        letterSpacing: `${values.letterSpacing}em`,
        lineHeight: `${values.lineHeight}`,
        textAlign: `${hAlign}`,        
      }    
    }

    let divId='slide_pm_input'
    if(elementId){
      divId=`slide_pm_input_${elementId}`
    }

    return (
      <>
        <div data-variable-text-no-wrap={variableTextNoWrap} data-resize-type={element.metadata.textResizeType} data-h-align={hAlign} data-hide-selections={isDraggingElement} style={{...style}} className='editor-slideEditor-textArea'>
          <div className='editor-slideEditor-PMContainer ' id={divId} />          
        </div>        
        {/* <SlideEditorTextInputHiddenFontPreloader fontFamily={element.metadata.textProperties.fontFamily} /> */}
      </>
    );
  }
}


export default SlideEditorTextInput
