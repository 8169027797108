import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import Icon from '../../misc/Icon'
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'
import {getCurrentUserName} from '../../../utils/getCurrentUserName'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import LibrarySideMenuTab from './LibrarySideMenuTab'
import LibrarySideMenuCollectionTab from './LibrarySideMenuCollectionTab'
import {randomID} from '../../../utils/randomID'
import {createCollection} from '../../../actions/collections'
import { useDispatch } from 'react-redux';
import {createNewProject} from '../../../utils/projectUtils/createNewProject'
import {createToastNotification} from '../../../utils/createToastNotification'
import * as Tooltip from '@radix-ui/react-tooltip';
import UpdatesPopover from '../../changelog/UpdatesPopover'
import sortBy from 'lodash/sortBy'


function LibrarySideMenu(props) {
	const history = useHistory();
	const dispatch = useDispatch()

	const userName = getCurrentUserName()
	let orgNameLabel = getOrgNameLabel()

	const showToastNotification = () => {
		
		//// Delete Project
		// const toastType='deleteProject'
		// const data={
		// 	projectName:'Launch video v7' // project name
		// }

		//// Move to team library
		// const toastType='moveToTeamLibrary'
		// const data={
		// 	projectName: 'Announcement Video',
		// 	orgName:'Faire' // team name
		// }

		//// Add to single collection 		
		// const toastType='addToSingleCollection'
		// const data={
		// 	projectName: 'Settings Demo',
		// 	collectionName:'Spring Campaign',
		// 	collectionColor:'purple',
		// }

		//// Add to two collections (can skip if tricky!)
		// const toastType='addToTwoCollections'
		// const data={
		// 	projectName: 'Settings Demo',
		// 	collection1Name:'Spring Campaign',
		// 	collection1Color:'purple',
		// 	collection2Name:'iOS Help',
		// 	collection2Color:'blue',
		// }

		//// Add to more than two collections
		// const toastType='addToMoreThanTwoCollections'
		// const data={
		// 	projectName: 'Settings Demo',			
		// }

		//// Remove from single collection 		
		//// remove only happens if haven't added to any collections
		// const toastType='removeFromSingleCollection'
		// const data={
		// 	projectName: 'Settings Demo',
		// 	collectionName:'Spring Campaign',
		// 	collectionColor:'purple',
		// }

		//// Remove from multiple collections
		//// remove only happens if haven't added to any collections
		// const toastType='removeFromMultipleCollections'
		// const data={
		// 	projectName: 'Settings Demo',			
		// }

		// Delete Colelction
		// const toastType='deleteCollection'
		// const data={			
		// 	collectionName:'Spring Campaign',
		// 	collectionColor:'purple',
		// }

		// Restore Item 
		const toastType='restoreProject'
		const data={
			projectName:'Launch video v7' // project name
		}
		createToastNotification(toastType,data)
	}

	const [showUpdatesPopover, setShowUpdatesPopover] = useState(false);

  const openUpdatesPopover = () => {
    if (!showUpdatesPopover) {
      setShowUpdatesPopover(true);
    }
    if (showUpdatesPopover) {    
      setShowUpdatesPopover(false);
    }
  };  


	const [openPopoverId, setOpenPopoverId] = useState(null);

	const handleNewCollection = () => {
		const newCollectionId =`${randomID()}`
		dispatch(createCollection(newCollectionId));
		setTimeout(() => {
        setOpenPopoverId(newCollectionId)
    }, 50);
	}


	const {libraryPageType,subPage,collectionId,collections} = props

	const hasCollections = collections && collections.length>0


	//const sortedCollections = sortBy(collections, collection => collection.title.toLowerCase())
	//collections with title "New Collection" go at the bottom with the most recent new collection at the bottom
	
	const sortedCollections = sortBy(collections, collection => {
		if (collection.title.toLowerCase() === 'untitled collection ') { //make sure tehr eis a space after collection 
		// For 'New Collection', use a high value to push it after other collections,
		// then sort by created_at in ascending order
		return [1, new Date(collection.created_at).getTime()];
		}
		// For other collections, use a lower value to keep them at the top,
		// then sort by title
		return [0, collection.title.toLowerCase()];
	})

		return (
			<div className='libraryPage-sideMenu'>

				<div className='libraryPage-sideMenu-yarnLogo'>
					<Icon name='yarnLogoNew' />
				</div>

				<div className='libraryPage-sideMenu-personalTab'>
					<div className='libraryPage-sideMenu-personalTab-leftContainer'>
						<div className='libraryPage-sideMenu-personalTab-name'>
							{userName}
						</div>
						<div className='libraryPage-sideMenu-personalTab-organization'>
							{orgNameLabel}
						</div>
					</div>
					<div className='libraryPage-sideMenu-personalTab-rightContainer'>						

						<Tooltip.Provider>
							<Tooltip.Root delayDuration={200}>
					      <Tooltip.Trigger asChild>
					        <button onClick={()=>{createNewProject(history)}} className='libraryPage-sideMenu-personalTab-newDraftBtn'>
					        	<div className='libraryPage-sideMenu-personalTab-newDraftBtn-inner'>
											<Icon name='composeMedium' />
										</div>
									</button>				     
					      </Tooltip.Trigger>   
					      <Tooltip.Portal>         
					        <Tooltip.Content side="right" className="tooltip tooltip--toggleBtn forceDarkTheme">
					          New Draft <span className='tooltip-light'>⌘N</span>
					        </Tooltip.Content>   
					      </Tooltip.Portal>                              				      
					    </Tooltip.Root>
				    </Tooltip.Provider>


					</div>
				</div>        
				
				<LibrarySideMenuTab 
					label='Library'
					iconName='yourlibrary'
					activeIconName='yourlibraryFill'
					onClick={()=>{history.push('/library')}}
					active={libraryPageType=='home'}
				/>

				<LibrarySideMenuTab 
					label='Your Drafts'
					iconName='drafts'
					activeIconName='draftsFill'
					onClick={()=>{history.push('/library/drafts')}}
					active={subPage=='drafts'}
				/>
				
				{/*}
				<div className='libraryPage-sideMenu-collectionsHeader'>
					<div className='libraryPage-sideMenu-collectionsHeader-label'>
						Collections
					</div>
					<button onClick={handleNewCollection} className='libraryPage-sideMenu-collectionsHeader-newBtn'>
						New Collection
					</button>
				</div>
				*/}

				<div className='libraryPage-sideMenu-smallVSpacer' />				
					<LibrarySideMenuTab 
						label={orgNameLabel}
						orgLibraryTab
						onClick={()=>{history.push('/library/org')}}
						active={subPage=='org'}
					/>


					
				{sortedCollections.map((collection)=>{
					return(
						<LibrarySideMenuCollectionTab 
							key={collection.id}
							title={collection.title}
							collection={collection}
							collectionColor={collection.color}
							onClick={()=>{history.push(`/library/collection/${collection.id}`)}}
							active={collectionId==collection.id}
							popoverOpen={openPopoverId === collection.id}
							setOpenPopoverId={setOpenPopoverId}
						/>

						)
				})}
				

					{hasCollections &&
						<div className='libraryPage-sideMenu-newCollectionBtnContainer'>
							<button onClick={handleNewCollection} className='libraryPage-sideMenu-newCollectionBtn'>
								New Collection
							</button>						
						</div>
					}

					{!hasCollections &&
						<div className='libraryPage-sideMenu-firstCollection'>
							<button onClick={handleNewCollection} className='libraryPage-sideMenu-firstCollection-btn'>
								Add Collection
							</button>
							<div className='libraryPage-sideMenu-firstCollection-explainer'>
								Collections are a lightweight way <br/>to organize your content
							</div>
						</div>
					}

					{/*      
  				<LibrarySideMenuTab 
					label='How Tos'          
					collection
					collectionColor="#6DB98F"
					onClick={()=>{history.push('/library/collection/1')}}
					active={collectionId==1}
				/>

				<LibrarySideMenuTab 
					label='Extras'
					collection
					collectionColor="#B979E8"
					onClick={()=>{history.push('/library/collection/2')}}
					active={collectionId==2}
				/>

				<LibrarySideMenuTab 
					label='Launches'          
					collection
					collectionColor="#72A8FF"
				/>

				<LibrarySideMenuTab 
					label='Sales'          
					collection
					collectionColor="#FF90C7"
				/>*/}

				<div className='libraryPage-sideMenu-collectionsFooter' />

				{/*}
				<LibrarySideMenuTab 
					label='Edit Themes'
					iconName='editTheme'
					activeIconName='editTheme'
				/>

				<LibrarySideMenuTab 
					label='Samples'
					iconName='samples'
					activeIconName='samplesFill'       
				/>

				<LibrarySideMenuTab 
					label='Tutorials'
					iconName='tutorials'       
					activeIconName='tutorialsFill'          
				/>
				*/}

				


				<div className='libraryPage-sideMenu-vSpacer' />        

				

				<LibrarySideMenuTab 
					label='Deleted'
					iconName='deleted'
					activeIconName='deletedFill'
					onClick={()=>{history.push('/library/deleted')}}
				/>


				{/*

			*/}
				<UpdatesPopover
          showModal={showUpdatesPopover}
          closeModal={() => setShowUpdatesPopover(false)}            
        >


          <div className='libraryPage-sideMenu-updatesTrigger' />
        </UpdatesPopover>



        <LibrarySideMenuTab 
						label='Updates'
						iconName='updates'      
						activeIconName='updatesFill' 
						onClick={openUpdatesPopover}
					/>
	


				
								
				<LibrarySideMenuTab 
					label='Settings'
					iconName='settings'  
					activeIconName='settingsFill'     
					onClick={()=>{history.push('#settings/subscription')}}   
					//onClick={showToastNotification}   
				/>

				
				{/*}
				<LibrarySideMenuTab 
					label='Join the Slack'
					iconName='slackIcon'  
					activeIconName='slackIcon'          
				/>
				
				{/*}

				<div className='libraryPage-sideMenu-tabContainer'>
					<button onClick={handleSettingsClick} className='libraryPage-sideMenu-tab'>
						<div className='libraryPage-sideMenu-tab-label'>
							Settings
						</div>
					</button>  
				</div>

				*/}        

				

			</div>

		);
}

export default LibrarySideMenu;
