const TranscriptChunkNodeSpec ={
    group: "block",
    attrs: {
        clipId:{default:'defaultID'},
        sceneId:{default:'defaultID'},
        //clipDuration:{default:0},
        chunkRefreshKey:{default:0},
        requiresUpdate:{default:true},
        //startTime:{default:0},
        transcriptChunkIndex:{default:0},
        transcriptChunkCount:{default:1}
    },
    content: "paragraph",
    // isolating:true,
  	toDOM(node) {return ['transcriptChunk',0]},
    parseDOM: [{tag: 'transcriptChunk'}],

}
    

 export default TranscriptChunkNodeSpec
 
