import React, { useState, useRef, useEffect } from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../../misc/Icon';

const StyleButton = ({ styleName, isActive, onClick }) => (
  <button
    className='selectCaptionsDropdown-styleBtn'
    data-active-state={isActive ? 'active' : 'inactive'}
    onClick={onClick} 
  >
    <div className='selectCaptionsDropdown-styleBtn-inner'>
      {styleName}
    </div>
  </button>
);

function SelectCaptionsPopover({ children, handleClosePopover, activeStyle, onStyleChange, handleGenerateSRTFile }) {
  const [isOpen, setIsOpen] = useState(false);
  const styleNames = ['Standard', 'Pop'];
  
  const handleStyleClick = (styleName) => {
    onStyleChange(styleName);
  };

  const handleClose = () => {
    setIsOpen(false);
    handleClosePopover();
  };

  return (
    <Popover.Root 
      open={isOpen}
      onOpenChange={(open) => {
        if (open) setIsOpen(true);
        // Do not close when clicking outside
      }}
      modal={false}
    >
      <Popover.Trigger asChild onClick={() => setIsOpen(true)}>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content           
          align="end"
          alignOffset={-4}
          sideOffset={-37}
          className='dropdownMenu selectCaptionsDropdown forceDarkTheme'
          onInteractOutside={(event) => event.preventDefault()} // Prevent closing when clicking outside
        >
          <div className='selectCaptionsDropdown-header'>  
            <div className='selectCaptionsDropdown-header-iconContainer'>                                    
              <Icon name='captionsOutlineRegular' />
            </div>
            <div className='selectCaptionsDropdown-header-label'>
              Captions
            </div>
            
            <div className='selectVoiceDropdown-header-hSpacer' />
            <button onClick={handleClose} className='selectVoiceDropdown-header-closeBtn'>
              <div className='selectVoiceDropdown-header-closeBtn-inner'>              
                <Icon name='crossSemibold' />
              </div>
            </button>            
          </div>
          <div className='selectVoiceDropdown-styleBtnList'>
            {styleNames.map((styleName) => (
              <StyleButton
                key={styleName}
                styleName={styleName}
                isActive={activeStyle === styleName}
                onClick={() => handleStyleClick(styleName)}
              />
            ))}
          </div>
          <div className='selectCaptionsDropdown-srtBtnContainer'>
            <button onClick={handleGenerateSRTFile} className='selectCaptionsDropdown-srtBtn'>
              Download SRT...
            </button>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default SelectCaptionsPopover;