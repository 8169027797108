import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import schema  from '../../../../../prosemirror/schema/editorSchema'
import TranscriptChunkNodeView from '../../../../../prosemirror/nodeViews/TranscriptChunkNodeView'
import SceneHeaderNodeView from '../../../../../prosemirror/nodeViews/SceneHeaderNodeView' 
import { baseKeymap } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
import { makeDocJson } from '../utils/makeDocJson'
import { updateTranscriptChunkAndSceneCounts } from '../utils/updateTranscriptChunkAndSceneCounts'
import {enterKeyHandler} from '../utils/enterKeyHandler'
import {tabKeyHandler} from '../utils/tabKeyHandler'
import {handlePaste} from '../utils/handlePaste'
import { inputRules } from 'prosemirror-inputrules';
import { insertSceneInputRule } from './inputRules/insertSceneInputRule'
import paragraphButtonsPlugin from '../../../../../prosemirror/plugins/paragraphButtonsPlugin'
import {cursorInsideNodePlugin} from '../../../../../prosemirror/plugins/cursorInsideNodePlugin'
import {showInlineControlsPlugin} from '../../../../../prosemirror/plugins/showInlineControlsPlugin'
import {randomID} from '../../../../../utils/randomID'

const SET_DOC_FOR_DEV_PANEL=false //show the transcript doc in dev panel

const getParsedChunks = (view) => { //for syncing the timeline with transcript nodes
  const {state} = view;
  let transaction = state.tr;
  let transcriptChunks=[]
  let sceneHeaders=[]
  state.doc.descendants((node, pos) => {
   if (node.type.name === "transcriptChunk") {
		const attrs = node.attrs

		const domNode = view.nodeDOM(pos);
		let dimensions = { top: 0, height: 0 };

		if (domNode) {
      dimensions = {
        top: domNode.offsetTop,
        height: domNode.offsetHeight
      };
    }
    

		let chunk={
			clipId:attrs.clipId,
			sceneId:attrs.sceneId,
			clipIndex:attrs.transcriptChunkIndex,
			textContent:node.textContent,
			clipIndex:attrs.transcriptChunkIndex,
			clipCount:attrs.transcriptChunkCount,
			dimensions:dimensions
		}
		transcriptChunks.push(chunk)
	}else if (node.type.name === "sceneHeader") {

		const domNode = view.nodeDOM(pos);
		let dimensions = { top: 0, height: 0 };

		if (domNode) {
      dimensions = {
        top: domNode.offsetTop,
        height: domNode.offsetHeight
      };
    }
   
			const attrs = node.attrs
			let chunk={
				sceneId:attrs.sceneId,
				sceneTitle:node.textContent || 'Untitled Scene',
				dimensions:dimensions,
				//sceneDuration:node.attrs.sceneDuration
			}
			sceneHeaders.push(chunk)
		}

  });
  return {transcriptChunks:transcriptChunks,sceneHeaders:sceneHeaders}
}


export class TranscriptProsemirrorManager {

	 constructor(setPMDocForDevMode, updateTimelineFromTranscript,playClipFromTranscript,recalculateAudioClip,addSceneFromTranscriptPanel,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk,handleCursorInsideTranscriptChunk) {
		this.setPMDocForDevMode = setPMDocForDevMode;
		this.updateTimelineFromTranscript=updateTimelineFromTranscript
		this.playClipFromTranscript=playClipFromTranscript
		this.recalculateAudioClip=recalculateAudioClip
		this.addSceneFromTranscriptPanel=addSceneFromTranscriptPanel
		this.handleMouseOverTranscriptChunk=handleMouseOverTranscriptChunk
		this.handleMouseLeaveTranscriptChunk=handleMouseLeaveTranscriptChunk
		this.handleCursorInsideTranscriptChunk=handleCursorInsideTranscriptChunk
		this.view = null;
	}


	init(container, voiceoverClips, scenes) {
		const doc = makeDocJson(voiceoverClips, scenes);
		const handleMouseOverTranscriptChunk =this.handleMouseOverTranscriptChunk
		const handleMouseLeaveTranscriptChunk = this.handleMouseLeaveTranscriptChunk

		if (this.view) {// If view exists, update the state
			this.view.updateState(EditorState.create({
				doc: doc,
				plugins: this.createPlugins(),
			}));
		} else {
			this.view = new EditorView(container, {
				state: EditorState.create({
				doc: doc,
				plugins: this.createPlugins(),
		}),
		nodeViews: {
			transcriptChunk: (node, view, getPos) => new TranscriptChunkNodeView(node, view, getPos,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk),
			sceneHeader: (node, view, getPos) => new SceneHeaderNodeView(node, view, getPos)
		},
		handlePaste:handlePaste,
		dispatchTransaction: this.dispatchTransaction.bind(this)
	});
	}
		if (SET_DOC_FOR_DEV_PANEL) {
			this.setPMDocForDevMode(this.view.state.doc);
		}
	}

	getInitialChunks(){
		if(this.view){
			const chunksArray = getParsedChunks(this.view)
			return chunksArray
		}
	}


	manuallyAddAudioClip(){
		const clipId=randomID()
		const {state} = this.view
		let {tr} = state
		let lastSceneId = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader") {
				lastSceneId = node.attrs.sceneId;
			} 
		});
		
		const newNode = schema.nodes.transcriptChunk.createAndFill({
			clipId: clipId,
			sceneId: lastSceneId,
		});

		const insertPos = state.doc.content.size;
		tr = tr.insert(insertPos, newNode);

		const newNodePos = insertPos + 1; // +1 to move inside the node
		tr = tr.setSelection(TextSelection.create(tr.doc, newNodePos));

		this.view.dispatch(tr);
		this.view.focus()
	}

	// updateSceneDurations(scenes){
	// 	const sceneDurations = new Map(scenes.map(scene => [scene.id, scene.duration]));
  // 	let tr = this.view.state.tr;
  // 	let updated = false;
	//   this.view.state.doc.descendants((node, pos) => {
	//     if (node.type.name === "sceneHeader") {
	//       const newDuration = sceneDurations.get(node.attrs.sceneId);
	//       if (newDuration !== undefined && newDuration !== node.attrs.sceneDuration) {
	//         const updatedAttrs = { ...node.attrs, sceneDuration: newDuration };
	//         tr.setNodeMarkup(pos, null, updatedAttrs);
	//         updated = true;
	//       }
	//     }
	//   });

	//   if (updated) {
	//     tr.setMeta('addToHistory', false);
	//     let newState = this.view.state.apply(tr);
	//     this.view.updateState(newState);

	//     if (SET_DOC_FOR_DEV_PANEL) {
	//       this.setPMDocForDevMode(this.view.state.doc);
	//     }
	//   }
	// }


	focusSceneHeader(sceneId) { //actually lets focus the first transcript chunk
		const { state, dispatch } = this.view;
		let foundPos = null;
		let foundNode = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader" && node.attrs.sceneId === sceneId) {
				foundPos = pos;
				foundNode = node;
				return false; // Stop the search
			}
		});
		if (foundPos !== null && foundNode) {
		


			// TEMP this puts cursor in the header this does the text in the header
			const start = foundPos + 2;
			//const end = start + foundNode.nodeSize - 1;
				const end = start
/////////////



			// //this for the first transcript chunk
			// const start = foundPos + foundNode.nodeSize+2;
			// const end =start



			const transaction = state.tr.setSelection(TextSelection.create(state.doc, start, end));
			dispatch(transaction);
			this.view.focus();
		} else {
			console.log(`Scene header with sceneId ${sceneId} not found.`);
		}
	}

	createPlugins() {
		return [
			keymap({
				"Shift-Enter": this.regenClip,
				"Mod-Enter": this.playClip,
				"Enter": enterKeyHandler(),
				"Tab":tabKeyHandler()
			}),
			keymap(baseKeymap),
			//paragraphButtonsPlugin,
			cursorInsideNodePlugin(this.handleCursorInsideTranscriptChunk),
			//showInlineControlsPlugin(),
			inputRules({
				rules: [insertSceneInputRule(this.addSceneFromTranscriptPanel)],
			}),
		];
	}

	regenClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.recalculateAudioClip(clipId)
		} 
	}

	playClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.playClipFromTranscript(clipId)
		} 
	}


	updateRequiresUpdateStatus(id,requiresUpdate){
		if(this.view){
			let tr = this.view.state.tr
			this.view.state.doc.descendants((node, pos) => {
			  if (node.type.name === "transcriptChunk" && node.attrs.clipId==id) {
					const updatedAttrs = { ...node.attrs, requiresUpdate:requiresUpdate };
					tr.setNodeMarkup(pos, null, updatedAttrs);
				}
			});
			tr.setMeta('addToHistory', false)
			let newState = this.view.state.apply(tr);
			this.view.updateState(newState);
			// this.editorState = this.editorState.apply(tr);
			if(SET_DOC_FOR_DEV_PANEL){
				this.setPMDocForDevMode(this.view.state.doc);
			}
		}
	}

	updateTranscriptFromTimeline(voiceoverClips, scenes) {
		const { state } = this.view;
		const { from, to } = state.selection;
		const currentSelectionPos = { from, to }
		const container = null;
		this.init(container, voiceoverClips, scenes);
		const newState = this.view.state;
		let newFrom = Math.min(currentSelectionPos.from, newState.doc.content.size);
		let newTo = Math.min(currentSelectionPos.to, newState.doc.content.size);
		const transaction = newState.tr.setSelection(TextSelection.create(newState.doc, newFrom, newTo));
		this.view.dispatch(transaction);
		this.view.focus();
	}

	dispatchTransaction(transaction) {
		let newState = this.view.state.apply(transaction);
		this.view.updateState(newState);
		if (transaction.docChanged) { 
			const updateTransaction = updateTranscriptChunkAndSceneCounts(newState);
			if(updateTransaction.docChanged){
				newState = this.view.state.apply(updateTransaction);
				this.view.updateState(newState);
			}
			const chunksArray = getParsedChunks(this.view)

			this.updateTimelineFromTranscript(chunksArray);
			if (SET_DOC_FOR_DEV_PANEL) {
				this.setPMDocForDevMode(newState.doc);
			}
		}
	}

	destroy() {
		if (this.view) {
			this.view.destroy();
			this.view = null;
		}
	}
}

//TODO how to handle delete the only voicer clip in a scene? 

// updateTranscriptFromTimeline(voiceoverClips,scenes){
// 	const container = null
// 	 this.init(container, voiceoverClips, scenes);
// }


// calculateInsertPosition(state, clipIndex) {
// 	let position = state.doc.content.size; // Default to end if no better position is found
// 	state.doc.descendants((node, pos) => {
// 		if (node.type.name === "transcriptChunk" && node.attrs.transcriptChunkIndex > clipIndex) {
// 			position = pos-1;
// 			return false; // Found the position, stop searching
// 		}
// 	});
// 	return position;
// }

// function findPositionForNewNode(clip, doc) {
// 	let position = 0;
// 	let found = false;
// 	doc.descendants((node, pos) => {
// 		if (!found && node.type.name === "transcriptChunk" && node.attrs.transcriptChunkIndex > clip.clipIndex) {
// 			position = pos;
// 			found = true;
// 			return false; // Stop the search
// 		}
// 	});
// 	return found ? position : doc.content.size; // Insert at the found position or at the end if no suitable position was found
// }



// const customArrowHandler = (dir) => {
// 	return (state, dispatch, view) => {
// 		const newPos = calculateNewPosAfterArrowKey(state, dir);
// 		if (newPos !== null) {
// 			const transaction = state.tr.setSelection(TextSelection.near(state.doc.resolve(newPos)));
// 			dispatch(transaction);
// 			return true;
// 		}
// 		return false;
// 	}
// }

