import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import DPSelectMenu from './DPSelectMenu';

const EditorDetailPanelVideo = (props) => {

  const { trimMode, toggleTrimMode} = props

  const handleToggleTrimMode = () => {
    toggleTrimMode();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'l') {
        handleToggleTrimMode();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



  
  const [stylesDetail, showStylesDetail] = useState(false);

  const [volume, setVolume] = useState(100);
  const [shadowStyle, setShadowStyle] = useState('none');
  const [videoType, setVideoType] = useState('clip');

  const initialImageWidth = 160;
  const initialImageHeight = 90;
  const aspectRatio = 16 / 9; // 1.6 for 16:9 aspect ratio

  const [imageWidth, setImageWidth] = useState(initialImageWidth);
  const [imageHeight, setImageHeight] = useState(initialImageHeight);

  const [cornerRounding, setCornerRounding] = useState(3);

  const [isBGVideo, setIsBGVideo] = useState(false);

  let volumeIcon;
  if (volume === 0) {
    volumeIcon = 'speaker-muted';
  } else if (volume > 0 && volume <= 25) {
    volumeIcon = 'speaker-wave-0';
  } else if (volume > 25 && volume <= 90) {
    volumeIcon = 'speaker-wave-1';
  } else if (volume > 90 && volume <= 125) {
    volumeIcon = 'speaker-wave-2';
  } else if (volume > 125) {
    volumeIcon = 'speaker-wave-3';
  }

  const shadowStyles = [
    { value: 'none', label: 'None' },
    { value: 'standard', label: 'Standard' },    
    { value: 'soft', label: 'Soft' },
  ];

  const videoTypes = [
    { value: 'clip', label: 'Clip' },
    { value: 'slide', label: 'Slide' },    
  ];

  const handleWidthChange = (newWidth) => {
  setImageWidth(prevWidth => {
    const updatedWidth = typeof newWidth === 'function' ? newWidth(prevWidth) : newWidth;        
    setImageHeight(Math.round(updatedWidth / aspectRatio));    
    return updatedWidth;
  });
};

const handleHeightChange = (newHeight) => {
  setImageHeight(prevHeight => {
    const updatedHeight = typeof newHeight === 'function' ? newHeight(prevHeight) : newHeight;        
    setImageWidth(Math.round(updatedHeight * aspectRatio));    
    return updatedHeight;
  });
};

  let label = 'Video'
  if(isBGVideo){
    label = 'Background Video'
  }
  if(videoType === 'clip'){
    label = 'Video Clip'
  }

  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          {label}
        </div>
        <div className='editor-detailPanel-header-vSpacer' />        
        <button 
          onClick={() => showStylesDetail(!stylesDetail)}
          data-active-state={stylesDetail}
          className='dpButton dpButton--iconOnly'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='showDetails' />
          </div>
        </button>
      </div>
      
      <div className='editor-detailPanel-row'>
        <DPNumberInput 
          min={0}
          max={1600}          
          value={isBGVideo ? 1920 : imageWidth}
          setValue={handleWidthChange}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='W'          
          disabled={isBGVideo}
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={0}
          max={1600}               
          value={isBGVideo ? 1080 : imageHeight}
          setValue={handleHeightChange} 
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='H'
          disabled={isBGVideo}
          width={79}
        />
        <div className='editor-detailPanel-row-hSpacer' />
        <button 
          onClick={() => setIsBGVideo(!isBGVideo)}
          data-active-state={isBGVideo}
          className='dpButton dpButton--iconOnly dpButton--mid dpButton--backgroundImage'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='backgroundImage' />
          </div>
        </button>
      </div>

      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <button 
          onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Edit Trim
          </div>
        </button>
      </div>
      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Volume
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={0}
          max={150}
          value={volume}
          setValue={setVolume}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName={volumeIcon}
        />
      </div>     

      {!isBGVideo && stylesDetail &&
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Corner Rounding
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput 
              min={0}
              max={1000}
              value={cornerRounding}
              setValue={setCornerRounding}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='cornerRadius'              
            />
          </div>

          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Shadow
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPSelectMenu
              width={89}
              value={shadowStyle}
              onChange={setShadowStyle}
              options={shadowStyles}
            />
          </div>
        </>
      }
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Video Type
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPSelectMenu
          value={videoType}
          onChange={setVideoType}
          options={videoTypes}
        />
      </div>
      {videoType === 'clip' &&
        <> 
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Background
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            
          </div>
        </>
      }
    </>
  );
};

export default EditorDetailPanelVideo;