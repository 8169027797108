import {TextSelection} from "prosemirror-state"
import {estimateAudioDuration} from '../../../../../utils/estimateAudioDuration'
import {randomID} from '../../../../../utils/randomID'

export const enterKeyHandler = () => {
	return (state, dispatch, view) => {
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		let sceneHeaderNode = null
		let nodePos
		let tr = state.tr
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
			if (node.type.name === 'transcriptChunk') {
				transcriptChunkNode = node;
				nodePos=pos
				return false
			}
			if (node.type.name === 'sceneHeader') {
				sceneHeaderNode = node;
				nodePos=pos
				return false
			}
		})
		if (transcriptChunkNode) {
			const belowClipId = transcriptChunkNode.attrs.clipId;
			const relativePos = $from.pos - nodePos - 2;
			const textBeforeCursor = transcriptChunkNode.textContent.slice(0, relativePos).trim()
			const textAfterCursor = transcriptChunkNode.textContent.slice(relativePos).trim()

			let newOriginalClipPara = textBeforeCursor ?
			state.schema.nodes.paragraph.createAndFill(null, [state.schema.text(textBeforeCursor)]) :
			state.schema.nodes.paragraph.createAndFill();

			let newClipPara = textAfterCursor ?
			state.schema.nodes.paragraph.createAndFill(null, [state.schema.text(textAfterCursor)]) :
			state.schema.nodes.paragraph.createAndFill();

			const newClipId=randomID()
			const estimatedDuration = estimateAudioDuration(textAfterCursor)/1000

			let newOriginalTranscriptChunkNode = state.schema.nodes.transcriptChunk.createAndFill({...transcriptChunkNode.attrs}, [newOriginalClipPara]);

			let newTranscriptChunkNode = state.schema.nodes.transcriptChunk.createAndFill({clipId:newClipId,clipDuration:estimatedDuration}, [newClipPara]);

			tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, newOriginalTranscriptChunkNode);
			const insertPos = nodePos + newOriginalTranscriptChunkNode.nodeSize;
			tr.insert(insertPos, newTranscriptChunkNode);

			const newCursorPos = insertPos +2
			const newSelection = TextSelection.create(tr.doc, newCursorPos);
			tr.setSelection(newSelection);
			dispatch(tr);
		}else if(sceneHeaderNode){
			const newCursorPos = nodePos +sceneHeaderNode.nodeSize +2
			const newSelection = TextSelection.create(tr.doc, newCursorPos);
			tr.setSelection(newSelection);
			dispatch(tr);
		} 

	} 
}

