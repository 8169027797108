import React from 'react'
import {getNameForVoiceId} from '../../../../utils/voiceover/getNameForVoiceId'
import TranscriptPanelTopBar from './TranscriptPanelTopBar'
import TranscriptPanelDragHandleGutter from './TranscriptPanelDragHandleGutter'
import TranscriptPanelSceneHeaderGutter from './TranscriptPanelSceneHeaderGutter'
import isEqual from 'lodash/isEqual'

class TranscriptPanel extends React.Component{ 
	constructor(props) {
		super(props);   
		this.state = {
			isFocused: false,
			isDocEmpty: true,
			isDragging: false,
			draggingId: null,
			dragPosition: 0,
			scrollPosition: 0
		} 
		this.listRef = React.createRef();
	}


	shouldComponentUpdate(nextProps, nextState) {
		if (!isEqual(this.props.voiceoverClips, nextProps.voiceoverClips)) {
			return true;
		}

		const propsToCheck = ['activeVoice', 'transcriptChunkWithCursorInside', 'transcriptSceneHeaders', 'hoveredTranscriptChunk', 'previewingAudioClipId', 'activeVoiceoverPlaybackRate','showCaptions','subtitlesType'];

		for (let prop of propsToCheck) {
			if (this.props[prop] !== nextProps[prop]) {
				return true;
			}
		}
		if (!isEqual(this.state, nextState)) {
			return true;
		}
		// If we've made it here, no relevant props or state have changed
		return false;
	}



	
	 componentDidMount() {
    this.props.transcriptPmManager.init(document.querySelector("#editor"), this.props.voiceoverClips, this.props.scenes);
    this.listRef.current.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount(){
    this.props.transcriptPmManager.destroy();
    this.listRef.current.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    if (this.listRef.current) {
      this.setState({ scrollPosition: this.listRef.current.scrollTop });
    }
  }

	handleDragStateChange = (id, isDragging, yPosition) => {
		this.setState({
			isDragging,
			draggingId: isDragging ? id : null,
			dragPosition: yPosition
		});
	}

	render(){     
		const {voiceoverClips, activeVoice,transcriptChunkWithCursorInside, hideTranscriptPanel} = this.props
		const {isDragging, isDocEmpty, isFocused, draggingId, dragPosition, scrollPosition} = this.state
		const speakerName = getNameForVoiceId(activeVoice) 		
		const hasMoreThanOneChunk = voiceoverClips && voiceoverClips.length > 1 


		return (                  
			<div 
				data-dragging-state={isDragging ? 'dragging' : 'notDragging'} 
				className={`editor-transcriptPanel editor-transcriptPanel--transcript ${isDocEmpty ? 'editor-transcriptPanel--emptyTranscript' : 'editor-transcriptPanel--nonEmptyTranscript'} ${isDragging ? 'editor-transcriptPanel--isDragging' : ''}`}
			>  
				<TranscriptPanelTopBar 
					speakerName={speakerName}
					handleChangeActiveVoice={this.props.handleChangeActiveVoice}
					handleChangeVoiceoverPlaybackRate={this.props.handleChangeVoiceoverPlaybackRate}
					activeVoiceoverPlaybackRate={this.props.activeVoiceoverPlaybackRate}
					activeSpeakerVoice={activeVoice}
					hideTranscriptPanel={hideTranscriptPanel}
					//showCaptions={this.props.showCaptions}
					//toggleShowCaptions={this.props.toggleShowCaptions}
					//generateSubtitles={this.props.generateSubtitles}
					subtitlesType={this.props.subtitlesType}
					handleChangeSubtitlesType={this.props.handleChangeSubtitlesType}
					handleGenerateSRTFile={this.props.handleGenerateSRTFile}
					regenerateAllSubtitles={this.props.regenerateAllSubtitles}
				/>
				<div className='editor-transcriptPanel-listBG' />
				<div className='editor-transcriptPanel-list' ref={this.listRef}>  

					
					<TranscriptPanelSceneHeaderGutter 
						transcriptSceneHeaders={this.props.transcriptSceneHeaders}
						scenes={this.props.scenes}
						mergeScene={this.props.mergeScene}
					/>
					
					
					<TranscriptPanelDragHandleGutter 
						transcriptChunksForDragHandles={this.props.transcriptChunksForDragHandles}
						hoveredTranscriptChunk={this.props.hoveredTranscriptChunk}
						onDragStateChange={this.handleDragStateChange}
						isDragging={isDragging}
						draggingId={draggingId}
						dragPosition={dragPosition}
						handleTranscriptPanelChunkDrop={this.props.handleTranscriptPanelChunkDrop}
						transcriptChunkWithCursorInside={this.props.transcriptChunkWithCursorInside}
						voiceoverClips={voiceoverClips}
						playClipFromTranscript={this.props.playClipFromTranscript}
						recalculateAudioClip={this.props.recalculateAudioClip}
						scrollPosition={scrollPosition}
						previewingAudioClipId={this.props.previewingAudioClipId}
						cancelPreviewingAudioClip={this.props.cancelPreviewingAudioClip}
						addSceneAtEndOfProject={this.props.addSceneAtEndOfProject}
						addAudioClipAtEndOfProject={this.props.addAudioClipAtEndOfProject}
					/>        
					<div id={"editor"}/>														
				</div>
			</div>
		)
	}
}

export default TranscriptPanel