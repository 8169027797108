
import { InputRule } from 'prosemirror-inputrules';
import { schema } from '../../../../../../prosemirror/schema/editorSchema';
import {getAnchorNode} from '../../utils/getAnchorNode'



export function insertSceneInputRule(addSceneFromTranscriptPanel) {
  return new InputRule(/^#$/, (state, match, start, end) => {
    let {$from} = state.selection
    const anchorNode=getAnchorNode($from,'transcriptChunk')
    if(anchorNode){
      if(!anchorNode.textContent){
        const {attrs} = anchorNode
        const {clipId,sceneId,transcriptChunkIndex} = attrs
        addSceneFromTranscriptPanel(sceneId,clipId,transcriptChunkIndex)
      }
    }
  return null;
  });
}


















//stuff to turn audio clip into a scene header 
// import { InputRule } from 'prosemirror-inputrules';
// // import { schema } from '../../../../../../prosemirror/schema/editorSchema';
// import schema  from '../../../../../../prosemirror/schema/editorSchema'
// import { getAnchorNode } from '../../utils/getAnchorNode';

// export function insertSceneInputRule(addSceneFromTranscriptPanel) {
//   return new InputRule(/^# $/, (state, match, start, end) => {
//     let { $from } = state.selection;
//     const anchorNode = getAnchorNode($from, 'transcriptChunk');

//     if (anchorNode) {
//       const { attrs, textContent } = anchorNode;
//       const { clipId, sceneId, transcriptChunkIndex } = attrs;
//       let title = textContent.trim();
//       if (title.startsWith('#')) {
//         title = title.slice(1).trim();
//       }
//       addSceneFromTranscriptPanel(sceneId, clipId, transcriptChunkIndex, title);


//       // Create a new transaction to update the document
//       const tr = state.tr;
      
//       let pos = $from.start(0);
      
//       for (let i = 0; i < state.doc.childCount; i++) {
//         const child = state.doc.child(i);
//         if (child === anchorNode) {
//           break;
//         }
//         pos += child.nodeSize;
//       }
      
//       // Replace the content of the transcript chunk with an empty paragraph
//       const emptyNode = schema.nodes.paragraph.create();
//       tr.replaceWith(pos, pos + anchorNode.nodeSize, emptyNode);
      

//       return tr;
//   }

//   return null;
//   });
// }