import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { useControls, button } from 'leva';
import * as THREE from 'three';
import SlideDonutChart from './SlideDonutChart';
import { getOrgBrand } from '../../../../utils/brands/getOrgBrand'
import find from 'lodash/find'
import { getBackgroundForId } from '../../../../utils/brands/getBackgroundForId'
import {getTextColorForBackgroundId} from '../../../../utils/brands/getTextColorForBackgroundId'
import {getChartColorForBackgroundId} from '../../../../utils/brands/getChartColorForBackgroundId'
import renderOrders from '../../../renderOrders'


const SlideDonutChartContainer = ({ time, element, showChartStatic, showChartAnimated, projectBackground, slideBackgroundId, slideStartTime}) => {
	const orgBrand=getOrgBrand()
	
	const chartData = element.metadata.donutChartData
	const animationType = element.metadata.donutChartData.animationType
	const motionStyle = element.metadata.donutChartData.motionStyle


	const outOfRange = time < element.startTime || time > (element.startTime + element.duration)	
	const localTime = time - element.startTime;
	const isAnimatedHidden = outOfRange || !showChartAnimated

	const outOfSlideRange = time < slideStartTime || time > (element.startTime + element.duration)
	const isPreviewHidden = outOfSlideRange || !showChartStatic		


	const fontFamily = orgBrand.font.fontFamily
	const fontWeight = "Bold"
	const chartLabelFontWeight = "Medium"

	
  let chartBackground = projectBackground
	const isNoneBGColor = slideBackgroundId =='none'

	if(slideBackgroundId && !isNoneBGColor){
  	chartBackground=getBackgroundForId(slideBackgroundId)
	}

	let chartColorId = element.metadata.donutFillColorId
	
	if(!element.metadata.donutFillColorId){ // auto
		chartColorId = getChartColorForBackgroundId(chartBackground.id)				
	}

	// chartColorId id ether a brand bg color (not image) or chart colors
	// that to a real rgba value

	let chartsDonutBackground = getBackgroundForId(chartColorId).rgba
	
	if(chartColorId === 'white'){
		chartsDonutBackground = 'rgba(255,255,255)'
	}


	//let chartsTextColor = orgBrand.textColors[0].rgba
	const chartsTextColor=getTextColorForBackgroundId(chartBackground.id).rgba



	// more advanced handling in future for charts colors
	let numberValueTextColor = chartsTextColor
	let numberLabelTextColor = chartsTextColor
	let donutValueTextColor = chartsTextColor
	let donutLabelTextColor = chartsTextColor
	let donutFillColor = chartsDonutBackground

	// console.log(element.metadata)

	let donutPartRingMaxOpacity = 0.5
	if(element.metadata.donutOpacity){
		donutPartRingMaxOpacity = element.metadata.donutOpacity
	}
	
	let donutFullRingMaxOpacity = 0.2



	return (
		<>
		
			{chartData && chartData.value && !isAnimatedHidden &&				
				<SlideDonutChart
					isHidden={false}						
					targetValue={chartData.value.number}	          
          //suffix={chartData.value.suffix?chartData.value.suffix:null}
          suffix='%' // for now, can handle fancy stuff later
          preLabel={chartData.aboveLabel}
          postLabel={chartData.belowLabel}
          time={localTime}
          animationType={element.metadata.donutChartData.animationType}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          donutValueTextColor={donutValueTextColor}
          donutLabelTextColor={donutLabelTextColor}
          donutFillColor={donutFillColor}
          donutPartRingMaxOpacity={donutPartRingMaxOpacity}
          donutFullRingMaxOpacity={donutFullRingMaxOpacity}

          chartWidth={element.width}
          chartHeight={element.height}
          chartX={element.x}
          chartY={element.y}
				/>			
			}
			{chartData && chartData.value && !isPreviewHidden &&
				<SlideDonutChart
					isHidden={false}						
					targetValue={chartData.value.number}	          
          //suffix={chartData.value.suffix?chartData.value.suffix:null}
          suffix='%' // for now, can handle fancy stuff later
          preLabel={chartData.aboveLabel}
          postLabel={chartData.belowLabel}
          time={localTime}
          animationType={'none'}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          donutValueTextColor={donutValueTextColor}
          donutLabelTextColor={donutLabelTextColor}
          donutFillColor={donutFillColor}
          donutPartRingMaxOpacity={donutPartRingMaxOpacity}
          donutFullRingMaxOpacity={donutFullRingMaxOpacity}

          chartWidth={element.width}
          chartHeight={element.height}
          chartX={element.x}
          chartY={element.y}
				/>					
			}



			
			
			
  
		</>
	);
};

export default SlideDonutChartContainer;