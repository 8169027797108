const WebcamClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'webcam'},
		isUploadingVideo:{default:false},
		videoId:{default:null},
		fileName:{default:null},
		name:{default:null},
		relativeStartTime: {default:0},
		duration:  {default:0},
		metadata: { default: [] },
		zIndex:  {default:0},
		sceneId:{default:'defaultSceneId'},
		clipPlaybackRate:{default:1}
	},
};

 export default WebcamClipNodeSpec
 

