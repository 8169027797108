import {fetch,batchedFetch,post,put} from '../api'
import axios from 'axios'
import {downloadMissingWebcamVideos} from '../utils/assets/downloadMissingWebcamVideos'
import * as Sentry from '@sentry/electron/renderer'


export function fetchWebcamVideos(){
  return (dispatch) => fetch(`/webcam-video`)
  .then((response) => {
  //  dispatch({ type: 'FETCH_WEBCAM_VIDEOS_SUCCESS', response })
   // downloadMissingWebcamVideos(response)
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}


function calculateDisplayWidth(originalWidth, originalHeight) {
  const maxWidth = 1600;
  const maxHeight = 900;
  let displayWidth = originalWidth;
  let displayHeight = originalHeight;
  if (originalWidth <= maxWidth && originalHeight <= maxHeight) { //Dont scale up
    return  displayWidth
  }
  const originalAspectRatio = originalWidth / originalHeight;
  const targetAspectRatio = maxWidth / maxHeight;
  if (originalAspectRatio > targetAspectRatio) {
    displayWidth = maxWidth;
    displayHeight = maxWidth / originalAspectRatio;
  } else {
    displayHeight = maxHeight;
    displayWidth = maxHeight * originalAspectRatio;
  }
  return Math.round(displayWidth)
}



const CHUNK_SIZE = 10 * 1024 * 1024; 

export const uploadWebcamVideoAndHandleResponse = (file, captureId) => async (dispatch) => {
  try {
    const uniqueUploadId = `${captureId}-${Date.now()}`;
    const signatureResponse = await post('/webcam-video', { public_id: captureId});
    const { apiKey, timestamp, signature } = signatureResponse;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    
    const uploadChunks = async () => {
      let uploadResponse;
      for (let i = 0; i < totalChunks; i++) {
        uploadResponse = await uploadChunk(
          file, captureId,i, CHUNK_SIZE, file.size, uniqueUploadId, 
          apiKey, timestamp, signature
        );
      }
      return uploadResponse;
    };
    
    const uploadResponse = await uploadChunks();
   
    console.log('upload respnse is ')
    console.log(uploadResponse)
    const {width,height,original_filename,public_id,asset_id,url,format,version} = uploadResponse;

    console.log(`upload response width ${width}`)
    console.log(`uploadresponse height ${height}`)
   
    const display_width = calculateDisplayWidth(uploadResponse.width, uploadResponse.height);
    console.log(`display width ${display_width}`)
   
    const reqBody = {
      original_filename: uploadResponse.original_filename, 
      public_id: uploadResponse.public_id, 
      format: uploadResponse.format, 
      original_height: uploadResponse.height, 
      original_width: uploadResponse.width,
      asset_id: uploadResponse.asset_id,
      version: uploadResponse.version,
      display_width: display_width,
      duration: uploadResponse.duration
    };

    const updateVideoObjResponse = await put(`/webcam-video/${captureId}/complete`, reqBody);

    return updateVideoObjResponse;
  } catch (error) {
    console.error('Error during video upload process:', error);
    error.name = 'Video upload error';
    Sentry.captureException(error);
  }
};


const uploadChunk = async (file, fileId, index, chunkSize, totalFileSize, uniqueUploadId, apiKey, timestamp, signature) => {
   const start = index * chunkSize;
  const end = Math.min(start + chunkSize, totalFileSize);
  const blob = file.slice(start, end);
  const contentRange = `bytes ${start}-${end - 1}/${totalFileSize}`;
  
  const headers = {
    "X-Unique-Upload-Id": timestamp,
    "Content-Range": contentRange,
  };

  const form = new FormData();
    form.append("upload_large", true);
    form.append('file', blob);
    form.append("upload_preset",'webcam_video')
    form.append('api_key', apiKey);
    form.append('timestamp', timestamp);
    form.append('signature', signature);
    form.append('public_id', fileId);
    
    console.log('Uploading chunk:', { index, start, end, contentRange });

  try {
    delete axios.defaults.headers.common['Cache-Control'];
    delete axios.defaults.headers.common['Pragma'];
    delete axios.defaults.headers.common['Expires'];

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/yarn/upload`,
      form,
      { headers }
    );
    console.log('Chunk upload response:', response.data);

    axios.defaults.headers.common['Cache-Control']='no-cache'
    axios.defaults.headers.common['Pragma']='no-cache'
    axios.defaults.headers.common['Expires']='0'
    return response.data;
  } catch (error) {
    console.error('Error uploading chunk:', error.response ? error.response.data : error.message);
    throw error;
  }
};

