import React from 'react'
import TranscriptChunkNodeSpec from './TranscriptChunkNodeSpec'
import SceneHeaderNodeSpec from './SceneHeaderNodeSpec'

//https://github.com/ProseMirror/prosemirror-schema-basic/blob/master/src/schema-basic.js
const pDOM = ["p", 0], blockquoteDOM = ["blockquote", 0], hrDOM = ["hr"],
      preDOM = ["pre", ["code", 0]], brDOM = ["br"]

export const nodes = {
  doc: {
   // content: "sceneHeader+ transcriptChunk+"
    content: "(sceneHeader transcriptChunk+)+"
  },
  paragraph: {
    attrs:{
     // indentLevel:{default:0},
    },
    content: "inline*",
    group: "block",
    parseDOM: [
      {
        tag: "p"
      },
    ],
    toDOM(node) {
      const {nodeId,indentLevel} = node.attrs;
      return ["div",{class:`doc-para`}, 0]
    }},
  transcriptChunk:TranscriptChunkNodeSpec,
  sceneHeader:SceneHeaderNodeSpec,
  text: {
    group: "inline"
  },
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    parseDOM: [{tag: "br"}],
    toDOM() { return brDOM }
  }
}

export default nodes