import React, { useState, useEffect } from 'react';

const AddFromLibraryDialogGridVideo = ({ mediaItem }) => {
	
	return (
		<>
			{mediaItem.type === 'basicVideo' &&
				<img                   
					src={`https://res.cloudinary.com/yarn/video/upload/so_1,h_460/${mediaItem.cloudinaryId}.webp`} 
					className="addFromLibraryDialog-grid-item-preview-image"
				/>
			}                
			
			{mediaItem.type === 'recording' &&
				<img                           
					src={`https://res.cloudinary.com/yarn/video/upload/so_1,h_460/${mediaItem.cloudinaryId}.webp`} 
					className="addFromLibraryDialog-grid-item-preview-image"
				/>                        
			}

			{mediaItem.type === 'deviceRecording' &&
				<img                   
					src={`https://res.cloudinary.com/yarn/video/upload/so_1,h_460/${mediaItem.cloudinaryId}.webp`} 
					className="addFromLibraryDialog-grid-item-preview-image"
				/>
			}

			{mediaItem.type === 'screenRecording' &&
				<img                   
					src={`https://res.cloudinary.com/yarn/video/upload/so_1,h_460/${mediaItem.cloudinaryId}.webp`} 
					className="addFromLibraryDialog-grid-item-preview-image"
				/>
			}


			</>
	);
}


export default AddFromLibraryDialogGridVideo;