import React, { useState, useMemo } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import AddFromLibraryDialogHeader from './AddFromLibraryDialogHeader';
import AddFromLibraryDialogGrid from './AddFromLibraryDialogGrid';
import Measure from 'react-measure';
import { getAssetLibrary } from '../../../../utils/getAssetLibrary';
import { filter } from 'lodash';

function AddFromLibraryDialog({ children, handleClosePopover, showPopover ,insertImageFromRecent,insertVideoFromRecent,addRecordingFromVideoLibrary}) {
  const [measurements, setMeasurements] = useState({ width: 0 });
  const [assets, setAssets] = useState([]);
  const [layoutType, setLayoutType] = useState('grid');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [activeTypeTab, setActiveTypeTab] = useState('everything');

  const [recentAssets, setRecentAssets] = useState([]);

  const handleOpenDialog = async () => {
    const fetchedAssets = await getAssetLibrary();
    const sortedAssets = fetchedAssets.sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    // Take only the first 100 assets
    //const recentAssets = sortedAssets.slice(0, 200);
    const recentAssets = sortedAssets

    setAssets(fetchedAssets);
    setRecentAssets(recentAssets)
  };

  const closeDialog = () => {
    handleClosePopover();
  };

  const filteredAssets = useMemo(() => {
    // Filter out assets without dimensions or cloudinaryId
    const validAssets = filter(recentAssets, asset => 
      typeof asset.width === 'number' && 
      typeof asset.height === 'number' && 
      asset.width > 0 && 
      asset.height > 0 &&
      typeof asset.cloudinaryId === 'string' &&
      asset.cloudinaryId.length > 0
    );

    // Then apply the type filtering
    if (activeTypeTab === 'everything') {
      return validAssets;
    }

    return filter(validAssets, (asset) => {
      switch (activeTypeTab) {
        case 'screenClip':
          return ['recording', 'screenRecording', 'deviceRecording'].includes(asset.type);
        case 'videoClip':
          return asset.type === 'basicVideo';
        case 'image':
          return asset.type === 'image';
        default:
          return true;
      }
    });
  }, [recentAssets, activeTypeTab]);


  const insertFromLibrary=(mediaItem)=>{
    if(mediaItem.type=='image'){
      insertImageFromRecent(mediaItem.libraryObj)
    }else if(mediaItem.type=='basicVideo'){
      console.log(mediaItem.libraryObj)
      insertVideoFromRecent(mediaItem.libraryObj)
    }else{
      const isDevice = mediaItem.type=='deviceRecording'?true:false 
      const isScreenRecording = mediaItem.type=='screenRecording'?true:false
      addRecordingFromVideoLibrary(mediaItem.captureId,isDevice,isScreenRecording)
    }
    closeDialog()
  }



  return (
    <Dialog.Root
      open={showPopover}
      onOpenChange={(open) => {
        if (!open) {
          closeDialog();
        }
      }}
    >
      <Dialog.Trigger asChild onClick={handleOpenDialog}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="addFromLibraryDialog-overlay" />
        <Dialog.Content className="addFromLibraryDialog forceDarkTheme">
          <AddFromLibraryDialogHeader             
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            layoutType={layoutType}
            setLayoutType={setLayoutType}
            activeTypeTab={activeTypeTab}
            setActiveTypeTab={setActiveTypeTab}
          />
          
          
          {layoutType === 'grid' &&          
            <AddFromLibraryDialogGrid 
              media={filteredAssets}
              rowWidth={measurements.width}
              insertFromLibrary={insertFromLibrary}
            />          
          }
                      
          <Measure
            bounds
            onResize={(contentRect) => {
              setMeasurements(contentRect.bounds);
            }}
          >
            {({ measureRef }) => (
              <div className="addFromLibraryDialog-measure" ref={measureRef} />
            )}
          </Measure>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default AddFromLibraryDialog;