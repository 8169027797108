import {TimelineClip} from './TimelineClip'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import find from 'lodash/find'
import {fetchWebcamVideo} from '../three/utils/webcamVideos/fetchWebcamVideo'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'

const defaultColorAdjustments={
  exposure:0,
  highlights:0,
  shadows:0,
  brightness:0,
  contrast:0,
  saturation:0,
  whiteBalance:0
}

class WebcamClip extends TimelineClip {

	constructor(options,scene,handleVideoClipLoaded,updateVideoClipSegments){
    super(options,scene)
		this.video = document.createElement('video'); 
    this.video.muted=false
    this.isUploadingVideo=options.isUploadingVideo || false
    this.videoId=options.videoId
    this.segments=options.segments || []
    this.recordingSegments = options.recordingSegments||[]
    this.hasSegments = this.segments.length
    this.hasRecordingSegments = this.recordingSegments.length
    this.clipPlaybackRate = options.clipPlaybackRate || 1

    this._handleVideoClipLoaded=handleVideoClipLoaded
    this.updateVideoClipSegments=updateVideoClipSegments

    if(!this.isUploadingVideo){
       this.initalizeWebcamVideo()
    }
    if(!this.metadata.colorAdjustments){
      this.metadata.colorAdjustments=defaultColorAdjustments
    }
  }

   async calculateRecordingSegments(captureId){
      const segment={
        id: 0,
        originalDuration: this.recordingDuration,
        originalStart: 0,
        originalEnd:this.recordingDuration ,
        isQuiet: false,
        playbackRate:1,
        newStart:0,
        newEnd: this.recordingDuration,
        newDuration: this.recordingDuration,
        timeStretch:1
      }
      const segments = [segment]
      this.recordingSegments=segments
      this.calculateTrimmedSegments()   
  }

  async finishUpload(){
    await this.initalizeWebcamVideo()
   // this.isUploadingVideo = false
  }


 async initalizeWebcamVideo() {
    const videoUrl = `https://res.cloudinary.com/yarn/video/upload/webcamVideos/${this.videoId}.mp4`
    this.videoUrl = videoUrl
    this.video.src = videoUrl
    this.video.crossOrigin = 'anonymous' // Add this line to enable CORS
    this.video.preload = 'auto'
    this.video.playbackRate = this.clipPlaybackRate
    this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this))
  }

 
  updateClipPlaybackRate(playbackRate){
    this.clipPlaybackRate = playbackRate 
    this.video.playbackRate=this.clipPlaybackRate
    this.calculateTrimmedSegments()
  }

  updateSkipSegmentDuration(segmentId,newDuration,direction){
    const adjustedSegments =updateSkipSegmentDuration(this.recordingSegments,segmentId,newDuration,this.recordingDuration,direction)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }


  calculateTrimmedSegments(){ //apply trim and clip playback rate
    const isInitialLoad = this.segments.length==0
    this.segments = calculateTrimmedSegments(this.recordingSegments,this.metadata.trimStart,this.metadata.trimEnd,this.clipPlaybackRate)
    this.calculateDurationFromSegments()
    if(isInitialLoad){
       this.updateVideoClipSegments(this.id)
    }
   // this._handleVideoClipLoaded()
  }

   calculateDurationFromSegments() {
    let currentTime = this.metadata.trimStart;
    let totalDuration = 0;
    this.segments.forEach(segment => {
      totalDuration += segment.newDuration;
    });
    this.duration = totalDuration;
  }



  updateTrimValues(trimStartTime,trimEndTime){
    this.metadata.trimStart=trimStartTime
    this.video.currentTime=trimStartTime
    this.metadata.trimEnd=trimEndTime
    this.calculateTrimmedSegments()
  }



  // onMetadataLoaded() {
  //   console.log('on metadata loaded here-------------------')
  //   this.isUploadingVideo=false 
  //   this.recordingDuration = this.video.duration   
  //   console.log(`recording duration ${this.recordingDuration}`)
  //   if(!this.metadata.trimEnd){
  //     this.metadata.trimStart=0
  //     this.metadata.trimEnd=this.video.duration
  //   }
  //   this.video.currentTime = this.metadata.trimStart
  //   if(!this.hasSegments || !this.hasRecordingSegments){
  //     console.log('calculate recording segments')
  //     this.calculateRecordingSegments()
  //   }else{
  //   //  this._handleVideoClipLoaded()
  //   }
  // }

  onMetadataLoaded() { //DOnt use duration from this as it seems weird- maybe because of cloudinary streaming stuff
    // console.log('on metadata loaded here-------------------')
   
    this.recordingDuration = this.duration  
  //  console.log(`recording duration ${this.recordingDuration}`)
    if(!this.metadata.trimEnd){
      this.metadata.trimStart=0
      this.metadata.trimEnd=this.duration
    }
    this.video.currentTime = this.metadata.trimStart
    if(!this.hasSegments || !this.hasRecordingSegments){
   //   console.log('calculate recording segments')
      this.calculateRecordingSegments()
    }else{
    //  this._handleVideoClipLoaded()
    }
     this.isUploadingVideo=false 
  }

  playFromCurrentTime(currentTime) { //updated to take into account playback rates
  // console.log('PLAY WEBCAM CLIP')
   if(this.video && this.video.src){
    if (this.ended) {
      return
    }
    if (currentTime >= this.startTime && currentTime < this.endTime) {
      const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
      this.video.currentTime = videoTime;
      this.video.play();
    }
    }
  }

  pause() {
    this.video.pause();
  }

  seek(currentTime) {
   // console.log('seek webcam clip')
    this.ended = false
    if(this.video && this.video.src && !this.isUploadingVideo){
      if (currentTime >= this.startTime && currentTime < this.endTime) {
        const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
        this.video.currentTime = videoTime
     } else {
        this.video.pause(); // If the current time is outside the clip, pause it
      }
    }
  }

  ///colors and stuff
  setBackgroundColor(backgroundColorId){
    let backgroundColor
    if(!backgroundColorId){ //remove background
      backgroundColor = this.projectBackground
      this.metadata.isAutoBackgroundColor=true
    }else{
      backgroundColor = find(this.brandKit.backgrounds,{id:backgroundColorId})
      this.metadata.isAutoBackgroundColor=false
    }
    this.metadata.backgroundColor=backgroundColor
  }

  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      captureId:this.captureId,
      isDeviceRecording:this.isDeviceRecording,
      isUploadingVideo:this.isUploadingVideo,
      videoId:this.videoId,
      fileName:this.fileName,
      isBasicVideo:this.isBasicVideo,
      startTime:this._startTime,
      absoluteStartTime:this.startTime, //for server side export add this for audio- TODO maybe update ssr to calc based on scenes
      duration:this.duration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate
    };
    return json
  }

   


  destroy() {
    if (!this.video.paused) {
      this.video.pause();
    }
    this.video.removeEventListener('playing', this.onPlaying);
    this.video.removeEventListener('ended', this.onEnded);
    this.video.removeEventListener('loadedmetadata', this.onMetadataLoaded);
    this.video.src = '';
    this.video.load(); // Forces the release of the video buffer
    this.video = null;
    this.ended = false;
    this.captureId = null;
  }

}

export { WebcamClip }