import React, { useState, useEffect } from 'react';
import AddFromLibraryDialogGridVideo from './AddFromLibraryDialogGridVideo'
import { List, AutoSizer } from 'react-virtualized';

const ROW_HEIGHT = 260
const ITEM_FOOTER_HEIGHT = 30
const ITEM_OUTER_PADDING = 4
const ITEM_INNER_PADDING = 4

const mediaGallerySizing = (media, rowHeight, rowWidth, minItemWidth, maxItemWidth) => {
    if (!media || !media.length) return [];
    
    const footerHeight = ITEM_FOOTER_HEIGHT;
    const outerPadding = ITEM_OUTER_PADDING;
    const innerPadding = ITEM_INNER_PADDING;
    const totalPadding = (outerPadding + innerPadding) * 2;
    
    const availableHeight = rowHeight - totalPadding - footerHeight;
    
    const rows = [];
    let currentRow = [];
    let currentRowWidth = 0;

    media.forEach((item) => {
        const aspectRatio = item.width / item.height;
        let width = availableHeight * aspectRatio;

        if (width < minItemWidth) width = minItemWidth;
        if (width > maxItemWidth) width = maxItemWidth;

        if (currentRowWidth + width <= rowWidth) {
            currentRow.push({ 
                ...item, 
                calculatedWidth: width,
                originalAspectRatio: aspectRatio
            });
            currentRowWidth += width;
        } else {
            if (currentRow.length > 0) {
                // Process current row
                const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.calculatedWidth, 0);
                const scale = rowWidth / totalCurrentRowWidth;

                const scaledRow = currentRow.map(img => ({
                    ...img,
                    containerWidth: Math.floor(img.calculatedWidth * scale)
                }));

                rows.push(scaledRow);
            }

            // Start new row with current item
            currentRow = [{ 
                ...item, 
                calculatedWidth: width, 
                originalAspectRatio: aspectRatio 
            }];
            currentRowWidth = width; // Reset row width with current item's width
        }
    });

    // Handle the last row
    if (currentRow.length > 0) {
        const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.calculatedWidth, 0);
        const scale = rowWidth / totalCurrentRowWidth;

        const scaledRow = currentRow.map(img => ({
            ...img,
            containerWidth: Math.floor(img.calculatedWidth * scale)
        }));
        rows.push(scaledRow);
    }

    // console.log(rows)

    return rows;


};


const Row = ({ mediaItems, rowHeight,insertFromLibrary }) => {
  const outerPadding = ITEM_OUTER_PADDING;
  const innerPadding = ITEM_INNER_PADDING;
  const footerHeight = ITEM_FOOTER_HEIGHT;
  const totalPadding = (outerPadding + innerPadding) * 2;
  
  

  return (
    <div className="popover--addMediaPopover-list-row" style={{ height: `${rowHeight}px` }}>
      {mediaItems.map((mediaItem, index) => {
        const availableWidth = mediaItem.containerWidth - totalPadding;
        // Subtract footer height from available height
        const availableHeight = rowHeight - totalPadding - footerHeight;
        
        let itemWidth;
        let itemHeight;
        
        if (availableWidth / availableHeight > mediaItem.width / mediaItem.height) {
          itemHeight = availableHeight;
          itemWidth = itemHeight * (mediaItem.width / mediaItem.height);
        } else {
          itemWidth = availableWidth;
          itemHeight = itemWidth / (mediaItem.width / mediaItem.height);
        }

        {/*console.log(mediaItem)*/}

        const isVideo = mediaItem.type === 'basicVideo' || mediaItem.type === 'recording' || mediaItem.type === 'deviceRecording' || mediaItem.type=='screenRecording'

        return (
          <div 
            key={index} 
            style={{ 
              width: `${mediaItem.containerWidth}px`, 
              height: `${rowHeight}px`, 
              padding: `${outerPadding}px`
            }} 
            onClick={() => insertFromLibrary(mediaItem)} 
            className="addFromLibraryDialog-grid-itemOuterContainer"
          >
            <div 
              style={{
                padding: `${innerPadding}px`,                
                height: `${rowHeight - (outerPadding * 2)}px`,
                paddingBottom: `${innerPadding + footerHeight}px`,          
              }} 
              data-item-type={mediaItem.type}
              className="addFromLibraryDialog-grid-item"
            >
              <div 
                className="addFromLibraryDialog-grid-item-preview"
                style={{
                  width: `${itemWidth}px`,
                  height: `${itemHeight}px`,                  
                }}
              >
                {mediaItem.type === 'image' &&
                  <img 
                    src={`https://res.cloudinary.com/yarn/image/upload/h_460/${mediaItem.cloudinaryId}.png`} 
                    className="addFromLibraryDialog-grid-item-preview-image"
                  />
                }
                
                <AddFromLibraryDialogGridVideo
                  mediaItem={mediaItem}
                />
                
              </div>
              <div 
                className="addFromLibraryDialog-grid-item-footer"
                style={{
                  height: `${footerHeight}px`,                  
                }}
              >                
                {mediaItem.title}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function AddFromLibraryDialogGrid({ media, rowWidth,insertFromLibrary }) {
   const rowHeight = ROW_HEIGHT;
  const minItemWidth = 100;
  const maxItemWidth = 320;
  const [rows, setRows] = useState([]);

  useEffect(() => {
      if (media && media.length > 0) {
          const calculatedRows = mediaGallerySizing(
              media,
              rowHeight,
              rowWidth,
              minItemWidth,
              maxItemWidth
          );
          setRows(calculatedRows);
      }
  }, [media, rowWidth]);

  const rowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style}>
        <Row
          mediaItems={rows[index]}
          rowHeight={rowHeight}
          insertFromLibrary={insertFromLibrary}
        />
      </div>
    );
  };

  return (
     <div className="addFromLibraryDialog-scrollContainer" style={{ height: '100%' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
              width={width || rowWidth}
              height={height || 400} // Default height if AutoSizer parent has no height
              rowCount={rows.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              overscanRowCount={3} // Number of rows to render above/below visible area
              className="addFromLibraryDialog-grid"
          />
        )}
      </AutoSizer>
    </div>
  );
}


export default AddFromLibraryDialogGrid;