const blobCache = {};


export const fetchWebcamVideo = async (captureId) => {
  let videoURL
  const videoPath = `webcamVideos/${captureId}/video.mp4`;
  if (blobCache[videoPath]) {
    return blobCache[videoPath];
  } else {
    if(window.isElectron){
      const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
      const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
      videoURL = URL.createObjectURL(videoBlob);
      blobCache[videoPath] = videoURL;
      return videoURL;
    }else{
      return `https://res.cloudinary.com/yarn/video/upload/v1719086772/video_uploads/${fileName}`
    }
  }
};



